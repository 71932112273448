/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import classes from "./AppDownloadModal.module.css";
import BrowserLinks from "../../UI/Links/BrowserLinks/BrowserLinks";
import { getMobileAppDownloadLink } from "../../../util/util";

const AppDownloadModal = (props) => {
  useEffect(() => {
    window.$("#appDownloadModal").modal({
      backdrop: "static",
      keyboard: false
    });
    window.$("#appDownloadModal").modal("show");
  }, []);

  const closeHandler = () => {
    window.$("#appDownloadModal").modal("hide");
    props.close();
  };

  return (
    <div className={classes.AppDownloadModal}>
      <div
        id="appDownloadModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.AppDownloadModalContent} modal-content`}>
            <div className={`${classes.AppDownloadModalHeader} modal-header`}>
              <h5>Download the Hawkit Mobile App</h5>
              <button onClick={closeHandler} className={classes.CloseButton}>
                <span className="fas fa-times"></span>
              </button>
            </div>
            <div
              id="appDownloadModalBody"
              className={`${classes.AppDownloadModalBody} modal-body`}
            >
              <p className="f-12">
                Download the Hawkit Mobile App today on Android and enjoy a
                great user experience with amazing features such as:
              </p>
              <ul className="px-4 py-2">
                <li>
                  <p className="f-12 mb-2">
                    <b>Save Your Data:</b> The Hawkit Mobile App saves you alot
                    of data as you don't need to be reloading the site every
                    time. You simply log into the app easily with very low
                    amount of data.
                  </p>
                </li>
                <li>
                  <p className="f-12 mb-2">
                    <b>Never Miss a Task:</b> With the Hawkit Mobile App, you
                    will get instant notifications on new task so you don't miss
                    a task anymore.
                  </p>
                </li>
                <li>
                  <p className="f-12 mb-2">
                    <b>Easy and Faster User Experience:</b> The Hawkit Mobile
                    App has been built to give you a much faster user experience
                    compared to the website. It loads faster and allows you to
                    perform your tasks easier and faster
                  </p>
                </li>
                <li>
                  <p className="f-12 mb-2">
                    <b>Easy Access to the Hawkit Market:</b> The Hawkit Mobile
                    App gives you easy access to the Hawkit Market where you can
                    easily post your products or service and contact your
                    sellers easily. You can chat easily with buyers and sellers on the app.
                  </p>
                </li>
              </ul>
              <div className="py-2">
                <BrowserLinks
                  to={getMobileAppDownloadLink()}
                  className="mr-1 btn btn-success btn-rounded"
                >
                  DOWNLOAD MOBILE APP
                </BrowserLinks>
                <button
                  onClick={closeHandler}
                  className="btn btn-danger btn-rounded"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppDownloadModal;
