import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import classes from "./ProductView.module.css";
import ProductViewHeader from "../../../components/App/ProductView/ProductViewHeader/ProductViewHeader";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import LazyImage from "../../../hoc/LazyImage/LazyImage";
import ProductDetailsModal from "../../../components/App/ProductView/ProductDetailsModal/ProductDetailsModal";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import { Helmet } from "react-helmet";
import ResellModal from "../../../components/App/Products/ResellModal/ResellModal";
import ReviewsModal from "../../../components/App/Products/ReviewsModal/ReviewsModal";

class ProductView extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      activePhoto: null,
      post: null,
      next: null,
      prev: null,
      product: null,
      loading: false,
      is_review: false,
      is_resell: false,
      error: null,
    };
  }

  showReview = (is_review) => {
    this.setState({
      is_review: is_review,
    });
  };

  showResell = (is_resell) => {
    this.setState({
      is_resell: is_resell,
    });
  };

  setProduct = (product) => {
    const photos = product.photos;
    const activePhotoIndex = 0;
    const activePhoto = photos[activePhotoIndex];
    let next = null,
      prev = null;
    if (activePhotoIndex + 1 < photos.length) {
      next = activePhotoIndex + 1;
    }
    if (activePhotoIndex - 1 >= 0) {
      prev = activePhotoIndex - 1;
    }
    this.setState({
      activePhoto: activePhoto,
      next: next,
      prev: prev,
      product: product,
      loading: false,
      error: null,
    });
  };

  getProduct = () => {
    const { productId } = this.props.match.params;
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/products/${productId}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const product = response.data.data.product;
        this.setProduct(product);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.getProduct();
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.getProduct();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  previousHandler = (event) => {
    event.preventDefault();
    let { prev } = this.state;
    const product = this.state.product;
    const photos = product.photos;
    const activePhoto = photos[prev];
    let next = null,
      newPrev = null;
    if (prev + 1 < photos.length) {
      next = prev + 1;
    }
    if (prev - 1 >= 0) {
      newPrev = prev - 1;
    }
    this.setState({
      activePhoto: activePhoto,
      next: next,
      prev: newPrev,
    });
  };

  nextHandler = (event) => {
    event.preventDefault();
    let { next } = this.state;
    const product = this.state.product;
    const photos = product.photos;
    const activePhoto = photos[next];
    let newNext = null,
      prev = null;
    if (next + 1 < photos.length) {
      newNext = next + 1;
    }
    if (next - 1 >= 0) {
      prev = next - 1;
    }
    this.setState({
      activePhoto: activePhoto,
      next: newNext,
      prev: prev,
    });
  };

  render() {
    const { activePhoto, next, prev, product } = this.state;
    let content = null;
    if (this.state.loading) {
      content = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      content = (
        <div className={classes.PostLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    if (activePhoto) {
      const activeImage = activePhoto.src;
      let reviewBg = "bg-warning text-dark";
      if (product.status === "SUCCESS") {
        reviewBg = "bg-success";
      } else if (product.status === "FAILED") {
        reviewBg = "bg-danger";
      }

      let feedbackContent = "";
      if (product.feedback && product.feedback.length) {
        feedbackContent =
          "<p>" +
          product.feedback
            .replace(/\n{2,}/g, "</p><p>")
            .replace(/\n/g, "<br>") +
          "</p>";
      }

      content = (
        <div className={classes.ProductView}>
          <Helmet>
            <title>View Product</title>
          </Helmet>
          <ProductViewHeader product={product} />
          <div className={classes.ProductViewContent}>
            {this.props.user &&
            this.props.user._id === product.user._id &&
            feedbackContent ? (
              <div
                dangerouslySetInnerHTML={{ __html: feedbackContent }}
                className={`${classes.ProductStatus} ${reviewBg}`}
              ></div>
            ) : null}
            <div className={classes.ProductViewImage}>
              <LazyImage src={activeImage} size="3" />
            </div>
            <div className={classes.PhotoNavigation}>
              {prev !== null ? (
                <div
                  onClick={this.previousHandler}
                  className={`${classes.Navigation} ${classes.Left}`}
                >
                  <span className="fal fa-2x fa-chevron-left"></span>
                </div>
              ) : null}
              {next !== null ? (
                <div
                  onClick={this.nextHandler}
                  className={`${classes.Navigation} ${classes.Right}`}
                >
                  <span className="fal fa-2x fa-chevron-right"></span>
                </div>
              ) : null}
            </div>
          </div>          
          <ProductDetailsModal product={product} />
          {this.state.is_resell ? (
            <ResellModal
              product={product}
              destroy={() => this.showResell(false)}
            />
          ) : null}
          {this.state.is_review ? (
            <ReviewsModal
              user={product.user}
              destroy={() => this.showReview(false)}
            />
          ) : null}
        </div>
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>{content}</PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    posts: state.postState.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
    setPosts: (posts) => dispatch(actionCreators.setPosts(posts)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
