import React from "react";
import classes from "./UploadVideo.module.css";

const UploadVideo = (props) => {
  return (
    <div className={classes.UploadVideo}>
      <input
        type="file"
        name="image"
        onChange={props.upload}
        className={classes.InputFile}
        id="videoUploadField"
        accept="video/*"
      />
      <label htmlFor="videoUploadField">
        <div
          style={props.size ? { fontSize: `${props.size}px` } : null}
          className={classes.UploadLabel}
        >
          <i className="fad text-info fa-2x fa-plus-circle"></i>
          <p className="mt-2">{props.text}</p>
        </div>
      </label>
    </div>
  );
};

export default UploadVideo;
