import React, { Component } from "react";
import classes from "./WrapperLayout.module.css";
import UserSideMenu from "../../components/App/Layout/SideMenu/UserSideMenu/UserSideMenu";
import SideMenu from "../../components/App/Layout/SideMenu/SideMenu";
import SideColumn from "../../components/App/Layout/SideMenu/SideColumn/SideColumn";
import Footer from "../../components/App/Layout/Footer/Footer";
import MobileFooter from "../../components/App/Layout/Footer/MobileFooter/MobileFooter";
import { connect } from "react-redux";
import RecentActivities from "../../containers/App/RecentActivities/RecentActivities";

class WrapperLayout extends Component {
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="offset-xl-1 col-xl-10">
              <div className="row">
                <div className="d-none pr-0 d-lg-block col-lg-3">
                  <div className={classes.SideMenu}>
                    <SideMenu>
                      <UserSideMenu />
                    </SideMenu>
                  </div>
                </div>
                <div className="pr-md-0 col-sm-8 col-lg-6">
                  {this.props.children}
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className={classes.SideColumn}>
                    <SideColumn>
                      <RecentActivities />
                      <Footer />
                    </SideColumn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.MobileFooter}>
          <MobileFooter user={this.props.user} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(WrapperLayout);
