import React, { useEffect, useState } from "react";
import classes from "./LinkSocialModal.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BrowserLinks from "../../../../components/UI/Links/BrowserLinks/BrowserLinks";
import { toFirstLetterUppercase } from "../../../../util/util";
import axios from "../../../../axios/axios-instance";

let _isMounted = false;

const LinkSocialModal = (props) => {
  const user_social = props.category.user_social;

  const [formError, setFormError] = useState(null);
  const [formResult, setFormResult] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(null);
  const [profileLink, setProfileLink] = useState(
    user_social ? user_social.profile_link : ""
  );
  const [postLink, setPostLink] = useState(
    user_social ? user_social.post_link : ""
  );
  const closeHandler = (event) => {
    event.preventDefault();
    window.$("#LinkSocialModal").off("hidden.bs.modal");
    window.$("#LinkSocialModal").on("hidden.bs.modal", (event) => {
      props.refresh();
    });
    window.$("#LinkSocialModal").modal("hide");
  };

  const profileInputHandler = (event) => {
    const value = event.target.value;
    setProfileLink(value);
  };

  const postInputHandler = (event) => {
    const value = event.target.value;
    setPostLink(value);
  };

  let platform_url = `${props.category.type}.com`;

  const validateProfileLink = () => {
    if (!profileLink.includes(platform_url)) {
      return false;
    }
    return true;
  };

  const validatePostLink = () => {
    if (!postLink.includes(platform_url)) {
      return false;
    }
    return true;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setFormResult(null);
    if (!validateProfileLink()) {
      setFormError(
        `Please enter a valid profile link. Your Profile link must be in the format: ${
          props.category.type === "tiktok"
            ? `${platform_url}/@{username}`
            : `${platform_url}/{username}`
        } where {username} should be replaced with your ${categoryType} username`
      );
      setTimeout(() => {
        setFormError(null);
      }, 5000);
      return;
    }
    if (!validatePostLink()) {
      setFormError(
        `Please enter a valid post link. Your Post link must be in the format: ${platform_url}/{postId} where {postId} should be replaced with your ${categoryType} Post ID. You can get the post link when you click on your post.`
      );
      setTimeout(() => {
        setFormError(null);
      }, 5000);
      return;
    }
    const userToken = props.userToken;    
    setFormSubmitting(true);
    setFormError(null);
    axios({
      method: "put",
      url: `/users/me/update_social/${props.category.type}`,
      data: {
        profileLink: profileLink,
        postLink: postLink,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!_isMounted) return;
        const result = response.data.data.result;
        setFormResult(result);
        setFormSubmitting(false);
      })
      .catch((error) => {
        if (!_isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setFormSubmitting(false);
            setFormError(data.error[0].msg);
          } else {
            setFormSubmitting(false);
            setFormError(data.error);
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setFormSubmitting(false);
          setFormError(errorMsg);
        }
      });
  };

  useEffect(() => {
    _isMounted = true;
    window.$("#LinkSocialModal").modal({
      backdrop: "static",
      keyboard: false,
    });
    window.$("#LinkSocialModal").modal("show");
    return () => {
      _isMounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);

  const categoryType = toFirstLetterUppercase(props.category.type);

  return (
    <div className={classes.LinkSocialModal}>
      <div
        id="LinkSocialModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.LinkSocialModalContent} modal-content`}>
            <div
              id="LinkSocialModalBody"
              className={`${classes.LinkSocialModalBody} modal-body`}
            >
              <button onClick={closeHandler} className={classes.CloseButton}>
                <span className="fas fa-times"></span>
              </button>
              <div className={`${classes.LinkSocialModalContainer}`}>
                <div className={classes.LinkSocialTitle}>
                  <h4>Link Your {categoryType} Account</h4>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.category.req_write_up,
                  }}
                  className={classes.LinkSocialContent}
                ></div>
                <div className={classes.LinkSocialAdvert}>
                  <div className="container-fluid">
                    <div className="row no-gutters">
                      <div className="col-6">
                        <div className="mr-2">
                          <div className={`${classes.LinkSocialInput} mb-2`}>
                            <p className="f-8 fw-600 mb-1">
                              Advert Image/Video
                            </p>
                            <p className="font-italic f-8 mb-1">
                              Download the Advert Media using the 'Download'
                              button below and then upload to {categoryType}{" "}
                              along with the advert text shown below.
                            </p>
                            <BrowserLinks
                              to={props.category.advert_media}
                              className="btn btn-success btn-xs f-8"
                            >
                              DOWNLOAD ADVERT
                            </BrowserLinks>
                          </div>
                          <div className={`${classes.LinkSocialInput}`}>
                            <p className="f-8 fw-600 mb-1">
                              Advert Text{" "}
                              <button className="ml-2 f-8">
                                <i className="fal fa-copy"></i> Copy Text
                              </button>
                            </p>
                            <textarea
                              value={props.category.advert_text}
                              className="form-control mb-1"
                              readOnly
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className={classes.Input}>
                          {formError ? (
                            <div className="alert alert-danger mb-1 px-3 py-1">
                              <p className="f-8 mb-0">{formError}</p>
                            </div>
                          ) : null}
                          {formResult ? (
                            <div className="alert alert-success mb-1 px-3 py-1">
                              <p className="f-8 mb-0">{formResult}</p>
                            </div>
                          ) : null}
                          <label>
                            Enter your {categoryType} Profile Link and the link
                            to the New {categoryType} Post you just created:
                          </label>
                          <input
                            type="text"
                            placeholder={`${categoryType} Profile Link`}
                            className="form-control f-8"
                            value={profileLink}
                            onChange={profileInputHandler}
                          />
                          <input
                            type="text"
                            placeholder={`${categoryType} Post Link`}
                            className="form-control f-8"
                            value={postLink}
                            onChange={postInputHandler}
                          />
                          <button
                            onClick={submitHandler}
                            disabled={formSubmitting}
                            className="btn btn-xs btn-coloured-heavy f-8"
                          >
                            {formSubmitting ? (
                              <i className="fas fa-spin fa-spinner fa-2x"></i>
                            ) : (
                              "LINK ACCOUNT"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(LinkSocialModal));
