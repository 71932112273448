import React, { Component } from "react";
import classes from "./LazyImage.module.css";

class LazyImage extends Component {
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  loadImage = () => {
    this.setState({ loaded: false });
    const img = new Image();
    img.onload = () => {
      if (this._isMounted) {
        this.setState({
          loaded: true,
        });
      }
    };
    img.src = this.props.src;
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.loadImage();
    }
  }

  render() {
    let image = (
      <div
        style={{ fontSize: `${this.props.size}px` }}
        className={classes.LazySpinner}
      />
    );
    if (this.props.showLoading && !this.state.loaded) {
      image = <div className={classes.ShowLoading}>{image}</div>;
    }
    if (this.state.loaded) {
      image = (
        <div
          className={`${classes.Image} ${
            this.props.no_center ? "align-items-start" : ""
          }`}
        >
          <img
            style={
              this.props.width
                ? {
                    width: `${this.props.width}px`,
                    height: `${this.props.height}px`,
                  }
                : null
            }
            className={this.props.imgClasses}
            src={this.props.src}
            alt={this.props.alt}
          />
        </div>
      );
    }
    return <div className={classes.LazyImage}>{image}</div>;
  }
}

export default LazyImage;
