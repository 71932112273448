import React from "react";
import classes from "./ShowActivities.module.css";
import ShowActivity from "./ShowActivity/ShowActivity";

const ShowActivities = (props) => {
  const content = props.activities.map((activity) => (
    <ShowActivity key={activity._id} activity={activity} />
  ));

  return (
    <div
      style={props.nomargin ? { margin: 0 } : null}
      id="showActivities"
      className={classes.ShowActivities}
    >
      {content}
    </div>
  );
};

export default ShowActivities;
