/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./Member.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import { getRegistrationFee } from "../../../util/util";
import PaymentCompletedModal from "../../../components/App/Order/PaymentCompletedModal/PaymentCompletedModal";
import PaymentModal from "../../../components/App/PaymentModal/PaymentModal";
import PaymentWalletModal from "../../../components/App/PaymentWalletModal/PaymentWalletModal";

class Member extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      paymentCompleted: false,
      show_payment_modal: false,
      show_wallet_payment: false,
      wallet_success: null,
      wallet_error: null,
    };
    this.taskBottomRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (this.taskBottomRef.current) {
      this.taskBottomRef.current.style.width =
        this.taskBottomRef.current.offsetWidth + "px";
      this.taskBottomRef.current.style.position = "fixed";
    }
    const write_up = this.props.page_states[this.props.location.pathname];
    if (!write_up) {
      this.loadWriteUp();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadWriteUp = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/write-up?link=social-member-write-up.html`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const write_up = response.data.data.write_up;
        const page_states = {...this.props.page_states};
        page_states[this.props.location.pathname] = write_up;
        this.props.setPageStates(page_states);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  payWithWallet = () => {
    this.close_payment_modal();
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: `/social/member/pay-with-wallet`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        if (window.fbq) {
          window.fbq("track", "CompleteRegistration", {
            currency: "NGN",
            content_category: "Registration",
            content_name: "Hawkit_Member_Registration",
            value: 1000,
          });
        }
        const status = response.data.data.status;
        const user = response.data.data.user;
        this.props.processUser(user);
        this.setState({
          wallet_success: status,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              wallet_error: data.error[0].msg,
            });
          } else {
            this.setState({
              wallet_error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            wallet_error: errorMsg,
          });
        }
      });
    this.setState({
      wallet_success: null,
      wallet_error: null,
      show_wallet_payment: true,
    });
  };

  memberGetStarted = () => {
    this.props.history.replace("/refresh");
  };

  close_payment_modal = () => {
    this.setState({ show_payment_modal: false });
  };

  close_wallet_payment_modal = () => {
    this.setState({ show_wallet_payment: false });
  };

  payWithFlutterwave = () => {
    if (!this.props.user) {
      this.props.history.push("/auth/login");
      return;
    }
    this.close_payment_modal();
    let total = this.props.meta.registration_fee;
    window.FlutterwaveCheckout({
      public_key: this.props.meta.flutterwave_public_key,
      tx_ref: `Membership-${this.props.user._id}-${Date.now()}`,
      amount: total,
      currency: this.props.user.country.currency.short,
      country: this.props.user.country.code,
      customer: {
        username: this.props.user.username,
        email: this.props.user.email,
        name: this.props.user.name,
      },
      callback: (data) => {
        if (data.status === "successful") {
          if (window.fbq) {
            window.fbq("track", "CompleteRegistration", {
              currency: "NGN",
              content_category: "Registration",
              content_name: "Hawkit_Member_Registration",
              value: 1000,
            });
          }
          this.setState({
            paymentCompleted: true,
          });
        }
      },
      onclose: () => {},
    });
  };

  payHandler = () => {
    if (!this.props.user) {
      this.props.history.push("/auth/login");
      return;
    }
    this.setState({ show_payment_modal: true });
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadWriteUp} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;

    const page_states = {...this.props.page_states};
    const write_up = page_states[this.props.location.pathname];

    if (write_up) {
      content = (
        <div
          dangerouslySetInnerHTML={{ __html: write_up }}
          className={classes.WriteUp}
        ></div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <div
              className={`${classes.Member} ${
                this.props.user && this.props.user.is_member
                  ? classes.NoPadding
                  : ""
              }`}
            >
              {content}
              {!this.props.user || !this.props.user.is_member ? (
                <div ref={this.taskBottomRef} className={classes.TaskBottom}>
                  <div className={classes.TaskBottomText}>
                    <p>Membership Fee</p>
                    <h4>
                      {getRegistrationFee(this.props.user, this.props.meta)}
                    </h4>
                  </div>
                  <div className={classes.TaskBottomAction}>
                    <button
                      onClick={this.payHandler}
                      className="btn btn-coloured-heavy d-block btn-block"
                    >
                      CLICK HERE TO PAY NOW
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            {this.state.paymentCompleted ? (
              <PaymentCompletedModal
                description="Your Payment is successful. Thank you for becoming a member. You can now start earning and enjoying the maximum benefits of using Hawkit."
                refresh={this.memberGetStarted}
                goBack="GO TO HOME PAGE"
              />
            ) : null}
            {this.props.user && this.state.show_payment_modal ? (
              <PaymentModal
                pay_online={this.payWithFlutterwave}
                pay_wallet={this.payWithWallet}
                amount={1000}
                close={this.close_payment_modal}
              />
            ) : null}
            {this.props.user && this.state.show_wallet_payment ? (
              <PaymentWalletModal
                go_success={this.memberGetStarted}
                success={this.state.wallet_success}
                error={this.state.wallet_error}
                cancel={() => this.setState({ show_wallet_payment: false })}
              />
            ) : null}
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Member);
