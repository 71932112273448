/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./DeleteModal.module.css";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";

let is_Mounted = false;

const DeleteModal = props => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    completed: null
  });

  const cancelModalHandler = event => {
    event.preventDefault();
    window.$("#deleteModal").modal("hide");
  };

  const deleteHandler = () => {
    setState({
      ...state,
      error: null,
      loading: true
    });
    const userToken = props.context.userToken;
    let url;
    if (props.context.deleteModal.link === "post") {
      url = `/posts/${props.context.deleteModal.id}`;
    } else {
      url = `/posts/${props.context.deleteModal.parent}/comments/${props.context.deleteModal.id}`;
    }
    axios({
      method: "delete",
      url: url,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!is_Mounted) return;
        const status = response.data.data.status;
        if (status === "success") {
          setState({
            ...state,
            loading: false,
            completed: true
          });
          if (props.context.deleteModal.link === "post") {
            const updatedPosts = props.context.posts.filter(
              el => el._id !== props.context.deleteModal.id
            );
            props.context.setPosts(updatedPosts);
            props.setFeedScroll(0);
          } else if (props.context.deleteModal.link === "comment") {
            const postIndex = props.context.posts.findIndex(
              el => el._id === props.context.deleteModal.parent
            );
            const updatedPosts = [...props.context.posts];
            const updatedPost = { ...props.context.posts[postIndex] };
            updatedPost.comments--;
            updatedPosts[postIndex] = updatedPost;
            props.context.setPosts(updatedPosts);
          }
        }
      })
      .catch(error => {
        if (!is_Mounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg
          });
        }
      });
  };

  let content = null;
  if (state.loading) {
    content = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.completed) {
    content = (
      <div className="py-2 text-center">
        <i className="mb-2 fas fa-check-circle fa-3x text-success"></i>
        <p className="mb-2">
          {props.context.deleteModal.title} deleted Successfully
        </p>
        <button
          onClick={cancelModalHandler}
          className="btn btn-danger btn-rounded"
        >
          BACK
        </button>
      </div>
    );
  } else {
    content = (
      <div className="py-2">
        {state.error ? (
          <div className="alert alert-danger">{state.error}</div>
        ) : null}
        <button
          onClick={deleteHandler}
          className="mr-1 btn btn-success btn-rounded"
        >
          CONFIRM
        </button>
        <button
          data-dismiss="modal"
          aria-label="Close"
          className="btn btn-danger btn-rounded"
        >
          CANCEL
        </button>
      </div>
    );
  }

  useEffect(() => {
    is_Mounted = true;
    window.$("#deleteModal").modal({
      backdrop: "static"
    });
    window.$("#deleteModal").off("shown.bs.modal");
    window.$("#deleteModal").off("hidden.bs.modal");
    window.$("#deleteModal").modal("show");
    window.$("#deleteModal").on("hidden.bs.modal", event => {
      props.destroy();
    });
    return () => {
      is_Mounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#deleteModal").modal("hide");
    };
  }, []);
  return (
    <div className={classes.DeleteModal}>
      <div
        id="deleteModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.DeleteModalContent} modal-content`}>
            <div className={`${classes.DeleteModalHeader} modal-header`}>
              <h5>Delete {props.context.deleteModal.title}</h5>
            </div>
            <div
              id="deleteModalBody"
              className={`${classes.DeleteModalBody} modal-body`}
            >
              <p>
                Are you sure you want to delete this{" "}
                {props.context.deleteModal.link}? This action cannot be undone
                once it has been completed
              </p>
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setFeedScroll: feedScroll =>
      dispatch(actionCreators.setFeedScroll(feedScroll))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DeleteModal);
