/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from "react";
import classes from "./ProductPostImages.module.css";
import { withRouter } from "react-router-dom";
import ProductPostImage from "./ProductPostImage/ProductPostImage";

const ProductPostImages = props => {
  const ProductPostImagesRef = useRef(null);

  useEffect(() => {
    const $product_images = window
      .$(ProductPostImagesRef.current)
      .find(".item");
    let active = 0;
    const items = $product_images.length;
    if (!items) return;
    setInterval(() => {
      ++active;
      active = active >= items ? 0 : active;
      $product_images.removeClass("active prev-active");
      $product_images
        .eq(active > 0 ? active - 1 : items - 1)
        .addClass("prev-active");
      $product_images.eq(active).addClass("active");
    }, 3000);
  }, []);
  const content = props.product.photos.map((image, index) => {
    const viewPostHandler = event => {
      event.preventDefault();
      event.stopPropagation();
      if (props.is_product)
        props.history.push(`/products/${props.product._id}`);
      else props.history.push(`/posts/${props.postId}/${image._id}`);
    };
    return (
      <div key={image._id} className={`item ${index === 0 ? "active" : ""}`}>
        <ProductPostImage clicked={viewPostHandler} image={image} />
      </div>
    );
  });

  return (
    <div ref={ProductPostImagesRef} className={classes.ProductPostImages}>
      <div id="productCarousel" className="carousel">{content}</div>
    </div>
  );
};

export default withRouter(ProductPostImages);
