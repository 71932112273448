import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Invite.module.css";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import UserPhoto from "../../../components/App/User/UserPhoto/UserPhoto";
import { connect } from "react-redux";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";
import TitleLinks from "../../../components/App/Layout/Title/TitleLinks";
import CreateTextField from "../../../components/UI/Forms/FormElements/CreateTextField/CreateTextField";

class Invite extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      invite: props.meta.invite
        ? props.meta.invite +
          `\n\nTo get started, simply visit https://hawkit.ng/#/ref/${props.user.username} to download and register on the app. You will thank me later.`
        : "",
      copy: "Copy Message",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onTextAreaChange = (event) => {
    const value = event.target.value;
    this.setState({
      invite: value,
    });
  };

  onTextInput = (event) => {
    event.target.style.height = "inherit";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  copyHandler = () => {
    window.$("textarea").select();
    document.execCommand("copy");
    this.setState(
      {
        copy: "Copied",
      },
      () => {
        setTimeout(() => {
          this.setState({
            copy: "Copy Message",
          });
        }, 3000);
      }
    );
  };

  render() {
    let content = (
      <div className={classes.Invite}>
        {this.state.formError ? (
          <div className={classes.Error}>
            <AlertError error={this.state.formError} />
          </div>
        ) : null}
        <div className={classes.CreateField}>
          <UserPhoto
            photo={this.props.user.profile_pic}
            width="40"
            height="40"
          />
          <div className="flex-grow-1">
            <CreateTextField
              content={this.state.invite}
              placeholder="Enter Invite Message"
              onInput={this.onTextInput}
              onChange={this.onTextAreaChange}
            />
          </div>
        </div>
        <div className={classes.ShareButtons}>
          <a
            target="_new"
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              this.state.invite
            )}`}
            className={`${classes.ShareContent} ${classes.Whatsapp}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-whatsapp"></i>
            </div>
            <div>
              <h5>Invite From Whatsapp</h5>
              <p className="f-10">
                Simply Click here to send the invite message to your whatsapp
                contacts.
              </p>
            </div>
          </a>
          <BrowserLinks
            to={`https://www.facebook.com/`}
            className={`${classes.ShareContent} ${classes.Facebook}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-facebook"></i>
            </div>
            <div>
              <h5>Invite From Facebook</h5>
              <p className="f-10">
                Simply Copy the Invite Message above and post on your Facebook
                page
              </p>
            </div>
          </BrowserLinks>
          <BrowserLinks
            to={`https://twitter.com/share?text=https://hawkit.ng/download`}
            className={`${classes.ShareContent} ${classes.Twitter}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-twitter"></i>
            </div>
            <div>
              <h5>Invite From Twitter</h5>
              <p className="f-10">
                Simply Copy the Invite Message above and post on your Twitter
                page
              </p>
            </div>
          </BrowserLinks>
          <BrowserLinks
            to={`https://www.linkedin.com/sharing/share-offsite/?url=https://hawkit.ng/download`}
            className={`${classes.ShareContent} ${classes.LinkedIn}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-linkedin"></i>
            </div>
            <div>
              <h5>Invite From LinkedIn</h5>
              <p className="f-10">
                Simply Copy the Invite Message above and post on your LinkedIn
                Page
              </p>
            </div>
          </BrowserLinks>
        </div>
      </div>
    );
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Invite Friends</h4>
                </TitleHeader>
                <TitleLinks>
                  <button onClick={this.copyHandler}>{this.state.copy}</button>
                </TitleLinks>
              </Title>
              {content}
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
