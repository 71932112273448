/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import affix from "../../../../hooks/affix-hook";
import classes from "./SideMenu.module.css";

const SideMenu = (props) => {
  const sideMenu = useRef(null);
  useEffect(() => affix(sideMenu.current, props.user), [props.children]);
  return (
    <div className={classes.SideMenu} ref={sideMenu}>
      {props.children}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(SideMenu);
