import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Earn.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import SocialTransactionContents from "../../../components/App/SocialTransactionContents/SocialTransactionContents";
import * as actionCreators from "../../../store/actions/actions";
import { getRegistrationFee } from "../../../util/util";
import { Link } from "react-router-dom";

class Earn extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      transactions: null,
      loading: false,
      error: null,
      type: "advertise",
    };
    this.taskBottomRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (this.taskBottomRef.current) {
      this.taskBottomRef.current.style.width =
        this.taskBottomRef.current.offsetWidth + "px";
      this.taskBottomRef.current.style.position = "fixed";
    }
    this.loadTaskPricing();
  }

  setTaskType = (type) => {
    this.setState({
      type: type,
    });
  };

  loadTaskPricing = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/tasks/pricing`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const transactions = response.data.data.task_pricing;
        // const page_states = {...this.props.page_states};
        // page_states[this.props.location.pathname] = transactions;
        // this.props.setPageStates(page_states);
        this.setState({
          transactions: transactions,
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadTaskPricing} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;

    // const page_states = {...this.props.page_states};
    const transactions = this.state.transactions;
    let advert_task_count, engagement_task_count;

    if (transactions) {
      const active_transactions = transactions.filter(
        (el) => el.task_type === this.state.type
      );
      advert_task_count = transactions.reduce((total, el) => {
        if (el.task_type === "advertise") {
          return total + el.tasks;
        }
        return total;
      }, 0);
      engagement_task_count = transactions.reduce((total, el) => {
        if (el.task_type === "engagement") {
          return total + el.tasks;
        }
        return total;
      }, 0);
      content = (
        <SocialTransactionContents
          rowClass=""
          content_type="task"
          transactions={active_transactions}
        />
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <div className={classes.EarnTitle}>
                    <h4>Perform Social Tasks and Earn</h4>
                    <p>
                      Click <Link to="/earn/tasks">here</Link> to see and
                      monitor your past tasks
                    </p>
                  </div>
                </TitleHeader>
              </Title>
              <div
                className={`${classes.Earn} ${
                  this.props.user && this.props.user.is_member
                    ? classes.NoPadding
                    : ""
                }`}
              >
                <div className={classes.EarnHeading}>
                  <p>
                    You can earn constant daily income by reselling products,
                    posting adverts of various businesses and top brands on your
                    social media accounts. To get started, simply click on any
                    of the earning options shown below:
                  </p>
                  {!this.props.user || !this.props.user.is_member ? (
                    <div className="alert alert-danger mb-0 py-1 px-3">
                      <p className="mb-0">
                        Note that you must activate your account by paying a
                        one-time membership fee of{" "}
                        {getRegistrationFee(this.props.user, this.props.meta)}{" "}
                        before you can start earning. Click{" "}
                        <Link to="/earn/member">here</Link> to become a member
                        today.
                      </p>
                    </div>
                  ) : (
                    <div className={classes.TaskCount}>
                      {this.props.meta.tasks_count.pending ? (
                        <div className="alert alert-info py-2 f-10">
                          You have <b>{this.props.meta.tasks_count.pending}</b>{" "}
                          Pending Task(s) that you have not done. Click{" "}
                          <Link to="/earn/tasks">here</Link> to perform the
                          tasks now before they expire
                        </div>
                      ) : null}
                      {this.props.meta.tasks_count.uploaded ? (
                        <div className="alert alert-primary py-2 f-10 mb-0">
                          You have <b>{this.props.meta.tasks_count.uploaded}</b>{" "}
                          Uploaded Task(s) that you have done but still IN
                          REVIEW. Please be patient while your task is being
                          reviewed. Click{" "}
                          <Link to="/earn/tasks/uploaded">here</Link> to monitor
                          and track the progress of your tasks in review.
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className={classes.EarningHeader}>
                  <p className="mb-2">
                    <button
                      onClick={() => this.props.history.push("/shop")}
                      className={classes.ResellButton}
                    >
                      <h4 className="mr-2">
                        CLICK HERE TO RESELL PRODUCTS AND EARN UP TO ₦100,000
                        MONTHLY IN SALES COMMISSIONS
                      </h4>
                      <div className="mr-auto">
                        <span className="badge badge-danger">
                          HOT AND NEW!!!
                        </span>
                      </div>
                    </button>
                  </p>
                  <p className="mb-3">
                    <button
                      onClick={() => this.setTaskType("advertise")}
                      className={`btn mr-2 ${
                        this.state.type === "advertise"
                          ? "btn-success"
                          : "btn-light"
                      }`}
                    >
                      Post Adverts{" "}
                      {advert_task_count ? (
                        <span className="badge badge-danger">
                          {advert_task_count}
                        </span>
                      ) : null}
                    </button>
                    <button
                      onClick={() => this.setTaskType("engagement")}
                      className={`btn ${
                        this.state.type === "engagement"
                          ? "btn-success"
                          : "btn-light"
                      }`}
                    >
                      Perform Engagement Tasks{" "}
                      {engagement_task_count ? (
                        <span className="badge badge-danger">
                          {engagement_task_count}
                        </span>
                      ) : null}
                    </button>
                  </p>
                  {this.state.type === "advertise" ? (
                    <>
                      <p className="f-12 mb-2">
                        Earn steady income by posting adverts of businesses and
                        top brands on your social media page. To post adverts on
                        Facebook, Instagram, Twitter or Tiktok, you MUST have
                        atleast <b>1,000 Followers</b> on your social media
                        account.
                      </p>
                      <p className="f-10">
                        Don't have up to 1,000 followers? Click{" "}
                        <button
                          onClick={() => this.setTaskType("engagement")}
                          className="text-primary"
                        >
                          here
                        </button>{" "}
                        to perform engagement tasks
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="f-12 mb-2">
                        Earn steady income by performing simple social and
                        engagement tasks for businesses on your social media
                        account
                      </p>
                      <p className="f-10">
                        Do you have up to 1,000 followers? Click{" "}
                        <button
                          onClick={() => this.setTaskType("advertise")}
                          className="text-primary"
                        >
                          here
                        </button>{" "}
                        to earn from posting adverts on your social media
                        account.
                      </p>
                    </>
                  )}
                </div>
                {content}
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Earn);
