/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./SalesPage.module.css";
import axios from "../../../../axios/axios-instance";
import Error from "../../../../components/UI/Error/Error";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import { getAdvertFee } from "../../../../util/util";
import { withRouter } from "react-router";
import InsufficientFundModal from "../../../../components/App/InsufficientFundModal/InsufficientFundModal";

class SalesPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      show_insufficient_modal: false,
    };
    this.taskBottomRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (this.taskBottomRef.current) {
      this.taskBottomRef.current.style.width =
        this.taskBottomRef.current.offsetWidth + "px";
      this.taskBottomRef.current.style.position = "fixed";
    }
    const write_up = this.props.page_states[this.props.location.pathname];
    if (!write_up) {
      this.loadWriteUp();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadWriteUp = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/write-up?link=advert-write-up.html`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const write_up = response.data.data.write_up;
        const page_states = {...this.props.page_states};
        page_states[this.props.location.pathname] = write_up;
        this.props.setPageStates(page_states);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  continueSalesHandler = () => {
    if (
      !this.props.user.is_member &&
      this.props.user.wallet.shopping < this.props.meta.advert_fee
    ) {
      this.setState({
        show_insufficient_modal: true,
      });
    } else if (
      this.props.user.is_member &&
      this.props.user.product_count &&
      this.props.user.wallet.shopping < this.props.meta.advert_fee
    ) {
      this.setState({
        show_insufficient_modal: true,
      });
    } else this.props.next();
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadWriteUp} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;

    const page_states = {...this.props.page_states};
    const write_up = page_states[this.props.location.pathname];

    if (write_up) {
      content = (
        <div
          dangerouslySetInnerHTML={{ __html: write_up }}
          className={classes.WriteUp}
        ></div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <div
              className={`${classes.SalesPage} ${
                this.props.user && this.props.user.is_member
                  ? classes.NoPadding
                  : ""
              }`}
            >
              {content}
              <div ref={this.taskBottomRef} className={classes.TaskBottom}>
                <div className={classes.TaskBottomText}>
                  <p>Advert Fee</p>
                  <h4>{getAdvertFee(this.props.user, this.props.meta)}</h4>
                </div>                
                <div className={classes.TaskBottomAction}>
                  <button
                    onClick={this.continueSalesHandler}
                    className="btn btn-coloured-heavy d-block btn-block"
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
            {this.state.show_insufficient_modal ? (
              <InsufficientFundModal
                cancel={() => this.setState({ show_insufficient_modal: false })}
                description="You do not have enough funds in your wallet to pay for this advert. Please fund your wallet and try again."
              />
            ) : null}
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SalesPage));
