import React from "react";
import { withRouter } from "react-router-dom";
import classes from "./BackButton.module.css";

const BackButton = props => {
  const clicked = props.clicked ? props.clicked : () => props.history.goBack();
  return (
    <button
      style={props.color ? { color: props.color } : null}
      onClick={clicked}
      className={`${classes.BackButton} ${
        props.isDarkBg ? classes.WhiteButton : ""
      }`}
    >
      <i className="fas fa-chevron-left"></i>
    </button>
  );
};

export default withRouter(BackButton);
