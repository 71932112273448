/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import classes from "../Post.module.css";
import * as actionCreators from "../../../../store/actions/actions";

const PreviewLikeContent = (props) => {
  useEffect(() => {
    props.addPostView(props.postId);
  }, []);

  return (
    <div className={classes.BelieveContent}>
      <p>{props.content}</p>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPostView: (id) => dispatch(actionCreators.addPostView(id)),
  };
};

export default connect(null, mapDispatchToProps)(PreviewLikeContent);
