import React from "react";
import SocialTransactionContent from "./SocialTransactionContent/SocialTransactionContent";

const SocialTransactionContents = (props) => {
  const content = props.transactions.map((el, index) => {
    return (
      <SocialTransactionContent
        rowClass={props.rowClass}
        content_type={props.content_type}
        transaction={el}
        key={index}
      />
    );
  });
  return <div className="row no-gutters">{content}</div>;
};

export default SocialTransactionContents;
