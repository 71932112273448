import React, { Component } from "react";
import classes from "./UserLayout.module.css";
import Footer from "../../../components/App/Layout/Footer/Footer";
import { Switch, Route, NavLink, Link } from "react-router-dom";
import SideMenu from "../../../components/App/Layout/SideMenu/SideMenu";
import SideColumn from "../../../components/App/Layout/SideMenu/SideColumn/SideColumn";
import UserReviews from "../User/UserReviews/UserReviews";
import UserPosts from "../User/UserPosts/UserPosts";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import { abbreviateNumber, getRegistrationFee } from "../../../util/util";
import UserModal from "../../../components/App/User/UserModal/UserModal";
import axios from "../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import { Helmet } from "react-helmet";
import UserHeading from "../../../components/App/User/UserHeading/UserHeading";
import UserWallet from "../../../components/App/User/UserWallet/UserWallet";
import UserDetails from "../../../components/App/User/UserDetails/UserDetails";
import UserFollower from "../../../components/App/User/UserFollower/UserFollower";
import MobileFooter from "../../../components/App/Layout/Footer/MobileFooter/MobileFooter";
import RecentActivities from "../RecentActivities/RecentActivities";

class UserLayout extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      user: null,
      userModal: null,
      referral_program: null,
      nav_fixed: false,
      nav_pos: null,
    };
    this.referralInputRef = React.createRef();
  }

  loadUser = () => {
    const userToken = this.props.userToken;
    const { username } = this.props.match.params;
    if (!username) {
      this.props.history.replace("/users");
    }
    const url = `/users/${username}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        const referral_program = response.data.data.referral_program;
        this.setState(
          {
            loading: false,
            user: user,
            referral_program: referral_program,
          },
          this.loadUserPosts
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  initialiseAll = () => {
    this.setState({
      loading: false,
      error: null,
      user: null,
      userModal: null,
      nav_fixed: false,
      nav_pos: null,
    });
  };

  setUserModal = (userModal) => {
    this.setState({
      userModal: userModal,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadUser();
    window.addEventListener("scroll", this.fixUserNavEventListener);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.username !== prevProps.match.params.username) {
      this.initialiseAll();
      this.loadUser();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.fixUserNavEventListener);
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadUser();
  };

  followHandler = (data) => {
    if (!this.state.user || !data) return;
    const updatedUser = {
      ...this.state.user,
      followers: data.followers,
    };
    this.setState({
      user: updatedUser,
    });
  };

  fixUserNavEventListener = () => {
    const scrollHeight = window.$(window).scrollTop();
    const userNav = document.getElementById("usersNav");
    if (!userNav) return;
    let userNavOffset = this.state.nav_pos
      ? this.state.nav_pos
      : userNav.offsetTop;
    let currentWidth = userNav.offsetWidth;
    if (scrollHeight > 60 && scrollHeight >= userNavOffset) {
      userNav.style.width = currentWidth + "px";
      if (!this.state.nav_fixed) {
        this.setState({
          nav_fixed: true,
          nav_pos: userNavOffset,
        });
      }
    } else if (this.state.nav_fixed) {
      this.setState({
        nav_fixed: false,
        nav_pos: null,
      });
    }
  };

  copyReferralLink = (event) => {
    var referralInputText = this.referralInputRef.current;
    referralInputText.select();
    document.execCommand("Copy");
    window.$(event.target).html("Copied");
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    let content = loadingContent;

    if (this.state.user) {
      content = (
        <>
          <Helmet>
            <title>{this.state.user.username.toUpperCase()}'s Profile</title>
          </Helmet>
          <div className="container-fluid">
            <div className="row">
              <div className="offset-xl-1 col-xl-10">
                <div className="row">
                  <div className="pr-md-0 pl-md-0 col-md-4 col-lg-3">
                    <div className={classes.SideMenu}>
                      <SideMenu>
                        <UserHeading
                          followHandler={this.followHandler}
                          active={
                            this.props.user &&
                            this.props.user.username ===
                              this.state.user.username
                          }
                          activeUser={this.props.user}
                          user={this.state.user}
                        />
                        {this.props.user &&
                        this.props.user.username ===
                          this.state.user.username ? (
                          <UserWallet user={this.state.user} />
                        ) : null}
                        <UserFollower
                          setUserModal={this.setUserModal}
                          active={
                            this.props.user &&
                            this.props.user.username ===
                              this.state.user.username
                          }
                          user={this.state.user}
                        />
                        <UserDetails
                          active={
                            this.props.user &&
                            this.props.user.username ===
                              this.state.user.username
                          }
                          user={this.state.user}
                        />
                      </SideMenu>
                    </div>
                  </div>
                  <div className="position-static pr-md-0 pr-lg-3 col-md-8 col-lg-6">
                    <ContentLayout>
                      <SingleColumnLayout>
                        <MainContent>
                          {this.props.user &&
                          this.props.user.username ===
                            this.state.user.username ? (
                            <div className={classes.ReferralContainer}>
                              <div className={classes.ReferralHeading}>
                                <h5>Refer and Earn Big</h5>
                                <div className="ml-auto">
                                  <Link
                                    to="/invite"
                                    className="btn btn-xs btn-coloured-heavy btn-rounded"
                                  >
                                    INVITE FRIENDS
                                  </Link>
                                </div>
                              </div>
                              <div className={classes.ReferralContent}>
                                <p>My Referral Link:</p>
                                {this.props.user &&
                                this.props.user.is_member ? (
                                  <>
                                    <div className="input-group my-1">
                                      <input
                                        type="text"
                                        ref={this.referralInputRef}
                                        className="form-control f-12"
                                        value={`https://hawkit.ng/#/ref/${this.props.user.username}`}
                                        readOnly
                                      />
                                      <div className="input-group-append">
                                        <button
                                          onClick={this.copyReferralLink}
                                          className="btn btn-warning btn-xs"
                                          type="submit"
                                        >
                                          COPY
                                        </button>
                                      </div>
                                    </div>
                                    <p>
                                      Or You can tell your referral to use your
                                      username{" "}
                                      <b>({this.props.user.username})</b> under
                                      the referral section at the point of
                                      registration.
                                    </p>
                                  </>
                                ) : (
                                  <div className="alert alert-danger mb-0 mt-1 py-1 px-3">
                                    <p className="mb-0">
                                      You must activate your account by paying a
                                      one-time membership fee of{" "}
                                      {getRegistrationFee(
                                        this.props.user,
                                        this.props.meta
                                      )}{" "}
                                      before you can get your referral link and
                                      start earning. Click{" "}
                                      <Link to="/social/member">here</Link> to
                                      see why you should pay a membership fee.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                          <div
                            id="usersNav"
                            className={`${
                              this.state.nav_fixed ? classes.NavFixed : ""
                            } ${classes.UsersNav}`}
                          >
                            <NavLink
                              replace
                              exact
                              activeClassName={classes.Active}
                              to={`/users/${this.state.user.username}`}
                            >
                              Selling (
                              {this.state.user.posts.length
                                ? abbreviateNumber(
                                    this.state.user.posts[0].total
                                  )
                                : 0}
                              )
                            </NavLink>
                            <NavLink
                              replace
                              exact
                              activeClassName={classes.Active}
                              to={`/users/${this.state.user.username}/reviews`}
                            >
                              Reviews (
                              {this.state.user.reviews.length
                                ? abbreviateNumber(
                                    this.state.user.reviews[0].total
                                  )
                                : 0}
                              )
                            </NavLink>
                          </div>
                          <div className={classes.UsersNavContent}>
                            <Switch>
                              <Route
                                path="/users/:username/reviews"
                                component={UserReviews}
                              />
                              <Route
                                path="/users/:username"
                                component={UserPosts}
                              />
                            </Switch>
                          </div>
                          {this.state.userModal ? (
                            <UserModal
                              username={this.state.user.username}
                              userToken={this.props.userToken}
                              activeUser={this.props.user}
                              destroy={() => this.setState({ userModal: null })}
                              userModal={this.state.userModal}
                            />
                          ) : null}
                        </MainContent>
                      </SingleColumnLayout>
                    </ContentLayout>
                  </div>
                  <div className="pl-md-0 d-none d-lg-block col-lg-3">
                    <div className={classes.SideColumn}>
                      <SideColumn>
                        <RecentActivities />
                        <Footer />
                      </SideColumn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className={classes.UserLayout}>
        {content}
        <div className={classes.MobileFooter}>
          <MobileFooter user={this.props.user} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);
