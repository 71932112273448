import React from "react";
import classes from "./Footer.module.css";
import BrowserLinks from "../../../UI/Links/BrowserLinks/BrowserLinks";

const Footer = props => {
  return (
    <div className={classes.Footer}>
      <nav className={classes.FooterNav}>
        <p>
          <BrowserLinks to="https://hawkit.ng/about">About</BrowserLinks>
          <BrowserLinks to="https://hawkit.ng/support">Support</BrowserLinks>
          <BrowserLinks to="https://hawkit.ng/about/terms">Terms</BrowserLinks>
          <BrowserLinks to="https://hawkit.ng/about/privacy-policy">
            Privacy
          </BrowserLinks>
        </p>
        <p>© {new Date().getFullYear()} Hawkit International Limited.</p>
      </nav>
    </div>
  );
};

export default Footer;
