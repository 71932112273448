/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import classes from "./AppReviewModal.module.css";
import axios from "../../../axios/axios-instance";
import BrowserLinks from "../../UI/Links/BrowserLinks/BrowserLinks";
import { getLastAppReview, getMobileAppDownloadLink, setLastAppReview } from "../../../util/util";

let is_Mounted = false;

const AppReviewModal = (props) => {
  const updateHandler = () => {
    axios({
      method: "get",
      url: "auth/app-review",
    })
      .then((response) => {
        if (!is_Mounted) return;
        const reviewDate = response.data.data.app_review;
        if (reviewDate < getLastAppReview()) {
          return;
        }
        setLastAppReview();
        window.$("#appReviewModal").modal({
          backdrop: "static",
          keyboard: false
        });
        window.$("#appReviewModal").modal("show");
      })
      .catch((error) => {
        if (!is_Mounted) return;
        console.log(error);
      });
  };

  useEffect(() => {
    is_Mounted = true;
    updateHandler();
    return () => {
      is_Mounted = false;
    };
  }, []);

  return (
    <div className={classes.AppReviewModal}>
      <div
        id="appReviewModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.AppReviewModalContent} modal-content`}>
            <div className={`${classes.AppReviewModalHeader} modal-header`}>
              <h5>Rate Our App</h5>
            </div>
            <div
              id="appReviewModalBody"
              className={`${classes.AppReviewModalBody} modal-body`}
            >
              <p>
                Please take a minute to review our app so we can know how to
                serve you better. Thank you for choosing Hawkit.
              </p>
              <div className="py-2">
                <BrowserLinks
                  to={getMobileAppDownloadLink()}
                  className="mr-1 btn btn-success btn-rounded"
                >
                  REVIEW APP
                </BrowserLinks>
                <button
                  data-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-danger btn-rounded"
                >
                  NOT NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppReviewModal;
