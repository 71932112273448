import React from "react";
import logo from "../../../../assets/images/logo.png";
import classes from "./LogoImage.module.css";

const logoImage = props => {
  return (
    <div className={classes.LogoImage}>
      <img className="mb-1" src={logo} alt="logo" />
    </div>
  );
};

export default logoImage;
