/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import { connect } from "react-redux";
import classes from "./PurchaseOrders.module.css";
import PurchaseOrder from "../../../../components/App/Order/PurchaseOrder/PurchaseOrder";
import { Link } from "react-router-dom";

class PurchaseOrders extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      orders: []
    };
  }

  loadOrders = () => {
    let page = 1;
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/orders/purchase?page=${page}`,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const orders = response.data.data.orders;
        const filteredOrders = orders.filter(el => {
          return !this.state.orders.find(order => order._id === el._id);
        });
        const updatedOrders = [...this.state.orders, ...filteredOrders];
        const pages = response.data.data.pages;
        this.setState({
          loading: false,
          pages: pages,
          orders: updatedOrders
        });
      })
      .catch(error => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg
            });
          } else {
            this.setState({
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg
          });
        }
      });
    this.setState({
      loading: true,
      error: null
    });
  };

  loadMoreOrders = event => {
    event.preventDefault();
    this.loadOrders();
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadOrders();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.Loading}>
          <a onClick={this.loadMoreOrders} href="/">
            Load More <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.Loading}>
          <Error refresh={this.loadOrders} error={this.state.error} />
        </div>
      );
    }
    let content = null;
    if (this.state.orders.length) {
      const purchaseOrders = this.state.orders.map((order, index) => {
        return (
          <PurchaseOrder index={index + 1} order={order} key={order._id} />
        );
      });
      content = <div className={classes.PurchaseOrders}>{purchaseOrders}</div>;
    } else if (!this.state.loading && !this.state.error) {
      content = (
        <div className={classes.OrdersEmpty}>
          <i className="fal fa-shopping-bag"></i>
          <p className="mb-3">
            You have not made any purchase orders. Start placing orders by
            browsing through our huge collections of products.
          </p>
          <Link to="/shop" className="btn btn-coloured-heavy">
            Browse Products
          </Link>
        </div>
      );
    }

    return (
      <>
        {content}
        {loadingContent}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken
  };
};

export default connect(mapStateToProps)(PurchaseOrders);
