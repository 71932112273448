/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component } from "react";
import {
  createSelectElement,
  createInputElement,
  createButtonElement,
} from "../../../UI/Forms/utility/form-creators";
import * as validators from "../../../UI/Forms/utility/validator";
import Form from "../../../UI/Forms/Form";
import classes from "./AirtimeBuyModal.module.css";
import axios from "../../../../axios/axios-instance";
import AlertError from "../../../UI/Error/AlertError/AlertError";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import { withRouter } from "react-router-dom";
import AirtimeReview from "./AirtimeReview/AirtimeReview";
import FundingForm from "../../../../containers/App/FundWallet/FundingForm/FundingForm";

class AirtimeBuyModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    const quantitiesList = [];
    const quantities =
      props.post.product.airtime.mode === "DATA"
        ? 1
        : props.post.product.quantities;
    for (let index = 0; index < quantities; index++) {
      quantitiesList.push({
        value: index + 1,
        option: index + 1,
      });
    }
    let formData = {
      phoneNumber: createInputElement(
        "number",
        "Recipient's Phone Number",
        "Phone Number",
        "",
        this.validatePhoneNumber,
        "Please enter the phone number that you want to recharge.",
        "fas fa-mobile-alt",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "phoneNumber")
      ),
      quantity: createSelectElement(
        "Quantities",
        this.validateQuantity,
        "fas fa-list",
        "col-sm-12",
        quantitiesList,
        "Select Quantities",
        this.inputChangedHandler.bind(this, "quantity"),
        "1"
      ),
    };
    this.state = {
      formData: {
        ...formData,
        submit: createButtonElement("submit", "BUY NOW", true),
      },
      total: this.props.post.product.price,
      balance_error: false,
      member_error: false,
      loading: false,
      error: false,
      completed: false,
      confirm_order: false,
      show_funding: false,
    };
  }

  validatePhoneNumber(value) {
    if (validators.isEmpty(value.trim())) {
      return "Pleaase enter valid phone number!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Phone Number. Only numbers are allowed";
    }
    if (!validators.validateLength(value.trim(), 5, 20)) {
      return "Please enter valid phone number.";
    }
    return null;
  }

  validateQuantity = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select the number of quantities you want to order.";
    }
    if (+value > this.props.post.product.quantities) {
      return "The option you selected is not in the above list. Please select a valid option from the above list.";
    }
    return null;
  };

  componentDidMount() {
    this._isMounted = true;
    window.$("#AirtimebuyModal").modal({
      backdrop: "static",
      keyboard: false
    });
    window.$("#AirtimebuyModal").modal("show");
    window.$("#AirtimebuyModal").off("hidden.bs.modal");
    window.$("#AirtimebuyModal").on("hidden.bs.modal", (event) => {
      this.props.destroy();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$(".modal-backdrop").remove();
    window.$("body").removeClass("modal-open");
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
    };
    updatedFormData[name] = formElementData;
    if (name === "quantity") {
      const value = +formElementData.value;
      const total = value * this.props.post.product.price;
      this.setState({
        total: total,
      });
    }
    this.setState({
      formData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    if (!this.state.total) {
      this.setState({
        error: "Please select the quantity you want to purchase below.",
        confirm_order: false,
        balance_error: false,
      });
      return false;
    }
    if (this.state.total > this.props.user.wallet.shopping) {
      this.setState({
        balance_error: true,
        show_funding: false,
      });
      return false;
    }
    if (this.props.user && !this.props.user.is_member) {
      this.setState({
        member_error: true,
      });
      return false;
    }
    const userToken = this.props.userToken;
    let data = {
      quantity: this.state.formData.quantity.value,
      phoneNumber: this.state.formData.phoneNumber.value,
    };
    axios({
      method: "post",
      url: `/users/purchase-airtime/${this.props.post._id}`,
      data: data,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        this.setState({
          loading: false,
        });
        if (window.fbq) {
          window.fbq("track", "Purchase", {
            currency: "NGN",
            content_ids: [this.props.post.product._id],
            content_category: this.props.post.product.category._id,
            content_name: this.props.post.product.title,
            content_type: "product",
            contents: [
              {
                id: this.props.post.product._id,
                quantity: this.state.formData.quantity.value,
                price: this.props.post.product.price,
                post: this.props.post._id,
                category: this.props.post.product.category._id,
                phoneNumber: this.state.formData.phoneNumber.value,
              },
            ],
            value:
              +this.state.formData.quantity.value *
              +this.props.post.product.price,
          });
        }
        const user = response.data.data.user;
        if (user) {
          this.props.processUser(user);
          this.setState({
            completed: true,
          });
        } else {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      confirm_order: false,
      balance_error: false,
      member_error: false
    });
  };

  confirmHandler = async (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    if (!this.props.user) {
      this.props.history.push("/login");
      return false;
    }
    if (this.props.user && !this.props.user.is_member) {
      this.setState({
        member_error: true,
      });
      return false;
    }
    if (!this.state.total) {
      this.setState({
        error: "Please select the quantity you want to purchase below.",
        confirm_order: false,
        balance_error: false,
      });
      return false;
    }
    if (this.state.total > this.props.user.wallet.shopping) {
      this.setState({
        balance_error: true,
        show_funding: false,
      });
      return false;
    }
    this.setState({
      confirm_order: true,
      balance_error: false,
      member_error: false,
      show_funding: false,
    });
  };

  cancelOrderHandler = async (event) => {
    event.preventDefault();
    this.setState({
      confirm_order: false,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  closeHandler = (event) => {
    event.preventDefault();
    window.$("#AirtimebuyModal").modal("hide");
  };

  membershipHandler = (event) => {
    event.preventDefault();
    window.$("#AirtimebuyModal").off("hidden.bs.modal");
    window.$("#AirtimebuyModal").on("hidden.bs.modal", (event) => {
      this.props.destroy();
      this.props.history.push("/social/member");
    });
    window.$("#AirtimebuyModal").modal("hide");
  };

  airtimeHistoryHandler = (event) => {
    event.preventDefault();
    window.$("#AirtimebuyModal").off("hidden.bs.modal");
    window.$("#AirtimebuyModal").on("hidden.bs.modal", (event) => {
      this.props.destroy();
      this.props.history.push("/transactions/airtime");
    });
    window.$("#AirtimebuyModal").modal("hide");
  };

  fundWalletHandler = (event) => {
    event.preventDefault();
    this.setState({
      show_funding: true,
    });
  };

  render() {
    let content = null;

    if (this.state.completed) {
      content = (
        <div className={classes.Completed}>
          <i className="mb-2 fas fa-check-circle text-success"></i>
          <h6 className="mb-2">Order Received Successfully.</h6>
          <p className="mb-2">{this.props.post.product.airtime.feedback}</p>
          <AirtimeReview product={this.props.post.product} />
          <p>
            <button
              onClick={this.airtimeHistoryHandler}
              className="btn btn-outline btn-xs mr-1"
            >
              Airtime History
            </button>
            <button
              onClick={this.closeHandler}
              className="btn btn-outline btn-xs"
            >
              Close
            </button>
          </p>
        </div>
      );
    } else {
      let fund_wallet_content = (
        <div className={classes.BalanceError}>
          <p className="mb-2">
            Oops! Your wallet balance is insufficient for this order. Please
            fund your wallet and try again.
          </p>
          <button className="btn btn-light" onClick={this.fundWalletHandler}>
            <i className="fas fa-purse"></i> FUND WALLET NOW
          </button>
        </div>
      );
      const member_content = (
        <div className={classes.BalanceError}>
          <p className="mb-2">
            You need to activate your membership before you can purchase airtime
            and data at great discounts on Hawkit. Please click the button below
            to activate your membership now.
          </p>
          <button className="btn btn-light" onClick={this.membershipHandler}>
            <i className="fas fa-user"></i> ACTIVATE MEMBERSHIP
          </button>
        </div>
      );
      if (this.state.show_funding) {
        fund_wallet_content = <FundingForm />;
      }
      content = (
        <>
          {this.props.user ? (
            <div className={classes.DeliveryInfo}>
              <p>Wallet Balance:</p>
              <h5 className="ml-auto mb-0">
                {this.props.user.country.currency.symbol}{" "}
                {this.props.user.wallet.shopping
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h5>
            </div>
          ) : null}
          {this.state.balance_error ? fund_wallet_content : null}
          {this.state.member_error ? member_content : null}
          {!this.state.confirm_order ? (
            <div className={classes.OrderForm}>
              {this.state.error ? (
                <AlertError error={this.state.error} />
              ) : null}
              <Form
                loading={this.state.loading}
                formData={this.state.formData}
                submit={this.confirmHandler}
              />
              <button
                onClick={this.airtimeHistoryHandler}
                className="mt-2 btn btn-block btn-outline"
              >
                Airtime History
              </button>
            </div>
          ) : (
            <div className={classes.OrderForm}>
              <div className={classes.ConfirmOrder}>
                <h6>Confirm Order</h6>
                <p className="mb-3">
                  Are you sure you want to buy{" "}
                  {this.state.formData.quantity.value} Qty(s) of{" "}
                  {this.props.post.product.title}?
                </p>
                <p>Recipient's Phone Number: </p>
                <h4>{this.state.formData.phoneNumber.value}</h4>
              </div>
              <div className="text-center">
                <p className="mb-2">
                  <button
                    onClick={this.submitHandler}
                    className="btn btn-outline mr-2"
                  >
                    CONFIRM ORDER
                  </button>
                  <button
                    onClick={this.cancelOrderHandler}
                    className="btn btn-outline"
                  >
                    <i className="fas fa-caret-left"></i> BACK
                  </button>
                </p>
                <p className="f-12">
                  Please note that this order cannot be reversed. Once you have
                  confirmed, the airtime or data will be sent instantly.
                </p>
              </div>
            </div>
          )}
          {this.state.total ? (
            <div className={classes.TotalInfo}>
              <p>You will pay:</p>
              <h5 className="ml-auto mb-0">
                {this.props.post.product.country.currency.symbol}{" "}
                {this.state.total
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h5>
            </div>
          ) : null}
          <div className={classes.Report}>
            <div className="mr-2">
              <i className="fad fa-lock"></i>
            </div>
            <div className={classes.ReportContent}>
              <h5>Instant Recharge. No Network Failure.</h5>
              <p>
                Your Phone Number will be credited instantly with the airtime or
                data. No Network Failure. Fast Recharge Guaranteed.
              </p>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className={classes.AirtimeBuyModal}>
        <div
          id="AirtimebuyModal"
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby=""
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`${classes.AirtimeBuyModalContent} modal-content`}>
              <div className={`${classes.AirtimeBuyModalHeader} modal-header`}>
                <div className={classes.AirtimeBuyModalTitle}>
                  <h5>{this.props.post.product.title}</h5>
                  <p>
                    {`${
                      this.props.post.product.country.currency.symbol
                    } ${this.props.post.product.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                id="AirtimeBuyModalBody"
                className={`${classes.AirtimeBuyModalBody} modal-body`}
              >
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AirtimeBuyModal));
