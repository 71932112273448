import React from "react";
import classes from "./OrderHeader.module.css";
import LazyImage from "../../../../hoc/LazyImage/LazyImage";
import { Link } from "react-router-dom";

const moment = require("moment");

const OrderHeader = props => {
  const product = props.item.product;
  const post = props.item.post;

  let price;

  if (props.cart) {
    price = product.price;
  } else {
    price = props.item.price;
  }

  const now = moment();
  const orderMoment = moment(props.item.created_on);
  const daysDiff = now.diff(orderMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = orderMoment.calendar();
  } else {
    formatDate = orderMoment.fromNow();
  }

  return (
    <div className={classes.OrderHeader}>
      <div className={classes.ProductPhoto}>
        <LazyImage src={product.photos[0].src} size="3" />
      </div>
      <div className={classes.ProductTitle}>
        <div className={classes.Title}>
          <h5>
            <Link to={`/posts/${post}`}>
              {product.title}
            </Link>
          </h5>
        </div>
        <div className={classes.SubTitle}>
          <p className={classes.Price}>{`${
            product.country.currency.symbol
          } ${price
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
          <p className={classes.Date}>
            <span className="fal fa-clock"></span> {formatDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderHeader;
