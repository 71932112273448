import React from "react";

const BrowserLinks = (props) => {
  const linkHandler = (event) => {
    event.preventDefault();
    let url = props.to;
    if (
      !url.match(/^https?:\/\//i) &&
      !url.includes("tel") &&
      !url.includes("mailto") &&
      !url.includes("whatsapp")
    ) {
      url = "http://" + url;
    }
    if (window.cordova) {
      window.cordova.InAppBrowser.open(url, "_system");
    } else {
      window.open(url);
    }
    return false;
  };

  return (
    <a href={props.to} onClick={linkHandler} {...props}>
      {props.children}
    </a>
  );
};

export default BrowserLinks;
