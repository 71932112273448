import React from "react";

const UploadInput = props => {
  let error = null;
  if (props.invalid) {
    error = <div className="invalid-feedback d-block">{props.invalid}</div>;
  }
  let inputElement = (
    <React.Fragment>
      <input
        id={props.name}
        type="file"
        className="file"
        placeholder={props.placeholder}
        onChange={props.changeHandler}
      />
      {error}
    </React.Fragment>
  );
  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      {inputElement}
      <small className="form-text text-muted">{props.hint}</small>
    </div>
  );
};

export default UploadInput;
