import React from "react";
import classes from "./CreateTextField.module.css";

const CreateTextField = (props) => {
  return (
    <div className={classes.CreateField}>
      <textarea
        rows={props.is_title ? "2" : undefined}
        style={
          props.is_title ? { minHeight: "0px", maxHeight: "none" } : undefined
        }
        className="form-control"
        placeholder={props.placeholder || "What do you want to share?"}
        onChange={props.onChange}
        onInput={props.onInput}
        value={props.content || ""}
      ></textarea>
    </div>
  );
};
export default CreateTextField;
