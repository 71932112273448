import React from "react";
import classes from "./PostImage.module.css";
import LazyImage from "../../../../../hoc/LazyImage/LazyImage";

const postImage = props => {
  return (
    <div
      onClick={props.clicked}
      style={{
        top: `${props.image.top}px`,
        left: `${props.image.left}px`,
        width: `${props.image.width}px`,
        height: `${props.image.height}px`
      }}
      className={classes.PostImage}
    >
      {props.rem ? (
        <div className={classes.ImagesRemaining}>
          <h5>+{props.rem}</h5>
        </div>
      ) : null}
      <LazyImage src={props.image.src} size="3" />
    </div>
  );
};

export default postImage;
