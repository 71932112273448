export const createLocationInputElement = (
  type,
  label,
  placeholder,
  value,
  validator,
  hint,
  sideStyle,
  width,
  changeHandler,
  setLocationHandler,
  editLocationHandler,
  id = null,
  disabled,
  confirming = false,
  confirmLocationHandler = null,
  errorConfirmHandler = null
) => {
  return {
    element: "input",
    type: type,
    placeholder: placeholder,
    label: label,
    value: value,
    hint: hint,
    sideStyle: sideStyle,
    validate: validator,
    invalid: null,
    width: width,
    changeHandler: changeHandler,
    setLocation: setLocationHandler,
    editLocation: editLocationHandler,
    confirmLocation: confirmLocationHandler,
    errorConfirm: errorConfirmHandler,
    id: id,
    disabled: disabled,
    confirming: confirming,
    location: true,
  };
};

export const createInputElement = (
  type,
  label,
  placeholder,
  value,
  validator,
  hint,
  sideStyle,
  width,
  changeHandler,
  id = null,
  disabled = false,
  readOnly = false
) => {
  return {
    element: "input",
    type: type,
    placeholder: placeholder,
    label: label,
    value: value,
    hint: hint,
    sideStyle: sideStyle,
    validate: validator,
    invalid: null,
    width: width,
    changeHandler: changeHandler,
    id: id,
    disabled: disabled,
    readOnly: readOnly,
  };
};

export const createTagInputElement = (
  type,
  label,
  placeholder,
  value,
  validator,
  hint,
  sideStyle,
  width,
  changeHandler,
  id = null
) => {
  return {
    element: "input",
    type: type,
    placeholder: placeholder,
    label: label,
    value: value,
    hint: hint,
    sideStyle: sideStyle,
    validate: validator,
    invalid: null,
    width: width,
    changeHandler: changeHandler,
    tag: true,
    id: id,
  };
};

export const createSearchInputElement = (
  placeholder,
  value,
  changeHandler,
  height
) => {
  return {
    element: "input",
    placeholder: placeholder,
    value: value,
    changeHandler: changeHandler,
    search: true,
    height: height,
  };
};

export const createCommentInputElement = (
  placeholder,
  value,
  changeHandler,
  invalid,
  submitting,
  submitted,
  id
) => {
  return {
    element: "input",
    placeholder: placeholder,
    value: value,
    changeHandler: changeHandler,
    submitting: submitting,
    invalid: invalid,
    submitted: submitted,
    id: id,
    comment: true,
  };
};

export const createChatInputElement = (
  placeholder,
  value,
  changeHandler,
  invalid,
  submitting,
  submitted,
  id
) => {
  return {
    element: "input",
    placeholder: placeholder,
    value: value,
    changeHandler: changeHandler,
    submitting: submitting,
    invalid: invalid,
    submitted: submitted,
    id: id,
    chat: true,
  };
};

export const createPhoneNumberInputElement = (
  type,
  label,
  placeholder,
  value,
  validator,
  hint,
  sideStyle,
  width,
  changeHandler,
  countries
) => {
  return {
    element: "input",
    type: type,
    placeholder: placeholder,
    label: label,
    value: value,
    hint: hint,
    sideStyle: sideStyle,
    validate: validator,
    invalid: null,
    width: width,
    changeHandler: changeHandler,
    countries: countries,
    phoneNumber: true,
  };
};

export const createPriceInputElement = (
  type,
  label,
  placeholder,
  value,
  validator,
  hint,
  sideStyle,
  width,
  changeHandler,
  country,
  id = null
) => {
  return {
    element: "input",
    type: type,
    placeholder: placeholder,
    label: label,
    value: value,
    hint: hint,
    sideStyle: sideStyle,
    validate: validator,
    invalid: null,
    width: width,
    changeHandler: changeHandler,
    country: country,
    price: true,
    id: id,
  };
};

export const createOtpInputElement = (
  type,
  label,
  placeholder,
  value,
  validator,
  hint,
  sideStyle,
  width,
  changeHandler,
  maxLength
) => {
  return {
    element: "input",
    type: type,
    placeholder: placeholder,
    label: label,
    value: value,
    hint: hint,
    sideStyle: sideStyle,
    validate: validator,
    invalid: null,
    width: width,
    changeHandler: changeHandler,
    maxLength: maxLength,
    otp: true,
  };
};

export const createUploadInputElement = (
  label,
  placeholder,
  width,
  value,
  hint,
  changeHandler,
  invalid
) => {
  return {
    element: "file",
    placeholder: placeholder,
    label: label,
    width: width,
    value: value,
    hint: hint,
    changeHandler: changeHandler,
    invalid: invalid,
  };
};

export const createCheckboxElement = (
  type,
  label,
  validator,
  width,
  changeHandler
) => {
  return {
    element: "checkbox",
    type: type,
    label: label,
    validate: validator,
    invalid: null,
    width: width,
    changeHandler: changeHandler,
  };
};

export const createSelectElement = (
  label,
  validator,
  sideStyle,
  width,
  options,
  placeholder,
  changeHandler,
  value,
  hint
) => {
  return {
    element: "select",
    label: label,
    validate: validator,
    invalid: null,
    sideStyle: sideStyle,
    width: width,
    options: options,
    placeholder: placeholder,
    changeHandler: changeHandler,
    value: value,
    hint: hint,
  };
};

export const createButtonElement = (
  type,
  label,
  isBlock,
  width,
  show = true
) => {
  return {
    element: "button",
    type: type,
    label: label,
    isBlock: isBlock,
    width: width,
    show: show,
  };
};

export const createTextAreaElement = (
  label,
  placeholder,
  value,
  validator,
  hint,
  width,
  changeHandler,
  id = null
) => {
  return {
    element: "textarea",
    label: label,
    placeholder: placeholder,
    width: width,
    value: value,
    validate: validator,
    hint: hint,
    changeHandler: changeHandler,
    id: id,
  };
};
