import React from "react";
import classes from "./DropdownMenu.module.css";

const DropdownMenu = props => {
  const content = props.children.map((el, index) => (
    <div key={index} className={classes.Link}>{el}</div>
  ));
  return (
    <div
      className={`${classes.DropdownMenu} dropdown-menu shadow dropdown-menu-right`}
    >
      {content}
    </div>
  );
};

export default DropdownMenu;
