/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./ReviewsModal.module.css";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";
import UserPhoto from "../../User/UserPhoto/UserPhoto";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Error from "../../../UI/Error/Error";

const moment = require("moment");

let is_Mounted = false;

const ReviewsModal = (props) => {
  const [state, setState] = useState({
    results: [],
    total: 0,
    error: false,
    loading: true,
  });
  const [pages, setPages] = useState(null);

  const loadMoreResults = (event) => {
    event.preventDefault();
    fetchContent(false);
  };

  const rating = [];

  let product_rating = 0;

  if (props.product.rating) {
    product_rating = props.product.rating;
  }

  for (let index = 0; index < Math.floor(product_rating); index++) {
    rating.push(
      <span key={index} className={`${classes.RatingActive} fa fa-star`}></span>
    );
  }

  if (product_rating % 1) {
    rating.push(
      <span
        key={Date.now()}
        className={`${classes.RatingActive} fa fa-star-half-alt`}
      ></span>
    );
  }

  for (let index = Math.ceil(product_rating); index < 5; index++) {
    rating.push(<span key={index} className="fal fa-star"></span>);
  }

  let loadingContent = null;
  if (state.loading) {
    loadingContent = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.error) {
    loadingContent = (
      <div className={classes.Loading}>
        <Error error={state.error} refresh={loadMoreResults} />
      </div>
    );
  } else if (pages && pages.next) {
    loadingContent = (
      <div className={classes.Loading}>
        <a onClick={loadMoreResults} href="/">
          View More <span className="fa fa-chevron-down"></span>
        </a>
      </div>
    );
  }
  let content = null;
  if (state.results.length) {
    const reviews = state.results.map((el) => {
      const now = moment();
      const postMoment = moment(el.created_on);
      const daysDiff = now.diff(postMoment, "days");
      let formatDate;
      if (daysDiff >= 1) {
        formatDate = postMoment.calendar();
      } else {
        formatDate = postMoment.fromNow();
      }
      const rating = [];

      for (let index = 0; index < Math.floor(el.rating); index++) {
        rating.push(
          <span
            key={index}
            className={`${classes.RatingActive} fa fa-star`}
          ></span>
        );
      }

      for (let index = Math.ceil(el.rating); index < 5; index++) {
        rating.push(<span key={index} className="fal fa-star"></span>);
      }

      const reviewContent =
        "<p>" +
        el.review.replace(/\n{2,}/g, "</p><p>").replace(/\n/g, "<br>") +
        "</p>";

      return (
        <div key={el._id} className={classes.UserSummary}>
          <div className={classes.UserPhoto}>
            <UserPhoto photo={el.userId.profile_pic} width="48" height="48" />
          </div>
          <div className={classes.UserContent}>
            <div className={classes.Title}>
              <div className={classes.MainTitle}>
                <Link to={`/users/${el.userId.username}`}>
                  <h4>{el.userId.username}</h4>
                </Link>
                <p className={classes.Rating}>
                  <span className={classes.Stars}>{rating}</span>
                  <span className={classes.RatingNumber}>
                    {el.rating.toFixed(1)}
                  </span>
                </p>
              </div>
              <div className={classes.Handle}>
                <p>
                  <span className="fal fa-clock"></span> {formatDate}
                </p>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: reviewContent }}
                className={classes.MainReview}
              ></div>
            </div>
          </div>
        </div>
      );
    });
    content = (
      <div className={classes.Reviews}>
        <div className={classes.Info}>
          <p>
            <strong>{state.total}</strong> Review(s) from Verified Buyers
          </p>
        </div>
        {reviews}
      </div>
    );
  } else if (!state.loading && !state.error) {
    content = (
      <p className="f-12 p-3">This product does not have any reviews.</p>
    );
  }

  const fetchContent = (isRefresh = true) => {
    let page = 1;
    if (pages && pages.next && !isRefresh) {
      page = pages.next;
    }
    if (isRefresh) {
      setPages(null);
      setState({
        ...state,
        error: false,
        results: [],
        loading: true,
      });
    } else {
      setState({
        ...state,
        error: false,
        loading: true,
      });
    }
    const userToken = props.userToken;
    axios({
      method: "get",
      url: `/products/${props.product._id}/reviews?page=${page}`,
      headers: {
        Authorization: userToken ? userToken.token : undefined,
      },
    })
      .then((response) => {
        if (!is_Mounted) return;
        const results = response.data.data.reviews;
        const total = response.data.data.total;
        const pages = response.data.data.pages;
        setPages(pages);
        let newResults;
        if (!isRefresh) {
          const filteredResults = results.filter((el) => {
            return !state.results.find((res) => res._id === el._id);
          });
          newResults = [...state.results, ...filteredResults];
        } else {
          newResults = results;
        }
        setState({
          ...state,
          results: newResults,
          total: total,
          loading: false,
          error: false,
        });
      })
      .catch((error) => {
        if (!is_Mounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg,
          });
        }
      });
  };

  useEffect(() => {
    is_Mounted = true;
    window.$("#ReviewsModal").off("shown.bs.modal");
    window.$("#ReviewsModal").off("hidden.bs.modal");
    window.$("#ReviewsModal").modal("show");
    window.$("#ReviewsModal").on("shown.bs.modal", (event) => {
      fetchContent();
    });
    window.$("#ReviewsModal").on("hidden.bs.modal", (event) => {
      props.destroy();
    });
    return () => {
      is_Mounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#ReviewsModal").modal("hide");
    };
  }, []);

  return (
    <div className={classes.ReviewsModal}>
      <div
        id="ReviewsModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.ReviewsModalContent} modal-content`}>
            <div className={`${classes.ReviewsModalHeader} modal-header`}>
              <div>
                <p className={classes.Rating}>
                  <span className={classes.Stars}>{rating}</span>
                  <span className={classes.RatingNumber}>
                    {product_rating.toFixed(1)}
                  </span>
                </p>
                <h6>{props.product.title}</h6>
                <p>{`${
                  props.product.country.currency.symbol
                } ${props.product.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="ReviewsModalBody"
              className={`${classes.ReviewsModalBody} modal-body`}
            >
              {content}
              {loadingContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(ReviewsModal));
