/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
// import classes from "../Input.module.css";

const input = props => {
  useEffect(() => {
    var language = new window.Bloodhound({
      datumTokenizer: window.Bloodhound.tokenizers.obj.whitespace("name"),
      queryTokenizer: window.Bloodhound.tokenizers.whitespace,
      prefetch: {
        url: "https://api.efluence.ng/posts/variations",
        filter: function(list) {
          return window.$.map(list, function(item) {
            return { name: item.name };
          });
        }
      }
    });
    language.initialize();

    window.$(`#${props.id}`).tagsinput({
      confirmKeys: [13, 44],
      typeaheadjs: {
        name: "name",
        displayKey: "name",
        valueKey: "name",
        source: language.ttAdapter()
      }
    });

    const onTagChange = function(event) {
      // console.log(event);
      props.changeHandler(event);
    };

    window.$(`#${props.id}`).on("itemAdded", onTagChange);
    window.$(`#${props.id}`).on("itemRemoved", onTagChange);
    return () => {
      window.$(`#${props.id}`).off("itemAdded", onTagChange);
    };
  }, []);
  useEffect(() => {
    window.$(`#${props.id}`).tagsinput("removeAll");
    window.$(`#${props.id}`).tagsinput("add", props.value);
  }, [props.value]);
  let hint = null;
  let error = null;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    error = <div className="d-block invalid-feedback">{props.invalid}</div>;
  }
  let inputElement = (
    <React.Fragment>
      <input
        id={props.id}
        type={props.type ? props.type : "text"}
        className="form-control"
        value={props.value}
        onChange={props.changeHandler}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
    </React.Fragment>
  );
  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      {inputElement}
      {error ? error : hint}
    </div>
  );
};

export default input;
