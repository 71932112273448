/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classes from "../Input.module.css";
import locationClasses from "./LocationInput.module.css";
import axios from "axios";
import { connect } from "react-redux";
import { getClientId } from "../../../../../../util/util";

let input = "";
let is_Mounted = false;
let cancelToken;

const LocationInput = (props) => {
  useEffect(() => {
    is_Mounted = true;
    return () => {
      is_Mounted = false;
    };
  });
  const [places, setPlaces] = useState(null);
  const [loading, setLoading] = useState(false);

  const getPlaces = (input) => {
    if (cancelToken) {
      cancelToken.cancel("Reloading Google Places");
    }
    if (!input.length) {
      setLoading(false);
      setPlaces(null);
      return;
    }
    cancelToken = axios.CancelToken.source();
    const userToken = props.userToken;
    axios({
      baseURL: "https://api.efluence.ng",
      method: "get",
      url: `/users/places?input=${input}`,
      headers: {
        "X-Client-Hash": "1a26257c5fb5e4c7edc048035704ca0a",
        "X-Client-Id": getClientId(),
        Authorization: userToken.token,
      },
      cancelToken: cancelToken.token,
    })
      .then((response) => {
        if (!is_Mounted) return;
        const predictions = response.data.data.predictions;
        setLoading(false);
        setPlaces(predictions);
      })
      .catch((error) => {
        if (!is_Mounted || axios.isCancel(error)) return;
        setLoading(false);
        if (error.response) {
          const data = error.response.data;
          props.errorConfirm(input, data.error);
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          props.errorConfirm(input, errorMsg);
        }
      });
    setLoading(true);
    setPlaces(null);
  };

  const getPlacesHandler = (event) => {
    if (
      input.trim().toLowerCase() !== event.target.value.trim().toLowerCase()
    ) {
      getPlaces(event.target.value.trim());
    }
    input = event.target.value.trim();
  };

  const inputClasses = [classes.Input, "form-control border-0"];
  // if (props.invalid) {
  //   inputClasses.push("is-invalid");
  // }
  let hint = null;
  let error = null;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    error = <div className="d-block invalid-feedback">{props.invalid}</div>;
  }

  let placesContent = null;

  const suggestionHandler = (event, place) => {
    event.preventDefault();
    props.setLocation(place);
    setPlaces(null);
  };

  if (loading) {
    placesContent = (
      <div className={locationClasses.Loading}>
        <p>
          <span className="fas fa-spinner fa-spin" /> Getting Suggestions...
        </p>
      </div>
    );
  } else if (places !== null) {
    const suggestions = places.map((el) => {
      return (
        <div
          key={el.place_id}
          onClick={(event) => suggestionHandler(event, el)}
          className={locationClasses.Suggestion}
        >
          <i className="fal fa-map-marker-alt"></i>
          <p>{el.description}</p>
        </div>
      );
    });
    placesContent = (
      <div className={locationClasses.Suggestions}>
        {places.length ? (
          suggestions
        ) : (
          <small className="form-text text-muted">
            We cannot find suggestions for the location you entered. Please
            enter valid location
          </small>
        )}
      </div>
    );
  }

  let inputElement = (
    <React.Fragment>
      <input
        type={props.type ? props.type : "text"}
        className={inputClasses.join(" ")}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.changeHandler}
        onFocus={focusHandler}
        // onKeyDown={getPlacesHandler}
        onInput={getPlacesHandler}
        readOnly={props.readOnly}
        disabled={props.disabled}
        id={props.id}
        autoComplete="new-password"
      />
    </React.Fragment>
  );
  if (props.sideStyle) {
    inputElement = (
      <div className={`${classes.InputGroup} px-2 input-group rounded`}>
        <div className="input-group-prepend">
          <span
            style={{ backgroundColor: "#fff" }}
            className="input-group-text border-0"
          >
            {props.sideStyle.startsWith("fa") ? (
              <span className={props.sideStyle} />
            ) : (
              props.sideStyle
            )}
          </span>
        </div>
        {inputElement}
        <div className="input-group-append">
          {!props.confirming ? (
            <span
              onClick={props.editLocation}
              className={`${classes.LocationCancel} input-group-text border-0`}
            >
              <span className="fa fa-edit" />
            </span>
          ) : (
            <span
              className={`${classes.LocationCancel} input-group-text border-0`}
            >
              <span className="fas fa-spinner fa-spin" />
            </span>
          )}
        </div>
        {placesContent}
      </div>
    );
  }

  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      {inputElement}
      {error ? error : hint}
    </div>
  );
};

const focusHandler = (event) => {
  event.target.scrollIntoView();
};

const mapStateToProps = (state) => {
  return {
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(LocationInput);
