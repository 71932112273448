/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./AppUpdateModal.module.css";
import axios from "../../../axios/axios-instance";
import BrowserLinks from "../../UI/Links/BrowserLinks/BrowserLinks";
import { getMobileAppDownloadLink } from "../../../util/util";

const moment = require("moment");

let is_Mounted = false;

const AppUpdateModal = (props) => {
  const [timeContent, setTimeContent] = useState(null);
  const [updateContent, setUpdateContent] = useState(null);
  const [daysDiff, setDaysDiff] = useState(0);
  const updateHandler = () => {
    axios({
      method: "get",
      url: "auth/updates",
    })
      .then((response) => {
        if (!is_Mounted) return;
        const update = response.data.data.update.expired;
        const version = response.data.data.update.version;
        const content = response.data.data.update.content;
        if (window.VERSION >= version) {
          return;         
        }
        const now = moment();
        const postMoment = moment(update);
        const daysDiff = postMoment.diff(now, "days");
        setDaysDiff(daysDiff);
        if (daysDiff < 30) {
          const timeContent =
            daysDiff > 0
              ? `You have less than ${daysDiff} day(s) left to update your app.`
              : "";
          setTimeContent(timeContent);
          setUpdateContent(content);
          window.$("#appUpdateModal").modal({
            backdrop: "static",
            keyboard: false
          });
          window.$("#appUpdateModal").modal("show");
        }
      })
      .catch((error) => {
        if (!is_Mounted) return;
        console.log(error);
      });
  };

  useEffect(() => {
    is_Mounted = true;
    updateHandler();
    return () => {
      is_Mounted = false;
    };
  }, []);

  return (
    <div className={classes.AppUpdateModal}>
      <div
        id="appUpdateModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.AppUpdateModalContent} modal-content`}>
            <div className={`${classes.AppUpdateModalHeader} modal-header`}>
              <h5>Update Your App</h5>
            </div>
            <div
              id="appUpdateModalBody"
              className={`${classes.AppUpdateModalBody} modal-body`}
            >
              <div
                className={classes.UpdateContent}
                dangerouslySetInnerHTML={{ __html: updateContent }}
              ></div>
              <p className="my-2">
                {timeContent} Please update your app by clicking on the "Update"
                Button below. Thank you.
              </p>
              <div className="py-2">
                <BrowserLinks
                  to={getMobileAppDownloadLink()}
                  className="mr-1 btn btn-success btn-rounded"
                >
                  UPDATE APP
                </BrowserLinks>
                {daysDiff > 0 ? (
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-danger btn-rounded"
                  >
                    CONTINUE
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUpdateModal;
