import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./SocialOrderPage.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import SocialOrder from "../../../components/App/Order/SocialOrder/SocialOrder";
import SocialOrderTask from "./SocialOrderTask/SocialOrderTask";
import ImageModal from "../../../components/UI/Modal/ImageModal/ImageModal";
import PaymentCompletedModal from "../../../components/App/Order/PaymentCompletedModal/PaymentCompletedModal";
import PaymentModal from "../../../components/App/PaymentModal/PaymentModal";
import PaymentWalletModal from "../../../components/App/PaymentWalletModal/PaymentWalletModal";

class SocialOrderPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tasks: [],
      taskLoading: false,
      taskError: null,
      error: null,
      order: null,
      pages: null,
      image: null,
      task_review_instruction: null,
      show_payment_modal: false,
      show_wallet_payment: false,
      wallet_success: null,
      wallet_error: null,
      paymentCompleted: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    this.loadSocialOrder();
  }

  loadSocialOrder = (event) => {
    if (event) event.preventDefault();
    const userToken = this.props.userToken;
    const { id } = this.props.match.params;
    axios({
      method: "get",
      url: `/social/orders/${id}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const order = response.data.data.order;
        const task_review_instruction =
          response.data.data.task_review_instruction;
        let new_meta = response.data.data.meta;
        if (!new_meta) new_meta = {};
        let meta = this.props.meta;
        this.props.setMeta({ ...meta, ...new_meta });
        this.setState(
          {
            loading: false,
            order: order,
            task_review_instruction: task_review_instruction,
          },
          this.loadTasks
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      order: null,
      loading: true,
      error: null,
      show_wallet_payment: false,
      show_payment_modal: false,
      paymentCompleted: null,
    });
  };

  loadTasks = (isRefresh = true) => {
    let page = 1;
    if (!isRefresh && this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    const { id } = this.props.match.params;
    axios({
      method: "get",
      url: `/social/orders/${id}/tasks?page=${page}`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const tasks = response.data.data.tasks;
        const filteredTasks = tasks.filter((el) => {
          return !this.state.tasks.find((task) => task._id === el._id);
        });
        const updatedTasks = [...this.state.tasks, ...filteredTasks];
        const pages = response.data.data.pages;
        this.setState({
          taskLoading: false,
          pages: pages,
          tasks: updatedTasks,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              taskLoading: false,
              taskError: data.error[0].msg,
            });
          } else {
            this.setState({
              taskLoading: false,
              taskError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            taskLoading: false,
            taskError: errorMsg,
          });
        }
      });
    this.setState({
      taskLoading: true,
      taskError: null,
    });
    if (isRefresh) {
      this.setState({
        tasks: [],
      });
    }
  };

  loadMoreTasks = (event) => {
    event.preventDefault();
    this.loadTasks(false);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  showImage = (image) => {
    this.setState(
      {
        image: image,
      },
      () => {
        document.getElementById("imageLightModal").style.display = "block";
      }
    );
  };

  hideImage = () => {
    this.setState({
      image: null,
    });
  };

  payWithFlutterwave = () => {
    if (!this.props.user) {
      this.props.history.push("/auth/login");
      return;
    }
    this.close_payment_modal();
    const order = this.state.order;
    const order_total = order.social_task_category.pricing.buyer * order.amount;
    let total = order_total;
    window.FlutterwaveCheckout({
      public_key: this.props.meta.flutterwave_public_key,
      tx_ref: `Order-${order._id}-${Date.now()}`,
      amount: total,
      currency: this.props.user.country.currency.short,
      country: this.props.user.country.code,
      customer: {
        username: this.props.user.username,
        email: this.props.user.email,
        name: this.props.user.name,
      },
      callback: (data) => {
        if (data.status === "successful") {
          if (window.fbq) {
            window.fbq("track", "Purchase", {
              currency: "NGN",
              content_name: order.social_task_category.title.buyer,
              content_category: "Social_Task",
              content_type: "social_task",
              value: total,
            });
          }
          this.setState({
            paymentCompleted: order._id,
          });
        }
      },
      onclose: () => {},
    });
  };

  payWithWallet = () => {
    this.close_payment_modal();
    if (!this.state.order) return;
    const userToken = this.props.userToken;
    const order = this.state.order;
    const order_total = order.social_task_category.pricing.buyer * order.amount;
    axios({
      method: "post",
      url: `/social/order/${this.state.order._id}/pay-with-wallet`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        if (window.fbq) {
          window.fbq("track", "Purchase", {
            currency: "NGN",
            content_name: order.social_task_category.title.buyer,
            content_category: "Social_Task",
            content_type: "social_task",
            value: order_total,
          });
        }
        const status = response.data.data.status;
        const user = response.data.data.user;
        this.props.processUser(user);
        this.setState({
          wallet_success: status,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              wallet_error: data.error[0].msg,
            });
          } else {
            this.setState({
              wallet_error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            wallet_error: errorMsg,
          });
        }
      });
    this.setState({
      wallet_success: null,
      wallet_error: null,
      show_wallet_payment: true,
    });
  };

  close_payment_modal = () => {
    this.setState({ show_payment_modal: false });
  };

  open_payment_modal = () => {
    this.setState({ show_payment_modal: true });
  };

  close_wallet_payment_modal = () => {
    this.setState({ show_wallet_payment: false });
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadSocialOrder} error={this.state.error} />
        </div>
      );
    }

    let taskLoadingContent = null,
      taskContent = null;

    if (this.state.pages && this.state.pages.next) {
      taskLoadingContent = (
        <div className={classes.PostLoading}>
          <a onClick={this.loadMoreTasks} href="/">
            Load More <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    } else if (this.state.taskLoading) {
      taskLoadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.taskError) {
      taskLoadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadTasks} error={this.state.taskError} />
        </div>
      );
    }
    let content = loadingContent;
    const order = this.state.order;

    if (this.state.tasks.length) {
      const tasks = this.state.tasks.map((task, index) => {
        return (
          <div key={index} className={classes.SocialOrderTask}>
            <SocialOrderTask showImage={this.showImage} task={task} />
          </div>
        );
      });
      taskContent = <div className={classes.SocialOrderTasks}>{tasks}</div>;
    } else if (!this.state.taskLoading && !this.state.taskError) {
      taskContent = (
        <div className={classes.TasksEmpty}>
          <i className="fal fa-spin fa-spinner"></i>
          <p className="f-10">
            Your order has not yet been allocated to any user. Please wait while
            we allocate users to begin to perform your task for you.
          </p>
        </div>
      );
    }

    if (order) {
      content = (
        <>
          <div className={classes.SocialOrder}>
            <SocialOrder
              pay={this.open_payment_modal}
              updateTask={this.updateTask}
              order={order}
              show_info
            />
          </div>
          {this.state.task_review_instruction ? (
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.task_review_instruction,
              }}
            ></div>
          ) : null}
          <div className={classes.Results}>
            <h5>Allocation Results</h5>
            <p className="mb-2">
              Your order will be allocated to various users so they can perform
              your task for you. You have to verify each of the tasks performed
              by the users below.
            </p>
          </div>
          {taskContent}
          {taskLoadingContent}
        </>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <div className={classes.SocialOrderPage}>{content}</div>
            <ImageModal close={this.hideImage} src={this.state.image} />
            {this.state.paymentCompleted ? (
              <PaymentCompletedModal
                description="Your Payment is successful. Thank you for completing your order. Your order has been created and you will begin to see results shortly."
                refresh={this.loadSocialOrder}
                goBack="VIEW & MONITOR YOUR ORDER"
              />
            ) : null}
            {this.state.order && this.state.show_payment_modal ? (
              <PaymentModal
                pay_online={this.payWithFlutterwave}
                pay_wallet={this.payWithWallet}
                amount={
                  this.state.order.social_task_category.pricing.buyer *
                  this.state.order.amount
                }
                close={this.close_payment_modal}
              />
            ) : null}
            {this.state.order && this.state.show_wallet_payment ? (
              <PaymentWalletModal
                go_success={this.loadSocialOrder}
                success={this.state.wallet_success}
                error={this.state.wallet_error}
                cancel={this.close_wallet_payment_modal}
              />
            ) : null}
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
    setMeta: (meta) => dispatch(actionCreators.setMeta(meta)),
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialOrderPage);
