/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component } from "react";
import {
  createSelectElement,
  createButtonElement,
  createInputElement,
} from "../../../UI/Forms/utility/form-creators";
import * as validators from "../../../UI/Forms/utility/validator";
import Form from "../../../UI/Forms/Form";
import classes from "./BuyModal.module.css";
import axios from "../../../../axios/axios-instance";
import AlertError from "../../../UI/Error/AlertError/AlertError";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import { withRouter } from "react-router-dom";
import { getDeliveryDate, getReseller } from "../../../../util/util";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../UI/Error/Error";

const moment = require("moment");

class BuyModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      completed: 0,
      formData: null,
      deliveryFormData: null,
      country_states: null,
    };
  }

  initialiseFormData = () => {
    const quantitiesList = [];
    let country_states = this.props.country_states;
    if (!country_states || !country_states.length) {
      country_states = this.state.country_states;
    }
    let state = null;
    if (this.props.user) {
      state = country_states.find((el) => {
        return el.name === this.props.user.location.state;
      });
    }
    let lgas;
    if (state) {
      lgas = state.locals.map((el) => {
        return {
          value: el.name,
          option: el.name,
        };
      });
    }
    for (let index = 0; index < this.props.post.product.quantities; index++) {
      quantitiesList.push({
        value: index + 1,
        option: index + 1,
      });
    }
    let optionsList = [];
    if (
      this.props.post.product.options &&
      this.props.post.product.options.length
    ) {
      optionsList = this.props.post.product.options.map((option) => {
        return {
          value: option,
          option: option,
        };
      });
    }
    let coloursList = [];
    if (
      this.props.post.product.colours &&
      this.props.post.product.colours.length
    ) {
      coloursList = this.props.post.product.colours
        ? this.props.post.product.colours.map((option) => {
            return {
              value: option,
              option: option,
            };
          })
        : [];
    }
    let formData = {
      quantity: createSelectElement(
        "Quantities",
        this.validateQuantity,
        "fas fa-list",
        "col-sm-12",
        quantitiesList,
        "Select Quantities",
        this.inputChangedHandler.bind(this, "quantity"),
        ""
      ),
    };
    if (optionsList.length) {
      formData = {
        ...formData,
        options: createSelectElement(
          "Your Desired Size",
          this.validateOption,
          "fas fa-list",
          "col-sm-12",
          optionsList,
          "Select Size",
          this.inputChangedHandler.bind(this, "options"),
          "",
          "Please make sure you select your exact size. The seller will deliver exactly what you selected above."
        ),
      };
    }
    if (coloursList.length) {
      formData = {
        ...formData,
        colours: createSelectElement(
          "Your Desired Colour",
          this.validateColour,
          "fas fa-list",
          "col-sm-12",
          coloursList,
          "Select Option",
          this.inputChangedHandler.bind(this, "colours"),
          "",
          "Please make sure you select your desired colour. The seller will deliver exactly what you selected above."
        ),
      };
    }
    let contactFormData = {
      name: createInputElement(
        "text",
        "Your Full Name",
        "Enter Your Name",
        "",
        this.validateName,
        "Please enter your full name so we can contact you easily",
        "fa fa-user",
        "col-sm-12",
        this.contactInputChangedHandler.bind(this, "name"),
        ""
      ),
      phoneNumber: createInputElement(
        "text",
        "Your Phone Number",
        "Enter Your Phone Number",
        "",
        this.validatePhoneNumber,
        "Please enter the phone number that should be contacted when we want to deliver your order.",
        "fa fa-phone-alt",
        "col-sm-12",
        this.contactInputChangedHandler.bind(this, "phoneNumber"),
        ""
      ),
    };
    let deliveryFormData = {
      state: createSelectElement(
        "",
        this.validateState,
        "fas fa-list",
        "col-sm-12",
        country_states.map((el) => {
          return {
            value: el.name,
            option: el.name,
          };
        }),
        "Select Your Delivery State",
        this.deliveryInputChangedHandler.bind(this, "state"),
        this.props.user && this.props.user.location.state
          ? this.props.user.location.state
          : ""
      ),
      lga: createSelectElement(
        "",
        this.validateLGA,
        "fas fa-list",
        "col-sm-12",
        lgas,
        "Select Delivery Area",
        this.deliveryInputChangedHandler.bind(this, "lga"),
        this.props.user && this.props.user.location.lga
          ? this.props.user.location.lga
          : "",
        "You will have to select your state before selecting the LGA."
      ),
      location: createInputElement(
        "text",
        "",
        "Enter Full Delivery Address",
        this.props.user && this.props.user.location.address
          ? this.props.user.location.address
          : "",
        this.validateLocation,
        "Please enter your full delivery address such as Building No., Street, Area, City, State.",
        "fa fa-map-marker-alt",
        "col-sm-12",
        this.deliveryInputChangedHandler.bind(this, "location"),
        "locationField"
      ),
    };
    if (!this.props.user) {
      contactFormData = {
        ...contactFormData,
        email: createInputElement(
          "text",
          "Your Email Address",
          "Enter Your Email Address",
          "",
          this.validateEmail,
          "Your order details and login details will be sent to your email address. Ensure you enter a valid email address.",
          "fa fa-envelope",
          "col-sm-12",
          this.contactInputChangedHandler.bind(this, "email"),
          ""
        ),
      };
    }
    this.setState({
      formData: {
        ...formData,
        submit: createButtonElement(
          "submit",
          "SUBMIT AND PROCEED TO DELIVERY",
          true
        ),
      },
      deliveryFormData: {
        ...deliveryFormData,
        submit: createButtonElement("submit", "SUBMIT DELIVERY LOCATION", true),
      },
      contactFormData: {
        ...contactFormData,
        submit: createButtonElement("submit", "SUBMIT ORDER AND PAY", true),
      },
    });
  };

  loadCountryStates = () => {
    axios("/users/states")
      .then((response) => {
        if (!this._isMounted) return;
        const states = response.data.data.states;
        this.setState(
          {
            loading: false,
            error: null,
            country_states: states,
          },
          this.initialiseFormData
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          this.setState({
            loading: false,
            error: data.error,
          });
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  validateQuantity = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select the number of quantities you want to order.";
    }
    if (+value > this.props.post.product.quantities) {
      return "The option you selected is not in the above list. Please select a valid option from the above list.";
    }
    return null;
  };

  validateOption = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your desired size";
    }
    if (!this.props.post.product.options.includes(value.trim())) {
      return "The size you selected is not in the above list. Please select a valid size from the above list.";
    }
    return null;
  };

  validateName(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter the name of your account.";
    }
    if (!validators.validateLength(value.trim(), 3, 50)) {
      return "Please enter valid name of account. 3 - 50 characters are allowed";
    }
    if (!validators.validateName(value.trim())) {
      return "Please enter valid name of account. Only letters are allowed";
    }
    return null;
  }

  validateEmail(value) {
    if (validators.isEmpty(value)) {
      return "Please enter a valid email address!";
    }
    if (!validators.checkEmail(value)) {
      return "Enter valid email address.";
    }
    return null;
  }

  validatePhoneNumber(value) {
    if (validators.isEmpty(value.trim())) {
      return "Pleaase enter valid phone number!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Phone Number. Only numbers are allowed";
    }
    if (!validators.validateLength(value.trim(), 5, 11)) {
      return "Please enter valid phone number. Don't enter the country code (e.g +234). Only the phone number (e.g 080******) is needed";
    }
    return null;
  }

  validateColour = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your desired colour";
    }
    if (!this.props.post.product.colours.includes(value.trim())) {
      return "The colour you selected is not in the above list. Please select a valid colour from the above list.";
    }
    return null;
  };

  validateLGA = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your LGA from the list above. Make sure you select the state first before selecting the LGA.";
    }
    return null;
  };

  validateState = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your state from the list above.";
    }
    return null;
  };

  validateLocation = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter your full address.";
    }
    if (!validators.validateLength(value.trim(), 3, 500)) {
      return "Please enter your full location such as Building No., Street, Area, City, State.";
    }
    return null;
  };

  componentDidMount = () => {
    this._isMounted = true;
    if (this.props.country_states && this.props.country_states.length) {
      this.initialiseFormData();
    } else {
      this.loadCountryStates();
    }
    window.$("#buyModal").modal({
      backdrop: "static",
    });
    window.$("#buyModal").modal("show");
    window.$("#buyModal").off("hidden.bs.modal");
    window.$("#buyModal").on("hidden.bs.modal", (event) => {
      this.props.destroy();
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.$(".modal-backdrop").remove();
    window.$("body").removeClass("modal-open");
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({
      formData: updatedFormData,
    });
  };

  contactInputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.contactFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.contactFormData,
      [name]: formElementData,
    };
    this.setState({
      contactFormData: updatedFormData,
    });
  };

  deliveryInputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.deliveryFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.deliveryFormData,
      [name]: formElementData,
    };
    if (name === "state") {
      let country_states = this.props.country_states;
      if (!country_states || !country_states.length) {
        country_states = this.state.country_states;
      }
      const state = country_states.find((el) => {
        return el.name === formElementData.value;
      });
      const lgas = state.locals.map((el) => {
        return {
          value: el.name,
          option: el.name,
        };
      });
      updatedFormData.lga = {
        ...updatedFormData.lga,
        value: "",
        options: lgas,
      };
    }
    this.setState({
      deliveryFormData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  checkIsValidDeliveryFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.deliveryFormData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      deliveryFormData: formData,
    });
    return isFormActivated;
  }

  checkIsValidContactFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.contactFormData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      contactFormData: formData,
    });
    return isFormActivated;
  }

  submitHandler = async (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      this.setState({
        completed: 0,
      });
      return false;
    }
    if (!this.checkIsValidDeliveryFormData()) {
      this.setState({
        completed: 1,
      });
      return false;
    }
    if (!this.checkIsValidContactFormData()) {
      this.setState({
        completed: 2,
      });
      return false;
    }
    const userToken = this.props.userToken;
    const reseller = getReseller();
    const state = this.state.deliveryFormData.state.value;
    const lga = this.state.deliveryFormData.lga.value;
    const location = this.state.deliveryFormData.location.value;
    const phoneNumber = this.state.contactFormData.phoneNumber.value;
    const name = this.state.contactFormData.name.value;
    let data = {
      postId: this.props.post._id,
      quantity: this.state.formData.quantity.value,
      state: state,
      lga: lga,
      location: location,
      phoneNumber: phoneNumber,
      name: name,
      reseller: reseller
    };
    if (this.state.formData.options) {
      data = {
        ...data,
        options: this.state.formData.options.value,
      };
    }
    if (this.state.contactFormData.email) {
      data = {
        ...data,
        email: this.state.contactFormData.email.value,
      };
    }
    if (this.state.formData.colours) {
      data = {
        ...data,
        colours: this.state.formData.colours.value,
      };
    }
    axios({
      method: "post",
      url: `/orders`,
      data: data,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        this.setState({
          loading: false,
        });
        const order_id = response.data.data.order.id;
        const user = response.data.data.user;
        const auth = response.data.data.auth;
        if (this.props.userToken) {
          this.props.processUser(user);
        }
        if (auth) {
          this.props.clientLogin(auth);
        }
        this.props.history.push(`/orders/checkout/${order_id}`);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            const err1 = this.createFormError(data.error);
            const err3 = this.createContactFormError(data.error);
            const err2 = this.createDeliveryFormError(data.error);
            if (err1) {
              this.setState({
                completed: 0,
                formData: err1,
              });
            } else if (err2) {
              this.setState({
                completed: 1,
                deliveryFormData: err2,
              });
            } else if (err3) {
              this.setState({
                completed: 2,
                contactFormData: err3,
              });
            }
            this.setState({
              loading: false,
              error:
                "There is an error in some of the entries you submitted. Please check through your entries and try again",
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  nextHandler = async (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    this.setState({
      completed: 1,
    });
  };

  contactHandler = async (event) => {
    event.preventDefault();
    if (!this.checkIsValidDeliveryFormData()) {
      return false;
    }
    this.setState({
      completed: 2,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      if (el.param in this.state.formData) {
        errorForm[el.param] = {
          ...this.state.formData[el.param],
          invalid: el.msg,
        };
      }
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  createContactFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      if (el.param in this.state.contactFormData) {
        errorForm[el.param] = {
          ...this.state.contactFormData[el.param],
          invalid: el.msg,
        };
      }
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.contactFormData,
      ...errorForm,
    };
  }

  createDeliveryFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      if (el.param in this.state.deliveryFormData) {
        errorForm[el.param] = {
          ...this.state.deliveryFormData[el.param],
          invalid: el.msg,
        };
      }
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.deliveryFormData,
      ...errorForm,
    };
  }

  closeHandler = (event) => {
    event.preventDefault();
    window.$("#buyModal").modal("hide");
  };

  reloadCountryStates = (event) => {
    event.preventDefault();
    this.loadCountryStates();
  };

  back = () => {
    this.setState((state) => {
      return {
        completed: state.completed <= 0 ? 0 : state.completed - 1,
        error: null,
      };
    });
  };

  render() {
    const deliveryMoment = moment(
      getDeliveryDate(
        this.props.post.product.delivery_days
          ? this.props.post.product.delivery_days
          : 5
      )
    );

    let content = null;

    const returnPolicyContent = (
      <div className={classes.Report}>
        <div className="mr-2">
          <i className="fad fa-truck"></i>
        </div>
        <div className={classes.ReportContent}>
          <h5>Return Policy</h5>
          <p>
            We have a very good return policy that allows you to return faulty
            or wrong items that are delivered to you. You can return the item
            within <b>{this.props.post.product.return_policy} day(s)</b> after
            purchase if the product is in good condition.
          </p>
        </div>
      </div>
    );

    if (
      (this.props.country_states && this.props.country_states.length) ||
      this.state.country_states
    ) {
      if (this.state.completed === 1) {
        content = (
          <>
            <div className={classes.DeliveryInfo}>
              <div className={classes.BackButton}>
                <button onClick={this.back}>
                  <i className="fas fa-chevron-left"></i>
                </button>
              </div>
              <div className={classes.DeliveryContent}>
                <h4>Delivery Details</h4>
                <p>
                  Please enter your delivery address. Make sure you enter a well
                  detailed delivery address so we can get the product right to
                  you.
                </p>
              </div>
            </div>
            <div className={classes.OrderForm}>
              {this.state.error ? (
                <AlertError error={this.state.error} />
              ) : null}
              <Form
                loading={this.state.loading}
                formData={this.state.deliveryFormData}
                submit={this.contactHandler}
              />
            </div>
            {returnPolicyContent}
          </>
        );
      } else if (this.state.completed === 2) {
        content = (
          <>
            <div className={classes.DeliveryInfo}>
              <div className={classes.BackButton}>
                <button onClick={this.back}>
                  <i className="fas fa-chevron-left"></i>
                </button>
              </div>
              <div className={classes.DeliveryContent}>
                <h4>Contact Details</h4>
                <p>
                  Please enter your contact details below so we can contact you
                  properly in order ensure your product is delivered to you
                </p>
              </div>
            </div>
            <div className={classes.OrderForm}>
              {this.state.error ? (
                <AlertError error={this.state.error} />
              ) : null}
              <Form
                loading={this.state.loading}
                formData={this.state.contactFormData}
                submit={this.submitHandler}
              />
            </div>
            {returnPolicyContent}
          </>
        );
      } else {
        content = (
          <>
            <div className={classes.DeliveryInfo}>
              <p>
                This item will be delivered on or before:
                <br />
                <span>{deliveryMoment.format("dddd, MMMM Do")}</span>
              </p>
            </div>
            <div className={classes.OrderForm}>
              {this.state.error ? (
                <AlertError error={this.state.error} />
              ) : null}
              <Form
                loading={this.state.loading}
                formData={this.state.formData}
                submit={this.nextHandler}
              />
            </div>
            {returnPolicyContent}
          </>
        );
      }
    } else if (this.state.loading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className={classes.Loading}>
          <Error error={this.state.error} refresh={this.reloadCountryStates} />
        </div>
      );
    }

    return (
      <div className={classes.BuyModal}>
        <div
          id="buyModal"
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby=""
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`${classes.BuyModalContent} modal-content`}>
              <div className={`${classes.BuyModalHeader} modal-header`}>
                <div className={classes.BuyModalTitle}>
                  <h5>{this.props.post.product.title}</h5>
                  <p>
                    {`${
                      this.props.post.product.country.currency.symbol
                    } ${this.props.post.product.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                id="BuyModalBody"
                className={`${classes.BuyModalBody} modal-body`}
              >
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    country_states: state.userState.country_states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clientLogin: (token) => dispatch(actionCreators.clientLogin(token)),
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyModal));
