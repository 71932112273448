/* eslint-disable no-undef */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./ExploreUsers.module.css";
import Form from "../../../components/UI/Forms/Form";
import { createSearchInputElement } from "../../../components/UI/Forms/utility/form-creators";
import axios from "axios";
import Error from "../../../components/UI/Error/Error";
import { Link } from "react-router-dom";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import ShowUsers from "../../../components/App/User/ShowUsers/ShowUsers";
import { getClientId, getBaseUrl } from "../../../util/util";

let cancelToken;

class ExploreUsers extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      activeSearch: null,
      searchFormData: {
        search: createSearchInputElement(
          "Find All Users",
          "",
          this.searchChangeHandler
        ),
      },
      pages: null,
      loading: false,
      viewedAll: false,
      error: null,
      users: [],
    };
  }

  loadTopUsers = () => {
    if (cancelToken) {
      cancelToken.cancel("Refreshing Search...");
    }
    cancelToken = axios.CancelToken.source();
    let page = 1;
    let activeSearch = this.state.activeSearch ? this.state.activeSearch : "";
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    const url = `/search/users?page=${page}&query=${activeSearch}`;
    axios({
      baseURL: getBaseUrl(false),
      method: "get",
      url: url,
      headers: {
        "X-Client-Hash": "1a26257c5fb5e4c7edc048035704ca0a",
        "X-Client-Id": getClientId(),
        Authorization: userToken ? userToken.token : null,
      },
      cancelToken: cancelToken.token,
    })
      .then((response) => {
        if (!this._isMounted) return;
        const users = response.data.data.users;
        const pages = response.data.data.pages;
        const filteredUsers = users.filter((el) => {
          return !this.state.users.find((user) => user._id === el._id);
        });
        const updatedUsers = [...this.state.users, ...filteredUsers];
        this.setState(
          {
            loading: false,
            users: updatedUsers,
            pages: pages,
          },
          () => {
            this.setViewedAll(() => {
              const usersHeight = $("#usersContent").height();
              if (usersHeight < $(window).height() && !this.state.viewedAll) {
                this.loadTopUsers();
              }
            });
          }
        );
      })
      .catch((error) => {
        if (!this._isMounted || axios.isCancel(error)) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      viewedAll: false,
    });
    window.removeEventListener("scroll", this.autoLoadEventListener);
    window.addEventListener("scroll", this.autoLoadEventListener);
  };

  autoLoadEventListener = () => {
    const scrollHeight = $(window).scrollTop();
    const bottomY = $(document).height() - $(window).height();
    const offset = 1000;
    if (scrollHeight >= bottomY - offset) {
      this.loadTopUsers();
    }
  };

  setViewedAll = (callback) => {
    if (this.state.pages && this.state.pages.next) {
      callback();
      return;
    }
    this.setState({
      viewedAll: true,
    });
    window.removeEventListener("scroll", this.autoLoadEventListener);
  };

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    const isSearch = this.searchHandler();
    if (!isSearch) {
      this.loadTopUsers();
    }
  }

  searchHandler = () => {
    let querySearch = this.props.location.search;
    if (!querySearch) {
      querySearch = "";
    }
    let query = querySearch.split("=")[1];
    if (!query) {
      query = "";
    }
    const fullQuery = decodeURIComponent(query).split("+").join(" ");
    const activeSearch = this.state.activeSearch;
    if (activeSearch === fullQuery) {
      return false;
    }
    this.setState(
      {
        activeSearch: fullQuery,
        users: [],
        pages: null,
        searchFormData: {
          search: {
            ...this.state.searchFormData.search,
            value: fullQuery,
          },
        },
      },
      this.loadTopUsers
    );
    return true;
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const isSearch = this.searchHandler();
      if (!isSearch) {
        this.loadTopUsers();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.autoLoadEventListener);
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadTopUsers(this.state.pages ? this.state.pages.next : null);
  };

  searchChangeHandler = (event) => {
    event.preventDefault();
    const formElementData = { ...this.state.searchFormData.search };
    formElementData.value = event.target.value;
    const updatedFormData = {
      search: formElementData,
    };
    this.setState({ searchFormData: updatedFormData });
  };

  searchFormSubmit = (event) => {
    event.preventDefault();
    let query = this.state.searchFormData.search.value;
    query = query.trim().replace(/ /g, "+");
    this.props.history.replace(`/users?query=${query}`);
    return false;
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    if (this.state.viewedAll) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <div className={classes.ViewedAllContainer}>
            <p className="mb-2 text-info">
              <span className="fad fa-2x fa-search-location"></span>
            </p>
            <h4 className="mb-2">No more results to show!</h4>
            <p className="mb-2">
              There are no more buyers/sellers to show under your search query.
            </p>
            <p>
              <Link
                replace
                to="/sell"
                className="mr-2 btn btn-coloured-heavy btn-rounded"
              >
                <span className="fas fa-search"></span> Start Selling
              </Link>
            </p>
          </div>
        </div>
      );
    }
    const usersContent = (
      <div
        id="usersContent"
        className={`${classes.UsersContent} ${
          !this.state.activeSearch ? classes.TopPadding : ""
        }`}
      >
        <ShowUsers activeUser={this.props.user} users={this.state.users} />
      </div>
    );
    let content = (
      <div className={classes.ExploreTrending}>
        {usersContent}
        {loadingContent}
      </div>
    );
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <div className={classes.SearchForm}>
                    <Form
                      formData={this.state.searchFormData}
                      submit={this.searchFormSubmit}
                    />
                  </div>
                </TitleHeader>
              </Title>
              <div className={classes.Explore}>{content}</div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(ExploreUsers);
