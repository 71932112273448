import React, { Component } from "react";
import classes from "./UserReviews.module.css";
import Error from "../../../../components/UI/Error/Error";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import axios from "../../../../axios/axios-instance";
import UserPhoto from "../../../../components/App/User/UserPhoto/UserPhoto";
import { Link } from "react-router-dom";

const moment = require("moment");

class UserReviews extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      reviews: [],
    };
  }

  loadUserReviews = () => {
    let page = 1;
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    const { username } = this.props.match.params;
    const url = `/users/${username}/reviews?page=${page}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const reviews = response.data.data.reviews;
        const pages = response.data.data.pages;
        const filteredReviews = reviews.filter((el) => {
          return !this.state.reviews.find((review) => review._id === el._id);
        });
        const updatedReviews = [...this.state.reviews, ...filteredReviews];
        this.setState({
          reviews: updatedReviews,
          pages: pages,
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  loadMoreReviews = (event) => {
    event.preventDefault();
    this.loadUserReviews();
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadUserReviews();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.Loading}>
          <a onClick={this.loadMoreReviews} href="/">
            Load More Reviews <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.Loading}>
          <Error refresh={this.loadMoreReviews} error={this.state.error} />
        </div>
      );
    }

    let content = null;
    if (this.state.reviews.length) {
      const reviews = this.state.reviews.map((el) => {
        const now = moment();
        const postMoment = moment(el.created_on);
        const daysDiff = now.diff(postMoment, "days");
        let formatDate;
        if (daysDiff >= 1) {
          formatDate = postMoment.calendar();
        } else {
          formatDate = postMoment.fromNow();
        }
        const rating = [];

        for (let index = 0; index < Math.floor(el.rating); index++) {
          rating.push(
            <span
              key={index}
              className={`${classes.RatingActive} fa fa-star`}
            ></span>
          );
        }

        for (let index = Math.ceil(el.rating); index < 5; index++) {
          rating.push(<span key={index} className="fal fa-star"></span>);
        }

        const reviewContent =
          "<p>" +
          el.review.replace(/\n{2,}/g, "</p><p>").replace(/\n/g, "<br>") +
          "</p>";

        return (
          <div key={el._id} className={classes.UserSummary}>
            <div className={classes.UserPhoto}>
              <UserPhoto photo={el.userId.profile_pic} width="48" height="48" />
            </div>
            <div className={classes.UserContent}>
              <div className={classes.Title}>
                <div className={classes.MainTitle}>
                  <Link to={`/users/${el.userId.username}`}>
                    <h4>{el.userId.username}</h4>
                  </Link>
                  <p className={classes.Rating}>
                    <span className={classes.Stars}>{rating}</span>
                    <span className={classes.RatingNumber}>
                      {el.rating.toFixed(1)}
                    </span>
                  </p>
                </div>
                <div className={classes.Handle}>
                  <p>
                    <span className="fal fa-clock"></span> {formatDate}
                  </p>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: reviewContent }}
                  className={classes.MainReview}
                ></div>
              </div>
            </div>
          </div>
        );
      });
      content = <div className={classes.Reviews}>{reviews}</div>;
    } else if (!this.state.loading && !this.state.error) {
      content = (
        <div className={`bg-white ${classes.Loading}`}>
          <i className="fal fa-bell-slash"></i>
          <p className="mb-3">This user does not have any reviews yet.</p>
          <button
            className="btn btn-coloured-heavy"
            onClick={this.loadUserReviews}
          >
            Try Again
          </button>
        </div>
      );
    }

    return (
      <div className={classes.UserReviews}>
        {content}
        {loadingContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(UserReviews);
