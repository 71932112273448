import io from "socket.io-client";

let socket;

export default {
  init: (httpServer) => {
    socket = io(httpServer, {transports: ['websocket'], upgrade: false});
    return socket;
  },
  getSocket: () => {
    if (!socket) {
      return null;
    }
    return socket;
  },
  connectClient: (client) => {
    if (!socket) return;
    socket.emit("connect client", {
      client: client,
    });
  },
  connectUser: (user) => {
    if (!socket) return;    
    socket.emit("connect user", {
      userId: user._id,
    });
  },
  disconnectUser: () => {
    if (!socket) return;
    socket.emit("disconnect user", {});
  },
  disconnect: () => {
    if (socket) {
      socket.close();
      socket = undefined;
    }
  },
};
