import React from "react";
import classes from "./Error.module.css";

const Error = props => {
  return (
    <div className={classes.Error}>
      <div className={classes.ErrorContent}>
        <i
          style={{ color: props.iconColor }}
          className="fa fa-times-circle mb-2"
        ></i>
        <p style={{ color: props.color }}>{props.error}</p>
        <button onClick={props.refresh} className="mt-3 btn btn-info btn-xl">
          Try Again
        </button>
      </div>
    </div>
  );
};

export default Error;
