/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import TitleLinks from "../../../components/App/Layout/Title/TitleLinks";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "../EditProduct/EditProduct.module.css";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import { connect } from "react-redux";
import { isNumeric, isInt } from "validator";
import * as validators from "../../../components/UI/Forms/utility/validator";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import UploadSpinner from "../../../components/UI/Spinner/UploadSpinner/UploadSpinner";
import {
  createInputElement,
  createTagInputElement,
  createPriceInputElement,
  createTextAreaElement,
  createSelectElement,
} from "../../../components/UI/Forms/utility/form-creators";
import Form from "../../../components/UI/Forms/Form";
import LazyImage from "../../../hoc/LazyImage/LazyImage";
import PreviewImages from "../../../components/App/CreatePost/PreviewImages/PreviewImages";
import { Link } from "react-router-dom";

const moment = require("moment");

class EditProduct extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      loading: true,
      commission: null,
      categories: null,
      category: null,
      product: null,
      error: null,
      success: false,
      submitting: false,
      submitError: null,
      photos: [],
      previousImages: [],
      formData: null,
      categoryFormData: null,
      detailFormData: null,
    };
  }

  initialiseFormData = () => {
    const returnOptions = [];
    for (let i = 7; i <= 30; i++) {
      returnOptions.push({
        value: i,
        option: `${i} day(s) Return Policy`,
      });
    }
    const days = [];
    for (let i = 1; i <= 14; i++) {
      days.push({
        value: i,
        option: `${i} day(s)`,
      });
    }
    const categoryArray = [];
    let category = this.state.categories.find(
      (el) => el.value === this.state.product.category
    );
    while (category) {
      categoryArray.unshift(category);
      if (category.category) {
        category = this.state.categories.find(
          (el) => el.value === category.category
        );
      } else {
        category = null;
      }
    }
    const formData = {
      title: createInputElement(
        "text",
        "Title",
        "Enter clear and attractive title",
        this.state.product.title,
        this.validateTitle,
        "The title of your product should be very accurate and attractive. E.g the title of a fashion product can be 'High Quality Round Neck Men's Shirt - Blue'",
        "fas fa-check-circle",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "title"),
        "titleField"
      ),
      quantity: createInputElement(
        "text",
        "Quantities Left",
        "Enter Quantities Left",
        this.state.product.quantities + "",
        this.validateQuantity,
        "Please enter valid number of product quantities you have left in your stock. You can update this value frequently so buyers will know what you have in stock.",
        "fas fa-list",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "quantity"),
        "quantityField"
      ),
      price: createPriceInputElement(
        "text",
        "Price",
        "Enter Price",
        this.state.product.seller_price + "",
        this.validatePrice,
        "Please make sure your price is very affordable and competitive. Good Products with Great prices get massive sales within a short period of time.",
        "fa fa-user",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "price"),
        this.props.user.country,
        "priceField"
      ),
      description: createTextAreaElement(
        "Description",
        "Please provide detailed description of what you are selling. Make it convincing enough.",
        this.state.product.description,
        this.validateDescription,
        "Your product description should be in paragraphs (although it may include key points) and should describe all the important details of the product. It should include the USPs (unique selling points) of the product and why people should buy it.",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "description"),
        "descriptionField"
      ),
      options: createTagInputElement(
        "text",
        "Enter Available Sizes",
        null,
        this.state.product.options ? this.state.product.options.join() : "",
        this.validateOptions,
        "Please enter the available sizes you want your buyers to choose from. Make sure you enter a comma after each option. You can leave this field empty if this does not apply to your product",
        "fas fa-tag",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "options"),
        "optionsField"
      ),
      colours: createTagInputElement(
        "text",
        "Enter Available Colours",
        null,
        this.state.product.colours ? this.state.product.colours.join() : "",
        this.validateColours,
        "Please enter the available colurs you want your buyers to choose from. Make sure you enter a comma after each option. You can leave this field empty if this does not apply to the product",
        "fas fa-tag",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "colours"),
        "coloursField"
      ),
      return_policy: createSelectElement(
        "Return Policy",
        this.validateReturnPolicy,
        "fas fa-list",
        "col-sm-12",
        returnOptions,
        "Select Return Policy",
        this.inputChangedHandler.bind(this, "return_policy"),
        this.state.product.return_policy + "",
        "The buyer may return defected product within the above stated days after purchase. Note that buyers prefer products with longer return policy."
      ),
      category: createSelectElement(
        "Category",
        this.validateCategory,
        "fas fa-list",
        "col-sm-12",
        this.state.categories.filter((el) => {
          return !el.category;
        }),
        "Select Product Category",
        this.inputChangedHandler.bind(this, "category"),
        categoryArray.length ? categoryArray[0].value : ""
      ),
    };
    let categoryFormData = null;
    category = null;
    if (categoryArray.length) {
      category = categoryArray[categoryArray.length - 1];
      categoryFormData = {};
      for (let i = 1; i < categoryArray.length; i++) {
        const subCategories = this.state.categories.filter((el) => {
          return categoryArray[i - 1].value === el.category;
        });
        if (subCategories.length) {
          subCategories.push({
            value: "Others-" + categoryArray[i - 1].value,
            option: "Others",
          });
          const newName = Date.now() + i;
          const subCategoryField = createSelectElement(
            "",
            this.validateCategory,
            "fas fa-list",
            "col-sm-12",
            subCategories,
            "Select Subcategory",
            this.categoryHandler.bind(this, newName),
            categoryArray[i].value
          );
          categoryFormData[newName] = subCategoryField;
        }
      }
      const subCategories = this.state.categories.filter((el) => {
        return categoryArray[categoryArray.length - 1].value === el.category;
      });
      if (subCategories.length) {
        subCategories.push({
          value: "Others-" + categoryArray[categoryArray.length - 1].value,
          option: "Others",
        });
        const newName = Date.now() + categoryArray.length;
        const subCategoryField = createSelectElement(
          "",
          this.validateCategory,
          "fas fa-list",
          "col-sm-12",
          subCategories,
          "Select Subcategory",
          this.categoryHandler.bind(this, newName),
          "Others-" + categoryArray[categoryArray.length - 1].value
        );
        categoryFormData[newName] = subCategoryField;
      }
      const details = category.details;
      if (details && details.length) {
        this.createDetails(details);
      }
    }
    this.setState({
      formData: formData,
      categoryFormData: categoryFormData,
      category: category.value,
      previousImages: this.state.product.photos,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    const categoryFormData = { ...this.state.categoryFormData };
    for (let categorykey in categoryFormData) {
      if (categoryFormData[categorykey].validate) {
        const invalid = categoryFormData[categorykey].validate(
          categoryFormData[categorykey].value
        );
        categoryFormData[categorykey].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    const detailFormData = { ...this.state.detailFormData };
    for (let detailkey in detailFormData) {
      if (detailFormData[detailkey].validate) {
        const invalid = detailFormData[detailkey].validate(
          detailFormData[detailkey].value
        );
        detailFormData[detailkey].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
      categoryFormData: categoryFormData,
      detailFormData: detailFormData,
    });
    return isFormActivated;
  }

  validatePrice = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid price.";
    }
    if (!isNumeric(value.trim())) {
      return "Invalid Price. Only numbers are allowed. Please remove all spaces and commas.";
    }
    if (+value.trim() <= this.state.commission.min) {
      return `Price is too low. The price of product should be greater than ${this.props.user.country.currency.symbol}${this.state.commission.min}`;
    }
    return null;
  };

  validateOptions = (value) => {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (!validators.validateLength(value.trim(), 1, 100)) {
      return "Your options are too many. Maximum of 100 characters allowed. Please reduce the number of options you have entered";
    }
    return null;
  };

  validateColours = (value) => {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (!validators.validateLength(value.trim(), 1, 1000)) {
      return "Your options are too many. Maximum of 1000 characters allowed. Please reduce the number of options you have entered";
    }
    return null;
  };

  validateReturnPolicy = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select return policy.";
    }
    if (!isInt(value.trim())) {
      return "Invalid Return Policy. Only numbers are allowed. Please remove all spaces and commas.";
    }
    return null;
  };

  validateQuantity = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid quantities left.";
    }
    if (!isInt(value.trim())) {
      return "Invalid Quantity. Only numbers are allowed.";
    }
    return null;
  };

  validateTitle = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid Title.";
    }
    if (!validators.validateLength(value.trim(), 3, 150)) {
      return "Please enter valid Title. 3 - 150 characters are allowed";
    }
    return null;
  };

  validateCategory = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select valid category.";
    }
    return null;
  };

  validateDetail = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "This field should not be empty. Please fill all the required details before you can proceed.";
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 1, 100)) {
      return "Please enter valid detail. Maximum of 100 characters are allowed. You can leave this field empty if your wish";
    }
  };

  validateDescription = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid description and full details of your product.";
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 3, 5000)) {
      return "Please enter valid Description. 3 - 5000 characters are allowed.";
    }
  };

  validateYoutubeVideoLink = (value) => {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (!validators.validateLength(value.trim(), 5, 100)) {
      return "Invalid Youtube Video Link.";
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    if (name === "options") {
      const items = window.$("#optionsField").tagsinput("items");
      formElementData.value = items.join(",");
    } else if (name === "colours") {
      const items = window.$("#coloursField").tagsinput("items");
      formElementData.value = items.join(",");
    } else {
      formElementData.value = event.target.value;
    }
    formElementData.invalid = formElementData.validate(formElementData.value);
    if (name === "price") {
      if (!formElementData.invalid) {
        formElementData.hint = (
          <p className="form-text text-muted">
            <span
              style={{
                width: "110px",
                marginRight: "1rem",
                display: "inline-block",
                textAlign: "right",
              }}
            >
              Your Return:
            </span>
            <span className="text-success">
              {this.props.user.country.currency.symbol} {+formElementData.value}
            </span>
            <br />
            <span className="text-info">
              Please note that the price that will be shown to the buyer might
              be different from the price you entered above due to taxes,
              shipping costs, commissions or sales promo offers. However, be
              rest assured that we will pay you a total of{" "}
              <span style={{ fontWeight: "600" }}>
                {this.props.user.country.currency.symbol}{" "}
                {+formElementData.value}
              </span>{" "}
              per quantity of item sold.
            </span>
          </p>
        );
      }
    }
    if (name === "category") {
      this.clearDetails();
      const subCategories = this.state.categories.filter((el) => {
        return formElementData.value === el.category;
      });
      const category = this.state.categories.find((el) => {
        return formElementData.value === el.value;
      });
      if (subCategories.length) {
        subCategories.push({
          value: "Others-" + formElementData.value,
          option: "Others",
        });
        const newName = Date.now();
        const subCategoryField = createSelectElement(
          "",
          this.validateCategory,
          "fas fa-list",
          "col-sm-12",
          subCategories,
          "Select SubCategory",
          this.categoryHandler.bind(this, newName),
          ""
        );
        const categoryFormData = {};
        categoryFormData[newName] = subCategoryField;
        this.setState({
          category: null,
          categoryFormData: categoryFormData,
        });
      } else if (category) {
        const details = category.details;
        if (details && details.length) {
          this.createDetails(details);
        }
        this.setState({
          categoryFormData: null,
          category: formElementData.value,
        });
      } else {
        this.setState({
          categoryFormData: null,
          category: null,
        });
      }
    }
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({
      formData: updatedFormData,
    });
  };

  detailHandler = (name, event) => {
    let formElementData = { ...this.state.detailFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.detailFormData,
      [name]: formElementData,
    };
    this.setState({
      detailFormData: updatedFormData,
    });
  };

  categoryHandler = (name, event) => {
    let formElementData = { ...this.state.categoryFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.categoryFormData,
      [name]: formElementData,
    };
    this.clearDetails();
    this.deleteCategorySelect(name, updatedFormData);
    const subCategories = this.state.categories.filter((el) => {
      return formElementData.value === el.category;
    });
    const subcategory = this.state.categories.find((el) => {
      return formElementData.value === el.value;
    });
    if (subCategories.length) {
      subCategories.push({
        value: "Others-" + formElementData.value,
        option: "Others",
      });
      const newName = Date.now();
      const subCategoryField = createSelectElement(
        "",
        this.validateCategory,
        "fas fa-list",
        "col-sm-12",
        subCategories,
        "Select SubCategory",
        this.categoryHandler.bind(this, newName),
        ""
      );
      this.setState({ category: null });
      updatedFormData[newName] = subCategoryField;
    } else if (subcategory) {
      const details = subcategory.details;
      if (details && details.length) {
        this.createDetails(details);
      }
      this.setState({
        category: formElementData.value,
      });
    } else {
      try {
        const categoryStr = formElementData.value.split("-")[1];
        const category = this.state.categories.find((el) => {
          return categoryStr === el.value;
        });
        if (category) {
          const details = category.details;
          if (details && details.length) {
            this.createDetails(details);
          }
          this.setState({
            category: categoryStr,
          });
        }
      } catch (error) {}
    }
    this.setState({
      categoryFormData: updatedFormData,
    });
  };

  clearDetails = () => {
    this.setState({
      detailFormData: null,
    });
  };

  createDetails = (details) => {
    const detailFormData = {};
    details.forEach((detail) => {
      const productDetail = this.state.product.details.find(
        (el) => el.name === detail
      );
      detailFormData[detail] = createInputElement(
        "text",
        detail,
        "Enter " + detail,
        productDetail ? productDetail.value : "",
        this.validateDetail,
        "Please fill this detail. Enter 'None' if this does not apply to you.",
        "far fa-list",
        "col-sm-12",
        this.detailHandler.bind(this, detail)
      );
    });
    this.setState({
      detailFormData: detailFormData,
    });
  };

  deleteCategorySelect = (start, updatedFormData) => {
    if (!this.state.categoryFormData) {
      return;
    }
    for (let i in updatedFormData) {
      if (+i > start) {
        delete updatedFormData[i];
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    this.loadProduct();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadProduct();
  };

  loadProduct() {
    const userToken = this.props.userToken;
    const { productId } = this.props.match.params;
    axios(`/products/edit/${productId}`, {
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const product = response.data.data.product;
        const categories = response.data.data.categories;
        const commission = response.data.data.commission;
        this.setState(
          {
            loading: false,
            error: null,
            product: product,
            commission: commission,
            categories: categories.map((category) => {
              return {
                value: category._id,
                option: category.name,
                category: category.category,
                details: category.details,
              };
            }),
          },
          this.initialiseFormData
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          this.setState({
            loading: false,
            error: data.error,
          });
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  }

  uploadHandler = (event) => {
    const files = event.target.files;
    if (
      !files ||
      !files.length ||
      files.length +
        this.state.photos.length +
        this.state.previousImages.length >
        10
    ) {
      this.setState({
        error:
          "Invalid Number of Photos selected. You can only upload a maximum of 10 photos per product. Please try again.",
      });
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(file["type"])) {
        continue;
      }
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: file["type"] });
        const previewImage = window.URL.createObjectURL(blob);
        const previewPhoto = {
          file: file,
          photo: previewImage,
        };
        const previewPhotos = [...this.state.photos];
        previewPhotos.push(previewPhoto);
        this.setState({
          photos: previewPhotos,
        });
      };
    }
  };

  removePhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const photos = [...this.state.photos];
    photos.splice(index, 1);
    if (index === this.state.active) {
      if (index >= 1) {
        this.setState({
          active: index - 1,
        });
      }
    }
    this.setState({
      photos: photos,
    });
  };

  removePreviousPhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const photos = [...this.state.previousImages];
    photos.splice(index, 1);
    this.setState({
      previousImages: photos,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      this.setState({
        formError:
          "There is an error in your form. Please check all entries and try again.",
      });
      window.scrollTo(0, 0);
      return false;
    } else if (!(this.state.previousImages.length + this.state.photos.length)) {
      this.setState({
        formError:
          "No Product Photos Uploaded. You must add atleast one clear photo to your products.",
      });
      window.scrollTo(0, 0);
      return false;
    } else {
      this.setState({
        formError: null,
      });
    }
    const uploadFormData = new FormData();
    this.state.photos.forEach((el) => {
      uploadFormData.append("photos[]", el.file);
    });
    const userToken = this.props.userToken;
    const price = this.state.formData.price.value;
    const title = this.state.formData.title.value;
    const description = this.state.formData.description.value;
    const options = this.state.formData.options.value;
    const colours = this.state.formData.colours.value;
    const return_policy = this.state.formData.return_policy.value;
    const quantity = this.state.formData.quantity.value;
    const { productId } = this.props.match.params;
    const category = this.state.category;    
    uploadFormData.append("title", title);
    uploadFormData.append("price", price);
    uploadFormData.append("description", description);
    uploadFormData.append("options", options);
    uploadFormData.append("colours", colours);
    uploadFormData.append("quantity", quantity);
    uploadFormData.append("return_policy", return_policy);    
    uploadFormData.append("category", category);
    uploadFormData.append(
      "previous_images",
      JSON.stringify(this.state.previousImages)
    );
    const detailFormData = this.state.detailFormData;
    const details = [];
    for (let detailkey in detailFormData) {
      const detail = {
        name: detailkey,
        value: detailFormData[detailkey].value,
      };
      details.push(detail);
    }
    uploadFormData.append("details", JSON.stringify(details));
    axios({
      method: "put",
      url: `/products/${productId}`,
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const product = response.data.data.product;
        window.scrollTo(0, 0);
        this.setState({
          product: product,
          submitting: false,
          success: true,
        });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              submitError: data.error[0].msg,
            });
          } else {
            this.setState({
              submitting: false,
              submitError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            submitting: false,
            submitError: errorMsg,
          });
        }
      });
    this.setState({
      submitting: true,
      submitError: null,
    });
  };

  setActive = (active) => {
    this.setState({
      active: active,
    });
  };

  render() {
    let content = null;
    if (this.state.product) {
      const previousImagesContent = this.state.previousImages.map((photo) => {
        return {
          photo: photo.src,
        };
      });
      const now = moment();
      const orderMoment = moment(this.state.product.updated_on);
      const daysDiff = now.diff(orderMoment, "days");
      let formatDate;
      if (daysDiff >= 1) {
        formatDate = orderMoment.calendar();
      } else {
        formatDate = orderMoment.fromNow();
      }

      let reviewBg = "bg-warning text-dark";
      if (this.state.product.status === "SUCCESS") {
        reviewBg = "bg-success";
      } else if (this.state.product.status === "FAILED") {
        reviewBg = "bg-danger";
      } else {
        reviewBg = "bg-warning text-dark";
      }

      let feedbackContent =
        "<p>" +
        this.state.product.feedback
          .replace(/\n{2,}/g, "</p><p>")
          .replace(/\n/g, "<br>") +
        "</p>";

      content = (
        <React.Fragment>
          {feedbackContent ? (
            <div
              dangerouslySetInnerHTML={{ __html: feedbackContent }}
              className={`${classes.ProductStatus} ${reviewBg}`}
            ></div>
          ) : null}
          {this.state.success ? (
            <div className="alert alert-warning">
              <p>
                Product Updated Successfully! {this.state.product.feedback}{" "}
                Click{" "}
                <Link to={`/products/${this.state.product._id}`}>here</Link> to
                view the updated version of this product.
              </p>
            </div>
          ) : null}
          {this.state.formError ? (
            <div className={classes.Error}>
              <AlertError error={this.state.formError} />
            </div>
          ) : null}
          <div className={classes.EditInfo}>
            <div className={classes.ProductHeader}>
              <div className={classes.ProductPhoto}>
                <LazyImage src={this.state.product.photos[0].src} size="3" />
              </div>
              <div className={classes.ProductTitle}>
                <div className={classes.Title}>
                  <h5>{this.state.product.title}</h5>
                </div>
                <div className={classes.SubTitle}>
                  <p className={classes.Price}>{`${
                    this.state.product.country.currency.symbol
                  } ${this.state.product.seller_price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
                  <p className={classes.Date}>
                    <span className="fal fa-recycle"></span> {formatDate}
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.EditImages}>
              <div className={classes.SellImages}>
                <PreviewImages
                  multiple
                  noUpload
                  setActive={() => {}}
                  removePhoto={this.removePreviousPhotoHandler}
                  products={previousImagesContent}
                />
              </div>
              <div className={classes.SellImages}>
                <PreviewImages
                  multiple
                  setActive={this.setActive}
                  active={this.state.active}
                  upload={this.uploadHandler}
                  removePhoto={this.removePhotoHandler}
                  products={this.state.photos}
                />
              </div>
            </div>
            <Form formData={this.state.formData} />
            {this.state.categoryFormData ? (
              <Form formData={this.state.categoryFormData} />
            ) : null}
            {this.state.detailFormData ? (
              <Form formData={this.state.detailFormData} />
            ) : null}
            <div className="d-none d-md-block">
              <button
                onClick={this.submitHandler}
                className="btn btn-coloured-heavy btn-xl"
              >
                UPDATE PRODUCT
              </button>
            </div>
          </div>
          {this.state.submitting || this.state.submitError ? (
            <div className={classes.SubmissionModal}>
              {this.state.submitting ? (
                <UploadSpinner size="10" />
              ) : (
                <div className={classes.SubmissionError}>
                  <i className="fa fa-times-circle mb-2"></i>
                  <p className="mb-2">{this.state.submitError}</p>
                  <p>
                    <button
                      onClick={this.submitHandler}
                      className="mr-1 btn btn-coloured-heavy btn-rounded"
                    >
                      Submit Again
                    </button>
                    <button
                      onClick={(event) => this.setState({ submitError: null })}
                      className="btn btn-danger btn-rounded"
                    >
                      Back to Form
                    </button>
                  </p>
                </div>
              )}
            </div>
          ) : null}
        </React.Fragment>
      );
    }
    if (this.state.loading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      content = (
        <div className={classes.Loading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Edit Product</h4>
                </TitleHeader>
                {!this.state.loading && !this.state.error ? (
                  <TitleLinks>
                    <button onClick={this.submitHandler}>Submit</button>
                  </TitleLinks>
                ) : null}
              </Title>
              <div className={classes.EditProduct}>{content}</div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps, null)(EditProduct);
