/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import TitleLinks from "../../../../components/App/Layout/Title/TitleLinks";
import PageContent from "../../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import classes from "../SellProducts/SellProducts.module.css";
import PreviewImages from "../../../../components/App/CreatePost/PreviewImages/PreviewImages";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import { connect } from "react-redux";
import UploadProduct from "../../../../components/App/CreatePost/UploadProduct/UploadProduct";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import { isNumeric, isInt } from "validator";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";
import UploadSpinner from "../../../../components/UI/Spinner/UploadSpinner/UploadSpinner";
import {
  createInputElement,
  createTagInputElement,
  createSelectElement,
  createPriceInputElement,
  createTextAreaElement,
} from "../../../../components/UI/Forms/utility/form-creators";
import Form from "../../../../components/UI/Forms/Form";
import { getAdvertFee } from "../../../../util/util";
import InsufficientFundModal from "../../../../components/App/InsufficientFundModal/InsufficientFundModal";
import ConfirmModal from "../../../../components/UI/Modal/ConfirmModal/ConfirmModal";
import * as actionCreators from "../../../../store/actions/actions";

class SellProducts extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      loading: true,
      commission: null,
      categories: null,
      category: null,
      options: null,
      error: null,
      formError: null,
      submitting: false,
      submitError: null,
      photos: [],
      formData: null,
      categoryFormData: null,
      detailFormData: null,
      show_insufficient_modal: false,
      show_confirm_modal: false,
    };
  }

  initialiseFormData = () => {
    const returnOptions = [];
    for (let i = 7; i <= 30; i++) {
      returnOptions.push({
        value: i,
        option: `${i} day(s) Return Policy`,
      });
    }
    const days = [];
    for (let i = 1; i <= 14; i++) {
      days.push({
        value: i,
        option: `${i} day(s)`,
      });
    }
    const formData = {
      title: createInputElement(
        "text",
        "Title",
        "Enter clear and attractive title",
        "",
        this.validateTitle,
        "The title of your product should be very accurate and attractive. E.g the title of a fashion product can be 'High Quality Round Neck Men's Shirt - Blue'",
        "fas fa-check-circle",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "title"),
        "titleField"
      ),
      quantity: createInputElement(
        "text",
        "Quantities Left",
        "Enter Quantities Left",
        "",
        this.validateQuantity,
        "Please enter valid number of product quantities you have left in your stock. You can update this value frequently so buyers will know what you have in stock.",
        "fas fa-list",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "quantity"),
        "quantityField"
      ),
      price: createPriceInputElement(
        "text",
        "Price",
        "Enter Price",
        "",
        this.validatePrice,
        "Please make sure your price is very affordable and competitive. Good Products with Great prices get massive sales within a short period of time.",
        "fa fa-user",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "price"),
        this.props.user.country,
        "priceField"
      ),
      description: createTextAreaElement(
        "Description",
        "Please provide detailed description of what you are selling. Make it convincing enough.",
        "",
        this.validateDescription,
        "Your product description should be in paragraphs (although it may include key points) and should describe all the important details of the product. It should include the USPs (unique selling points) of the product and why people should buy it.",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "description"),
        "descriptionField"
      ),
      options: createTagInputElement(
        "text",
        "Enter Available Sizes",
        null,
        "",
        this.validateOptions,
        "Please enter the available sizes you want your buyers to choose from. Make sure you enter a comma after each option. You can leave this field empty if this does not apply to you.",
        "fas fa-tag",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "options"),
        "optionsField"
      ),
      colours: createTagInputElement(
        "text",
        "Enter Available Colours",
        null,
        "",
        this.validateColours,
        "Please enter the available colours you want your buyers to choose from. Make sure you enter a comma after each option. You can leave this field empty if this does not apply to you.",
        "fas fa-tag",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "colours"),
        "coloursField"
      ),
      return_policy: createSelectElement(
        "Return Policy",
        this.validateReturnPolicy,
        "fas fa-list",
        "col-sm-12",
        returnOptions,
        "Select Return Policy",
        this.inputChangedHandler.bind(this, "return_policy"),
        "",
        "The buyer may return defected product within the above stated days after purchase. Note that buyers prefer products with longer return policy. The minimum return policy you can set is 7 days. Note that your money will be paid into your bank account until the return policy days you set has elapsed."
      ),
      category: createSelectElement(
        "Category",
        this.validateCategory,
        "fas fa-list",
        "col-sm-12",
        this.state.categories.filter((el) => {
          return !el.category;
        }),
        "Select Product Category",
        this.inputChangedHandler.bind(this, "category"),
        ""
      ),
      // options: []
    };
    this.setState({
      formData: formData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    const categoryFormData = { ...this.state.categoryFormData };
    for (let categorykey in categoryFormData) {
      if (categoryFormData[categorykey].validate) {
        const invalid = categoryFormData[categorykey].validate(
          categoryFormData[categorykey].value
        );
        categoryFormData[categorykey].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    const detailFormData = { ...this.state.detailFormData };
    for (let detailkey in detailFormData) {
      if (detailFormData[detailkey].validate) {
        const invalid = detailFormData[detailkey].validate(
          detailFormData[detailkey].value
        );
        detailFormData[detailkey].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
      categoryFormData: categoryFormData,
      detailFormData: detailFormData,
    });
    return isFormActivated;
  }

  validateReturnPolicy = value => {
    if (validators.isEmpty(value.trim())) {
      return "Please select return policy.";
    }
    if (!isInt(value.trim())) {
      return "Invalid Return Policy. Only numbers are allowed. Please remove all spaces and commas.";
    }
    return null;
  };

  validateOptions = value => {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (!validators.validateLength(value.trim(), 1, 1000)) {
      return "Your options are too many. Maximum of 100 characters allowed. Please reduce the number of options you have entered";
    }
    return null;
  };

  validateColours = value => {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (!validators.validateLength(value.trim(), 1, 1000)) {
      return "Your options are too many. Maximum of 1000 characters allowed. Please reduce the number of options you have entered";
    }
    return null;
  };

  validatePrice = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid price.";
    }
    if (!isNumeric(value.trim())) {
      return "Invalid Price. Only numbers are allowed. Please remove all spaces and commas.";
    }
    return null;
  };

  validateYoutubeVideoLink = (value) => {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (!validators.validateLength(value.trim(), 5, 100)) {
      return "Invalid Youtube Video Link.";
    }
    return null;
  };

  validateQuantity = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid quantities left.";
    }
    if (!isInt(value.trim())) {
      return "Invalid Quantity. Only numbers are allowed.";
    }
    return null;
  };

  validateTitle = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid Title.";
    }
    if (!validators.validateLength(value.trim(), 3, 150)) {
      return "Please enter valid Title. 3 - 150 characters are allowed";
    }
    return null;
  };

  validateCategory = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select valid category.";
    }
    return null;
  };

  validateDetail = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "This field should not be empty. Please fill all the required details before you can proceed.";
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 1, 100)) {
      return "Please enter valid detail. Maximum of 100 characters are allowed. You can leave this field empty if your wish";
    }
  };

  validateDescription = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid description and full details of your product.";
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 3, 5000)) {
      return "Please enter valid Description. 3 - 5000 characters are allowed.";
    }
  };

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    if (name === "options") {
      const items = window.$("#optionsField").tagsinput("items");
      formElementData.value = items.join(",");
    } else if (name === "colours") {
      const items = window.$("#coloursField").tagsinput("items");
      formElementData.value = items.join(",");
    } else {
      formElementData.value = event.target.value;
    }
    formElementData.invalid = formElementData.validate(formElementData.value);
    if (name === "price") {
      if (!formElementData.invalid) {
        formElementData.hint = (
          <p className="form-text text-muted">
            <span
              style={{
                width: "110px",
                marginRight: "1rem",
                display: "inline-block",
                textAlign: "right",
              }}
            >
              Your Return:
            </span>
            <span style={{ fontWeight: "600" }} className="text-success f-12">
              {this.props.user.country.currency.symbol} {+formElementData.value}
            </span>
            <br />
            <span className="text-info">
              Please note that the price that will be shown to the buyer might
              be different from the price you entered above due to taxes,
              shipping costs, commissions and sales promo offers. However, be
              rest assured that we will pay you a total of{" "}
              <span style={{ fontWeight: "600" }}>
                {this.props.user.country.currency.symbol}{" "}
                {+formElementData.value}
              </span>{" "}
              per quantity of item sold.
            </span>
          </p>
        );
      }
    }
    if (name === "category") {
      this.clearDetails();
      const subCategories = this.state.categories.filter((el) => {
        return formElementData.value === el.category;
      });
      const category = this.state.categories.find((el) => {
        return formElementData.value === el.value;
      });
      if (subCategories.length) {
        subCategories.push({
          value: "Others-" + formElementData.value,
          option: "Others",
        });
        const newName = Date.now();
        const subCategoryField = createSelectElement(
          "",
          this.validateCategory,
          "fas fa-list",
          "col-sm-12",
          subCategories,
          "Select SubCategory",
          this.categoryHandler.bind(this, newName),
          ""
        );
        const categoryFormData = {};
        categoryFormData[newName] = subCategoryField;
        this.setState({
          category: null,
          categoryFormData: categoryFormData,
        });
      } else if (category) {
        const details = category.details;
        if (details && details.length) {
          this.createDetails(details);
        }
        this.setState({
          categoryFormData: null,
          category: formElementData.value,
        });
      }
    }
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({
      formData: updatedFormData,
    });
  };

  detailHandler = (name, event) => {
    let formElementData = { ...this.state.detailFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.detailFormData,
      [name]: formElementData,
    };
    this.setState({
      detailFormData: updatedFormData,
    });
  };

  categoryHandler = (name, event) => {
    let formElementData = { ...this.state.categoryFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.categoryFormData,
      [name]: formElementData,
    };
    this.clearDetails();
    this.deleteCategorySelect(name, updatedFormData);
    const subCategories = this.state.categories.filter((el) => {
      return formElementData.value === el.category;
    });
    const subcategory = this.state.categories.find((el) => {
      return formElementData.value === el.value;
    });
    if (subCategories.length) {
      subCategories.push({
        value: "Others-" + formElementData.value,
        option: "Others",
      });
      const newName = Date.now();
      const subCategoryField = createSelectElement(
        "",
        this.validateCategory,
        "fas fa-list",
        "col-sm-12",
        subCategories,
        "Select SubCategory",
        this.categoryHandler.bind(this, newName),
        ""
      );
      this.setState({ category: null });
      updatedFormData[newName] = subCategoryField;
    } else if (subcategory) {
      const details = subcategory.details;
      if (details && details.length) {
        this.createDetails(details);
      }
      this.setState({
        category: formElementData.value,
      });
    } else {
      try {
        const categoryStr = formElementData.value.split("-")[1];
        const category = this.state.categories.find((el) => {
          return categoryStr === el.value;
        });
        if (category) {
          const details = category.details;
          if (details && details.length) {
            this.createDetails(details);
          }
          this.setState({
            category: categoryStr,
          });
        }
      } catch (error) {}
    }
    this.setState({
      categoryFormData: updatedFormData,
    });
  };

  clearDetails = () => {
    this.setState({
      detailFormData: null,
    });
  };

  createDetails = (details) => {
    const detailFormData = {};
    details.forEach((detail) => {
      detailFormData[detail] = createInputElement(
        "text",
        detail,
        "Enter " + detail,
        "",
        this.validateDetail,
        "Please fill this detail. Enter 'None' if this does not apply to you.",
        "far fa-list",
        "col-sm-12",
        this.detailHandler.bind(this, detail)
      );
    });
    this.setState({
      detailFormData: detailFormData,
    });
  };

  deleteCategorySelect = (start, updatedFormData) => {
    if (!this.state.categoryFormData) {
      return;
    }
    for (let i in updatedFormData) {
      if (+i > start) {
        delete updatedFormData[i];
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    this.loadCategories();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadCategories();
    this.setState({
      loading: true,
      error: null,
    });
  };

  uploadHandler = (event) => {
    const files = event.target.files;
    if (
      !files ||
      !files.length ||
      files.length + this.state.photos.length > 10
    ) {
      this.setState({
        error:
          "Invalid Number of Photos selected. You can only upload a maximum of 10 photos per product. Please try again.",
      });
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(file["type"])) {
        continue;
      }
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: file["type"] });
        const previewImage = window.URL.createObjectURL(blob);
        const previewPhoto = {
          file: file,
          photo: previewImage,
        };
        const previewPhotos = [...this.state.photos];
        previewPhotos.push(previewPhoto);
        this.setState({
          photos: previewPhotos,
        });
      };
    }
  };

  setActive = (active) => {
    this.setState({
      active: active,
    });
  };

  removePhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const photos = [...this.state.photos];
    photos.splice(index, 1);
    if (index === this.state.active) {
      if (index >= 1) {
        this.setState({
          active: index - 1,
        });
      }
    }
    this.setState({
      photos: photos,
    });
  };

  loadCategories() {
    const userToken = this.props.userToken;
    axios("/posts/categories", {
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const categories = response.data.data.categories;
        const commission = response.data.data.commission;
        this.setState(
          {
            loading: false,
            error: null,
            commission: commission,
            categories: categories.map((category) => {
              return {
                value: category._id,
                option: category.name,
                category: category.category,
                details: category.details,
              };
            }),
          },
          this.initialiseFormData
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          this.setState({
            loading: false,
            error: data.error,
          });
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  }

  verifyEntryHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      this.setState({
        formError:
          "There is an error in your form. Please check all entries and try again.",
      });
      window.scrollTo(0, 0);
      return false;
    } else if (!this.state.category) {
      this.setState({
        formError: "Please choose a valid category",
      });
      window.scrollTo(0, 0);
      return false;
    } else if (!this.continueSalesHandler()) {
      this.setState({
        formError: `Your Advert Placement Fee is ${getAdvertFee(
          this.props.user,
          this.props.meta
        )}. Your wallet is insufficient to pay for your advert. Please fund your wallet and try again`,
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        formError: null,
        show_confirm_modal: true,
      });
    }
  };

  submitHandler = () => {
    this.setState({ show_confirm_modal: false });
    if (!this.checkIsValidFormData()) {
      this.setState({
        formError:
          "There is an error in your form. Please check all entries and try again.",
      });
      window.scrollTo(0, 0);
      return false;
    } else if (!this.state.category) {
      this.setState({
        formError: "Please choose a valid category",
      });
      window.scrollTo(0, 0);
      return false;
    } else if (!this.continueSalesHandler()) {
      this.setState({
        formError: `Your Advert Placement Fee is ${getAdvertFee(
          this.props.user,
          this.props.meta
        )}. Your wallet is insufficient to pay for your advert. Please fund your wallet and try again`,
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        formError: null,
      });
    }
    const userToken = this.props.userToken;
    const uploadFormData = new FormData();
    this.state.photos.forEach((el) => {
      uploadFormData.append("photos[]", el.file);
    });
    const price = this.state.formData.price.value;
    const title = this.state.formData.title.value;
    const description = this.state.formData.description.value;
    const quantity = this.state.formData.quantity.value;
    const category = this.state.category;
    const options = this.state.formData.options.value;
    const colours = this.state.formData.colours.value;
    const return_policy = this.state.formData.return_policy.value;
    uploadFormData.append("title", title);
    uploadFormData.append("price", price);
    uploadFormData.append("description", description);
    uploadFormData.append("options", options);
    uploadFormData.append("quantity", quantity);
    uploadFormData.append("category", category);
    uploadFormData.append("return_policy", return_policy);
    uploadFormData.append("colours", colours);
    const detailFormData = this.state.detailFormData;
    const details = [];
    for (let detailkey in detailFormData) {
      const detail = {
        name: detailkey,
        value: detailFormData[detailkey].value,
      };
      details.push(detail);
    }
    uploadFormData.append("details", JSON.stringify(details));
    axios({
      method: "post",
      url: "/products",
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const product = response.data.data.product;
        const user = response.data.data.user;
        this.setState({
          submitting: false,
          submitError: null,
        });
        this.props.completed(product._id);
        this.props.processUser(user);
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              submitError: data.error[0].msg,
            });
          } else {
            this.setState({
              submitting: false,
              submitError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            submitting: false,
            submitError: errorMsg,
          });
        }
      });
    this.setState({
      submitting: true,
      submitError: null,
    });
  };

  setActive = (active) => {
    this.setState({
      active: active,
    });
  };

  continueSalesHandler = () => {
    if (
      !this.props.user.is_member &&
      this.props.user.wallet.shopping < this.props.meta.advert_fee
    ) {
      this.setState({
        show_insufficient_modal: true,
      });
      return false;
    } else if (
      this.props.user.is_member &&
      this.props.user.product_count &&
      this.props.user.wallet.shopping < this.props.meta.advert_fee
    ) {
      this.setState({
        show_insufficient_modal: true,
      });
      return false;
    } else return true;
  };

  render() {
    let content = (
      <React.Fragment>
        {this.state.formError ? (
          <div className={classes.Error}>
            <AlertError error={this.state.formError} />
          </div>
        ) : null}
        <div className={classes.SellImages}>
          <PreviewImages
            multiple
            setActive={this.setActive}
            active={this.state.active}
            upload={this.uploadHandler}
            removePhoto={this.removePhotoHandler}
            products={this.state.photos}
          />
        </div>
        <div className={classes.SellInfo}>
          <Form formData={this.state.formData} />
          {this.state.categoryFormData ? (
            <Form formData={this.state.categoryFormData} />
          ) : null}
          {this.state.detailFormData ? (
            <Form formData={this.state.detailFormData} />
          ) : null}
          <div className="d-none d-md-block">
            <button
              onClick={this.verifyEntryHandler}
              className="btn btn-coloured-heavy btn-xl"
            >
              SUBMIT PRODUCT
            </button>
          </div>
        </div>
        {this.state.submitting || this.state.submitError ? (
          <div className={classes.SubmissionModal}>
            {this.state.submitting ? (
              <UploadSpinner size="10" />
            ) : (
              <div className={classes.SubmissionError}>
                <i className="fa fa-times-circle mb-2"></i>
                <p className="mb-2">{this.state.submitError}</p>
                <p>
                  <button
                    onClick={this.submitHandler}
                    className="mr-1 btn btn-coloured-heavy btn-rounded"
                  >
                    Upload Again
                  </button>
                  <button
                    onClick={(event) => this.setState({ submitError: null })}
                    className="btn btn-danger btn-rounded"
                  >
                    Back to Form
                  </button>
                </p>
              </div>
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
    if (!this.state.photos.length) {
      content = (
        <div className={classes.SellUpload}>
          <UploadProduct
            multiple
            upload={this.uploadHandler}
            size="20"
            text="Click to Upload"
          />
          <div className="alert alert-info mt-4 mb-0">
            <h4 className="alert-heading">Tips</h4>
            <p>
              Upload high quality images of your products. Ensure your pictures
              are very clean and clear. Do not upload pictures of different
              products in one post as your advert will be disqualified. You can
              however upload multiple pictures of the same product in one post
              but not different products. For example, you cannot upload a
              picture of an iphone 12 and a samsung phone in the same post.
            </p>
          </div>
        </div>
      );
    }
    if (this.state.loading) {
      content = (
        <div className={classes.SellLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      content = (
        <div className={classes.SellLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }

    let confirm_description =
      "Are you sure you want to place this advert? This action cannot be undone once it is completed";

    if (
      !this.props.user.is_member ||
      (this.props.user.is_member && this.props.user.product_count)
    ) {
      confirm_description = `Are you sure you want to place this advert? A total of ${getAdvertFee(
        this.props.user,
        this.props.meta
      )} will be deducted from your wallet.`;
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton clicked={(event) => this.props.prev()} />
                  <h4>Upload Your Product</h4>
                </TitleHeader>
                {this.state.photos.length ? (
                  <TitleLinks>
                    <button onClick={this.verifyEntryHandler}>Submit</button>
                  </TitleLinks>
                ) : null}
              </Title>
              <div className={classes.SellProducts}>{content}</div>
              {this.state.show_insufficient_modal ? (
                <InsufficientFundModal
                  cancel={() =>
                    this.setState({ show_insufficient_modal: false })
                  }
                  description={`Your Advert Placement Fee is ${getAdvertFee(
                    this.props.user,
                    this.props.meta
                  )}. You do not have enough funds in your wallet to pay for this advert. Please fund your wallet and try again`}
                />
              ) : null}
              {this.state.show_confirm_modal ? (
                <ConfirmModal
                  title="Confirm Advert Placement"
                  description={confirm_description}
                  confirm={this.submitHandler}
                  cancel={() => this.setState({ show_confirm_modal: false })}
                />
              ) : null}
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SellProducts);
