/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./UserModal.module.css";
import ShowUsers from "../ShowUsers/ShowUsers";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";

let is_Mounted = false;

const UserModal = (props) => {
  const [state, setState] = useState({
    results: [],
    loading: true,
  });
  const [pages, setPages] = useState(null);
  const loadMoreResults = (event) => {
    event.preventDefault();
    fetchContent(false);
  };

  let loadingContent = null;
  if (state.loading) {
    loadingContent = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (pages && pages.next) {
    loadingContent = (
      <div className={classes.Loading}>
        <a onClick={loadMoreResults} href="/">
          View More <span className="fa fa-chevron-down"></span>
        </a>
      </div>
    );
  }
  let content = null;
  if (state.results.length) {
    content = (
      <ShowUsers
        nofollow
        nomargin
        activeUser={props.activeUser}
        users={state.results}
      />
    );
  } else if (!state.loading) {
    if (
      props.userModal.title === "Followers" ||
      props.userModal.title === "Following" ||
      props.userModal.title === "Referrals"
    ) {
      content = (
        <p className="p-3">
          This user has no {props.userModal.title.toLowerCase()}.
        </p>
      );
    } else {
      content = (
        <p className="p-3">
          No {props.userModal.title.toLowerCase()} yet on this post
        </p>
      );
    }
  }

  const fetchContent = (isRefresh = true) => {
    let page = 1;
    if (pages && pages.next && !isRefresh) {
      page = pages.next;
    }
    if (isRefresh) {
      setPages(null);
      setState({
        ...state,
        results: [],
        loading: true,
      });
    } else {
      setState({
        ...state,
        loading: true,
      });
    }
    const userToken = props.userToken;
    const url = `/users/${props.username}/${props.userModal.link}?page=${page}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!is_Mounted) return;
        const results = response.data.data.users;
        const pages = response.data.data.pages;
        setPages(pages);
        let newResults;
        if (!isRefresh) {
          const filteredResults = results.filter((el) => {
            return !state.results.find((res) => res._id === el._id);
          });
          newResults = [...state.results, ...filteredResults];
        } else {
          newResults = results;
        }
        setState({
          results: newResults,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    is_Mounted = true;
    window.$("#userModal").off("shown.bs.modal");
    window.$("#userModal").off("hidden.bs.modal");
    window.$("#userModal").modal("show");
    window.$("#userModal").on("shown.bs.modal", (event) => {
      fetchContent();
    });
    window.$("#userModal").on("hidden.bs.modal", (event) => {
      props.destroy();
    });
    return () => {
      is_Mounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#userModal").modal("hide");
    };
  }, []);
  return (
    <div className={classes.UserModal}>
      <div
        id="userModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.UserModalContent} modal-content`}>
            <div className={`${classes.UserModalHeader} modal-header`}>
              <h5>{`${props.userModal.title} (${props.userModal.count}`})</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="userModalBody"
              className={`${classes.UserModalBody} modal-body`}
            >
              {content}
              {loadingContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
