/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import classes from "./PreviewSupport.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";

class PreviewSupport extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$(".crisp-client").css("display", "none");
  }

  createHandler = (event) => {
    event.preventDefault();
    this.props.history.push("/post");
  };

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadPosts(false);
  };

  startChat = () => {
    window.$(".crisp-client").css("display", "block");
    window.$crisp.push(["do", "chat:open"]);
  };

  render() {
    return (
      <div className={classes.PreviewSupport}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-7">
              <div className="py-5 support-div">
                <h2 className="mb-5">Help & Support</h2>
                <div className="alert alert-danger alert-sm">
                  <p className="fw-600 mb-2">DISCLAIMER!</p>
                  <p className="mb-2 f-10">
                    Please disregard any social media platform or Facebook
                    Groups posing as Hawkit. We do not have any Whatsapp Group
                    or Telegram Group. Beware of Fraudsters posing as Hawkit
                    agents or customer supports telling you to pay any amount of
                    money into their personal accounts or into any OPAY/PALMPAY
                    account. We DO NOT have an OPAY/PALMPAY account number.
                  </p>
                </div>
                <p>
                  If you have any questions or need additional information, do
                  not hesitate to contact us. We have an unbeatable support
                  system so you can contact us via our chat using the button
                  below.
                </p>
                <br />
                <div className="mb-2">
                  <h5>Live Chat Support</h5>
                  <p className="mb-2">
                    Click the button to chat with our support agent
                  </p>
                  <p>
                    <button
                      onClick={this.startChat}
                      className="btn btn-lg btn-success"
                    >
                      Chat with Hawkit Support
                    </button>
                  </p>
                </div>
                <p className="fw-10 mb-1">
                  See our official social media accounts below. Please disregard
                  any other social media account posing as Hawkit.
                </p>
                <div className="help-socials">
                  <ul className="list-inline">
                    <li>
                      <BrowserLinks
                        to="http://web.facebook.com/officialhawkit"
                        className="social"
                      >
                        <span className="fab fa-facebook"></span>
                        <span className="f-10 ml-1">@officialhawkit</span>
                      </BrowserLinks>
                    </li>
                    <li>
                      <BrowserLinks
                        to="http://instagram.com/hawkit.ng"
                        className="social"
                      >
                        <span className="fab fa-instagram"></span>
                        <span className="f-12 ml-1">@hawkit.ng</span>
                      </BrowserLinks>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    is_seller_product_failed: state.notificationState.is_seller_product_failed,
    meta: state.userState.meta,
    advert_banner: state.notificationState.advert_banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
    fetchCountryStates: () => dispatch(actionCreators.fetchCountryStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSupport);
