import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/auth-reducer";
import userReducer from "./reducers/user-reducer";
import loaderReducer from "./reducers/loader-reducer";
import postReducer from "./reducers/post-reducer";
import notificationReducer from "./reducers/notification-reducer";
import searchReducer from "./reducers/search-reducer";
import pageReducer from "./reducers/page-reducer";

const rootReducer = combineReducers({
  loader: loaderReducer,
  authState: authReducer,
  userState: userReducer,
  postState: postReducer,
  searchState: searchReducer,
  pageState: pageReducer,
  notificationState: notificationReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, devTools);

export default store;
