/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import classes from "./PaymentCompletedModal.module.css";

const PaymentCompletedModal = (props) => {
  const cancelModalHandler = (event) => {
    event.preventDefault();
    window.$("#PaymentCompletedModal").off("hidden.bs.modal");
    window.$("#PaymentCompletedModal").on("hidden.bs.modal", (event) => {
      props.refresh();
    });
    window.$("#PaymentCompletedModal").modal("hide");
  };

  useEffect(() => {
    window.$("#PaymentCompletedModal").modal({
      backdrop: "static",
      keyboard: false
    });
    window.$("#PaymentCompletedModal").modal("show");
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);
  return (
    <div className={classes.PaymentCompletedModal}>
      <div
        id="PaymentCompletedModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className={`${classes.PaymentCompletedModalContent} modal-content`}
          >
            <div
              id="PaymentCompletedModalBody"
              className={`${classes.PaymentCompletedModalBody} modal-body`}
            >
              <div className="py-2 text-center">
                <i className="mb-2 fas fa-check-circle fa-3x text-success"></i>
                <h5 className="mb-2">Payment Completed Successfully</h5>
                <p className="mb-2">
                  {props.description
                    ? props.description
                    : "Thank you for making payment for this order. Your money is locked in our escrow account until the seller delivers the items directly to you."}
                </p>
                <button
                  onClick={cancelModalHandler}
                  className="btn btn-success btn-rounded"
                >
                  <i className="fas fa-chevron-left"></i> GO BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCompletedModal;
