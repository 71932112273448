/* eslint-disable no-unused-vars */
import React from "react";
import Input from "./FormElements/Input/Input";
import OtpInput from "./FormElements/Input/OtpInput/OtpInput";
import UploadInput from "./FormElements/Input/UploadInput/UploadInput";
import Checkbox from "./FormElements/Checkbox/Checkbox";
import FormButton from "./FormElements/FormButton/FormButton";
import Select from "./FormElements/Select/Select";
import SearchInput from "./FormElements/Input/SearchInput/SearchInput";
import CommentInput from "./FormElements/Input/CommentInput/CommentInput";
import PhoneNumberInput from "./FormElements/Input/PhoneNumberInput/PhoneNumberInput";
import PriceInput from "./FormElements/Input/PriceInput/PriceInput";
import TagInput from "./FormElements/Input/TagInput/TagInput";
import TextArea from "./FormElements/TextArea/TextArea";
import LocationInput from "./FormElements/Input/LocationInput/LocationInput";
import ChatInput from "./FormElements/Input/ChatInput/ChatInput";
import classes from "./Form.module.css";

const form = (props) => {
  const formElements = [];
  for (let i in props.formData) {
    const propertyObj = props.formData[i];
    const width = !propertyObj.width ? "col-sm-12" : propertyObj.width;
    const element = propertyObj.element;
    let formElement = null;
    switch (element) {
      case "input":
        let input = <Input name={i} {...propertyObj} />;
        if (propertyObj.otp) {
          input = <OtpInput name={i} {...propertyObj} />;
        } else if (propertyObj.search) {
          input = <SearchInput name={i} {...propertyObj} />;
        } else if (propertyObj.comment) {
          input = <CommentInput name={i} {...propertyObj} />;
        } else if (propertyObj.chat) {
          input = <ChatInput name={i} {...propertyObj} />;
        } else if (propertyObj.phoneNumber) {
          input = <PhoneNumberInput name={i} {...propertyObj} />;
        } else if (propertyObj.price) {
          input = <PriceInput name={i} {...propertyObj} />;
        } else if (propertyObj.tag) {
          input = <TagInput name={i} {...propertyObj} />;
        } else if (propertyObj.location) {
          input = <LocationInput name={i} {...propertyObj} />;
        }
        formElement = (
          <div className={width} key={i}>
            {input}
          </div>
        );
        break;
      case "file":
        formElement = (
          <div className={width} key={i}>
            <UploadInput name={i} {...propertyObj} />
          </div>
        );
        break;
      case "checkbox":
        formElement = (
          <div className={width} key={i}>
            <Checkbox name={i} {...propertyObj} />
          </div>
        );
        break;
      case "select":
        formElement = (
          <div className={width} key={i}>
            <Select name={i} {...propertyObj} />
          </div>
        );
        break;
      case "button":
        formElement = (
          <div className={width} key={i}>
            <FormButton disabled={props.loading} {...propertyObj} />
          </div>
        );
        break;
      case "textarea":
        formElement = (
          <div className={width} key={i}>
            <TextArea {...propertyObj} />
          </div>
        );
        break;
      case "titleline":
        formElement = (
          <div className={width} key={i}>
            <div className={classes.TitleLine}>
              <label className="mb-0">{propertyObj.value}</label>
            </div>
          </div>
        );
        break;
      case "hintline":
        formElement = (
          <div className={width} key={i}>
            <div className="mb-1">
              <small className="form-text text-muted">
                {propertyObj.value}
              </small>
            </div>
          </div>
        );
        break;
      default:
        return;
    }
    formElements.push(formElement);
  }
  return (
    <form autoComplete="off" onSubmit={props.submit}>
      <div className="form-row">{formElements}</div>
    </form>
  );
};

export default form;
