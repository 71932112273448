var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export function abbreviateNumber(number) {
  var tier = (Math.log10(number) / 3) | 0;

  if (tier === 0) return number;

  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  var scaled = number / scale;

  return scaled.toFixed(1).replace(/\.0$/, "") + suffix;
}

export function getClientId() {
  let client_id = localStorage.getItem("client_id");
  if (!client_id) {
    client_id = generateClientId(16);
    localStorage.setItem("client_id", client_id);
  }

  return client_id;
}

export function getLastAppReview() {
  let app_review = localStorage.getItem("app_review");
  if (!app_review) {
    return Date.now();
  }

  return app_review;
}

export function setLastAppDownload() {
  localStorage.setItem("app_download", Date.now() + 48 * 60 * 60 * 1000);
}

export function getLastAppDownload() {
  let app_download = localStorage.getItem("app_download");
  if (!app_download) {
    return Date.now();
  }
  return app_download;
}

export function setLastAppReview() {
  localStorage.setItem("app_review", Date.now());
}

export function getBaseUrl(test) {
  return test ? "http://localhost:8080" : "https://api.efluence.ng";
}

export function getRedirect() {
  let redirect = localStorage.getItem("redirect");
  if (!redirect) return false;
  return redirect;
}

export function getReferrer() {
  let referrer = localStorage.getItem("referrer");
  if (!referrer) return false;
  return referrer;
}

export function getReseller() {
  let referrer = localStorage.getItem("reseller");
  if (!referrer) return false;
  return referrer;
}

export function getRegistrationFee(user, meta) {
  if (!user) return "₦1,000";
  else
    return `${user.country.currency.symbol}${meta.registration_fee
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function getAdvertFee(user, meta) {
  if (!user) return "₦500";
  else
    return `${user.country.currency.symbol}${meta.advert_fee
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function setReseller(reseller) {
  localStorage.setItem("reseller", reseller);
}

export function setReferrer(referrer) {
  localStorage.setItem("referrer", referrer);
}

function generateClientId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getDeliveryDate(days) {
  const curDate = new Date();
  let ct = 0,
    i = 1;
  let mainDate;
  while (ct < days) {
    const date = new Date();
    date.setDate(curDate.getDate() + i);
    mainDate = date;
    i++;
    if (date.getDay() === 0 || date.getDay() === 6) continue;
    ct++;
  }
  return mainDate;
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function getMobileAppDownloadLink() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "https://hawkit.ng/download";
  }

  if (/android/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.app.hawkitng";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "https://hawkit.ng/download";
  }

  return "https://hawkit.ng/download";
}

export function getYoutubeId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

export function htmlDecode(input) {
  var e = document.createElement("textarea");
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function stopAudioSound() {
  if (!window.audioSound) return;
  window.audioSound.stop();
  window.audioSound.release();
}

export function toFirstLetterUppercase(str) {
  return str[0].toUpperCase() + str.substring(1);
};

export function playMessageSound(type = "received") {
  if (!window.cordova) return;
  let url;
  if (type === "received") {
    url = "www/audio/msg-received.mp3";
  } else {
    url = "www/audio/msg-sent.mp3";
  }
  let src = window.cordova.file.applicationDirectory + url;
  stopAudioSound();
  window.audioSound = new window.Media(
    src,
    function () {},
    function (e) {
      console.log("Media Error: " + JSON.stringify(e));
    }
  );
  window.audioSound.play();
  window.audioSound.setVolume("1.0");
}

export function playGameSound(type = "select") {
  if (!window.cordova) return;
  let url;
  if (type === "select") {
    url = "www/audio/msg-sent.mp3";
  } else if (type === "success") {
    url = "www/audio/cheer.mp3";
  } else {
    url = "www/audio/boo.mp3";
  }
  let src = window.cordova.file.applicationDirectory + url;
  stopAudioSound();
  window.audioSound = new window.Media(
    src,
    function () {},
    function (e) {
      console.log("Media Error: " + JSON.stringify(e));
    }
  );
  window.audioSound.play();
  window.audioSound.setVolume("1.0");
}

export function unicodeToChar(text) {
  return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
  });
}

export function getDaysInMonth(month, year) {
  // Use 1 for January, 2 for February, etc.
  return new Date(year, month + 1, 0).getDate();
}

export function getDate(dateObj) {
  return new Date(dateObj.year, dateObj.month, dateObj.day);
}
