/* eslint-disable no-undef */
import React, { Component } from "react";
import classes from "./ResPage.module.css";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import axios from "../../../axios/axios-instance";
import { setReseller } from "../../../util/util";

class ResPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.verifyResellerLink();
  }

  verifyResellerLink = () => {
    const userToken = this.props.userToken;
    const { reseller_code } = this.props.match.params;
    const url = `/products/verify_reseller/${reseller_code}`;
    axios({
      method: "post",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const data = response.data.data;
        const reseller = data.reseller;
        if (reseller) {
          setReseller(reseller._id);
          this.props.history.replace(`/posts/${reseller.post}`);
        }
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          this.props.history.replace("/");
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.verifyResellerLink();
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
          <p>Loading...</p>
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    return <div className={classes.ResPage}>{loadingContent}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps, null)(ResPage);
