import React, { useRef, useEffect } from "react";
import UserPhoto from "../User/UserPhoto/UserPhoto";
import classes from "./Chat.module.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const moment = require("moment");

const Chat = (props) => {
  const chatRef = useRef();
  const chatContentRef = useRef();

  useEffect(() => {
    const chatWidth = chatRef.current.offsetWidth;
    chatContentRef.current.style.width = chatWidth - 80 + "px";
  }, []);

  const chat = props.chat;
  const now = moment();
  const postMoment = moment(chat.created_on);
  const daysDiff = now.diff(postMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = postMoment.calendar();
  } else {
    formatDate = postMoment.fromNow();
  }
  const chat_message = chat.chat_message;
  const recipient =
    props.chat.creator_user._id !== props.user._id
      ? props.chat.creator_user
      : props.chat.recipient_user;

  const chatHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.history.push(`/chats/${recipient.username}`);
  };

  const messageContent =
    "<span>" +
    chat_message.message
      .replace(/\n{2,}/g, "<span></span>")
      .replace(/\n/g, "<br>") +
    "</span>";

  return (
    <div
      ref={chatRef}
      key={chat._id}
      onClick={chatHandler}
      className={`${classes.Chat} ${chat.is_active ? classes.ChatActive : ""}`}
    >
      <div className={classes.ChatPicture}>
        <UserPhoto photo={recipient.profile_pic} width="40" height="40" />
      </div>
      <div ref={chatContentRef} className={classes.ChatContent}>
        <Link to={`/users/${recipient.username}`}>
          <h4>{recipient.username}</h4>
        </Link>
        <p className={`${chat.unread_count ? classes.Active : ""}`}>
          {chat_message.sender._id === props.user._id ? (
            <span className="f-10 fw-400 mr-1 badge badge-info">Me:</span>
          ) : null}
          <span dangerouslySetInnerHTML={{ __html: messageContent }}></span>
        </p>
        <p className={classes.Date}>
          <i className="fal fa-clock"></i> {formatDate}
        </p>
      </div>
      {chat.unread_count ? (
        <div className={classes.ChatSide}>
          <p className={classes.ChatCount}>
            <span className="badge badge-danger badge-lg">
              {chat.unread_count}
            </span>
          </p>
        </div>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps, null)(withRouter(Chat));
