import React from "react";
import { getImage } from "../../../../util/image-util";
import BrowserLinks from "../../../UI/Links/BrowserLinks/BrowserLinks";
import classes from "./SocialOrder.module.css";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

const moment = require("moment");

const SocialOrder = (props) => {
  let statusContent;

  if (props.order.status === "PENDING") {
    statusContent = (
      <span className="f-8 fw-400 badge badge-warning">
        <i className="fal fa-clock"></i> {props.order.status}
      </span>
    );
  } else if (props.order.status === "RUNNING") {
    statusContent = (
      <span className="f-8 fw-400 badge badge-info">
        <i className="fal fa-spin fa-spinner"></i> {props.order.status}
      </span>
    );
  } else if (props.order.status === "ALLOCATED") {
    statusContent = (
      <span className="f-8 fw-400 badge badge-primary">
        <i className="fal fa-spin fa-spinner"></i> {props.order.status}
      </span>
    );
  } else {
    statusContent = (
      <span className="f-8 fw-400 badge badge-success">
        <i className="fal fa-clock"></i> {props.order.status}
      </span>
    );
  }

  const orderMoment = moment(props.order.created_on);

  let order_total =
    props.order.amount * props.order.social_task_category.pricing.buyer;

  if (props.order.amount_paid) {
    order_total = props.order.amount_paid;
  }

  let infoContent;

  const payHandler = () => {
    if (props.pay) {
      props.pay();
    } else {
      props.history.push(`/social/orders/${props.order._id}`);
    }
  };
  if (props.show_info && props.order.status !== "PENDING") {
    infoContent = (
      <>
        <div className={classes.Amount}>
          <p>Allocated</p>
          <p className="fw-600">
            {props.order.metrics.allocated
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
        </div>
        <div className={classes.Amount}>
          <p>Success</p>
          <p className="fw-600">
            {props.order.metrics.success
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
        </div>
      </>
    );
  } else {
    infoContent = (
      <div className={classes.Status}>
        <p>
          {props.order.status === "PENDING" ? (
            <button onClick={payHandler} className="btn btn-coloured-heavy">
              MAKE PAYMENTS NOW
            </button>
          ) : (
            <Link
              to={`/social/orders/${props.order._id}`}
              className="btn btn-success"
            >
              VIEW & MONITOR RESULTS
            </Link>
          )}
        </p>
      </div>
    );
  }

  return (
    <div key={props.order._id} className={classes.SocialOrder}>
      <div className={classes.SocialOrderHeading}>
        <div className={classes.SocialOrderHeaderIcon}>
          <img src={getImage(props.order.platform)} alt="icon" />
        </div>
        <div className={classes.SocialOrderHeader}>
          <p className="f-8 text-gray">
            {orderMoment.format("MMM Do YYYY h:mm a")}
          </p>
          <h5>{props.order.social_task_category.title.buyer}</h5>
          <p>
            Pricing:{" "}
            {props.order.pricing_title && props.order.pricing_title.buyer
              ? props.order.pricing_title.buyer
              : props.order.social_task_category.pricing_title.buyer}
          </p>
        </div>
        {props.order.status === "PENDING" ? (
          <button
            onClick={props.deleteOrder}
            className="text-danger ml-auto pr-2"
          >
            <i className="fas fa-times-circle"></i>
          </button>
        ) : null}
      </div>
      <div className={classes.SocialOrderContent}>
        <div className="row no-gutters">
          <div className="col-5">
            <div className={`${classes.Info} mr-2`}>
              <h5>No. Of {props.order.social_task_category.text}</h5>
              <p className="f-10">
                {props.order.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            </div>
          </div>
          <div className="col-7">
            <div className={classes.Info}>
              <h5>
                Your Link -{" "}
                <BrowserLinks to={props.order.link}>
                  {props.order.social_task_category.is_upload
                    ? "Download Advert"
                    : "Visit Link"}
                </BrowserLinks>
              </h5>
              <p className="f-10">{props.order.link}</p>
            </div>
          </div>
        </div>
      </div>
      {props.show_info ? (
        <div className={classes.SocialOrderContent}>
          <div className="row no-gutters">
            <div className="col-4">
              <div className={`${classes.Info} mr-2`}>
                <h5>Gender</h5>
                <p className="f-10">
                  {props.order.gender ? props.order.gender : "All Gender"}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className={classes.Info}>
                <h5>Location</h5>
                <p className="f-10">
                  {props.order.location ? props.order.location : "All Nigeria"}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className={classes.Info}>
                <h5>Religion</h5>
                <p className="f-10">
                  {props.order.belief ? props.order.belief : "All Religion"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={classes.SocialOrderFooter}>
        <div className={classes.Amount}>
          <p>
            {props.order.status === "PENDING"
              ? "Amount to be Paid"
              : "Amount Paid"}
          </p>
          <h5>
            {props.user.country.currency.symbol}
            {order_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h5>
        </div>
        <div className={classes.Amount}>
          <p>Status</p>
          <p>{statusContent}</p>
        </div>
        {infoContent}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(SocialOrder));
