/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import classes from "../Input.module.css";
import CountryDropdown from "../../../../Dropdown/CountryDropdown/CountryDropdown";

const input = props => {
  const inputClasses = [classes.Input, "form-control border-0"];
  if (props.invalid) {
    inputClasses.push("is-invalid");
  }
  let hint = null;
  let error = null;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    error = <div className="d-block invalid-feedback">{props.invalid}</div>;
  }
  let inputElement = (
    <React.Fragment>
      <input
        type={props.type ? props.type : "text"}
        className={inputClasses.join(" ")}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.changeHandler}
        readOnly={props.readOnly}
        disabled={props.disabled}
        autoComplete="new-password"
      />
    </React.Fragment>
  );
  if (props.sideStyle) {
    inputElement = (
      <div className={`${classes.InputGroup} input-group`}>
        <div className="mr-1 input-group-prepend">
          <CountryDropdown countries={props.countries} />
        </div>
        {inputElement}
      </div>
    );
  }
  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      {inputElement}
      {error ? error : hint}
    </div>
  );
};

export default input;
