/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import classes from "./Title.module.css";

const Title = (props) => {
  const titleRef = useRef(null);
  const titleContentRef = useRef(null);

  useEffect(() => {
    if (props.fixed && window.$(window).width() >= 768) {
      titleRef.current.style.width = titleRef.current.offsetWidth + "px";
      titleRef.current.style.position = "fixed";
    }
    if (props.preview) {
      titleRef.current.style.top = "65px";
    }
  }, []);

  return (
    <div
      ref={titleRef}
      className={`${classes.Title} ${props.classes} ${
        props.fixed ? classes.Fixed : ""
      }`}
    >
      <div ref={titleContentRef} className={classes.TitleContent}>
        {props.children}
      </div>
    </div>
  );
};

export default Title;
