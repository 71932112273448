/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import classes from "./ProductPostTitle.module.css";
import { Link } from "react-router-dom";

const ProductPostTitle = props => {
  const titleRef = useRef(null);
  useEffect(() => {
    const titleWidth = document.getElementById(props.post._id).offsetWidth - 35;
    titleRef.current.style.width = titleWidth + "px";
  }, []);

  const address = [];

  if (props.post.product.location.lga) {
    address.push(props.post.product.location.lga);
  }
  if (props.post.product.location.state) {
    address.push(props.post.product.location.state);
  }
  address.push(props.post.product.country.name);

  return (
    <div ref={titleRef} className={classes.Title}>
      <div className={classes.MainTitle}>
        <Link to={`/users/${props.post.user.username}`}>
          <h4>{props.post.user.username}</h4>
        </Link>
      </div>
      <div className={classes.Handle}>
        <p>
          <span className="fal fa-map-marker-alt"></span> {address.join(", ")}
        </p>
      </div>
    </div>
  );
};

export default ProductPostTitle;
