import React from "react";
import classes from "./FormSpinner.module.css";

const spinner = props => {
  return (
    <div className={classes.FormSpinnerLoader} />
  );
};

export default spinner;
