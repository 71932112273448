import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const intialiseNotificationState = () => {
  return {
    notifications: [],
    notificationCount: null,
    messageCount: null,
    is_seller_product_failed: null,
    advert_banner: null,
  };
};

const initialState = intialiseNotificationState();

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION_COUNT:
      draft.notificationCount = action.notificationCount;
      return;
    case actionTypes.SET_MESSAGE_COUNT:
      draft.messageCount = action.messageCount;
      return;
    case actionTypes.SET_SELLERS_PRODUCT_FAILED:
      draft.is_seller_product_failed = action.is_seller_product_failed;
      return;
    case actionTypes.SET_ADVERT_BANNER:
      draft.advert_banner = action.advert_banner;
      return;
    case actionTypes.SET_NOTIFICATIONS:
      draft.notifications = action.notifications;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
