/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./EarnTask.module.css";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";
import { withRouter, Link } from "react-router-dom";
import { getImage } from "../../../../util/image-util";
import BrowserLinks from "../../../../components/UI/Links/BrowserLinks/BrowserLinks";
import UploadPhoto from "../../../../components/App/UploadPhoto/UploadPhoto";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import ConfirmModal from "../../../../components/UI/Modal/ConfirmModal/ConfirmModal";
import VideoModal from "../../../../components/UI/Modal/VideoModal/VideoModal";
import TaskCountdown from "../../../../components/App/SocialTasks/TaskCountdown/TaskCountdown";

const moment = require("moment");

class EarnTask extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      formError: null,
      formSubmitting: false,
      image: props.task.proof ? props.task.proof.photo : null,
      caption_image:
        props.task.proof && props.task.proof.caption_photo
          ? props.task.proof.caption_photo
          : null,
      formData: {
        photo: null,
        caption_photo: null,
        name: {
          value: props.task.proof ? props.task.proof.name : "",
        },
      },
      cancel_task: null,
      show_video: false,
    };
    this.captionRef = React.createRef();
  }

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({ formData: updatedFormData });
  };

  uploadPhoto = (file) => {
    const formElementData = { ...this.state.formData };
    formElementData.photo = file;
    this.setState({ formData: formElementData });
  };

  removePhoto = () => {
    const formElementData = { ...this.state.formData };
    formElementData.photo = null;
    this.setState({ formData: formElementData });
  };

  uploadCaptionPhoto = (file) => {
    const formElementData = { ...this.state.formData };
    formElementData.caption_photo = file;
    this.setState({ formData: formElementData });
  };

  removeCaptionPhoto = () => {
    const formElementData = { ...this.state.formData };
    formElementData.caption_photo = null;
    this.setState({ formData: formElementData });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateName = () => {
    const value = this.state.formData.name.value;
    if (!value || validators.isEmpty(value.trim())) {
      return false;
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 2, 500)) {
      return false;
    }
    return true;
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.validateName()) {
      this.setState(
        {
          formError:
            "Fill the Proof of Work Form below with the right details. " +
            this.props.task.social_task_category.proof_of_work.description,
        },
        () => {
          setTimeout(() => {
            this.setState({
              formError: null,
            });
          }, 3000);
        }
      );
      return;
    }
    if (!this.state.formData.photo) {
      this.setState(
        {
          formError:
            "You must upload a photo screenshot that shows you have performed the task above.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              formError: null,
            });
          }, 3000);
        }
      );
      return;
    }
    if (
      !this.state.formData.caption_photo &&
      (this.props.task.social_task_category.type === "whatsapp" ||
        this.props.task.social_task_category.upload_caption_text)
    ) {
      this.setState(
        {
          formError: this.props.task.social_task_category.caption_proof_validation
            ? this.props.task.social_task_category.caption_proof_validation
            : "You must upload a Caption/Advert Text Status screenshot that shows you posted ONLY the Advert Text on your status. Please read the instructions above for more information.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              formError: null,
            });
          }, 3000);
        }
      );
      return;
    }
    const userToken = this.props.userToken;
    const uploadFormData = new FormData();
    uploadFormData.append("photo", this.state.formData.photo);
    if (this.state.formData.caption_photo) {
      uploadFormData.append("caption_photo", this.state.formData.caption_photo);
    }
    uploadFormData.append("name", this.state.formData.name.value);
    axios({
      method: "post",
      url: `/social/task/${this.props.task._id}/proof`,
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const task = response.data.data.task;
        this.setState(
          {
            formSubmitting: false,
          },
          () => {
            this.props.updateTask(task);
            this.props.updateUser();
          }
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        window.scrollTo(0, 0);
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              formSubmitting: false,
              formError: data.error[0].msg,
            });
          } else {
            this.setState({
              formSubmitting: false,
              formError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            formSubmitting: false,
            formError: errorMsg,
          });
        }
      });
    this.setState({
      formSubmitting: true,
      formError: null,
    });
  };

  cancelTaskHandler = () => {
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: `/social/task/${this.props.task._id}/cancel`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const task = response.data.data.task;
        this.props.updateTask(task);
        this.setState({
          formSubmitting: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        window.scrollTo(0, 0);
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              formSubmitting: false,
              formError: data.error[0].msg,
            });
          } else {
            this.setState({
              formSubmitting: false,
              formError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            formSubmitting: false,
            formError: errorMsg,
          });
        }
      });
    this.setState({
      formSubmitting: true,
      formError: null,
      cancel_task: null,
    });
  };

  hideCancelTask = () => {
    this.setState({
      cancel_task: null,
    });
  };

  showCancelTask = () => {
    this.setState({
      cancel_task: true,
    });
  };

  closeVideo = () => {
    this.setState({
      show_video: false,
    });
  };

  showVideo = () => {
    this.setState({
      show_video: true,
    });
  };

  copyReferralLink = (event) => {
    var captionText = this.captionRef.current;
    captionText.select();
    document.execCommand("Copy");
    window.$(event.target).html("Copied");
  };

  render() {
    const orderMoment = moment(this.props.task.created_on);
    const is_whatsapp =
      this.props.task.social_task_category.type === "whatsapp";
    const upload_caption_text =
      this.props.task.social_task_category.upload_caption_text;
    let statusIcon, actionContent;
    if (this.props.task.status === "COMPLETED") {
      actionContent = (
        <div className="ml-auto">
          <Link
            to={`/earn/task/${this.props.task._id}`}
            className="btn btn-success btn-xs"
          >
            <i className="fas fa-check-circle"></i> VIEW TASK
          </Link>
        </div>
      );
      statusIcon = (
        <div className="text-success ml-auto pr-2">
          <i className="fas fa-check-circle"></i>
        </div>
      );
    } else if (
      this.props.task.status === "CANCELLED" ||
      this.props.task.status === "FAILED"
    ) {
      actionContent = (
        <div className="ml-auto">
          <Link
            to={`/earn/task/${this.props.task._id}`}
            className="btn btn-danger btn-xs"
          >
            <i className="fas fa-times-circle"></i> {this.props.task.status}
          </Link>
        </div>
      );
      statusIcon = (
        <div className="text-danger ml-auto pr-2">
          <i className="fas fa-times-circle"></i>
        </div>
      );
    } else {
      actionContent = (
        <div className="ml-auto">
          <Link
            to={`/earn/task/${this.props.task._id}`}
            className="btn btn-outline btn-xs"
          >
            <i className="fas fa-check-circle"></i> DO NOW
          </Link>
        </div>
      );
      statusIcon = (
        <div className="text-gray ml-auto pr-2">
          <i className="fas fa-spin fa-spinner"></i>
        </div>
      );
    }

    const commentText = this.props.task.social_task_order.comments
      ? "<p>" +
        this.props.task.social_task_order.comments
          .replace(/\n{2,}/g, "</p><p>")
          .replace(/\n/g, "<br>") +
        "</p>"
      : null;

    const captionText = this.props.task.social_task_order.caption
      ? "<p class='f-14'>" +
        this.props.task.social_task_order.caption
          .replace(/\n{2,}/g, "</p><p>")
          .replace(/\n/g, "<br>") +
        "</p>"
      : null;

    let uploadProofStatusContent, cancelTaskContent;

    if (this.props.task.proof && this.props.task.proof.photo) {
      if (this.props.task.status === "UPLOADED") {
        uploadProofStatusContent = (
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.task.tasks_in_review,
            }}
          ></div>
        );
      } else if (this.props.task.status === "FAILED") {
        uploadProofStatusContent = (
          <div className="alert alert-danger px-3 py-1 f-10">
            Your task has been disapproved because you didnt follow the strict
            instructions given above. You will be no longer be able to perform
            the task. Ensure you follow strict instructions when performing
            tasks in order not to have your membership suspended.
            <br />
            <br />
            <b>Reason for Disapproval:</b> {this.props.task.cancel_reason || ""}
          </div>
        );
      } else if (this.props.task.status === "COMPLETED") {
        uploadProofStatusContent = (
          <div className="alert alert-success px-3 py-1 f-10">
            Your task has been approved and completed successfully. Your money
            has been deposited into your wallet for withdrawal. Thank you.
          </div>
        );
      }
    }
    if (
      this.props.task.status === "CANCELLED" ||
      this.props.task.status === "FAILED"
    ) {
      cancelTaskContent = (
        <div className="alert alert-danger px-3 py-1 f-10">
          You can no longer perform the task. This task has been cancelled
          either because it expired or you cancelled it.
        </div>
      );
    } else if (!this.props.task.proof || !this.props.task.proof.photo) {
      cancelTaskContent = (
        <div className="alert rounded-0 text-center bg-light alert-secondary px-3 py-2">
          <p className="f-10 mb-1">
            Don't want to perform this task? You can cancel this task so that
            another one can be generated for you. There is NO penalty for
            cancelling a task.
          </p>
          <button
            disabled={this.state.formSubmitting}
            onClick={this.showCancelTask}
            className="btn btn-danger btn-xs"
          >
            {this.state.formSubmitting ? (
              <i className="fas fa-spin fa-spinner fa-2x"></i>
            ) : (
              "CANCEL THIS TASK"
            )}
          </button>
        </div>
      );
    }

    return (
      <div className={classes.EarnTask}>
        {this.state.cancel_task ? (
          <ConfirmModal
            title="Cancel this Task?"
            description="Are you sure you want to cancel this task. This action cannot be undone once it is performed."
            confirm={this.cancelTaskHandler}
            cancel={this.hideCancelTask}
          />
        ) : null}
        {this.props.show_info &&
        this.props.task.social_task_category.video &&
        this.props.task.social_task_category.video.seller ? (
          <div className={classes.VideoTutorialContainer}>
            <div>
              {this.props.task.social_task_category.video.is_seller_new ? (
                <p className="f-10 badge badge-success">New Video Tutorial</p>
              ) : null}
              <p>
                <i>
                  Please watch this short video tutorial to learn how to do this
                  task properly without failing the task and losing your money.
                </i>
              </p>
            </div>
            <button onClick={this.showVideo} className="btn btn-xs btn-outline">
              <i className="fas fa-play-circle"></i> WATCH VIDEO
            </button>
          </div>
        ) : null}
        <div className={classes.EarnTaskHeading}>
          <div className={classes.EarnTaskHeaderIcon}>
            <img
              src={getImage(this.props.task.social_task_order.platform)}
              alt="icon"
            />
          </div>
          <div className={classes.EarnTaskHeader}>
            <p className="f-8 text-gray">
              {orderMoment.format("MMM Do YYYY h:mm a")}
            </p>
            <h5>{this.props.task.social_task_category.title.seller}</h5>
            <p>
              Earnings:{" "}
              <b>
                {this.props.task.pricing_title
                  ? this.props.task.pricing_title.seller
                  : this.props.task.social_task_category.pricing_title.seller}
              </b>
            </p>
            {this.props.task.status === "PENDING" &&
            this.props.task.expires_on ? (
              <TaskCountdown
                title="This task will expire in:"
                refresh={this.props.updateTask}
                timer_count={this.props.task.expires_on}
              />
            ) : null}
            {this.props.task.status === "UPLOADED" &&
            this.props.task.verification_expires_on ? (
              <TaskCountdown
                title="Your task is currently being verified and your money will be released on or before:˝"
                refresh={this.props.updateTask}
                timer_count={this.props.task.verification_expires_on}
              />
            ) : null}
          </div>
          {this.props.show_info ? statusIcon : actionContent}
        </div>
        {this.props.show_info || this.props.task.status === "PENDING"
          ? cancelTaskContent
          : null}
        {this.state.formError ? (
          <AlertError error={this.state.formError} />
        ) : null}
        {this.props.show_info ? (
          <div className={classes.EarnTaskContent}>
            {uploadProofStatusContent}
            <div className={classes.Info}>
              <p className="f-10 fw-600">
                {this.props.task.social_task_category.is_upload
                  ? "Advert Image/Video Link"
                  : "Task Link"}
              </p>
              <div className="input-group mb-1">
                <input
                  type="text"
                  className="form-control f-10"
                  value={this.props.task.link}
                  readOnly
                />
                <div className="input-group-append">
                  <BrowserLinks
                    to={this.props.task.link}
                    className="d-flex align-items-center btn btn-warning btn-xs"
                  >
                    {this.props.task.social_task_category.is_upload
                      ? "Download"
                      : "Visit Link"}
                  </BrowserLinks>
                </div>
              </div>
              {this.props.task.social_task_order.caption ? (
                <div className={`my-2 ${classes.Comments}`}>
                  <h5>Advert Text</h5>
                  <div className="input-group mt-1">
                    <textarea
                      rows="4"
                      ref={this.captionRef}
                      type="text"
                      className="form-control f-10"
                      value={this.props.task.social_task_order.caption}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        onClick={this.copyReferralLink}
                        className="d-flex align-items-center btn btn-warning btn-xs"
                      >
                        COPY
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.task.task_steps,
                }}
                className={classes.TaskSteps}
              ></div>
              {this.props.task.social_task_order.comments ? (
                <div className={classes.Comments}>
                  <h5>
                    {this.props.task.social_task_category.instructions
                      ? this.props.task.social_task_category.instructions
                      : `${this.props.task.social_task_category.text} Examples`}
                  </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: commentText,
                    }}
                  ></div>
                </div>
              ) : null}
            </div>
            {this.props.task.status !== "CANCELLED" &&
            this.props.task.status !== "FAILED" ? (
              <>
                <p className="f-10 fw-600 mb-1">Upload Proof of Work</p>
                {this.state.formError ? (
                  <AlertError error={this.state.formError} />
                ) : null}
                <div className={classes.Form}>
                  <div className={classes.UploadPhoto}>
                    <UploadPhoto
                      upload={this.uploadPhoto}
                      remove={this.removePhoto}
                      image={this.state.image}
                      nomargin
                      showText
                      text={
                        is_whatsapp
                          ? "Upload Advert Proof"
                          : "Upload Screenshot"
                      }
                      size={is_whatsapp || upload_caption_text ? "7" : "10"}
                    />
                    {is_whatsapp || upload_caption_text ? (
                      <div className="mt-2">
                        <UploadPhoto
                          upload={this.uploadCaptionPhoto}
                          remove={this.removeCaptionPhoto}
                          image={this.state.caption_image}
                          nomargin
                          showText
                          text={
                            upload_caption_text
                              ? upload_caption_text
                              : "Upload Caption Proof"
                          }
                          size="7"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.Input}>
                    <label>
                      {
                        this.props.task.social_task_category.proof_of_work
                          .description
                      }
                    </label>
                    <input
                      type="text"
                      placeholder={
                        this.props.task.social_task_category.proof_of_work.title
                      }
                      className="form-control f-12"
                      value={this.state.formData.name.value}
                      onChange={(e) => this.inputChangedHandler("name", e)}
                    />
                    <button
                      onClick={this.submitHandler}
                      disabled={
                        this.state.formSubmitting ||
                        this.props.task.status === "COMPLETED"
                      }
                      className="btn btn-xs btn-coloured-heavy"
                    >
                      {this.state.formSubmitting ? (
                        <i className="fas fa-spin fa-spinner fa-2x"></i>
                      ) : (
                        "UPLOAD PROOF"
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            {this.props.task.social_task_category.video &&
            this.props.task.social_task_category.video.seller &&
            this.state.show_video ? (
              <VideoModal
                close={this.closeVideo}
                video={this.props.task.social_task_category.video.seller}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
    updateUser: () => dispatch(actionCreators.updateUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EarnTask));
