/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import classes from "./CommentBox.module.css";
import Comment from "./Comment/Comment";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../UI/Error/Error";

let is_Mounted = false;

const CommentBox = (props) => {
  const [state, setState] = useState({
    comments: [],
    pages: null,
    loading: true,
    error: null,
  });

  const fetchComments = (isRefresh = true) => {
    let page = 1;
    if (state.pages && state.pages.next && !isRefresh) {
      page = state.pages.next;
    }
    const userToken = props.userToken;
    axios({
      method: "get",
      url: `/posts/${props.post._id}/comments?page=${page}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!is_Mounted) return;
        const comments = response.data.data.comments;
        const pages = response.data.data.pages;
        let newComments;
        if (!isRefresh) {
          const filteredComments = comments.filter((el) => {
            return !state.comments.find((comment) => comment._id === el._id);
          });
          newComments = [...state.comments, ...filteredComments];
        } else {
          newComments = comments;
        }
        setState({
          ...state,
          loading: false,
          pages: pages,
          comments: newComments,
        });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg,
          });
        }
      });
    if (isRefresh) {
      setState({
        ...state,
        pages: null,
        loading: true,
        error: null,
        comments: [],
      });
    } else {
      setState({
        ...state,
        loading: true,
        error: null,
      });
    }
  };

  const loadMoreComments = (event) => {
    event.preventDefault();
    fetchComments(false);
  };

  useEffect(() => {
    is_Mounted = true;
    fetchComments();
    return () => {
      is_Mounted = false;
    };
  }, [props.post]);
  let loadingContent = null;
  if (state.loading) {
    loadingContent = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.error) {
    loadingContent = (
      <div className={classes.Loading}>
        <Error error={state.error} refresh={fetchComments} />
      </div>
    );
  } else if (state.pages && state.pages.next) {
    loadingContent = (
      <div className={classes.Loading}>
        <a onClick={loadMoreComments} href="/">
          View More Reviews <span className="fa fa-chevron-down"></span>
        </a>
      </div>
    );
  }
  let content = null;
  if (state.comments.length) {
    content = state.comments.map((comment) => {
      return <Comment key={comment._id} comment={comment} />;
    });
  } else if (!state.loading && !state.error) {
    content = (
      <p className="p-3">
        No reviews yet on this product. Buy this product now to be the first to
        review this product.
      </p>
    );
  }
  return (
    <div className={classes.CommentBox}>
      <div className={classes.CommentHeader}>
        <h5>Reviews ({props.post.comments})</h5>
      </div>
      <div className={classes.CommentList}>
        {content}
        {loadingContent}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.authState.userToken,
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(CommentBox);
