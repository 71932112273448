import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Sell.module.css";
import SellProducts from "./SellProducts/SellProducts";
import SellLocation from "./SellLocation/SellLocation";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SalesPage from "./SalesPage/SalesPage";

class Sell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1,
      completed: null,
    };
  }

  setCompletedHandler = (postId) => {
    this.setState({
      completed: postId,
      active: 0,
    });
  };

  componentDidMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    // if (!this.props.user.is_member) {
    //   this.props.history.replace("/social/member");
    // }
    window.scrollTo(0, 0);
  }

  nextHandler = () => {
    this.setState((state) => ({
      active: state.active >= 3 ? 3 : state.active + 1,
    }));
  };

  prevHandler = () => {
    this.setState((state) => ({
      active: state.active > 1 ? state.active - 1 : 1,
    }));
  };

  render() {
    let content = null;
    if (this.state.completed) {
      content = (
        <ContentLayout>
          <SingleColumnLayout>
            <MainContent>
              <PageContent>
                <Title>
                  <TitleHeader>
                    <BackButton />
                    <h4>Upload Successful</h4>
                  </TitleHeader>
                </Title>
                <div className={classes.CreateCompleted}>
                  <div className={classes.CreateCompletedContent}>
                    <i
                      style={{ color: "#5f04f6" }}
                      className="fad fa-spin fa-spinner fa-5x mb-2"
                    ></i>
                    <h4 className="mb-3">Your Product is under Review!</h4>
                    <p className="mb-3">
                      Your product has been uploaded successfully and it is
                      undergoing review. We will approve your product within
                      24hrs. Thank you for choosing Hawkit.
                    </p>
                    <p>
                      <Link
                        to={`/products/${this.state.completed}`}
                        className="btn btn-coloured-heavy btn-rounded btn-xl"
                      >
                        VIEW PRODUCT
                      </Link>
                    </p>
                  </div>
                </div>
              </PageContent>
            </MainContent>
          </SingleColumnLayout>
        </ContentLayout>
      );
    } else if (this.state.active === 1) {
      content = <SalesPage next={this.nextHandler} prev={this.prevHandler} />;
    } else if (this.state.active === 2) {
      content = (
        <SellLocation next={this.nextHandler} prev={this.prevHandler} />
      );
    } else if (this.state.active === 3) {
      content = (
        <SellProducts
          completed={this.setCompletedHandler}
          next={this.nextHandler}
          prev={this.prevHandler}
        />
      );
    }
    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(Sell);
