import React, { useEffect } from "react";
import classes from "./ResellInfoModal.module.css";
import { withRouter } from "react-router-dom";

const ResellInfoModal = (props) => {
  useEffect(() => {
    window.$("#ResellInfoModal").modal({
      backdrop: "static",
      keyboard: false,
    });
    window.$("#ResellInfoModal").modal("show");
  }, []);

  const closeHandler = (event) => {
    event.preventDefault();
    window.$("#ResellInfoModal").off("hidden.bs.modal");
    window.$("#ResellInfoModal").modal("hide");
  };

  return (
    <div className={classes.ResellModal}>
      <div
        id="ResellInfoModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div
          style={{ maxWidth: "450px" }}
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className={`${classes.ResellModalContent} modal-content`}>
            <div
              id="ResellModalBody"
              className={`${classes.ResellModalBody} modal-body`}
            >
              <button onClick={closeHandler} className={classes.CloseButton}>
                <span className="fas fa-times"></span>
              </button>
              <div
                className={`${classes.SellModalContainer} d-flex flex-column align-items-center`}
              >
                <div className="container-fluid p-0">
                  <div className="row no-gutters">
                    <div className="col-6">
                      <div className={`${classes.TaskBg}`}></div>
                    </div>
                    <div className="col-6">
                      <div className={`${classes.Task}`}>
                        <div className={classes.TaskHeading}>
                          <h5>
                            Resell Products of Top Sellers and Brands and Earn
                            Amazing Sales Commission
                          </h5>
                          <p className="mb-2">
                            You can now share products of various sellers and
                            brands on the Hawkit Market to your own contacts and
                            social network and you will earn amazing sales
                            commission when your contacts buy from the sellers
                            and top brands.
                          </p>
                          <p>Follow the following steps to get started: </p>
                        </div>
                        <div className={classes.TaskContent}>
                          <ol>
                            <li>
                              <p>
                                Browse through the products on the Hawkit Market
                                and select the product you want to resell.
                              </p>
                            </li>
                            <li>
                              <p>
                                Click on the 'Resell Product' button to generate
                                your unique product link
                              </p>
                            </li>
                            <li>
                              <p>
                                Share the product images and details with your
                                unique product link to your social media such as
                                Whatsapp, Facebook, Instagram etc.
                              </p>
                            </li>
                            <li>
                              <p>
                                You will earn your sales commission directly
                                into your wallet when someone buys using your
                                unique product link
                              </p>
                            </li>
                          </ol>
                        </div>
                        <div className={classes.TaskFooter}>
                          <p>
                            <button
                              onClick={closeHandler}
                              className="btn btn-coloured-heavy btn-xl mb-1"
                            >
                              GET STARTED NOW
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(ResellInfoModal);
