import React from "react";
import classes from "./Title.module.css";
import { Helmet } from "react-helmet";

const TitleHeader = props => {
  const headingTag = React.Children.toArray(props.children).find(el => {
    return el.type === "h4";
  });
  let helmet = null;
  if (headingTag) {
    helmet = (
      <Helmet>
        <title>{headingTag.props.children}</title>
      </Helmet>
    );
  } else {
    helmet = (
      <Helmet>
        <title>Hawkit {props.title ? " - " + props.title : ""}</title>
      </Helmet>
    );
  }
  return (
    <div className={classes.TitleHeader}>
      {helmet}
      {props.children}
    </div>
  );
};

export default TitleHeader;
