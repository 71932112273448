import React from "react";
import classes from "./ShopSideCategory.module.css";
import { Link } from "react-router-dom";

const ShopSideCategory = (props) => {
  return (
    <div className={classes.ShopSideCategory}>
      <Link
        to={`/${props.is_airtime ? "airtime" : props.link}/${
          props.category._id
        }`}
      >
        <i className="fas fa-list"></i>
        <span>{props.category.name}</span>
      </Link>
    </div>
  );
};

export default ShopSideCategory;
