/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { getYoutubeId } from "../../../../util/util";
import classes from "./VideoModal.module.css";
import OfficeSpinner from "../../Spinner/OfficeSpinner/OfficeSpinner";

const VideoModal = (props) => {
  const [videoActive, setVideoActive] = useState(false);
  const iframeRef = useRef();

  useEffect(() => {
    const iframe = iframeRef.current;
    iframe.onload = () => {
      setVideoActive(true);
    };
    return () => {};
  }, []);

  const closeHandler = () => {
    props.close();
  };

  const youtubeId = getYoutubeId(props.video);
  const youtube_link = `https://youtube.com/embed/${youtubeId}?rel=1&modestbranding=1&showinfo=0&fs=0&showsearch=0`;

  const loadingContent = <OfficeSpinner size="3" />;
  const videoContent = (
    <div
      className={
        videoActive
          ? "w-100 d-flex align-items-center justify-content-center"
          : "d-none"
      }
    >
      <iframe
        ref={iframeRef}
        title="Hawkit Video"
        className={classes.VideoIframe}
        src={youtube_link}
        allowFullScreen
      ></iframe>
    </div>
  );

  return (
    <div id="videoLightModal" className={classes.Modal}>
      <button onClick={closeHandler} id="close" className={classes.Close}>
        &times;
      </button>
      {!videoActive ? loadingContent : null}
      {videoContent}
    </div>
  );
};

export default VideoModal;
