/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import classes from "../Post.module.css";
import { Link } from "react-router-dom";

const moment = require("moment");

const PostTitle = props => {
  const now = moment();
  const postMoment = moment(props.post.created_on);
  const daysDiff = now.diff(postMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = postMoment.calendar();
  } else {
    formatDate = postMoment.fromNow();
  }
  const dateRef = useRef(null);
  const locationRef = useRef(null);
  const usernameRef = useRef(null);
  const titleRef = useRef(null);
  useEffect(() => {
    const titleWidth = document.getElementById("postContent").offsetWidth - 90;
    const usernameWidth = usernameRef.current.offsetWidth;
    dateRef.current.style.width = titleWidth - usernameWidth - 5 + "px";
    locationRef.current.style.width = titleWidth - 5 + "px";
  }, []);
  const address = [];

  if (props.post.user && props.post.user.location) {
    if (props.post.user.location.lga) {
      address.push(props.post.user.location.lga);
    }
    if (props.post.user.location.state) {
      address.push(props.post.user.location.state);
    }
    address.push(props.post.user.country.name);
  }

  return (
    <div ref={titleRef} className={classes.Title}>
      <div className={classes.MainTitle}>
        <Link ref={usernameRef} to={`/users/${props.post.user.username}`}>
          <h4>{props.post.user.username}</h4>
        </Link>
        <p ref={dateRef}>
          <span className="fal fa-clock"></span> {formatDate}
        </p>
      </div>
      <div className={classes.Handle}>
        <p ref={locationRef}>
          <span className="fal fa-map-marker-alt"></span>{" "}
          {props.post.product
            ? address.join(", ")
            : props.post.user.location.address}
        </p>
      </div>
    </div>
  );
};

export default PostTitle;
