/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import classes from "./Earning.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import axios from "../../../axios/axios-instance";
import SocialTransactionContents from "../../../components/App/SocialTransactionContents/SocialTransactionContents";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";

class Earning extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      type: "advertise",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    const transactions = this.props.page_states[this.props.location.pathname];
    if (!transactions) {
      this.loadTaskPricing();
    }
  }

  loadTaskPricing = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/tasks/pricing`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;                
        const transactions = response.data.data.task_pricing;
        const page_states = {...this.props.page_states};
        page_states[this.props.location.pathname] = transactions;
        this.props.setPageStates(page_states);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  setTaskType = (type) => {
    this.setState({
      type: type,
    });
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadTaskPricing} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;

    const page_states = {...this.props.page_states};
    const transactions = page_states[this.props.location.pathname];
    let advert_task_count, engagement_task_count;

    if (transactions) {
      const active_transactions = transactions.filter(
        (el) => el.task_type === this.state.type
      );
      advert_task_count = transactions.reduce((total, el) => {
        if (el.task_type === "advertise") {
          return total + el.tasks;
        }
        return total;
      }, 0);
      engagement_task_count = transactions.reduce((total, el) => {
        if (el.task_type === "engagement") {
          return total + el.tasks;
        }
        return total;
      }, 0);
      content = (
        <SocialTransactionContents
          rowClass="col-md-6 col-lg-4"
          content_type="task"
          transactions={active_transactions}
        />
      );
    }

    return (
      <div className={classes.Earning}>
        <div className="container">
          <div className="row no-gutters">
            <div className="offset-md-2 col-md-8 offset-lg-2 col-lg-8">
              <div className={classes.MainHeadingTitle}>
                <div className={classes.Title}>
                  <h1>Earn in Multiple Ways</h1>
                  <p className="mb-3">
                    You can earn on Hawkit by reselling products, posting
                    adverts on your social media page or by performing simple
                    social tasks on your account. Simply choose how you want to
                    earn below
                  </p>
                  <p className="f-12">
                    <b>
                      One-time subscription fee of ₦1,000 is required before you
                      can start earning.
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.EarningHeader}>
          <div className="row">
            <div className="offset-md-4 col-md-4">
              <p className="mb-2">
                <button
                  onClick={() => this.props.history.push("/shop")}
                  className={classes.ResellButton}
                >
                  <h4 className="mr-2">
                    CLICK HERE TO RESELL PRODUCTS AND EARN UP TO ₦100,000
                    MONTHLY IN SALES COMMISSIONS
                  </h4>
                  <div className="mr-auto">
                    <span className="badge badge-danger">HOT AND NEW!!!</span>
                  </div>
                </button>
              </p>
              <p className="mb-3">
                <button
                  onClick={() => this.setTaskType("advertise")}
                  className={`btn mr-2 ${
                    this.state.type === "advertise"
                      ? "btn-success"
                      : "btn-light"
                  }`}
                >
                  Post Adverts{" "}
                  {advert_task_count ? (
                    <span className="badge badge-danger">
                      {advert_task_count}
                    </span>
                  ) : null}
                </button>
                <button
                  onClick={() => this.setTaskType("engagement")}
                  className={`btn ${
                    this.state.type === "engagement"
                      ? "btn-success"
                      : "btn-light"
                  }`}
                >
                  Perform Engagement Tasks{" "}
                  {engagement_task_count ? (
                    <span className="badge badge-danger">
                      {engagement_task_count}
                    </span>
                  ) : null}
                </button>
              </p>
              {this.state.type === "advertise" ? (
                <>
                  <p className="f-12 mb-2">
                    Earn steady income by posting adverts of businesses and top
                    brands on your social media page. To post adverts on
                    Facebook, Instagram, Twitter or Tiktok, you MUST have
                    atleast <b>1,000 Followers</b> on your social media account.
                  </p>
                  <p className="f-10">
                    Don't have up to 1,000 followers? Click{" "}
                    <button
                      onClick={() => this.setTaskType("engagement")}
                      className="text-primary"
                    >
                      here
                    </button>{" "}
                    to perform engagement tasks
                  </p>
                </>
              ) : (
                <>
                  <p className="f-12 mb-2">
                    Earn steady income by performing simple social and
                    engagement tasks for businesses on your social media account
                  </p>
                  <p className="f-10">
                    Do you have up to 1,000 followers? Click{" "}
                    <button
                      onClick={() => this.setTaskType("advertise")}
                      className="text-primary"
                    >
                      here
                    </button>{" "}
                    to earn from posting adverts on your social media account.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classes.EarningContent}>
          <div className="container">{content}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Earning);
