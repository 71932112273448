import React from "react";
import classes from "./Select.module.css";

const select = props => {
  const selectClasses = [classes.Select, "custom-select"];
  if (props.invalid) {
    selectClasses.push("is-invalid");
  }
  let hint = null,
    invalid = null;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    invalid = <div className="pb-2 pl-3 invalid-feedback">{props.invalid}</div>;
  }
  let options = null;
  if (props.options) {
    options = props.options.map(el => {
      return (
        <option key={el.value} value={el.value}>
          {el.option}
        </option>
      );
    });
  }
  let selectElement = (
    <React.Fragment>
      <select
        className={selectClasses.join(" ")}
        value={props.value}
        onChange={props.changeHandler}
        disabled={props.disabled}
      >
        <option value="">
          {!props.placeholder ? "Select..." : props.placeholder}
        </option>
        {options}
      </select>
    </React.Fragment>
  );
  if (props.sideStyle) {
    selectElement = (
      <div className="input-group pr-2 border rounded">
        <div className="input-group-prepend">
          <span className="pl-3 input-group-text bg-white border-0">
            {props.sideStyle.startsWith("fa") ? (
              <span className={props.sideStyle} />
            ) : (
              props.sideStyle
            )}
          </span>
        </div>
        {selectElement}
        {invalid}
      </div>
    );
  }
  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      {selectElement}
      {hint}
    </div>
  );
};

export default select;
