class ImageCropper {
  _x = 0;
  _y = 0;
  _z = 0;
  _container = null;
  _cropSection = null;
  _editorSection = null;
  _imageSection = null;
  _image = null;
  _cropWidth = 0;
  _cropHeight = 0;
  _scale = 1;
  _cropSize = 0;
  _scrollSection = null;
  _callback = null;

  constructor(
    _container,
    _image,
    _cropSection,
    _editorSection,
    _imageSection,
    _callback
  ) {
    this._container = _container;
    this._image = _image;
    this._cropSection = _cropSection;
    this._editorSection = _editorSection;
    this._imageSection = _imageSection;
    this._callback = _callback;
  }

  _scrollPos = 0;
  _scrollSize = 0;
  _scrollMoving = false;

  _applyButton = null;

  setApplyButton(_applyButton) {
    this._applyButton = _applyButton;
    _applyButton.addEventListener("click", this.createEditorSectionImage);
  }

  setScrollSection(_scrollSection) {
    this._scrollSection = _scrollSection;
    const container = _scrollSection.container;
    // const indicator = _scrollSection.indicator;
    this._scrollSize = container.offsetWidth;
    container.addEventListener("mousedown", this.scrollStart);
    container.addEventListener("touchstart", this.scrollStart);
    container.addEventListener("touchmove", this.scrollMove);
    container.addEventListener("touchend", this.scrollEnd);
    container.addEventListener("mousemove", this.scrollMove);
    container.addEventListener("mouseup", this.scrollEnd);
  }

  scrollStart = event => {
    event.preventDefault();
    this._scrollMoving = true;
    if (event.targetTouches) {
      const touches = event.targetTouches;
      if (touches.length === 1) {
        this._scrollPos = touches[0].pageX;
      }
    } else {
      this._scrollPos = event.pageX;
    }
  };

  scrollMove = event => {
    event.preventDefault();
    if (this._scrollMoving) {
      if (event.changedTouches) {
        const touches = event.changedTouches;
        if (touches.length === 1) {
          const deltaX = touches[0].pageX - this._scrollPos;
          this.zoomScroll(-deltaX);
          this._scrollPos = touches[0].pageX;
        }
      } else {
        const deltaX = event.pageX - this._scrollPos;
        this.zoomScroll(-deltaX);
        this._scrollPos = event.pageX;
      }
    }
  };

  scrollEnd = event => {
    event.preventDefault();
    this._scrollMoving = false;
  };

  zoomScroll(deltaX) {
    const scrollFactor = (this._scale - 1) / 3;
    const scrollPercent = scrollFactor * 100;
    if (scrollPercent <= 100 && scrollPercent >= 0) {
      this.zoomHandler(deltaX);
    }
  }

  initialize() {
    const imageContainerHeight = this._container.offsetHeight;
    const imageContainerWidth = this._container.offsetWidth;
    let cropSize = Math.floor(imageContainerHeight) - 50;
    if (cropSize > imageContainerWidth) {
      cropSize = Math.floor(imageContainerWidth) - 50;
    }
    this._cropSize = cropSize;
    this._image.onload = event => {
      this._cropWidth = (this._image.width / this._image.height) * cropSize;
      this._cropHeight = (this._image.height / this._image.width) * cropSize;
      if (this._cropWidth < cropSize) {
        this._cropWidth = cropSize;
      } else if (this._cropHeight < cropSize) {
        this._cropHeight = cropSize;
      }
      this._cropSection.style.width = cropSize + "px";
      this._cropSection.style.height = cropSize + "px";
      this._editorSection.style.width = this._cropWidth + "px";
      this._editorSection.style.height = this._cropHeight + "px";
      this._imageSection.style.backgroundImage = `url(${this._image.src})`;
    };
    this.addEventListeners();
  }

  addEventListeners() {
    this._container.addEventListener("wheel", this.wheelEventListener);
    this._container.addEventListener("touchmove", this.touchMoveHandler);
    this._container.addEventListener("touchstart", this.touchStartHandler);
    this._container.addEventListener("touchend", this.touchEndHandler);
    this._container.addEventListener("touchcancel", this.touchEndHandler);
    this._container.addEventListener("mousedown", this.mouseDownHandler);
    this._container.addEventListener("mousemove", this.mouseMoveHandler);
    this._container.addEventListener("mouseup", this.mouseUpHandler);
  }

  zoomHandler = deltaY => {
    this._scale += deltaY * -0.01;
    this._scale = Math.min(Math.max(1, this._scale), 4);
    const newWidth = this._cropWidth * this._scale;
    const newHeight = this._cropHeight * this._scale;
    this.moveHandler(this._x, this._y);
    const scrollFactor = (this._scale - 1) / 3;
    const scrollPercent = scrollFactor * 100;
    this._scrollSection.indicator.style.left = scrollPercent + "%";
    this._scrollSection.size.style.flexGrow = scrollFactor + "";
    this._editorSection.style.width = `${newWidth.toFixed(2)}px`;
    this._editorSection.style.height = `${newHeight.toFixed(2)}px`;
  };

  _cursorPos = {
    x: 0,
    y: 0
  };

  mouseDownHandler = event => {
    event.preventDefault();
    this._startMoving = true;
    this._cursorPos = {
      x: event.pageX,
      y: event.pageY
    };
  };

  _startMoving = false;
  _deltaX = 0;
  _deltaY = 0;

  mouseMoveHandler = event => {
    event.preventDefault();
    if (this._startMoving) {
      const deltaX = this._x + event.pageX - this._cursorPos.x;
      const deltaY = this._y + event.pageY - this._cursorPos.y;
      this._cursorPos = {
        x: event.pageX,
        y: event.pageY
      };
      this.moveHandler(deltaX, deltaY);
    }
  };

  mouseUpHandler = event => {
    event.preventDefault();
    this._startMoving = false;
  };

  touchStartHandler = event => {
    event.preventDefault();
    var touches = event.targetTouches;
    switch (touches.length) {
      case 1:
        this._startMoving = true;
        this._cursorPos = {
          x: touches[0].pageX,
          y: touches[0].pageY
        };
        break;
      default:
        this._startMoving = false;
    }
  };

  touchMoveHandler = event => {
    event.preventDefault();
    var touches = event.changedTouches;
    if (this._startMoving) {
      const deltaX = this._x + touches[0].pageX - this._cursorPos.x;
      const deltaY = this._y + touches[0].pageY - this._cursorPos.y;
      this._cursorPos = {
        x: touches[0].pageX,
        y: touches[0].pageY
      };
      this.moveHandler(deltaX, deltaY);
    }
  };

  touchEndHandler = event => {
    event.preventDefault();
    this._startMoving = false;
  };

  verifyMoveLimits(x, y) {
    const curWidth = this._scale * this._cropWidth;
    const curHeight = this._scale * this._cropHeight;
    const limitX = (curWidth - this._cropSize) / 2;
    const limitY = (curHeight - this._cropSize) / 2;
    if (x >= 0) {
      this._x = x <= limitX ? x : limitX;
    } else {
      this._x = x >= -limitX ? x : -limitX;
    }
    if (y >= 0) {
      this._y = y <= limitY ? y : limitY;
    } else {
      this._y = y >= -limitY ? y : -limitY;
    }
  }

  moveHandler = (x, y) => {
    this.verifyMoveLimits(x, y);
    this._editorSection.style.transform = `translate3d(${this._x}px, ${this._y}px, ${this._z}px)`;
  };

  wheelEventListener = event => {
    event.preventDefault();
    this.zoomHandler(event.deltaY);
  };

  createEditorSectionImage = event => {
    event.preventDefault();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const imageObj = new Image();

    const width = this._scale * this._cropWidth;
    const height = this._scale * this._cropHeight;

    canvas.width = width;
    canvas.height = height;

    imageObj.onload = () => {
      context.drawImage(imageObj, 0, 0, width, height);
      canvas.toBlob(blob => {
        this.createCropImage(URL.createObjectURL(blob), imageObj.width);
      });
    };

    imageObj.src = this._image.src;
  };

  filterImage = image => {
    const oc = document.createElement("canvas");
    const octx = oc.getContext("2d");
    oc.width = image.width;
    oc.height = image.height;

    // steo 2: pre-filter image using steps as radius
    const steps = (oc.width / image.width) >> 1;
    octx.filter = `blur(${steps}px)`;
    octx.drawImage(image, 0, 0);
  };

  createCropImage = (image, originalWidth) => {
    const curWidth = this._scale * this._cropWidth;
    const curHeight = this._scale * this._cropHeight;
    const limitX = (curWidth - this._cropSize) / 2;
    const limitY = (curHeight - this._cropSize) / 2;
    const x = limitX - this._x;
    const y = limitY - this._y;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const imageObj = new Image();

    const width = this._cropSize;
    const height = this._cropSize;

    canvas.width = width;
    canvas.height = height;

    const actualScale = originalWidth / curWidth;

    const crop = {
      x: x * actualScale,
      y: y * actualScale,
      width: width * actualScale,
      height: height * actualScale
    };    

    imageObj.onload = () => {
      context.drawImage(imageObj, x, y, width, height, 0, 0, width, height);
      canvas.toBlob(blob => {
        this._callback(URL.createObjectURL(blob), crop);
      });
    };

    imageObj.src = image;
  };

  destroy() {
    this._cropSection.style.width = "0px";
    this._cropSection.style.height = "0px";
    this._editorSection.style.width = "0px";
    this._editorSection.style.height = "0px";
    this._imageSection.style.backgroundImage = null;
    this._editorSection.style.transform = "translate3d(0px, 0px, 0px)";
  }
}

export default ImageCropper;
