import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./EarnCategory.module.css";
import { Switch, Route, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import SocialTransactionContent from "../../../../components/App/SocialTransactionContents/SocialTransactionContent/SocialTransactionContent";
import BrowserLinks from "../../../../components/UI/Links/BrowserLinks/BrowserLinks";
import LinkSocialModal from "../LinkSocialModal/LinkSocialModal";
import { toFirstLetterUppercase } from "../../../../util/util";
import AllEarningTasks from "../AllEarningTasks/AllEarningTasks";

class EarnCategory extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      category: null,
      error: null,
      showModal: false,
      tasks_count: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    this.loadCategory();
  }

  loadCategory = () => {
    const userToken = this.props.userToken;
    const { category } = this.props.match.params;
    axios({
      method: "get",
      url: `/social/category/${category}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const category = response.data.data.category;
        const tasks_count = response.data.data.tasks_count;
        this.setState({
          loading: false,
          category: category,
          tasks_count: tasks_count,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  refresh = () => {
    this.loadCategory();
    this.setState({
      showModal: false,
    });
  };

  render() {
    let content = null;

    if (!this.props.user || !this.props.user.is_member) {
      return <Redirect to="/earn/member" />;
    }

    if (this.state.loading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className={classes.Loading}>
          <Error refresh={this.loadCategory} error={this.state.error} />
        </div>
      );
    } else if (this.state.category) {
      const categoryType = toFirstLetterUppercase(this.state.category.type);
      const user_social = this.state.category.user_social;
      let social_content;
      if (this.state.category.req_write_up) {
        if (user_social) {
          let link_button = (
            <BrowserLinks
              to={user_social.profile_link}
              className="f-8 d-flex align-items-center btn btn-success btn-xs"
            >
              <i className="fas fa-check-circle"></i> VERIFIED
            </BrowserLinks>
          );
          if (user_social.status === "IN REVIEW") {
            link_button = (
              <BrowserLinks
                to={user_social.profile_link}
                className="f-8 d-flex align-items-center btn btn-warning btn-xs"
              >
                <i className="fas fa-spin fa-spinner"></i> IN REVIEW
              </BrowserLinks>
            );
          } else if (user_social.status === "REJECTED") {
            link_button = (
              <BrowserLinks
                to={user_social.profile_link}
                className="f-8 d-flex align-items-center btn btn-danger btn-xs"
              >
                <i className="fas fa-times-circle"></i> REJECTED
              </BrowserLinks>
            );
          }
          let review_text = (
            <p className="f-10 text-info">
              Your {categoryType} task must be done from the above{" "}
              {categoryType} Profile which has been linked to your Hawkit
              Account
            </p>
          );
          if (user_social.status === "IN REVIEW") {
            review_text = (
              <>
                <p className="f-10 text-dark mb-2">
                  We are currently reviewing your {categoryType} Profile
                  account. This usually takes 24hrs to review. You will be able
                  to perform
                  {categoryType} tasks once your account is verified. You can
                  edit your profile link by clicking the button below.
                </p>
                <p>
                  <button
                    onClick={this.showModal}
                    className="btn btn-lg btn-warning"
                  >
                    <i className="fal fa-edit"></i> EDIT{" "}
                    {categoryType.toUpperCase()} PROFILE LINK
                  </button>
                </p>
              </>
            );
          } else if (user_social.status === "REJECTED") {
            review_text = (
              <>
                <p className="f-10 text-danger mb-2">
                  Your {categoryType} Profile Link was rejected because you
                  didn't meet all rules and requirements. Ensure you follow all
                  the rules and requirements. You can try again using the button
                  below:
                </p>
                <p>
                  <button
                    onClick={this.showModal}
                    className="btn btn-lg btn-warning"
                  >
                    <i className="fal fa-edit"></i> ADD{" "}
                    {categoryType.toUpperCase()} PROFILE LINK
                  </button>
                </p>
              </>
            );
          }
          social_content = (
            <div className="p-3">
              <p className="fw-600 mb-1">Your {categoryType} Profile Account</p>
              <div className="input-group mb-1">
                <input
                  type="text"
                  className="form-control f-10"
                  value={user_social.profile_link}
                  readOnly
                />
                <div className="input-group-append">{link_button}</div>
              </div>
              {review_text}
            </div>
          );
        } else {
          social_content = (
            <div className="py-2 px-3 alert alert-danger rounded-0">
              <h6>Link Your {categoryType} Account</h6>
              <p className="f-12 mb-3">
                You need to link your {categoryType} Account to Hawkit before
                you can start earning with your {categoryType} Account. Click
                the button below to link your {categoryType} account now.
              </p>
              <p>
                <button
                  onClick={this.showModal}
                  className="btn btn-light btn-lg"
                >
                  <i className={`fab fa-${this.state.category.type}`}></i> ADD{" "}
                  {categoryType.toUpperCase()} ACCOUNT
                </button>
              </p>
            </div>
          );
        }
      }
      content = (
        <div className={classes.EarnCategory}>
          {this.state.showModal ? (
            <LinkSocialModal
              refresh={this.refresh}
              category={this.state.category}
            />
          ) : null}
          <SocialTransactionContent transaction={this.state.category} />
          {social_content}
          <div className={classes.EarnCategoryNav}>
            <NavLink
              replace
              exact
              activeClassName={classes.Active}
              to={`/earn/${this.state.category._id}/pending`}
            >
              Pending{" "}
              {this.state.tasks_count.pending ? (
                <span className="badge badge-danger">
                  {this.state.tasks_count.pending}
                </span>
              ) : null}
            </NavLink>
            <NavLink
              replace
              exact
              activeClassName={classes.Active}
              to={`/earn/${this.state.category._id}/uploaded`}
            >
              In Review{" "}
              {this.state.tasks_count.uploaded ? (
                <span className="badge badge-danger">
                  {this.state.tasks_count.uploaded}
                </span>
              ) : null}
            </NavLink>
            <NavLink
              replace
              exact
              activeClassName={classes.Active}
              to={`/earn/${this.state.category._id}/failed`}
            >
              Failed{" "}
              {this.state.tasks_count.failed ? (
                <span className="badge badge-danger">
                  {this.state.tasks_count.failed}
                </span>
              ) : null}
            </NavLink>
            <NavLink
              replace
              exact
              activeClassName={classes.Active}
              to={`/earn/${this.state.category._id}/completed`}
            >
              Completed{" "}
              {this.state.tasks_count.completed ? (
                <span className="badge badge-danger">
                  {this.state.tasks_count.completed}
                </span>
              ) : null}
            </NavLink>
            <NavLink
              replace
              exact
              activeClassName={classes.Active}
              to={`/earn/${this.state.category._id}/cancelled`}
            >
              Cancelled{" "}
              {this.state.tasks_count.cancelled ? (
                <span className="badge badge-danger">
                  {this.state.tasks_count.cancelled}
                </span>
              ) : null}
            </NavLink>
          </div>
          <div className={classes.EarnCategoryContent}>
            <Switch>
              <Route
                path="/earn/:category/:status"
                component={AllEarningTasks}
              />
              <Redirect from="/earn/:category" to="/earn/:category/pending" />
            </Switch>
          </div>
        </div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>{content}</MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(EarnCategory);
