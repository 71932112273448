import React, { Component } from "react";
import classes from "./Loader.module.css";
import { connect } from "react-redux";

const LOADING_STATUS = "LOADING";

class Loader extends Component {
  render() {
    let loader = null;
    if (this.props.status) {
      const spinner =
        this.props.status === LOADING_STATUS ? (
          <div className={classes.LoaderSpinner} />
        ) : (
          <div className={classes.LoaderError}>
            <span className="fa fa-times-circle" />
          </div>
        );
      loader = (
        <div className={classes.Loader}>
          <div className={classes.LoaderContent}>
            {spinner}
            {this.props.statusMsg ? (
              <div className={classes.LoaderText}>
                <p style={{ fontSize: "14px" }}>
                  {this.props.statusMsg}                  
                </p>
              </div>
            ) : null}
          </div>
        </div>
      );
    }    
    return (
      <React.Fragment>
        {/* <div className={`${classes.Progress} progress`}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{width: `${this.props.progress}%`}}
            // eslint-disable-next-line jsx-a11y/aria-proptypes
            aria-valuenow={this.props.progress}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div> */}
        {loader}
        {this.props.children}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.loader.status,
    statusMsg: state.loader.statusMsg,
    progress: state.loader.progress
  };
};

export default connect(mapStateToProps)(Loader);
