import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.min.css";
// import "@fortawesome/fontawesome-free/css/all.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/store";

window.VERSION = "2.0.6";
window.PUSH_TOKEN = "";
window.ERROR_CONNECTION =
  "Cannot Connect to the Server. Please check your internet connection and try again.";
window.AUTH_ERROR = "AUTH_ERROR";
window.API_ERROR = "API_ERROR";
window.FORM_ERROR = "FORM_ERROR";
window.TOKEN_ERROR = "TOKEN_ERROR";
window.SUCCESS = "SUCCESS";
window.ORDER_AWAITING_PAYMENT = "ORDER AWAITING PAYMENT";
window.ORDER_PAID = "ORDER PAID";
window.ORDER_AWAITING_DELIVERY = "ORDER AWAITING DELIVERY";
window.ORDER_SHIPPED = "ORDER SHIPPED";
window.ORDER_CANCELLED = "ORDER CANCELLED";
window.ORDER_NOT_DELIVERED = "ORDER NOT DELIVERED";
window.ORDER_DELIVERED = "ORDER DELIVERED";
window.ORDER_REJECTED = "ORDER REJECTED";
window.ORDER_COMPLETED = "ORDER COMPLETED";
window.MAX_VIDEO_SIZE = 20 * 1024 * 1024;

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );  
};

const deviceReady = () => {
  startApp();
};

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener("deviceready", deviceReady, false);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
