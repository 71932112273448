/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import classes from "./ImageModal.module.css";
import OfficeSpinner from "../../Spinner/OfficeSpinner/OfficeSpinner";

let is_Mounted = false;

const ImageModal = (props) => {
  const [image, setImage] = useState({
    src: null,
  });
  const imageModalRef = useRef();

  const closeHandler = () => {
    const modal = imageModalRef.current;
    modal.style.display = "none";
    props.close();
  };

  useEffect(() => {
    is_Mounted = true;
    const img = new Image();
    setImage({
      src: null,
    });
    if (!props.src) return;
    img.onload = () => {
      if (is_Mounted) {
        setImage({
          src: props.src,
        });
      }
    };
    if (props.src) {
      img.src = props.src;
    }
    return () => {
      is_Mounted = false;
    };
  }, [props.src]);

  let imageContent = <OfficeSpinner size="3" />;
  if (image.src) {
    imageContent = (
      <img className={classes.ModalContent} src={image.src} alt="img" />
    );
  }

  return (
    <div id="imageLightModal" ref={imageModalRef} className={classes.Modal}>
      <button onClick={closeHandler} id="close" className={classes.Close}>
        &times;
      </button>
      {imageContent}
    </div>
  );
};

export default ImageModal;
