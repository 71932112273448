/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import classes from "./UserSummary.module.css";
import UserPhoto from "../UserPhoto/UserPhoto";
import FetchLink from "../../../UI/FetchLink/FetchLink";
import { withRouter } from "react-router-dom";
const moment = require("moment");

const userSummary = props => {
  const now = moment();
  const postMoment = moment(props.data.created_on);
  const daysDiff = now.diff(postMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = postMoment.calendar();
  } else {
    formatDate = postMoment.fromNow();
  }
  const dateRef = useRef(null);
  const locationRef = useRef(null);
  const usernameRef = useRef(null);
  useEffect(() => {
    const titleWidth =
      document.getElementById("postModalBody").offsetWidth - 105;
    const usernameWidth = usernameRef.current.offsetWidth;
    dateRef.current.style.width = titleWidth - usernameWidth - 5 + "px";
    locationRef.current.style.width = titleWidth - 5 + "px";
  }, []);
  let followContent = null;
  if (props.data.followers_count) {
    const urls = [
      {
        url: `/users/${props.data.user.username}/followers`,
        method: "delete"
      },
      {
        url: `/users/${props.data.user.username}/followers`,
        method: "post"
      }
    ];
    followContent = (
      <FetchLink title={["Unfollow", "Follow"]} url={urls}>
        <i className="fad fa-users"></i>
        <i className="fal fa-users"></i>
        <i className="fal fa-spin fa-spinner"></i>
      </FetchLink>
    );
  } else {
    const urls = [
      {
        url: `/users/${props.data.user.username}/followers`,
        method: "post"
      },
      {
        url: `/users/${props.data.user.username}/followers`,
        method: "delete"
      }
    ];
    followContent = (
      <FetchLink title={["Follow", "Unfollow"]} url={urls}>
        <i className="fal fa-users"></i>
        <i className="fad fa-users"></i>
        <i className="fal fa-spin fa-spinner"></i>
      </FetchLink>
    );
  }

  const showUserHandler = event => {
    event.preventDefault();
    props.history.push(`/users/${props.data.user.username}`);
  };

  const userAddress = [];

  if (props.data.user.location) {
    if (props.data.user.location.lga) {
      userAddress.push(props.data.user.location.lga);
    }
    if (props.data.user.location.state) {
      userAddress.push(props.data.user.location.state);
    }
    userAddress.push(props.data.user.country.name);
  }

  return (
    <div onClick={showUserHandler} className={classes.UserSummary}>
      <div className={classes.UserPhoto}>
        <UserPhoto photo={props.data.user.profile_pic} width="36" height="36" />
      </div>
      <div className={classes.UserContent}>
        <div className={classes.Title}>
          <div className={classes.MainTitle}>
            <a onClick={showUserHandler} ref={usernameRef} href="/">
              <h4>{props.data.user.username}</h4>
            </a>
            <p ref={dateRef}>
              <span className="fal fa-clock"></span> {formatDate}
            </p>
          </div>
          <div className={classes.Handle}>
            <p ref={locationRef}>
              <span className="fal fa-map-marker-alt"></span>{" "}
              {userAddress.join(", ")}
            </p>
          </div>
        </div>
        {props.showFollow ? (
          <div className={classes.UserFollow}>{followContent}</div>
        ) : null}
      </div>
    </div>
  );
};
export default withRouter(userSummary);
