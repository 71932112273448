import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const intialiseAuthState = () => {
  return {
    clientToken: null,
    userToken: null,    
    authLoading: true,
    authError: null,
    verifyPhone: null,
    forgotPassword: null,
    completeSignUp: null,
    country: null,
    redirectPath: null
  };
};

const initialState = intialiseAuthState();

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CLIENT_TOKEN:
      draft.clientToken = action.clientToken;
      return;
    case actionTypes.SET_AUTH_LOADING:
      draft.authLoading = action.authLoading;
      return;
    case actionTypes.SET_AUTH_COUNTRY:
      draft.country = action.country;
      return;
    case actionTypes.SET_AUTH_ERROR:
      draft.authError = action.authError;
      return;
    case actionTypes.LOGIN:
      draft.userToken = action.userToken;
      return;
    case actionTypes.LOGOUT:
      draft.userToken = null;
      draft.clientToken = null;
      return;
    case actionTypes.SET_VERIFY_PHONE:
      draft.verifyPhone = action.verifyPhone;
      return;
    case actionTypes.SET_COMPLETE_SIGN_UP:
      draft.completeSignUp = action.completeSignUp;
      return;
    case actionTypes.SET_FORGOT_PASSWORD:
      draft.forgotPassword = action.forgotPassword;
      return;
    case actionTypes.SET_REDIRECT_PATH:
      draft.redirectPath = action.redirectPath;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
