import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import ShowUsers from "../../../components/App/User/ShowUsers/ShowUsers";
import List from "../../../components/UI/List/List";
import classes from "./Suggestions.module.css";

class Suggestions extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      suggestions: null,
      suggestionLoading: false,
      suggestionError: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSuggestions();
  }

  loadSuggestions = () => {
    if (this.state.suggestionLoading) return;
    const userToken = this.props.userToken;
    const url = "/search/users?page=1&limit=10";
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : "",
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const suggestions = response.data.data.users;
        this.setState({
          suggestionLoading: false,
          suggestions: suggestions,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              suggestionLoading: false,
              suugestionError: data.error[0].msg,
            });
          } else {
            this.setState({
              suggestionLoading: false,
              suggestionError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            suggestionLoading: false,
            suggestionError: errorMsg,
          });
        }
      });
    this.setState({
      suggestionLoading: true,
      suugestionError: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let content = (
      <div className={classes.Loading}>
        <p>Loading Suggestions...</p>
      </div>
    );
    if (this.state.suggestionLoading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.suggestionError) {
      content = (
        <div className={classes.Loading}>
          <Error
            error={this.state.suggestionError}
            refresh={this.loadSuggestions}
          />
        </div>
      );
    } else if (this.state.suggestions) {
      content = (
        <ShowUsers
          activeUser={this.props.user}
          users={this.state.suggestions}
        />
      );
    }
    return (
      <div className={classes.Suggestions}>
        <List
          title="People Near You"
          showMore={this.state.suggestions ? "/users" : null}
        >
          {content}
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps, null)(Suggestions);
