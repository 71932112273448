/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./SocialBuyForm.module.css";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import SocialTransactionContent from "../../../../components/App/SocialTransactionContents/SocialTransactionContent/SocialTransactionContent";
import {
  createInputElement,
  createSelectElement,
  createTextAreaElement,
} from "../../../../components/UI/Forms/utility/form-creators";
import Form from "../../../../components/UI/Forms/Form";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import { isNumeric, isInt } from "validator";
import PreviewImages from "../../../../components/App/CreatePost/PreviewImages/PreviewImages";
import PreviewVideos from "../../../../components/App/CreatePost/PreviewVideos/PreviewVideos";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";
import PaymentCompletedModal from "../../../../components/App/Order/PaymentCompletedModal/PaymentCompletedModal";
import { withRouter } from "react-router-dom";
import PaymentModal from "../../../../components/App/PaymentModal/PaymentModal";
import PaymentWalletModal from "../../../../components/App/PaymentWalletModal/PaymentWalletModal";
import VideoModal from "../../../../components/UI/Modal/VideoModal/VideoModal";

class SocialBuyForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    const platformOptions = props.transaction.platforms.map((el) => {
      return {
        option: el.toUpperCase(),
        value: el,
      };
    });
    const locationOption = this.props.country_states.map((el) => {
      return {
        value: el.name,
        option: el.name,
      };
    });
    const genderOptions = [...this.props.meta.gender_options];
    genderOptions.push({
      value: "All Gender",
      option: "All Gender",
    });
    locationOption.unshift({
      value: "All Nigeria",
      option: "All Nigeria",
    });
    const beliefs = [...props.meta.beliefs];
    beliefs.unshift({
      value: "All Religion",
      option: "All Religion",
    });
    let is_upload = null;
    let formData = {
      platform: createSelectElement(
        "Select Platform",
        this.validatePlatform,
        "fas fa-list",
        "col-sm-12",
        platformOptions,
        "Select Platform",
        this.inputChangedHandler.bind(this, "platform"),
        "",
        "Please select the social media or App Store platform where you want to perform this action"
      ),
      amount: createInputElement(
        "number",
        `Number of ${props.transaction.text} You Want`,
        `No. Of ${props.transaction.text}`,
        "",
        this.validateAmount,
        `This is the desired Number of ${props.transaction.text} you want us to get for you.`,
        "fa fa-list",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "amount")
      ),
      gender: createSelectElement(
        "Select Gender",
        this.validateGender,
        "fas fa-list",
        "col-sm-12",
        genderOptions,
        "Select Gender",
        this.inputChangedHandler.bind(this, "gender"),
        "",
        "You can select the kind of gender whether male or female that you want to see your task. For example, if you are selling women fashion items, you can select the Female gender so your task will be shown to only females. Select 'All Gender' if you want to target all genders"
      ),
      location: createSelectElement(
        "Select Location",
        this.validateLocation,
        "fas fa-list",
        "col-sm-12",
        locationOption,
        "Select Location",
        this.inputChangedHandler.bind(this, "location"),
        "",
        "You can target and select a particular location where your task or advert will be mostly shown. Select 'All Nigeria' if you want to target every location in Nigeria"
      ),
      belief: createSelectElement(
        "Select Religion",
        this.validateBelief,
        "fas fa-list",
        "col-sm-12",
        beliefs,
        "Select Religion",
        this.inputChangedHandler.bind(this, "belief"),
        "",
        "You can target people of a particular religion or belief. Your advert and task will be shown to the particular religion you select. Select 'All Religion' if you want to target all religion"
      ),
    };
    if (!props.transaction.is_upload) {
      formData["link"] = createInputElement(
        "text",
        props.transaction.link_title
          ? props.transaction.link_title
          : "Your Link (e.g Social Media Post or Page Link, App Download Link etc.)",
        "Enter Your Link",
        "",
        this.validateLink,
        props.transaction.link_description
          ? props.transaction.link_description
          : "Please enter the social media or app link of the account, profile, page, post or app you want to perform this action on. Ensure you enter the full link starting with https://****",
        "fa fa-tag",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "link")
      );
    } else {
      formData["caption"] = createTextAreaElement(
        "Enter Advert Text or Caption",
        "",
        "",
        this.validateCaption,
        "Please enter the advert text or caption. The advert text or caption should be well detailed. You can also include a link to your site, a phone number for people to contact you or any information you want people to see on your advert",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "caption"),
        "captionField"
      );
      is_upload = {
        previewVideo: null,
        previewPhotos: [],
        insert: "images",
      };
    }
    if (props.transaction.is_comment) {
      formData["comments"] = createTextAreaElement(
        props.transaction.comment_title,
        "",
        "",
        this.validateComments,
        props.transaction.comment_description,
        "col-sm-12",
        this.inputChangedHandler.bind(this, "comments"),
        "commentsField"
      );
    }
    this.state = {
      loading: false,
      error: null,
      formData: formData,
      is_upload: is_upload,
      total: 0,
      task: null,
      show_payment_modal: false,
      show_wallet_payment: false,
      wallet_success: null,
      wallet_error: null,
      paymentCompleted: null,
      show_video: false,
    };
    this.taskBottomRef = React.createRef();
  }

  validateGender = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select valid gender from the list above";
    }
    const genderOptions = this.props.meta.gender_options.map((el) => el.value);
    if (!genderOptions.includes(value) && value !== "All Gender") {
      return "Please select valid gender from the list above";
    }
    return null;
  };

  validateBelief = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select valid religion from the list above";
    }
    const beliefOptions = this.props.meta.beliefs.map((el) => el.value);
    if (!beliefOptions.includes(value) && value !== "All Religion") {
      return "Please select valid religion from the list above";
    }
    return null;
  };

  validateLocation = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select valid location from the list above";
    }
    const locationOptions = this.props.country_states.map((el) => el.name);
    if (!locationOptions.includes(value) && value !== "All Nigeria") {
      return "Please select valid location from the list above";
    }
    return null;
  };

  validatePlatform = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select a platform";
    }
    const platformOptions = this.props.transaction.platforms.map((el) => el);
    if (!platformOptions.includes(value)) {
      return "Please select valid platform from the list above";
    }
    return null;
  };

  validateComments = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter a value.";
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 3, 1000)) {
      return "Please enter a value. 3 - 1000 characters are allowed.";
    }
  };

  validateLink(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter a valid link.";
    }
    if (!validators.validateLength(value.trim(), 2, 500)) {
      return "Please enter valid social media or app link of the account, profile, page, post or app you want to perform this action on.";
    }
    if (!validators.isValidHttpUrl(value.trim())) {
      return "Please enter valid link starting with https://. For example, your facebook link may look like: https://facebook.com/*******";
    }
    return null;
  }

  validateCaption(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter a valid caption.";
    }
    if (!validators.validateLength(value.trim(), 2, 5000)) {
      return "Please enter valid advert text and caption bwtween 2 and 5000 characters";
    }
    return null;
  }

  validateAmount = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid number.";
    }
    if (!isInt(value.trim())) {
      return "Invalid Entry. Only whole numbers are allowed.";
    }
    if (+value.trim() <= 0) {
      return `Invalid Entry. Value must be greater than 0`;
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    let total = this.state.total;
    if (name === "amount") {
      total = this.props.transaction.pricing * +formElementData.value;
    }
    this.setState({ formData: updatedFormData, total: total });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  uploadHandler = (event) => {
    const files = event.target.files;
    if (
      !files ||
      !files.length ||
      files.length + this.state.is_upload.previewPhotos.length > 1
    ) {
      window.scrollTo(0, 0);
      this.setState(
        {
          error:
            "Invalid Number of Photos selected. You can only upload a maximum of 1 Photo Advert. Please try again.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null,
            });
          }, 3000);
        }
      );
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(file["type"])) {
        continue;
      }
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: file["type"] });
        const previewImage = window.URL.createObjectURL(blob);
        const previewPhoto = {
          file: file,
          photo: previewImage,
        };
        const previewPhotos = [...this.state.is_upload.previewPhotos];
        previewPhotos.push(previewPhoto);
        this.setState({
          is_upload: {
            ...this.state.is_upload,
            previewPhotos: previewPhotos,
          },
        });
      };
    }
  };

  uploadVideoHandler = (event) => {
    const files = event.target.files;
    if (!files || !files.length || files.length > 1) {
      window.scrollTo(0, 0);
      this.setState(
        {
          error:
            "Invalid Number of Videos selected. You can only upload a maximum of 1 video per time. Please try again.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null,
            });
          }, 3000);
        }
      );
      event.target.value = "";
      return;
    }
    const file = files.item(0);
    const validImageTypes = ["video/mp4", "video/quicktime"];
    if (!validImageTypes.includes(file["type"])) {
      window.scrollTo(0, 0);
      this.setState(
        {
          error: `Invalid File Uploaded [${file["type"]}]. Only .mp4 Videos are allowed. Please try again.`,
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null,
            });
          }, 3000);
        }
      );
      event.target.value = "";
      return;
    }
    if (file.size > window.MAX_VIDEO_SIZE) {
      this.setState(
        {
          error:
            "Video is too large. The video size should be less than 50MB. Please try again.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null,
            });
          }, 3000);
        }
      );
      event.target.value = "";
      return;
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const blob = new Blob([e.target.result], { type: file["type"] });
      const video = window.URL.createObjectURL(blob);
      const previewVideo = {
        file: file,
        video: video,
      };
      this.setState({
        is_upload: {
          ...this.state.is_upload,
          previewVideo: previewVideo,
        },
      });
    };
  };

  removePhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const previewPhotos = [...this.state.is_upload.previewPhotos];
    previewPhotos.splice(index, 1);
    if (index === this.state.active) {
      if (index >= 1) {
        this.setState({
          active: index - 1,
        });
      }
    }
    this.setState({
      is_upload: {
        ...this.state.is_upload,
        previewPhotos: previewPhotos,
      },
    });
  };

  removeVideoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      is_upload: {
        ...this.state.is_upload,
        previewVideo: null,
      },
    });
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.taskBottomRef.current) {
      this.taskBottomRef.current.style.width =
        this.taskBottomRef.current.offsetWidth + "px";
      this.taskBottomRef.current.style.position = "fixed";
    }
  }

  loadBuyPricing = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/buy/pricing`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const transactions = response.data.data.buy_pricing;
        const page_states = {...this.props.page_states};
        page_states[this.props.location.pathname] = transactions;
        this.props.setPageStates(page_states);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  setInsertContent = (insert) => {
    if (this.state.is_upload.insert === insert) return;
    const is_upload = {
      ...this.state.is_upload,
      insert: insert,
      previewPhotos: [],
      previewVideo: null,
    };
    this.setState({
      is_upload: is_upload,
      videoImages: [],
      videoImageActive: 0,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      window.scrollTo(0, 0);
      this.setState(
        {
          error:
            "There is an error in your form. Please check all entries and try again.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null,
            });
          }, 3000);
        }
      );
      return;
    }
    if (
      this.state.is_upload &&
      !this.state.is_upload.previewPhotos.length &&
      !this.state.is_upload.previewVideo
    ) {
      window.scrollTo(0, 0);
      this.setState(
        {
          error:
            "You must upload either a photo or video advert before you can continue.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              error: null,
            });
          }, 3000);
        }
      );
      return;
    }
    const userToken = this.props.userToken;
    const uploadFormData = new FormData();
    if (this.state.is_upload) {
      const photos = this.state.is_upload.previewPhotos;
      const insert = this.state.is_upload.insert;
      const previewVideo = this.state.is_upload.previewVideo;
      photos.forEach((el) => {
        uploadFormData.append("photos[]", el.file);
      });
      if (previewVideo) {
        uploadFormData.append("video", previewVideo.file);
      }
      uploadFormData.append("insert", insert);
    }
    const platform = this.state.formData.platform.value;
    const location = this.state.formData.location.value;
    const gender = this.state.formData.gender.value;
    const belief = this.state.formData.belief.value;
    const amount = this.state.formData.amount.value;
    uploadFormData.append("platform", platform);
    uploadFormData.append("location", location);
    uploadFormData.append("gender", gender);
    uploadFormData.append("belief", belief);
    uploadFormData.append("amount", amount);
    uploadFormData.append("social_task_category", this.props.transaction._id);
    if (this.state.formData.link) {
      uploadFormData.append("link", this.state.formData.link.value);
    }
    if (this.state.formData.caption) {
      uploadFormData.append("caption", this.state.formData.caption.value);
    }
    if (this.state.formData.comments) {
      uploadFormData.append("comments", this.state.formData.comments.value);
    }
    axios({
      method: "post",
      url: "/social/buy",
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const task = response.data.data.task;
        let new_meta = response.data.data.meta;
        if (!new_meta) new_meta = {};
        let meta = this.props.meta;
        this.props.setMeta({ ...meta, ...new_meta });
        this.setState(
          {
            task: task,
          },
          this.open_payment_modal
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        window.scrollTo(0, 0);
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  payWithWallet = () => {
    this.close_payment_modal();
    if (!this.state.task) return;
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: `/social/order/${this.state.task.id}/pay-with-wallet`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        if (window.fbq) {
          window.fbq("track", "Purchase", {
            currency: "NGN",
            content_name: this.props.transaction.title,
            content_category: "Social_Task",
            content_type: "social_task",
            value: this.state.task.order_total,
          });
        }
        const status = response.data.data.status;
        const user = response.data.data.user;
        this.props.processUser(user);
        this.setState({
          wallet_success: status,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              wallet_error: data.error[0].msg,
            });
          } else {
            this.setState({
              wallet_error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            wallet_error: errorMsg,
          });
        }
      });
    this.setState({
      wallet_success: null,
      wallet_error: null,
      show_wallet_payment: true,
    });
  };

  close_payment_modal = () => {
    this.setState({ show_payment_modal: false });
  };

  open_payment_modal = () => {
    this.setState({ show_payment_modal: true });
  };

  close_wallet_payment_modal = () => {
    this.setState({ show_wallet_payment: false });
  };

  payWithFlutterwave = () => {
    if (!this.props.user) {
      this.props.history.push("/auth/login");
      return;
    }
    this.close_payment_modal();
    let total = this.state.task.order_total;
    window.FlutterwaveCheckout({
      public_key: this.props.meta.flutterwave_public_key,
      tx_ref: `Order-${this.state.task.id}-${Date.now()}`,
      amount: total,
      currency: this.props.user.country.currency.short,
      country: this.props.user.country.code,
      customer: {
        username: this.props.user.username,
        email: this.props.user.email,
        name: this.props.user.name,
      },
      callback: (data) => {
        if (data.status === "successful") {
          if (window.fbq) {
            window.fbq("track", "Purchase", {
              currency: "NGN",
              content_name: this.props.transaction.title,
              content_category: "Social_Task",
              content_type: "social_task",
              value: total,
            });
          }
          this.setState({
            paymentCompleted: true,
          });
        }
      },
      onclose: () => {
        if (!this.state.paymentCompleted && this.state.task) {
          this.props.history.replace("/social/orders/");
        }
      },
    });
  };

  viewOrder = () => {
    this.props.history.replace("/social/orders/" + this.state.task.id);
  };

  closeVideo = () => {
    this.setState({
      show_video: false,
    });
  };

  showVideo = () => {
    this.setState({
      show_video: true,
    });
  };

  render() {
    let insertContent = null,
      upload_content = null;
    switch (this.state.is_upload && this.state.is_upload.insert) {
      case "images":
        insertContent = (
          <div className={classes.PreviewImages}>
            <p className="f-10 mb-2">
              Upload a Photo of the Advert You want people to post on their
              social media post accounts like Whatsapp, Facebook, Instagram,
              Twitter etc.
            </p>
            <PreviewImages
              setActive={this.setActive}
              active={this.state.active}
              upload={this.uploadHandler}
              removePhoto={this.removePhotoHandler}
              products={this.state.is_upload.previewPhotos}
              caption="Upload Photo"
            />
          </div>
        );
        break;
      case "video":
        insertContent = (
          <div className={classes.PreviewVideos}>
            <p className="f-10 mb-2">
              Upload a Video of the Advert You want people to post on their
              social media post accounts like Whatsapp, Facebook, Instagram,
              Twitter, Tiktok etc.
            </p>
            <PreviewVideos
              setVideoSize={this.setVideoSize}
              upload={this.uploadVideoHandler}
              removeVideo={this.removeVideoHandler}
              previewVideo={this.state.is_upload.previewVideo}
            />
          </div>
        );
        break;
      default:
        insertContent = null;
    }
    if (this.state.is_upload) {
      upload_content = (
        <div className={classes.InsertContainer}>
          <p className="f-10 fw-600 mb-2">
            Choose one of the Advert Media Upload Below:
          </p>
          <div className={classes.InsertHeading}>
            <div className={classes.InsertButtons}>
              <button
                onClick={() => this.setInsertContent("images")}
                className={`
              ${
                this.state.is_upload.insert === "images"
                  ? classes.ActiveButton
                  : ""
              } btn btn-rounded btn-xs btn-outline`}
              >
                <i className="fas fa-image"></i> UPLOAD PHOTO ADVERT
              </button>
              <button
                onClick={() => this.setInsertContent("video")}
                className={`
              ${
                this.state.is_upload.insert === "video"
                  ? classes.ActiveButton
                  : ""
              } btn btn-outline btn-xs btn-rounded`}
              >
                <i className="fas fa-video"></i> UPLOAD VIDEO ADVERT
              </button>
            </div>
          </div>
          <div className={classes.InsertContent}>{insertContent}</div>
        </div>
      );
    }
    return (
      <div className={classes.SocialBuyForm}>
        {this.props.transaction.video ? (
          <div className={classes.VideoTutorialContainer}>
            <p>
              <i>
                Please watch this short video tutorial on how to fill the order
                form below so you can achieve massive result without errors.
              </i>
            </p>
            <button onClick={this.showVideo} className="btn btn-xs btn-outline">
              <i className="fas fa-play-circle"></i> WATCH VIDEO
            </button>
          </div>
        ) : null}
        <div className={classes.SocialBuyHeader}>
          <SocialTransactionContent
            content_type="advertise"
            transaction={this.props.transaction}
          />
        </div>
        {this.props.transaction.disclaimer ? (
          <div className="alert alert-info rounded-0">
            <p className="f-12">
              <i>{this.props.transaction.disclaimer}</i>
            </p>
          </div>
        ) : null}
        <div className={classes.Form}>
          {this.state.error ? <AlertError error={this.state.error} /> : null}
          <Form formData={this.state.formData} />
          {upload_content}
        </div>
        <div ref={this.taskBottomRef} className={classes.TaskBottom}>
          <div className={classes.TaskBottomText}>
            <p>You will pay:</p>
            <h4>
              {this.props.user.country.currency.symbol}
              {this.state.total
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h4>
          </div>
          <div className={classes.TaskBottomAction}>
            <button
              onClick={this.submitHandler}
              disabled={this.state.loading}
              className="btn btn-coloured-heavy d-block btn-block"
            >
              {this.state.loading ? (
                <i className="fas fa-spin fa-2x fa-spinner"></i>
              ) : (
                "SUBMIT AND MAKE PAYMENTS"
              )}
            </button>
          </div>
        </div>
        {this.state.paymentCompleted ? (
          <PaymentCompletedModal
            description="Your Payment is successful. Thank you for completing your order. Your order has been created and you will begin to see results shortly."
            refresh={this.viewOrder}
            goBack="VIEW & MONITOR YOUR ORDER"
          />
        ) : null}
        {this.state.task && this.state.show_payment_modal ? (
          <PaymentModal
            pay_online={this.payWithFlutterwave}
            pay_wallet={this.payWithWallet}
            amount={this.state.task.order_total}
            close={this.viewOrder}
          />
        ) : null}
        {this.state.task && this.state.show_wallet_payment ? (
          <PaymentWalletModal
            go_success={this.viewOrder}
            success={this.state.wallet_success}
            error={this.state.wallet_error}
            cancel={this.viewOrder}
          />
        ) : null}
        {this.props.transaction.video && this.state.show_video ? (
          <VideoModal
            close={this.closeVideo}
            video={this.props.transaction.video}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    country_states: state.userState.country_states,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
    setMeta: (meta) => dispatch(actionCreators.setMeta(meta)),
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SocialBuyForm));
