import React, { useEffect } from "react";
import classes from "./NewOrderModal.module.css";
import { withRouter } from "react-router-dom";
import marketIcon from "../../../../assets/images/market-icon.png";
import socialIcon from "../../../../assets/images/social-icons.jpg";

const NewOrderModal = (props) => {

  useEffect(() => {
    window.$("#NewOrderModal").modal({
      backdrop: "static",
    });
    window.$("#NewOrderModal").modal("show");
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);

  const closeHandler = (event) => {
    event.preventDefault();
    window.$("#NewOrderModal").off("hidden.bs.modal");
    window.$("#NewOrderModal").on("hidden.bs.modal", (event) => {
      props.close();
    });
    window.$("#NewOrderModal").modal("hide");
  };

  const showSocialOrderPage = (event) => {
    event.preventDefault();
    window.$("#NewOrderModal").off("hidden.bs.modal");
    window.$("#NewOrderModal").on("hidden.bs.modal", (event) => {
      props.history.push("/social/buy/pricing");
    });
    window.$("#NewOrderModal").modal("hide");
  };

  const showShopPage = (event) => {
    event.preventDefault();
    window.$("#NewOrderModal").off("hidden.bs.modal");
    window.$("#NewOrderModal").on("hidden.bs.modal", (event) => {
      props.history.push("/shop");
    });
    window.$("#NewOrderModal").modal("hide");
  };

  return (
    <div className={classes.NewOrderModal}>
      <div
        id="NewOrderModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.NewOrderModalContent} modal-content`}>
            <div
              id="NewOrderModalBody"
              className={`${classes.NewOrderModalBody} modal-body`}
            >
              <button onClick={closeHandler} className={classes.CloseButton}>
                <span className="fas fa-times"></span>
              </button>
              <div
                className={`${classes.SellModalContainer} d-flex flex-column align-items-center`}
              >
                <div className={classes.SellTitle}>
                  <h4>Select the type of order you want to create below:</h4>
                </div>
                <div className="container-fluid">
                  <div className="row no-gutters">
                    <div className="col-6 mb-2">
                      <div className={`${classes.Task} mr-2`}>
                        <div className={classes.TaskHeading}>
                          <h6>Buy Social Media Engagements and Adverts</h6>
                        </div>
                        <div className={classes.TaskContent}>
                          <div className={classes.TaskIcon}>
                            <img alt="social-icon" src={socialIcon} />
                          </div>
                          <p className="f-10">
                            Get people to post your adverts on social media and
                            perform various social media engagement tasks for
                            you on their social media accounts.
                          </p>
                        </div>
                        <div className={classes.TaskFooter}>
                          <p>
                            <button
                              onClick={showSocialOrderPage}
                              className="btn btn-outline mb-1"
                            >
                              GET STARTED
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className={`${classes.Task}`}>
                        <div className={classes.TaskHeading}>
                          <h6>
                            Buy Products from Verified Sellers on Hawkit Market
                          </h6>
                        </div>
                        <div className={classes.TaskContent}>
                          <div className={classes.TaskIcon}>
                            <img alt="social-icon" src={marketIcon} />
                          </div>
                          <p className="f-10">
                            Buy verified and authentic products from verified
                            sellers on the Hawkit Market and get them delivered
                            to you easily.
                          </p>
                        </div>
                        <div className={classes.TaskFooter}>
                          <p>
                            <button
                              onClick={showShopPage}
                              className="btn btn-outline mb-1"
                            >
                              GET STARTED
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(NewOrderModal);
