import React from "react";
import classes from "../Post.module.css";
import { abbreviateNumber } from "../../../../util/util";
import { withRouter } from "react-router";

const repost = props => {
  const showViews = event => {
    event.preventDefault();
    props.setPostModal({
      title: "Views",
      id: props.post._id,
      link: "views"
    });
  };
  const showComments = event => {
    event.preventDefault();
    props.history.push(`/posts/${props.post._id}`);
  };
  return (
    <div className={classes.Repost}>
      <a onClick={showViews} className="mr-2" href="/">
        {abbreviateNumber(props.post.views)} Views
      </a>
      {!props.showComment ? (
        <a onClick={showComments} href="/">
          {abbreviateNumber(props.post.comments)} Comments
        </a>
      ) : null}
    </div>
  );
};

export default withRouter(repost);
