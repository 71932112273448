/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./FetchLink.module.css";
import { connect } from "react-redux";
import axios from "../../../axios/axios-instance";

let is_Mounted = false;

const FetchLink = props => {
  const [state, setState] = useState({
    loading: null,
    current: 0
  });
  const fetchHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!state.loading) {
      setState({
        loading: true
      });
      fetchContent();
    }
  };
  let content = props.children[state.current];
  if (state.loading) {
    if (props.children[2]) {
      content = props.children[2];
    } else {
      content = <i className="fal fa-spin fa-spinner"></i>;
    }
  }

  const fetchContent = () => {
    const userToken = props.userToken;
    axios({
      method: props.url[state.current].method,
      url: props.url[state.current].url,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!is_Mounted) return;
        const data = response.data.data;
        if (props.callback) props.callback(data);
        setState({
          loading: false,
          current: state.current === 0 ? 1 : 0
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    is_Mounted = true;
    return () => {
      is_Mounted = false;
    };
  });

  return (
    <a
      href="/"
      title={props.title[state.current]}
      onClick={fetchHandler}
      style={props.activeColor ? { color: props.activeColor } : null}
      className={`${classes.FetchLink} ${props.className}`}
    >
      {content}
    </a>
  );
};

const mapStateToProps = state => {
  return {
    userToken: state.authState.userToken
  };
};

export default connect(mapStateToProps)(FetchLink);
