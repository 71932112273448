import React from "react";
import classes from "./OfficeSpinner.module.css";

const OfficeSpinner = props => {
  return (
    <div className={classes.OfficeSpinner}>
      <div style={{ fontSize: `${props.size}px` }}>
        <div
          style={{ borderLeftColor: props.color }}
          className={classes.OfficeSpinnerLoader}
        />
      </div>
    </div>
  );
};

export default OfficeSpinner;
