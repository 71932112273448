import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Earn from "../../Earn/Earn";
import EarnCategory from "../../Earn/EarnCategory/EarnCategory";
import Member from "../../Member/Member";
import EarnTaskPage from "../../Earn/EarnTaskPage/EarnTaskPage";
import SocialTasks from "../../SocialTasks/SocialTasks";
import Referral from "../../Referral/Referral";

class EarnLayout extends Component {
  render() {
    let content;
    if (this.props.user) {
      content = (
        <Switch>
          <Route path="/earn/referral" component={Referral} />
          <Route path="/earn/member" component={Member} />
          <Route path="/earn/tasks" component={SocialTasks} />
          <Route path="/earn/task/:id" component={EarnTaskPage} />
          <Route path="/earn/:category" component={EarnCategory} />
          <Route path="/earn" component={Earn} />
        </Switch>
      );
    } else {
      content = (
        <Switch>
          <Route path="/earn/member" component={Member} />
          <Route path="/earn/referral" component={Referral} />
          <Route exact path="/earn" component={Earn} />
          <Redirect to="/login" />
        </Switch>
      );
    }
    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(EarnLayout);
