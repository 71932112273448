import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const intialiseUserState = () => {
  return {
    user: null,
    userLoading: true,
    userError: null,
    collapsed: true,
    currency: null,
    country_states: [],
    meta: null,
  };
};

const initialState = intialiseUserState();

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      draft.user = action.user;
      return;
    case actionTypes.SET_USER_CURRENCY:
      draft.currency = action.currency;
      return;
    case actionTypes.SET_USER_LOADING:
      draft.userLoading = action.userLoading;
      return;
    case actionTypes.SET_USER_ERROR:
      draft.userError = action.userError;
      return;
    case actionTypes.SET_COLLAPSE_STATUS:
      draft.collapsed = action.collapsed;
      return;
    case actionTypes.SET_COUNTRY_STATES:
      draft.country_states = action.country_states;
      return;
    case actionTypes.SET_META:
      draft.meta = action.meta;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
