/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import classes from "./ChatMessage.module.css";

const moment = require("moment");

const ChatMessage = (props) => {
  const message = props.message;
  const now = moment();
  const postMoment = moment(message.created_on);
  const daysDiff = now.diff(postMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = postMoment.calendar();
  } else {
    formatDate = postMoment.fromNow();
  }

  useEffect(() => {
    const messageElement = document.getElementById(message._id);
    messageElement.scrollIntoView({
      alignToTop: false,
    });
  }, []);

  const messageContent =
    "<p>" +
    message.message.replace(/\n{2,}/g, "</p><p>").replace(/\n/g, "<br>") +
    "</p>";

  let status = null;

  if (props.is_user_active) {
    if (message.status.sent) {
      status = (
        <p className="ml-1">
          <span className="fw-400 f-8 badge badge-warning">Sent</span>
        </p>
      );
    }
    if (message.status.delivered) {
      status = (
        <p className="ml-1">
          <span className="fw-400 f-8 badge badge-info">Delivered</span>
        </p>
      );
    }
    if (message.status.read) {
      status = (
        <p className="ml-1">
          <span className="fw-400 f-8 badge badge-success">Read</span>
        </p>
      );
    }
  }

  return (
    <div className="d-flex w-100 px-3 mb-1">
      <div
        key={message._id}
        id={message._id}
        className={`${classes.ChatMessage} ${
          props.is_user_active ? classes.ChatActive : ""
        }`}
      >
        <div
          dangerouslySetInnerHTML={{ __html: messageContent }}
          className={classes.MessageContent}
        ></div>
        <div className={classes.ChatSide}>
          <p className={classes.Date}>{formatDate}</p>
          {status}
        </div>
      </div>
    </div>
  );
};
export default ChatMessage;
