import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./SocialTasks.module.css";
import AllSocialTasks from "./AllSocialTasks/AllSocialTasks";
import { Switch, Route, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

class SocialTasks extends Component {
  render() {
    if (!this.props.user || !this.props.user.is_member) {
      return <Redirect to="/earn/member" />;
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <div className={classes.SocialTaskTitleHeader}>
                    <h4>All Social Tasks</h4>
                    <p>
                      Click <Link to="/earn">here</Link> to see all the types of
                      tasks we have
                    </p>
                  </div>
                </TitleHeader>
              </Title>
              <div className={classes.SocialTask}>
                <div className={classes.SocialTaskNav}>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/earn/tasks/pending"
                  >
                    Pending{" "}
                    {this.props.meta.tasks_count.pending ? (
                      <span className="badge badge-danger">
                        {this.props.meta.tasks_count.pending}
                      </span>
                    ) : null}
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/earn/tasks/uploaded"
                  >
                    Uploaded{" "}
                    {this.props.meta.tasks_count.uploaded ? (
                      <span className="badge badge-danger">
                        {this.props.meta.tasks_count.uploaded}
                      </span>
                    ) : null}
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/earn/tasks/failed"
                  >
                    Failed{" "}
                    {this.props.meta.tasks_count.failed ? (
                      <span className="badge badge-danger">
                        {this.props.meta.tasks_count.failed}
                      </span>
                    ) : null}
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/earn/tasks/completed"
                  >
                    Completed{" "}
                    {this.props.meta.tasks_count.completed ? (
                      <span className="badge badge-danger">
                        {this.props.meta.tasks_count.completed}
                      </span>
                    ) : null}
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/earn/tasks/cancelled"
                  >
                    Cancelled{" "}
                    {this.props.meta.tasks_count.cancelled ? (
                      <span className="badge badge-danger">
                        {this.props.meta.tasks_count.cancelled}
                      </span>
                    ) : null}
                  </NavLink>
                </div>
                <div className={classes.SocialTaskContent}>
                  <Switch>
                    <Route
                      path="/earn/tasks/:status"
                      component={AllSocialTasks}
                    />
                    <Redirect from="/earn/tasks" to="/earn/tasks/pending" />
                  </Switch>
                </div>
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(SocialTasks);
