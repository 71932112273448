import React from "react";
import classes from "./UploadSpinner.module.css";
import { connect } from "react-redux";
import FormSpinner from "../FormSpinner/FormSpinner";

const UploadSpinner = props => {
  let content = (
    <div
      style={props.size ? { fontSize: `${props.size}px` } : null}
      className={classes.UploadSpinner}
    >
      <i className="fad fa-spin fa-spinner-third"></i>
      <div className={classes.UploadText}>
        <p>{props.progress}%</p>
      </div>
    </div>
  );
  if (props.progress >= 100) {
    content = <FormSpinner />;
  }
  return content;
};

const mapStateToProps = state => {
  return {
    progress: state.loader.progress
  };
};

export default connect(mapStateToProps)(UploadSpinner);
