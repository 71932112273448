import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./PostView.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import Post from "../../../components/App/Post/Post";
import PostContext from "../../../context/post-context";
import PostModal from "../../../components/App/Post/PostModal/PostModal";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import DeleteModal from "../../../components/App/Post/DeleteModal/DeleteModal";
import ReviewsModal from "../../../components/App/Products/ReviewsModal/ReviewsModal";
import ProductDescriptionModal from "../../../components/App/Post/ProductDescriptionModal/ProductDescriptionModal";
import ImageModal from "../../../components/UI/Modal/ImageModal/ImageModal";
import BuyModal from "../../../components/App/Post/BuyModal/BuyModal";
import ResellModal from "../../../components/App/Products/ResellModal/ResellModal";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import MobileFooter from "../../../components/App/Layout/Footer/MobileFooter/MobileFooter";

class PostView extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      share: null,
      buyPost: null,
      reviewPost: null,
      resellPost: null,
      descriptionPost: null,
      image: null,
      activeImage: null,
    };
  }

  loadPost = () => {
    const { postId } = this.props.match.params;
    const post = this.props.posts.find((el) => el._id === postId);
    if (post) {
      this.setState({
        activeImage: post.product.photos[0].src,
      });
      return;
    }
    this.setState({
      loading: true,
      error: null,
    });
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/posts/${postId}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const post = response.data.data.post;
        this.setState({
          activeImage: post.product.photos[0].src,
        });
        const newPosts = [post];
        const filteredPosts = newPosts.filter((el) => {
          return !this.props.posts.find((post) => post._id === el._id);
        });
        const posts = [...this.props.posts, ...filteredPosts];
        this.props.setPosts(posts);
        this.setState({
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadPost();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { postId } = this.props.match.params;
    const post = this.props.posts.find((el) => el._id === postId);
    let content = null;
    if (this.state.loading) {
      content = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      content = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadPost} error={this.state.error} />
        </div>
      );
    }
    if (post) {
      content = (
        <PostContext.Provider
          value={{
            userToken: this.props.userToken,
            toggleLikes: this.props.toggleLikes,
            addPostView: this.props.addPostView,
            postModal: this.props.postModal,
            deleteModal: this.props.deleteModal,
            posts: this.props.posts,
            setPosts: this.props.setPosts,
            setPostModal: this.props.setPostModal,
            setDeleteModal: this.props.setDeleteModal,
            user: this.props.user,
          }}
        >
          <Post
            shareHandler={() => this.setState({ share: post._id })}
            buyHandler={() => this.setState({ buyPost: post })}
            reviewHandler={(event) => {
              event.preventDefault();
              this.setState({ reviewPost: post });
            }}
            resellHandler={() => {
              this.setState({ resellPost: post });
            }}
            showImage={(image) => {
              if (image) {
                this.setState({ image: image, activeImage: image }, () => {
                  document.getElementById("imageLightModal").style.display =
                    "block";
                });
              } else if (this.state.activeImage)
                this.setState({ image: this.state.activeImage }, () => {
                  document.getElementById("imageLightModal").style.display =
                    "block";
                });
            }}
            descriptionHandler={() => this.setState({ descriptionPost: post })}
            showComment
            post={post}
          />
          {this.props.postModal ? (
            <PostModal
              activeUser={this.props.user}
              destroy={() => this.props.setPostModal(null)}
              context={this.props}
            />
          ) : null}
          {this.props.deleteModal ? (
            <DeleteModal
              destroy={() => this.props.setDeleteModal(null)}
              context={this.props}
            />
          ) : null}
          {this.state.reviewPost ? (
            <ReviewsModal
              product={this.state.reviewPost.product}
              destroy={() => this.setState({ reviewPost: null })}
            />
          ) : null}
          {this.state.resellPost ? (
            <ResellModal
              product={this.state.resellPost.product}
              destroy={() => this.setState({ resellPost: null })}
            />
          ) : null}
          {this.state.buyPost ? (
            <BuyModal
              post={this.state.buyPost}
              destroy={() => this.setState({ buyPost: null })}
            />
          ) : null}
          {this.state.descriptionPost ? (
            <ProductDescriptionModal
              post={this.state.descriptionPost}
              destroy={() => this.setState({ descriptionPost: null })}
            />
          ) : null}
          <ImageModal
            close={() => this.setState(null)}
            src={this.state.image}
          />
          {post.product.is_main_product ? <div className={classes.MobileFooter}>
            <button
              onClick={() => this.setState({ buyPost: post })}
              className="btn btn-coloured-heavy btn-xl d-block btn-block py-3 f-14"
            >
              BUY THIS PRODUCT NOW
            </button>
          </div> : <div className={classes.MobileFooter}>
            <MobileFooter />
          </div>}
        </PostContext.Provider>
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <div
              id="postContent"
              className={`${classes.PostView} ${this.props.user ? classes.PaddingTop : ""
                }`}
            >
              {this.props.user ? (
                <Title>
                  <TitleHeader>
                    <BackButton />
                    <h4>{post && post.product.is_main_product ? "Buy Product" : "View Product"}</h4>
                  </TitleHeader>
                </Title>
              ) : null}
              {content}
            </div>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    posts: state.postState.posts,
    postModal: state.postState.postModal,
    deleteModal: state.postState.deleteModal,
    feedScroll: state.postState.feedScroll,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
    setPosts: (posts) => dispatch(actionCreators.setPosts(posts)),
    setFeedScroll: (feedScroll) =>
      dispatch(actionCreators.setFeedScroll(feedScroll)),
    toggleLikes: (id) => dispatch(actionCreators.toggleLikes(id)),
    addPostView: (id) => dispatch(actionCreators.addPostView(id)),
    setPostModal: (postModal) =>
      dispatch(actionCreators.setPostModal(postModal)),
    setDeleteModal: (deleteModal) =>
      dispatch(actionCreators.setDeleteModal(deleteModal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostView);
