import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Orders.module.css";
import { Switch, Route, NavLink } from "react-router-dom";
import SalesOrders from "./SalesOrders/SalesOrders";
import PurchaseOrders from "./PurchaseOrders/PurchaseOrders";
import { connect } from "react-redux";
import SocialViewOrders from "./SocialViewOrders/SocialViewOrders";
import TitleLinks from "../../../components/App/Layout/Title/TitleLinks";
import NewOrderModal from "./NewOrderModal/NewOrderModal";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_order: false,
    };
  }

  componentDidMount() {
    if (!this.props.userToken) {
      this.props.history.replace("/auth/login");
      return;
    }
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }

  render() {
    let purchase_action =
      this.props.user &&
      this.props.user.order &&
      this.props.user.order.purchase_action
        ? this.props.user.order.purchase_action
        : 0;

    if (this.props.meta && this.props.meta.pending_purchase_orders) {
      purchase_action += this.props.meta.pending_purchase_orders;
    }

    const social_action =
      this.props.meta && this.props.meta.pending_orders
        ? this.props.meta.pending_orders
        : 0;

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>My Orders</h4>
                </TitleHeader>
                <TitleLinks>
                  <button onClick={() => this.setState({ new_order: true })}>
                    <i className="fas fa-plus-circle"></i> NEW ORDER
                  </button>
                </TitleLinks>
              </Title>
              <div className={classes.Orders}>
                <div className={classes.OrdersNav}>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/orders"
                  >
                    Social Orders
                    {social_action ? (
                      <span className="badge badge-danger ml-2">
                        {social_action}
                      </span>
                    ) : null}
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/orders/purchases"
                  >
                    Purchase Orders
                    {purchase_action ? (
                      <span className="badge badge-danger ml-2">
                        {purchase_action}
                      </span>
                    ) : null}
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/orders/sales"
                  >
                    Sales Orders
                    {this.props.user.order.sales_action ? (
                      <span className="badge badge-danger ml-2">
                        {this.props.user.order.sales_action}
                      </span>
                    ) : null}
                  </NavLink>
                </div>
                <div className={classes.OrdersContent}>
                  {this.state.new_order ? (
                    <NewOrderModal
                      close={() => this.setState({ new_order: false })}
                    />
                  ) : null}
                  <Switch>
                    <Route path="/orders/sales" component={SalesOrders} />
                    <Route
                      path="/orders/purchases"
                      component={PurchaseOrders}
                    />
                    <Route path="/orders" component={SocialViewOrders} />
                  </Switch>
                </div>
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(Orders);
