/* eslint-disable no-undef */
import React, { Component } from "react";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import classes from "./Shop.module.css";
import axios from "axios";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import ProductPosts from "../../../components/App/Post/ProductPosts/ProductPosts";
import { getBaseUrl, getClientId } from "../../../util/util";

let cancelToken;

class Shop extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      posts: [],
      total: 0,
    };
  }

  loadShopHandler = (isRefresh = true) => {
    if (cancelToken) {
      cancelToken.cancel("Refreshing Search...");
    }
    cancelToken = axios.CancelToken.source();
    if (isRefresh) {
      this.setState({
        posts: [],
      });
    }
    let currentCategory = "";
    if (this.props.shop.category.id) {
      currentCategory = this.props.shop.category.id;
    } else {
      currentCategory = "";
    }
    let location = "";
    if (!this.props.is_airtime) {
      location = this.props.searchLocation;
    }
    const userToken = this.props.userToken;
    const url = `/${
      this.props.is_airtime ? "shop/airtime" : "shop"
    }/posts/${currentCategory}?refresh=${isRefresh}&query=${
      this.props.search
    }&sortBy=${this.props.sortBy}&location=${location}`;
    axios({
      baseURL: getBaseUrl(false),
      method: "get",
      url: url,
      headers: {
        "X-Client-Hash": "1a26257c5fb5e4c7edc048035704ca0a",
        "X-Client-Id": getClientId(),
        "X-Client-Version": window.VERSION,
        Authorization: userToken ? userToken.token : null,
      },
      cancelToken: cancelToken.token,
    })
      .then((response) => {
        if (!this._isMounted) return;
        const posts = response.data.data.posts;
        const total = response.data.data.total;
        const filteredPosts = posts.filter((el) => {
          return !this.state.posts.find((post) => post._id === el._id);
        });
        const updatedPosts = [...this.state.posts, ...filteredPosts];
        if (window.fbq) {
          window.fbq("track", "Search", {
            currency: "NGN",
            content_ids: posts.map((post) => post.product._id),
            content_category: currentCategory,
            content_name: this.props.search,
            content_type: "product",
            contents: posts.map((post) => {
              return {
                id: post.product._id,
                price: post.product.price,
                post: post._id,
                category: post.product.category._id,
              };
            }),
            search_string: this.props.search,
          });
        }
        this.setState({
          loading: false,
          posts: updatedPosts,
          total: total,
        });
      })
      .catch((error) => {
        if (!this._isMounted || axios.isCancel(error)) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadShopHandler();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.search !== prevProps.search ||
      this.props.searchLocation !== prevProps.searchLocation ||
      this.props.sortBy !== prevProps.sortBy
    ) {
      this.loadShopHandler();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadShopHandler();
  };

  loadMoreProducts = (event) => {
    event.preventDefault();
    this.loadShopHandler(false);
  };

  render() {
    let loadingContent = null;
    if (this.state.total > this.state.posts.length) {
      loadingContent = (
        <div className={classes.Loading}>
          <a
            className="btn btn-outline"
            onClick={this.loadMoreProducts}
            href="/"
          >
            Load More Products <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
          <p>Loading Products...</p>
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    const posts = this.state.posts;
    const postContent = (
      <ProductPosts hideUser col="col-6 col-sm-4 col-md-6 col-lg-3" posts={posts} />
    );
    let content = null;
    if (!this.state.loading && !this.state.error && !posts.length) {
      content = (
        <div className={classes.EmptySearch}>
          <i className="fal fa-cart-plus"></i>
          <p>No post can be found under your search query.</p>
        </div>
      );
    } else {
      content = (
        <div className={classes.ShopContent}>
          {postContent}
          {loadingContent}
        </div>
      );
    }
    return <div className={classes.Shop}>{content}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    search: state.searchState.search,
    sortBy: state.searchState.sortBy,
    searchLocation: state.searchState.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSortBy: (sortBy) => dispatch(actionCreators.setSortBy(sortBy)),
    setSearch: (search) => dispatch(actionCreators.setSearch(search)),
    setSearchLocation: (location) =>
      dispatch(actionCreators.setSearchLocation(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
