/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./SellLocation.module.css";
import Form from "../../../../components/UI/Forms/Form";
import {
  createButtonElement,
  createInputElement,
  createSelectElement,
} from "../../../../components/UI/Forms/utility/form-creators";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";
import axios from "../../../../axios/axios-instance";
import BrowserLinks from "../../../../components/UI/Links/BrowserLinks/BrowserLinks";

class SellLocation extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      loading: false,
      error: false,
    };
  }

  initialiseFormData = () => {
    const state = this.props.country_states.find((el) => {
      return el.name === this.props.user.location.state;
    });
    let lgas;
    if (state) {
      lgas = state.locals.map((el) => {
        return {
          value: el.name,
          option: el.name,
        };
      });
    }
    const formData = {
      state: createSelectElement(
        "",
        this.validateState,
        "fas fa-list",
        "col-sm-12",
        this.props.country_states.map((el) => {
          return {
            value: el.name,
            option: el.name,
          };
        }),
        "Select State",
        this.inputChangedHandler.bind(this, "state"),
        this.props.user.location.state
      ),
      lga: createSelectElement(
        "",
        this.validateLGA,
        "fas fa-list",
        "col-sm-12",
        lgas,
        "Select LGA",
        this.inputChangedHandler.bind(this, "lga"),
        this.props.user.location.lga,
        "You will have to select your state before selecting the LGA."
      ),
      location: createInputElement(
        "text",
        "",
        "Enter Full Address",
        this.props.user.location.address || "",
        this.validateLocation,
        "Please enter your full address such as Building No., Street, Area, City, State.",
        "fa fa-map-marker-alt",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "location"),
        "locationField"
      ),
      submit: createButtonElement("submit", "SET LOCATION", true),
    };
    this.setState({
      formData: formData,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.initialiseFormData();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country_states !== this.props.country_states) {
      this.initialiseFormData();
    }
  }

  validateLGA = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your LGA from the list above. Make sure you select the state first before selecting the LGA.";
    }
    return null;
  };

  validateState = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your state from the list above.";
    }
    return null;
  };

  validateLocation = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter your full address.";
    }
    if (!validators.validateLength(value.trim(), 3, 500)) {
      return "Please enter your full location such as Building No., Street, Area, City, State.";
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    if (name === "state") {
      const state = this.props.country_states.find((el) => {
        return el.name === formElementData.value;
      });
      const lgas = state.locals.map((el) => {
        return {
          value: el.name,
          option: el.name,
        };
      });
      updatedFormData.lga = {
        ...updatedFormData.lga,
        value: "",
        options: lgas,
      };
    }
    this.setState({
      formData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  submitHandler = async (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const state = this.state.formData.state.value;
    const lga = this.state.formData.lga.value;
    const location = this.state.formData.location.value;
    const userToken = this.props.userToken;
    axios({
      method: "put",
      url: "/users/me/location/new",
      data: {
        state: state,
        lga: lga,
        location: location,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.setState({
          loading: false,
        });
        this.props.processUser(user);
        this.props.next();
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  render() {
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Sell Your Products</h4>
                </TitleHeader>
              </Title>
              <div className={classes.SellLocation}>
                <div className={classes.SellLocationTitle}>
                  <h4>Step 1: Set Current Location</h4>
                </div>
                <div className={classes.SellLocationContent}>
                  <p className="mb-3">
                    Please set your current location below. Your buyers may want
                    to meet you at your physical address so they can buy from
                    you.
                  </p>
                  <div className={classes.SellLocationForm}>
                    {this.state.error ? (
                      <AlertError error={this.state.error} />
                    ) : null}
                    <Form
                      loading={this.state.loading}
                      formData={this.state.formData}
                      submit={this.submitHandler}
                    />
                  </div>
                </div>
                <div className={classes.HawkFooter}>
                  <div className="alert alert-info m-0">
                    <h4 className="alert-heading">Tips</h4>
                    <p>
                      Great Products with Great Prices Lead to Massive Sales and
                      Conversions. Set affordable and competitive prices for
                      your products. Also make sure you upload clear and high
                      quality images of your products.
                    </p>
                    <hr />
                  </div>
                </div>
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    country_states: state.userState.country_states,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SellLocation);
