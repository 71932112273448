import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SocialLayout from "../../../App/Layout/SocialLayout/SocialLayout";
import PreviewHome from "../../../App/PreviewHome/PreviewHome";
import Pricing from "../../../App/Pricing/Pricing";
import Earning from "../../../App/Earning/Earning";
import PreviewShopLayout from "../../PreviewShopLayout/PreviewShopLayout";
import PreviewMobileHome from "../../../App/PreviewMobileHome/PreviewMobileHome";
import PreviewSupport from "../../../App/PreviewSupport/PreviewSupport";

class PreviewRouteLayout extends Component {
  render() {
    let content = (
      <Switch>
        <Redirect from="/shop/sell" to="/auth/login" />
        <Route path="/shop/:category" component={PreviewShopLayout} />
        <Route path="/shop" component={PreviewShopLayout} />
        <Route path="/social" component={SocialLayout} />
        <Route path="/support" component={PreviewSupport} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/earn" exact component={Earning} />
        <Route path="/auth" component={PreviewHome} />
        <Route path="/" exact component={PreviewHome} />
        <Redirect to="/auth/login" />
      </Switch>
    );
    if (window.cordova) {
      content = (
        <Switch>
          <Redirect from="/shop/sell" to="/auth/login" />
          <Route path="/shop/:category" component={PreviewShopLayout} />
          <Route path="/shop" component={PreviewShopLayout} />
          <Route path="/social" component={SocialLayout} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/earn" exact component={Earning} />
          <Route path="/auth" component={PreviewMobileHome} />
          <Route path="/" exact component={PreviewMobileHome} />
          <Redirect to="/auth/login" />
        </Switch>
      );
    }
    return content;
  }
}

export default PreviewRouteLayout;
