import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const intialisePostState = () => {
  return {
    products: [],
    product: null,
    top_grocery_products: null,
    top_airtime_products: null,
    hawkingLocation: null,
    posts: [],
    loadCount: 0,
    feedScroll: 0,
    postModal: null,
    deleteModal: null,
  };
};

const initialState = intialisePostState();

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.ADD_PRODUCTS:
      draft.products.push(action.product);
      return;
    case actionTypes.SET_PRODUCTS:
      draft.products = action.products;
      return;
    case actionTypes.SET_PRODUCT:
      draft.product = action.product;
      return;
    case actionTypes.SET_TOP_GROCERY_PRODUCTS:
      draft.top_grocery_products = action.top_grocery_products;
      return;
    case actionTypes.SET_TOP_AIRTIME_PRODUCTS:
      draft.top_airtime_products = action.top_airtime_products;
      return;
    case actionTypes.SET_HAWKING_LOCATION:
      draft.hawkingLocation = action.hawkingLocation;
      return;
    case actionTypes.SET_POSTS:
      draft.posts = action.posts;
      return;
    case actionTypes.SET_FEED_SCROLL:
      draft.feedScroll = action.feedScroll;
      return;
    case actionTypes.SET_POST_MODAL:
      draft.postModal = action.postModal;
      return;
    case actionTypes.SET_DELETE_MODAL:
      draft.deleteModal = action.deleteModal;
      return;
    case actionTypes.SET_LOAD_COUNT:
      draft.loadCount = action.loadCount;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
