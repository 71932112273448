import React from "react";
import classes from "./UserPhoto.module.css";
import profileIcon from "../../../../assets/images/no_user.png";

const UserPhoto = (props) => {
  return (
    <div
      style={{ width: `${props.width}px`, height: `${props.height}px` }}
      className={`${classes.UserPhoto} ${
        props.nomargin ? `${classes.NoMargin}` : null
      } ${props.no_rounded ? `${classes.NoRounded}` : null}`}
    >
      <img
        style={
          props.width
            ? {
                width: `${props.width}px`,
                height: `${props.height}px`,
              }
            : null
        }
        src={props.photo ? props.photo : profileIcon}
        alt={props.alt}
      />
    </div>
  );
};

export default UserPhoto;
