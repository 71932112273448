/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./FundingForm.module.css";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  createInputElement,
  createButtonElement,
} from "../../../../components/UI/Forms/utility/form-creators";
import Form from "../../../../components/UI/Forms/Form";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import * as actionCreators from "../../../../store/actions/actions";

class FundingForm extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      submittingError: null,
      loading: false,
      error: null,
      completed: null,
      formData: {
        amount: createInputElement(
          "number",
          null,
          `Amount`,
          "",
          this.validateAmount,
          null,
          this.props.user.country.currency.symbol,
          "col-6",
          this.inputChangedHandler.bind(this, "amount")
        ),
        submit: createButtonElement("submit", "FUND WALLET", true, "col-6"),
      },
    };
  }

  validateAmount = (value) => {
    if (validators.isEmpty(value)) {
      return "Please enter your amount!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Amount. Only digits are allowed";
    }
    if (+value < this.props.meta.minimum_funding) {
      return `Minimum Funding Amount is ${this.props.user.country.currency.symbol}${this.props.meta.minimum_funding}.`;
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    // formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
    };
    updatedFormData[name] = formElementData;
    this.setState({ formData: updatedFormData });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  loadUser = () => {
    const userToken = this.props.userToken;
    const url = "/users/me";
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.props.processUser(user);
        this.setState({
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  fundWallet = () => {
    const amount = this.state.formData.amount.value;
    const userToken = this.props.userToken;
    const url = "/users/funding";
    axios({
      method: "post",
      url: url,
      data: {
        amount: amount,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const fundingId = response.data.data.fundingId;
        this.setState({
          submitting: false,
        });
        this.payWithFlutterwave(amount, fundingId);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            submitting: false,
            submittingError: errorMsg,
          });
        }
      });
    this.setState({
      submitting: true,
      submittingError: null,
    });
  };

  payWithFlutterwave = (amount, fundingId) => {
    let total = amount;
    window.FlutterwaveCheckout({
      public_key: this.props.meta.flutterwave_public_key,
      tx_ref: `Funding-${fundingId}-${Date.now()}`,
      amount: total,
      currency: this.props.user.country.currency.short,
      country: this.props.user.country.code,
      customer: {
        email: this.props.user.email,
        phone_number: this.props.user.phoneNumber,
        name: this.props.user.name,
      },
      callback: (data) => {
        if (data.status === "successful" || data.status === "completed") {
          this.setState({
            completed: true,
          });
        }
      },
      onclose: () => {
        if (this.state.completed) {
          this.loadUser();
          if (this.props.success) this.props.success();
        } else {
          this.setState({
            submitting: false,
            submittingError:
              "Payment either failed or was cancelled. If you selected Bank Transfer or USSD, ensure you transfer the total amount shown to you including the charges. If you were debited, your money has been reversed back into your account. You can contact your bank for the reversal if you haven't seen it. Thank you.",
          });
          if (this.props.failed) this.props.failed();
        }
      },
    });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    this.fundWallet();
  };

  newFunding = () => {
    this.setState((state) => {
      const formData = { ...state.formData };
      formData.amount.value = "";
      return {
        completed: null,
        submitting: false,
        submittingError: null,
        loading: false,
        error: null,
        formData: formData,
      };
    });
  };

  render() {
    let content = (
      <div className={classes.FundingForm}>
        {this.state.submittingError ? (
          <div className="alert alert-danger py-2 f-12">
            {this.state.submittingError}
          </div>
        ) : null}
        {this.state.completed ? (
          <div className="alert alert-success">{this.state.completed}</div>
        ) : null}
        <div className={classes.FundingFormHeader}>
          <p>Please enter the amount you want to fund your wallet with.</p>
        </div>
        <Form
          loading={this.state.submitting}
          formData={this.state.formData}
          submit={this.submitHandler}
        />
        <div className={classes.FundingFormFooter}>
          <p className="mb-1">
            You can choose your preferred method of payment such as Card
            Payment, Bank Transfer, USSD etc. Simply click on "Change Payment"
            button on the Payment Checkout page.
          </p>
          
        </div>
      </div>
    );

    if (this.state.completed) {
      content = (
        <div className={classes.PaymentCompleted}>
          <div className={classes.PaymentCompletedIcon}>
            <i className="fas fa-check-circle"></i>
          </div>
          <div className={classes.PaymentCompletedContent}>
            <h5>Payment Completed Succcessfully</h5>
            <p className="mb-1">
              Your payment has been completed successfully and your wallet has
              been updated with your funds. Click the button below if you want
              to fund your wallet again.
            </p>
            <p>
              <button className="btn btn-outline" onClick={this.newFunding}>
                Create New Funding
              </button>
            </p>
          </div>
        </div>
      );
      if (this.state.loading) {
        content = (
          <div className={classes.PaymentCompleted}>
            <div className={classes.PaymentCompletedIcon}>
              <i className="fas fa-check-circle"></i>
            </div>
            <div className={classes.PaymentCompletedContent}>
              <h5>Payment Completed Succcessfully</h5>
              <p>
                Your payment has been completed successfully. Please wait while
                we update your wallet.
              </p>
              <div className={classes.PaymentCompletedLoading}>
                <OfficeSpinner size="3" />
              </div>
            </div>
          </div>
        );
      } else if (this.state.error) {
        content = (
          <div className={classes.PaymentCompleted}>
            <div className={classes.PaymentCompletedIcon}>
              <i className="fas text-danger fa-times-circle"></i>
            </div>
            <div className={classes.PaymentCompletedContent}>
              <h5>An Error Occured!</h5>
              <p className="mb-1">{this.state.error}</p>
              <button className="f-10 btn btn-outline" onClick={this.loadUser}>
                Try Again
              </button>
            </div>
          </div>
        );
      }
    }

    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FundingForm));
