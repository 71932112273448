import React from "react";
import classes from "./DropdownLink.module.css";

const DropdownLink = props => {
  return (
    <a
      style={props.color ? { color: `${props.color}` } : null}
      href="/"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      data-display="static"
      id="optionDropdownLink"
      className={`${props.classes} ${classes.DropdownLink} dropdown-toggle`}
    >
      {props.children}
    </a>
  );
};

export default DropdownLink;
