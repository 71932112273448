import React, { Component } from "react";
import classes from "./Wrapper.module.css";
import { Switch, Route, withRouter } from "react-router-dom";
import Loader from "../../hoc/Loader/Loader";
import { connect } from "react-redux";
import Layout from "../App/Layout/Layout";
import AppUpdateModal from "../../components/App/AppUpdateModal/AppUpdateModal";
import PreviewLayout from "../Preview/PreviewLayout/PreviewLayout";
import AppReviewModal from "../../components/App/AppReviewModal/AppReviewModal";
import socketio from "../../util/socket";
import MessageNotificationModal from "../../components/App/MessageNotificationModal/MessageNotificationModal";
import {
  getLastAppDownload,
  playMessageSound,
  setLastAppDownload,
} from "../../util/util";
import { CSSTransition } from "react-transition-group";
import AppDownloadModal from "../../components/App/AppDownloadModal/AppDownloadModal";

class Wrapper extends Component {
  timeout = null;

  constructor(props) {
    super(props);
    this.state = {
      chat: null,
    };
  }

  componentDidMount() {    
    window.$crisp.push([
      "on",
      "session:loaded",
      () => {
        window.$(".crisp-client").css("display", "none");
      },
    ]);
    window.$crisp.push([
      "on",
      "chat:closed",
      () => {
        window.$(".crisp-client").css("display", "none");
      },
    ]);
    let socket = socketio.getSocket();
    if (!socket) {
      socket = socketio.init("https://api.efluence.ng");
      socket.on("connect", () => {
        console.log("Socket Connected");
        if (this.props.user) socketio.connectUser(this.props.user);
      });
      socket.on("message received", (message) => {
        const location = this.props.location.pathname;
        const username = message.sender.username;
        if (!location.startsWith(`/chats/${username}`)) {
          playMessageSound("received");
          clearTimeout(this.timeout);
          this.setState(
            {
              chat: message,
            },
            () => {
              this.timeout = setTimeout(this.closeHandler, 3000);
            }
          );
          socket.emit("message delivered", message._id);
        }
      });
    }
  }

  closeHandler = () => {
    this.setState({
      chat: null,
    });
  };

  render() {
    let routes = (
      <Switch>
        <Route path="/" component={PreviewLayout} />
      </Switch>
    );
    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/" component={Layout} />
        </Switch>
      );
    }
    return (
      <div className={classes.Wrapper}>
        <Loader>{routes}</Loader>
        {window.cordova ? (
          <>
            <AppUpdateModal />
            <AppReviewModal />
          </>
        ) : null}
        {!window.cordova &&
        this.props.user &&
        this.props.user.is_member &&
        this.props.user.phoneNumber &&
        getLastAppDownload() <= Date.now() ? (
          <AppDownloadModal close={() => setLastAppDownload()} />
        ) : null}
        <CSSTransition
          in={this.state.chat === null ? false : true}
          timeout={300}
          unmountOnExit
          classNames="transition"
        >
          {this.state.chat ? (
            <MessageNotificationModal
              close={this.closeHandler}
              chat={this.state.chat}
            />
          ) : (
            <div></div>
          )}
        </CSSTransition>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authState.userToken !== null,
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(withRouter(Wrapper));
