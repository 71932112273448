/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import classes from "./ScrollButtons.module.css";

const ScrollButtons = (props) => {
  useEffect(() => {
    const productPost = document.getElementById(props.scrollContainer);
    productPost.addEventListener("scroll", function (event) {
      window.$(`#${props.parent} .next`).css("display", "flex");
      window.$(`#${props.parent} .prev`).css("display", "flex");
      if (event.target.scrollLeft === 0) {
        window.$(`#${props.parent} .prev`).css("display", "none");
      } else if (
        event.target.scrollLeft + event.target.offsetWidth >=
        event.target.scrollWidth
      ) {
        window.$(`#${props.parent} .next`).css("display", "none");
      }
    });
  }, []);

  const nextHandler = (event) => {
    event.stopPropagation();
    const productPost = document.getElementById(props.scrollContainer);
    productPost.scrollBy(100, 0);
  };

  const prevHandler = (event) => {
    event.stopPropagation();
    const productPost = document.getElementById(props.scrollContainer);
    productPost.scrollBy(-100, 0);
  };

  return (
    <div className={classes.ScrollButtons}>
      <div        
        onClick={prevHandler}
        className={`prev ${classes.ScrollButton} ${classes.Prev}`}
      >
        <i className="fas fa-chevron-left"></i>
      </div>
      <div        
        onClick={nextHandler}
        className={`next ${classes.ScrollButton} ${classes.Next}`}
      >
        <i className="fas fa-chevron-right"></i>
      </div>
    </div>
  );
};

export default ScrollButtons;
