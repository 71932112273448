/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./PostModal.module.css";
import UserSummary from "../../User/UserSummary/UserSummary";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";
import Error from "../../../UI/Error/Error";

let is_Mounted = false;

const postModal = (props) => {
  const [state, setState] = useState({
    results: [],
    loading: true,
    error: null,
  });
  const [pages, setPages] = useState(null);

  const loadMoreResults = (event) => {
    event.preventDefault();
    fetchContent(false);
  };

  let loadingContent = null;
  if (state.loading) {
    loadingContent = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.error) {
    loadingContent = (
      <div className={classes.Loading}>
        <Error refresh={loadMoreResults} error={state.error} />
      </div>
    );
  } else if (pages && pages.next) {
    loadingContent = (
      <div className={classes.Loading}>
        <a onClick={loadMoreResults} href="/">
          View More <span className="fa fa-chevron-down"></span>
        </a>
      </div>
    );
  }
  let content = null;
  if (state.results.length) {
    content = state.results.map((el) => (
      <UserSummary
        showFollow={
          props.activeUser && el.user.username !== props.activeUser.username
        }
        key={el.user.username}
        data={el}
      />
    ));
  } else if (!state.loading && !state.error) {
    content = (
      <p className="p-3">
        No {props.context.postModal.title.toLowerCase()} yet on this post
      </p>
    );
  }

  const fetchContent = (isRefresh = true) => {
    let page = 1;
    if (pages && pages.next && !isRefresh) {
      page = pages.next;
    }
    if (isRefresh) {
      setPages(null);
      setState({
        ...state,
        results: [],
        loading: true,
        error: null,
      });
    } else {
      setState({
        ...state,
        loading: true,
        error: null,
      });
    }
    const userToken = props.context.userToken;
    axios({
      method: "get",
      url: `/posts/${props.context.postModal.id}/${props.context.postModal.link}?page=${page}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!is_Mounted) return;
        const results = response.data.data.results;
        const pages = response.data.data.pages;
        setPages(pages);
        let newResults;
        if (!isRefresh) {
          const filteredResults = results.filter((el) => {
            return !state.results.find((res) => res._id === el._id);
          });
          newResults = [...state.results, ...filteredResults];
        } else {
          newResults = results;
        }
        setState({
          results: newResults,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg,
          });
        }
      });
  };

  useEffect(() => {
    is_Mounted = true;
    window.$("#postModal").off("shown.bs.modal");
    window.$("#postModal").off("hidden.bs.modal");
    window.$("#postModal").modal("show");
    window.$("#postModal").on("shown.bs.modal", (event) => {
      fetchContent();
    });
    window.$("#postModal").on("hidden.bs.modal", (event) => {
      props.destroy();
    });
    return () => {
      is_Mounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#postModal").modal("hide");
    };
  }, []);
  return (
    <div className={classes.PostModal}>
      <div
        id="postModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.PostModalContent} modal-content`}>
            <div className={`${classes.PostModalHeader} modal-header`}>
              <h5>{props.context.postModal.title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="postModalBody"
              className={`${classes.PostModalBody} modal-body`}
            >
              {content}
              {loadingContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default postModal;
