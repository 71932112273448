/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react";
import classes from "./ResellModal.module.css";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BrowserLinks from "../../../UI/Links/BrowserLinks/BrowserLinks";

let is_Mounted = false;

const ResellModal = (props) => {
  const referralInputRef = useRef(null);
  const descriptionTextRef = useRef(null);

  const [state, setState] = useState({
    loading: false,
    error: null,
    completed: null,
  });

  const copyReferralLink = (event) => {
    var referralInputText = referralInputRef.current;
    referralInputText.select();
    document.execCommand("Copy");
    window.$(event.target).html("Copied");
  };

  const copyDescriptionText = (event) => {
    var descriptionText = descriptionTextRef.current;
    descriptionText.select();
    document.execCommand("Copy");
    window.$(event.target).html("Copied");
  };

  const cancelHandler = (event) => {
    event.preventDefault();
    window.$("#ResellModal").off("hidden.bs.modal");
    window.$("#ResellModal").on("hidden.bs.modal", (event) => {
      props.destroy();
    });
    window.$("#ResellModal").modal("hide");
  };

  const resellHandler = () => {
    setState({
      ...state,
      error: null,
      loading: true,
    });
    const userToken = props.userToken;
    if (!userToken) {
      props.history.push("/auth/login");
      return;
    }
    if (props.user && !props.user.is_member) {
      props.history.push("/earn/member");
      return;
    }
    axios({
      method: "post",
      url: `/products/${props.product._id}/resell`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!is_Mounted) return;
        const resell = response.data.data.resell;
        setState({
          ...state,
          loading: false,
          completed: resell,
        });
      })
      .catch((error) => {
        if (!is_Mounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg,
          });
        }
      });
  };

  const commission =
    props.product.commission && props.product.commission.reseller
      ? props.product.commission.reseller
      : 0;

  let content = null;

  if (state.loading) {
    content = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.completed) {
    content = (
      <div className={classes.ResellDetails}>
        <p className="mb-2">
          Your Unique Reseller Link has been generated successfully. You have to
          share your unique reseller link along with the product images and
          descriptions to your social network such as Whatsapp, Facebook,
          Instagram etc.
        </p>
        <p className="fw-600">Unique Reseller Link</p>
        <div className="input-group my-1">
          <input
            type="text"
            ref={referralInputRef}
            className="form-control f-12"
            value={state.completed.link}
            readOnly
          />
          <div className="input-group-append">
            <button
              onClick={copyReferralLink}
              className="btn btn-warning btn-xs"
              type="submit"
            >
              COPY
            </button>
          </div>
        </div>
        <div className={classes.ResellProductImages}>
          <p className="fw-600 mb-1">Download Product Images</p>
          <p className="font-italic f-8 mb-2">
            Click each of the button below to download the product images to
            your device and then upload them to your various social media
            platforms along with the product description and your unique
            reseller link
          </p>
          {props.product.photos.map((el, index) => {
            return (
              <BrowserLinks
                className="f-8 btn btn-outline btn-xs btn-rounded"
                key={index}
                to={el.src}
              >
                Product Image {index + 1}
              </BrowserLinks>
            );
          })}
        </div>
        <div className={`${classes.ResellProductDescription}`}>
          <p className="fw-600 mb-2">
            Product Description{" "}
            <button onClick={copyDescriptionText} className="ml-2 f-8">
              <i className="fal fa-copy"></i> Copy Text
            </button>
          </p>
          <textarea
            ref={descriptionTextRef}
            value={state.completed.description}
            className="form-control mb-1"
            readOnly
          ></textarea>
        </div>
        <p className="f-10 mb-2">
          Share your unique reseller link along with the product images and
          descriptions to your social network such as Whatsapp, Facebook,
          Instagram, Twitter etc. You will get a reseller's commission of{" "}
          <b>{`${props.product.country.currency.symbol}${commission
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</b>{" "}
          everytime someone buys this product using your unique reseller link.
        </p>
        <div className={classes.ShareButtons}>
          <a
            target="_new"
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              state.completed.description
            )}`}
            className={`${classes.ShareContent} ${classes.Whatsapp}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-whatsapp"></i>
            </div>
            <div>
              <h5>Share and Resell on Whatsapp</h5>
            </div>
          </a>
          <BrowserLinks
            to={`https://www.facebook.com/`}
            className={`${classes.ShareContent} ${classes.Facebook}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-facebook"></i>
            </div>
            <div>
              <h5>Share and Resell on Facebook</h5>
            </div>
          </BrowserLinks>
          <BrowserLinks
            to={`https://twitter.com/share?text=https://hawkit.ng/download`}
            className={`${classes.ShareContent} ${classes.Twitter}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-instagram"></i>
            </div>
            <div>
              <h5>Share and Resell on Instagram</h5>
            </div>
          </BrowserLinks>
          <BrowserLinks
            to={`https://twitter.com/share?text=https://hawkit.ng/download`}
            className={`${classes.ShareContent} ${classes.Twitter}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-twitter"></i>
            </div>
            <div>
              <h5>Share and Resell on Twitter</h5>
            </div>
          </BrowserLinks>
          <BrowserLinks
            to={`https://www.linkedin.com/sharing/share-offsite/?url=${state.completed.link}`}
            className={`${classes.ShareContent} ${classes.LinkedIn}`}
          >
            <div className={classes.ShareIcon}>
              <i className="fab fa-linkedin"></i>
            </div>
            <div>
              <h5>Share and Resell on LinkedIn</h5>
            </div>
          </BrowserLinks>
        </div>
        <div className="mb-2">
          <button
            onClick={cancelHandler}
            className="ml-1 btn btn-block btn-danger"
          >
            <i className="fal fa-chevron-left"></i> BACK TO PRODUCTS
          </button>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="py-2">
        {state.error ? (
          <div className="alert alert-danger">{state.error}</div>
        ) : null}
        <button
          onClick={resellHandler}
          className="mr-1 btn btn-success btn-rounded"
        >
          GENERATE RESELLER LINK
        </button>
        <button onClick={cancelHandler} className="btn btn-danger btn-rounded">
          CANCEL
        </button>
      </div>
    );
  }

  useEffect(() => {
    is_Mounted = true;
    window.$("#ResellModal").modal({
      backdrop: "static",
    });
    window.$("#ResellModal").off("hidden.bs.modal");
    window.$("#ResellModal").modal("show");
    return () => {
      is_Mounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#ResellModal").modal("hide");
      props.destroy();
    };
  }, []);
  return (
    <div className={classes.ResellModal}>
      <div
        id="ResellModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.ResellModalContent} modal-content`}>
            <div
              id="ResellModalBody"
              className={`${classes.ResellModalBody} modal-body`}
            >
              <div className={`${classes.ResellModalHeader}`}>
                <h6>Resell this product</h6>
                <h5>{props.product.title}</h5>
                <p className="f-10 mt-2">
                  Reseller Commission:{" "}
                  <b>
                    {`${props.product.country.currency.symbol}${commission
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}{" "}
                    per sale
                  </b>
                </p>
              </div>
              {!state.completed ? (
                <>
                  <p className="f-12">
                    To resell this product, you have to click the button below
                    to generate your <i>Unique Reseller Link</i> for this
                    particular product. You will get a reseller's commission of{" "}
                    <b>{`${props.product.country.currency.symbol}${commission
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</b>{" "}
                    everytime someone buys this product using your unique
                    reseller link.
                  </p>
                  <p className="f-12 my-3 text-center">
                    <b>
                      <i>The more you sell, the more you earn!</i>
                    </b>
                  </p>
                </>
              ) : null}
              {content}
              <p className="f-8 text-danger">
                Please note that the unique reseller link you generate is only
                valid for this product. If you want to resell another product,
                you have to generate another reseller link for that product
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(ResellModal));
