import * as actionTypes from "./actionTypes";
import * as actionCreators from "./actions";
// import axios from "../../axios/axios-instance";
import socketio from "../../util/socket";

export const updateClientToken = (clientToken) => {
  return {
    type: actionTypes.UPDATE_CLIENT_TOKEN,
    clientToken: clientToken,
  };
};

export const initialiseAuthLoading = () => {
  return (dispatch) => {
    dispatch(setAuthError(null));
    dispatch(setAuthLoading(true));
  };
};

export const setAuthLoading = (authLoading) => {
  return {
    type: actionTypes.SET_AUTH_LOADING,
    authLoading: authLoading,
  };
};

export const setAuthCountry = (country) => {
  return {
    type: actionTypes.SET_AUTH_COUNTRY,
    country: country,
  };
};

export const setAuthError = (authError) => {
  return {
    type: actionTypes.SET_AUTH_ERROR,
    authError: authError,
  };
};

export const setVerifyPhone = (verifyPhone) => {
  return {
    type: actionTypes.SET_VERIFY_PHONE,
    verifyPhone: verifyPhone,
  };
};

export const setCompleteSignUp = (completeSignUp) => {
  return {
    type: actionTypes.SET_COMPLETE_SIGN_UP,
    completeSignUp: completeSignUp,
  };
};

export const setForgotPassword = (forgotPassword) => {
  return {
    type: actionTypes.SET_FORGOT_PASSWORD,
    forgotPassword: forgotPassword,
  };
};

export const setRedirectPath = (redirectPath) => {
  return {
    type: actionTypes.SET_REDIRECT_PATH,
    redirectPath: redirectPath,
  };
};

// eslint-disable-next-line no-unused-vars
const processLogin = (userToken) => {
  return {
    type: actionTypes.LOGIN,
    userToken: userToken,
  };
};

export const logout = () => {
  localStorage.removeItem("userToken");  
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGOUT,
    });
    socketio.disconnectUser();
    dispatch(actionCreators.processUser(null));
    dispatch(actionCreators.setPosts([]));
    dispatch(actionCreators.setProducts([]));
    dispatch(actionCreators.setHawkingLocation(null));
    dispatch(actionCreators.setFeedScroll(0));
    dispatch(actionCreators.setLoadCount(0));
  };
};

// const getClientTokenFromServer = dispatch => {
//   axios("/home/index")
//     .then(response => {
//       if (response.data.error) {
//         const errorMsg = response.data.error.message;
//         const errorType = response.data.error.type;
//         if (errorType === window.AUTH_ERROR) {
//           dispatch(setAuthError(errorMsg));
//         }
//         return;
//       }
//       const clientToken = {
//         token: response.data.token,
//         expiryDate: Math.round(
//           new Date().getTime() / 1000 + response.data.expiresIn
//         )
//       };
//       setTimeout(() => {
//         setAuthLoading(true);
//         getClientTokenFromServer(dispatch);
//       }, response.data.expiresIn * 1000);
//       dispatch(updateClientToken(clientToken));
//       dispatch(setAuthLoading(false));
//     })
//     .catch(error => {
//       const errorString =
//         error.message === "Network Error"
//           ? window.ERROR_CONNECTION
//           : error.message;
//       dispatch(setAuthError(errorString));
//     });
// };

const verifyAuthState = (dispatch) => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  if (userToken && new Date().getTime() < userToken.expiryDate) {
    const expiresIn = userToken.expiryDate - new Date().getTime();
    setTimeout(() => {
      dispatch(logout());
    }, expiresIn);
    dispatch(processLogin(userToken));
  } else {
    dispatch(logout());
  }
  dispatch(setAuthLoading(false));
};

export const newUserLogin = (userToken) => {
  return (dispatch) => {
    const updatedUserToken = {
      token: userToken.token,
      expiryDate: Math.round(new Date().getTime() + userToken.expires_in),
    };
    localStorage.removeItem("client_token");
    localStorage.setItem("userToken", JSON.stringify(updatedUserToken));
    dispatch(updateClientToken(null));
    dispatch(processLogin(updatedUserToken));
    dispatch(actionCreators.setPosts([]));
    dispatch(actionCreators.setFeedScroll(0));
    dispatch(actionCreators.setLoadCount(0));
    setTimeout(() => {
      dispatch(logout());
    }, userToken.expires_in);
  };
};

export const clientLogin = (userToken) => {
  const updatedUserToken = {
    token: userToken.token,
    expiryDate: Math.round(new Date().getTime() + userToken.expires_in),
  };
  localStorage.setItem("client_token", JSON.stringify(updatedUserToken));
  return (dispatch) => {
    dispatch(updateClientToken(updatedUserToken));
  };
};

export const initClientToken = (dispatch) => {
  const userToken = JSON.parse(localStorage.getItem("client_token"));
  if (userToken && new Date().getTime() < userToken.expiryDate) {
    const expiresIn = userToken.expiryDate - new Date().getTime();
    setTimeout(() => {
      dispatch(updateClientToken(null));
    }, expiresIn);
    dispatch(updateClientToken(userToken));
  }
};

export const checkAuthState = () => {
  return (dispatch) => {
    // getClientTokenFromServer(dispatch);
    verifyAuthState(dispatch);
    initClientToken(dispatch);
  };
};
