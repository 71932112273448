/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classes from "./PreviewVideo.module.css";

const PreviewVideo = (props) => {
  return (
    <div className={classes.PreviewVideo}>
      <video
        key={props.video}
        controls
        playsInline
        controlsList="nodownload"
        preload="auto"
      >
        <source src={props.video} type="video/mp4" />
      </video>
      <div
        className={classes.RemoveContainer}
        onClick={(event) => props.removeVideo(event)}
        title="Remove Video"
      >
        <span className="fa fa-times"></span>
      </div>
    </div>
  );
};
export default PreviewVideo;
