import React, { Component } from "react";
import classes from "./UploadProfilePicture.module.css";
import UploadImage from "../../../components/App/UploadImage/UploadImage";
import axios from "../../../axios/axios-instance";
import Form from "../../../components/UI/Forms/Form";
import { createButtonElement } from "../../../components/UI/Forms/utility/form-creators";
import UploadSpinner from "../../../components/UI/Spinner/UploadSpinner/UploadSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import RegFlowLayout from "../../../components/App/Layout/RegFlowLayout/RegFlowLayout";
import Header from "../../../components/App/Layout/Header/Header";
import { Helmet } from "react-helmet";

class UploadProfilePicture extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      image: null,
      crop: null,
      formData: {
        submit: createButtonElement("submit", "CLICK TO PROCEED", false)
      },
      loading: false,
      error: false,
      upload: false,
      skipUpload: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectImageHandler = (image, crop) => {
    this.setState({
      image: image,
      crop: crop,
      skipUpload: false
    });
  };

  removeImageHandler = () => {
    this.setState({
      image: null,
      crop: null,
      skipUpload: true
    });
  };

  skipHandler = event => {
    event.preventDefault();
    const flow = 2;
    const userToken = this.props.userToken;
    axios({
      method: "put",
      url: "/users/me/reg-flow",
      data: {
        flow: flow
      },
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
      })
      .catch(error => {
        console.log(error);
      });
    const updatedUser = {
      ...this.props.user,
      reg_flow: flow
    };
    this.props.processUser(updatedUser);
  };

  uploadHandler = async event => {
    event.preventDefault();
    const image = this.state.image;
    let crop = this.state.crop;
    if (!crop) {
      crop = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      };
    }
    if (!image) {
      return;
    }
    const userToken = this.props.userToken;
    const uploadFormData = new FormData();
    uploadFormData.append("picture", image);
    uploadFormData.append("cropX", crop.x);
    uploadFormData.append("cropY", crop.y);
    uploadFormData.append("cropWidth", crop.width);
    uploadFormData.append("cropHeight", crop.height);
    axios({
      method: "put",
      url: "/users/me/picture",
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.setState({
          loading: false,
          upload: true
        });
        this.props.processUser(user);
      })
      .catch(error => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg
            });
          } else {
            this.setState({
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg
          });
        }
      });
    this.setState({
      loading: true,
      error: null
    });
  };

  render() {
    let uploading = <UploadSpinner size="10" />;
    if (this.state.upload) {
      uploading = <div className="alert alert-success">Upload Successful</div>;
    }
    return (
      <div className="w-100">
        <Helmet>
          <title>Upload Picture</title>
        </Helmet>
        <div className={classes.Header}>
          <Header searchFormData={this.state.formData} submit={null} />
        </div>
        <RegFlowLayout
          isSkipEnabled={this.state.skipUpload}
          skip={this.skipHandler}
          title="Upload Profile Picture"
        >
          <div className={classes.Upload}>
            <div className={classes.UploadContent}>
              <div className={classes.UploadForm}>
                <div className={classes.UploadContainer}>
                  <i
                    style={{ color: "#5f04f6" }}
                    className="fa fa-camera fa-5x mb-2"
                  ></i>
                  <h4 className="mb-2">You are almost done!</h4>
                  <p className="mb-3 f-12">
                    You are almost done with setting up your account. Simply
                    upload a profile picture to enable people recognise and
                    connect with you faster.
                  </p>
                  {!this.state.loading && !this.state.upload ? (
                    <React.Fragment>
                      <UploadImage
                        size="15"
                        showText
                        remove={this.removeImageHandler}
                        upload={this.selectImageHandler}
                      />
                      {this.state.image ? (
                        <div className={classes.UploadButton}>
                          {this.state.error ? (
                            <AlertError error={this.state.error} />
                          ) : null}
                          <Form
                            loading={this.state.loading}
                            formData={this.state.formData}
                            submit={this.uploadHandler}
                          />
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : (
                    uploading
                  )}
                </div>
              </div>
            </div>
          </div>
        </RegFlowLayout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    processUser: user => dispatch(actionCreators.processUser(user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadProfilePicture);
