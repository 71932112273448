import facebookIcon from "../assets/images/facebook.png";
import instagramIcon from "../assets/images/instagram.jpg";
import twitterIcon from "../assets/images/twitter.png";
import tiktokIcon from "../assets/images/tiktok.png";
import linkedinIcon from "../assets/images/linkedin.png";
import whatsappIcon from "../assets/images/whatsapp.png";
import googlePlayIcon from "../assets/images/google-play.png";
import appstoreIcon from "../assets/images/apple-store.png";
import likeIcon from "../assets/images/like.png";
import followIcon from "../assets/images/follow.png";
import commentIcon from "../assets/images/comment.png";
import shareIcon from "../assets/images/share.png";
import retweetIcon from "../assets/images/retweet.png";
import hashtagIcon from "../assets/images/hashtag.png";
import youtubeIcon from "../assets/images/youtube.png";
import subscribeIcon from "../assets/images/subscribe.png";
import telegramIcon from "../assets/images/telegram.png";
import audioMackIcon from "../assets/images/audiomack.png";
import websiteIcon from "../assets/images/website.png";

export function getImage(type) {
  switch (type) {
    case "facebook":
      return facebookIcon;
    case "facebook-page-like":
      return facebookIcon;
    case "instagram":
      return instagramIcon;
    case "twitter":
      return twitterIcon;
    case "whatsapp":
      return whatsappIcon;
    case "like":
      return likeIcon;
    case "follow":
      return followIcon;
    case "comment":
      return commentIcon;
    case "share":
      return shareIcon;
    case "facebook-share":
      return shareIcon;
    case "retweet":
      return retweetIcon;
    case "hashtag":
      return hashtagIcon;
    case "googlePlay":
      return googlePlayIcon;
    case "appStore":
      return appstoreIcon;
    case "tiktok":
      return tiktokIcon;
    case "linkedin":
      return linkedinIcon;
    case "youtube":
      return youtubeIcon;
    case "subscribe":
      return subscribeIcon;
    case "telegram":
      return telegramIcon;
    case "audiomack":
      return audioMackIcon;
    case "website":
      return websiteIcon;
    default:
      return likeIcon;
  }
}
