import React from "react";
import classes from "./SocialTransactionContent.module.css";
import { Link } from "react-router-dom";
import { getImage } from "../../../../util/image-util";
import { abbreviateNumber } from "../../../../util/util";

const SocialTransactionContent = (props) => {
  const iconContent = (
    <>
      <img src={getImage(props.transaction.type)} alt="icon" />
      {props.rowClass != null ? (
        <div className="mt-3">
          <button className="f-10 btn btn-outline">Select</button>
        </div>
      ) : null}
    </>
  );

  let link = `/earn/${props.transaction._id}`;

  if (props.content_type === "advertise") {
    link = `/social/buy/${props.transaction._id}`;
  }

  return (
    <div className={props.rowClass}>
      <Link to={link} className={`${classes.SocialTransactionContent}`}>
        <div className={classes.IconContent}>{iconContent}</div>
        <div className={classes.MainContent}>
          <div className={classes.Heading}>
            <h4>{props.transaction.title}</h4>
            <p className="f-12">
              {props.content_type === "advertise" ? "Pricing:" : "Earnings:"}{" "}
              <b>{props.transaction.earning}</b>
            </p>
          </div>
          <p>{props.transaction.description}</p>
          <div className="d-flex align-items-center">
            <div className={classes.Platforms}>
              <p>Platforms:</p>
              <div className={classes.PlatformIcon}>
                {props.transaction.platforms.map((el, index) => (
                  <img key={index} src={getImage(el)} alt="el" />
                ))}
              </div>
            </div>
            {props.transaction.tasks ? (
              <div className={classes.TaskCount}>
                <span className="f-8 fw-400 badge p-1 badge-success">
                  {abbreviateNumber(props.transaction.tasks)} Tasks Available
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SocialTransactionContent;
