/* eslint-disable no-undef */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./Logout.module.css";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import axios from "../../../axios/axios-instance";

class Logout extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.logoutProcess();
  }

  logoutProcess = () => {
    const userToken = this.props.userToken;
    const url = "/auth/logout";
    axios({
      method: "post",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const data = response.data.data;
        if (data.status === "success") {
          this.props.logout();          
        }
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.logoutProcess();
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
          <p>Logging out...</p>
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <div className={classes.Logout}>{loadingContent}</div>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actionCreators.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
