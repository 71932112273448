import React from "react";
import classes from "./SocialMenuModal.module.css";
import { withRouter } from "react-router-dom";
import socialIcon from "../../../../assets/images/social-icons.jpg";
import handIcon from "../../../../assets/images/hand.png";

const SocialMenuModal = (props) => {
  const closeHandler = (event) => {
    event.preventDefault();
    window.$("#SocialMenuModal").off("hidden.bs.modal");
    window.$("#SocialMenuModal").modal("hide");
  };

  const showSocialTaskPage = (event) => {
    event.preventDefault();
    window.$("#SocialMenuModal").off("hidden.bs.modal");
    window.$("#SocialMenuModal").on("hidden.bs.modal", (event) => {
      props.history.push("/earn");
    });
    window.$("#SocialMenuModal").modal("hide");
  };

  const showReferralPage = (event) => {
    event.preventDefault();
    window.$("#SocialMenuModal").off("hidden.bs.modal");
    window.$("#SocialMenuModal").on("hidden.bs.modal", (event) => {
      props.history.push("/shop");
    });
    window.$("#SocialMenuModal").modal("hide");
  };

  return (
    <div className={classes.SocialMenuModal}>
      <div
        id="SocialMenuModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.SocialMenuModalContent} modal-content`}>
            <div
              id="SocialMenuModalBody"
              className={`${classes.SocialMenuModalBody} modal-body`}
            >
              <button onClick={closeHandler} className={classes.CloseButton}>
                <span className="fas fa-times"></span>
              </button>
              <div
                className={`${classes.SellModalContainer} d-flex flex-column align-items-center`}
              >
                <div className={classes.SellTitle}>
                  <h4>How do you want to earn today?</h4>
                </div>
                <div className="container-fluid">
                  <div className="row no-gutters">
                    <div className="col-6 mb-2">
                      <div className={`${classes.Task} mr-2`}>
                        <div className={classes.TaskHeading}>
                          <h6>Perform Social Tasks and Earn Daily</h6>
                        </div>
                        <div className={classes.TaskContent}>
                          <div className={classes.TaskIcon}>
                            <img alt="social-icon" src={socialIcon} />
                          </div>
                          <p className="f-10">
                            Earn Daily by posting adverts of various businesses
                            and performing tasks on your social media pages.
                          </p>
                        </div>
                        <div className={classes.TaskFooter}>
                          <p>
                            <button
                              onClick={showSocialTaskPage}
                              className="btn btn-outline mb-1"
                            >
                              GET STARTED
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className={`${classes.Task}`}>
                        <div className={classes.TaskHeading}>
                          <h6>Resell Products and Earn Big Commissions</h6>
                        </div>
                        <div className={classes.TaskContent}>
                          <div className={classes.TaskIcon}>
                            <img alt="social-icon" src={handIcon} />
                          </div>
                          <p className="f-10">
                            Resell products of top sellers and brands and earn
                            up to N100,000 monthly in sales commissions
                          </p>
                        </div>
                        <div className={classes.TaskFooter}>
                          <p>
                            <button
                              onClick={showReferralPage}
                              className="btn btn-outline mb-1"
                            >
                              GET STARTED
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(SocialMenuModal);
