import React from "react";
import Dropdown from "../Dropdown";
import DropdownLink from "../DropdownLink/DropdownLink";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import classes from "./CountryDropdown.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";

const CountryDropdown = props => {
  let activeCountry = props.country;
  const setActiveCountry = (event, country) => {
    if (event) event.preventDefault();
    props.setAuthCountry(country);
    return false;
  };
  let options = props.countries.map(el => {
    let style = {};
    if (!activeCountry && el.active) {
      activeCountry = el;
      setActiveCountry(null, el);
    }
    if (activeCountry && activeCountry.code === el.code) {
      style = {
        backgroundColor: "#e8e8e8",
        color: "#5f04f6"
      };
    }
    return (
      <a
        style={style}
        key={el.code}
        href="/"
        onClick={event => setActiveCountry(event, el)}
      >
        {`${el.name} (${props.isCurrency ? el.currency : el.d_code})`}
      </a>
    );
  });
  if (!activeCountry) {
    activeCountry = props.countries[0];
    setActiveCountry(null, activeCountry);
  }
  let content = `${activeCountry.code} ${activeCountry.d_code}`;
  if (props.isCurrency) {
    content = activeCountry.currency;
  }
  return (
    <div className={classes.CountryDropdown}>
      <Dropdown>
        <DropdownLink>
          <span className="fal fa-chevron-down"></span> {content}
        </DropdownLink>
        <DropdownMenu>{options}</DropdownMenu>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    country: state.authState.country
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAuthCountry: country => {
      dispatch(actionCreators.setAuthCountry(country));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryDropdown);
