/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react";
import classes from "./PostImages.module.css";
import PostImage from "./PostImage/PostImage";
import { withRouter } from "react-router-dom";

let parentHeight = 0;

const postImages = props => {
  const [state, setState] = useState({
    parentWidth: 0
  });

  const postImagesRef = useRef(null);

  useEffect(() => {
    setState({
      parentWidth: postImagesRef.current.getBoundingClientRect().width
    });
  }, []);
  const imageLayout = createImageLayout(props.imageData, state.parentWidth);
  const actualLength = props.imageData.length;
  const layoutLength = imageLayout.length;
  const imageRem = actualLength - layoutLength;
  const content = imageLayout.map((image, index) => {
    const viewPostHandler = event => {
      event.preventDefault();
      event.stopPropagation();
      if (props.isProduct)
        props.history.push(`/products/${props.postId}`);
      else props.history.push(`/posts/${props.postId}/${image.id}`);
    };
    return (
      <PostImage
        clicked={viewPostHandler}
        rem={index === layoutLength - 1 ? imageRem : null}
        key={image.id}
        image={image}
      />
    );
  });

  return (
    <div
      ref={postImagesRef}
      style={{ height: `${parentHeight}px` }}
      className={classes.PostImages}
    >
      {content}
    </div>
  );
};

const getMaxImageRatio = imageData => {
  return imageData.reduce((acc, cur) => {
    const ratio = cur.photo.width / cur.photo.height;
    // cur.width >= cur.height ? cur.width / cur.height : cur.height / cur.width;
    if (ratio > acc) {
      return ratio;
    }
    return acc;
  }, 0);
};

const getLandscapeColumns = noOfImages => {
  switch (noOfImages) {
    case 5:
      return 2;
    case 4:
      return 3;
    default:
      return 2;
  }
};

const getPortraitColumns = noOfImages => {
  switch (noOfImages) {
    case 5:
      return 2;
    case 4:
      return 2;
    case 3:
      return 2;
    default:
      return 2;
  }
};

const getEqualSizeColumns = noOfImages => {
  switch (noOfImages) {
    case 5:
      return 2;
    case 4:
      return 2;
    case 3:
      return 2;
    default:
      return 2;
  }
};

const getColumns = imageData => {
  const maxRatio = getMaxImageRatio(imageData);
  if (maxRatio > 1) {
    return getLandscapeColumns(imageData.length);
  } else if (maxRatio < 1) {
    return getPortraitColumns(imageData.length);
  } else {
    return getEqualSizeColumns(imageData.length);
  }
};

const calculateMinimumHeight = (imageData, parentWidth) => {
  const cols = getColumns(imageData);
  const gutters = imageData.length * 2;
  const width = (parentWidth - gutters) / cols;
  const minHeight = imageData.reduce((acc, cur) => {
    const height = (cur.photo.height * width) / cur.photo.width;
    // console.log("Minimum Height", height)
    if (height < acc) {
      return height;
    }
    return acc;
  }, Number.POSITIVE_INFINITY);
  // console.log(minHeight, width)
  return minHeight;
};

const createImageLayout = (imageData, parentWidth) => {
  let nom_h = 100;
  if (parentWidth) {
    // nom_h =
    //   ((1 / getMaxImageRatio(imageData)) * parentWidth) /
    //   imageData.length;
    nom_h = calculateMinimumHeight(imageData, parentWidth);
    // nom_h = parentWidth / 5;
  }
  const gutter = 2;
  const layout = [];
  const result = [];
  imageData.forEach(element => {
    const width = element.photo.width;
    const height = element.photo.height;
    const img_width = (width * nom_h) / height;
    const img_height = nom_h;
    const image = {};
    image.width = img_width;
    image.height = img_height;
    image.src = element.photo.src;
    image.id = element.id;
    image.currency = element.currency;
    let j = layout.length;
    let done = false;
    while (j--) {
      const row = layout[j];
      if (row.width + gutter + img_width < parentWidth) {
        row.data.push(image);
        row.width += gutter + img_width;
        done = true;
        break;
      }
    }
    if (!done) {
      const newRow = {
        width: img_width,
        data: [image]
      };
      layout.push(newRow);
    }
  });
  layout.splice(2);
  layout.sort((el1, el2) => {
    return el1.data.length >= el2.data.length ? 1 : -1;
  });
  let top = 0;
  for (let i = 0; i < layout.length; i++) {
    const row = layout[i];
    const rowGutter = (row.data.length - 1) * gutter;
    let left = 0;
    let scaleFactor = (parentWidth - rowGutter) / (row.width - rowGutter);
    if (isNaN(scaleFactor)) {
      continue;
    } else {
      row.height = nom_h * scaleFactor;
    }
    for (let j = 0; j < row.data.length; j++) {
      const image = row.data[j];
      image.width *= scaleFactor;
      image.height = row.height;
      image.top = top;
      image.left = left;
      left += image.width + gutter;
      result.push(image);
    }
    top += row.height + gutter;
  }
  parentHeight = top - gutter;
  return result;
};

export default withRouter(postImages);
