import React from "react";
import classes from "./PageContent.module.css";

const PageContent = (props) => {
  return (
    <div
      className={`${classes.PageContent} ${props.fixed ? classes.Fixed : ""}`}
    >
      {props.children}
    </div>
  );
};

export default PageContent;
