/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./AddBelief.module.css";
import axios from "../../../axios/axios-instance";
import Form from "../../../components/UI/Forms/Form";
import {
  createButtonElement,
  createSelectElement,
} from "../../../components/UI/Forms/utility/form-creators";
import * as validators from "../../../components/UI/Forms/utility/validator";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import RegFlowLayout from "../../../components/App/Layout/RegFlowLayout/RegFlowLayout";
import Header from "../../../components/App/Layout/Header/Header";
import { Helmet } from "react-helmet";
import { getDaysInMonth } from "../../../util/util";

const moment = require("moment");

class AddBelief extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    const beliefs = props.meta.beliefs;
    const dayOptions = [];
    for (let i = 1; i <= 31; i++) {
      dayOptions.push({
        value: i,
        option: i,
      });
    }
    const currYear = new Date().getFullYear();
    const yearOptions = [];
    for (let i = currYear; i >= 1900; i--) {
      yearOptions.push({
        value: i,
        option: i,
      });
    }
    const months = moment.months();
    const monthOptions = [];
    for (let i = 0; i < months.length; i++) {
      monthOptions.push({
        value: i,
        option: months[i],
      });
    }
    this.state = {
      formData: {
        birthday_line: {
          element: "titleline",
          value: "Add Your Birthday",
        },
        birthday_hint_line: {
          element: "hintline",
          value:
            "Get more tasks that is in line with your birthday. Enter your correct date of birth. No one will see this information",
        },
        day: createSelectElement(
          "",
          this.validateDay,
          "fas fa-list",
          "col-4",
          dayOptions,
          "Day",
          this.inputChangedHandler.bind(this, "day"),
          props.user.birthday ? props.user.birthday.day + "" : "",
          ""
        ),
        month: createSelectElement(
          "",
          this.validateMonth,
          "fas fa-list",
          "col-4",
          monthOptions,
          "Month",
          this.inputChangedHandler.bind(this, "month"),
          props.user.birthday ? props.user.birthday.month + "" : "",
          ""
        ),
        year: createSelectElement(
          "",
          this.validateYear,
          "fas fa-list",
          "col-4",
          yearOptions,
          "Year",
          this.inputChangedHandler.bind(this, "year"),
          props.user.birthday ? props.user.birthday.year + "" : "",
          ""
        ),
        belief: createSelectElement(
          "What's Your Religion?",
          this.validateBelief,
          "fas fa-list",
          "col-sm-12",
          beliefs,
          "Select Your Religion",
          this.inputChangedHandler.bind(this, "belief"),
          props.user.belief ? props.user.belief : "",
          "Please select your religion so we can personalise your tasks according to what you believe in."
        ),
        submit: createButtonElement("submit", "CONTINUE", true, null),
      },
      loading: false,
      error: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateGender = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select a gender";
    }
    const genderOptions = this.props.belief_options.gender_options.map(
      (el) => el.value
    );
    if (!genderOptions.includes(value)) {
      return "Please select valid gender from the list above";
    }
    return null;
  };

  validateBelief = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select valid religion from the list above";
    }
    const beliefOptions = this.props.meta.beliefs.map(
      (el) => el.value
    );
    if (!beliefOptions.includes(value)) {
      return "Please select valid religion from the list above";
    }
    return null;
  };

  validateDay = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Select Day";
    }
    const month = this.state.formData.month.value;
    const year = this.state.formData.year.value;
    if (month.length && year.length) {
      const daysInMonth = getDaysInMonth(+month, +year);
      if (+value > daysInMonth) {
        return `No Day ${value} in month`;
      }
    }
    return null;
  };

  validateYear(value) {
    if (validators.isEmpty(value.trim())) {
      return "Select Year";
    }
    return null;
  }

  validateMonth(value) {
    if (validators.isEmpty(value.trim())) {
      return "Select Month";
    }
    return null;
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
    };
    updatedFormData[name] = formElementData;
    this.setState({
      locationData: null,
      formData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  submitHandler = async (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const day = this.state.formData.day.value;
    const month = this.state.formData.month.value;
    const year = this.state.formData.year.value;
    const belief = this.state.formData.belief.value;
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: "/users/me/belief",
      data: {
        day: day,
        month: month,
        year: year,
        belief: belief,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.setState({
          loading: false,
          upload: true,
        });
        this.props.processUser(user);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  render() {
    return (
      <div className="w-100">
        <div className={classes.Header}>
          <Header submit={null} />
        </div>
        <RegFlowLayout noSkip title="More About You">
          <div className={classes.AddBelief}>
            <Helmet>
              <title>Hawkit - More About You</title>
            </Helmet>
            <div className={classes.AddBeliefContent}>
              <div className={classes.AddBeliefForm}>
                <div className={classes.AddBeliefContainer}>
                  <p className="mb-3 f-12">
                    Let's know more about you so we can personalise your
                    experience on Hawkit
                  </p>
                  <div className={classes.BeliefData}>
                    {this.state.error ? (
                      <AlertError error={this.state.error} />
                    ) : null}
                    <Form
                      loading={this.state.loading}
                      formData={this.state.formData}
                      submit={this.submitHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RegFlowLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBelief);
