/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./EditProfile.module.css";
import UploadImage from "../../../../components/App/UploadImage/UploadImage";
import { connect } from "react-redux";
import {
  createInputElement,
  createTextAreaElement,
  createSelectElement,
} from "../../../../components/UI/Forms/utility/form-creators";
import Form from "../../../../components/UI/Forms/Form";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import TitleLinks from "../../../../components/App/Layout/Title/TitleLinks";
import axios from "../../../../axios/axios-instance";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import UploadSpinner from "../../../../components/UI/Spinner/UploadSpinner/UploadSpinner";
import * as actionCreators from "../../../../store/actions/actions";
import { Link } from "react-router-dom";
import { getDaysInMonth } from "../../../../util/util";

const moment = require("moment");

class EditProfile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const formattedPhoneNumber = props.user.phoneNumber
      ? props.user.phoneNumber.replace(props.user.country.d_code, "0")
      : "";
    const genderOptions = this.props.meta.gender_options;
    const beliefs = props.meta.beliefs;
    const dayOptions = [];
    for (let i = 1; i <= 31; i++) {
      dayOptions.push({
        value: i,
        option: i,
      });
    }
    const currYear = new Date().getFullYear();
    const yearOptions = [];
    for (let i = currYear; i >= 1900; i--) {
      yearOptions.push({
        value: i,
        option: i,
      });
    }
    const months = moment.months();
    const monthOptions = [];
    for (let i = 0; i < months.length; i++) {
      monthOptions.push({
        value: i,
        option: months[i],
      });
    }
    this.state = {
      image: null,
      crop: null,
      formData: {
        fullName: createInputElement(
          "text",
          "Name",
          "Enter Your Name",
          props.user.name,
          this.validateFullName,
          null,
          "fa fa-user",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "fullName")
        ),
        bio: createTextAreaElement(
          "Short Bio",
          "Write a short bio to tell people more about yourself",
          props.user.bio ? props.user.bio : "",
          this.validateBio,
          "You can leave this field empty if you wish",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "bio"),
          "bioField"
        ),
        username: createInputElement(
          "text",
          "Username",
          "Enter Your Username",
          props.user.username,
          this.validateUsername,
          "Your username is also your handle. Make sure you create something unique and interesting.",
          "fa fa-user",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "username")
        ),
        gender: createSelectElement(
          "What's Your Gender",
          this.validateGender,
          "fas fa-list",
          "col-sm-12",
          genderOptions,
          "Select Gender",
          this.inputChangedHandler.bind(this, "gender"),
          this.props.user.gender ? this.props.user.gender : "",
          ""
        ),
        birthday_line: {
          element: "titleline",
          value: "What's your Birthday",
        },
        birthday_hint_line: {
          element: "hintline",
          value:
            "Get more tasks that is in line with your birthday. Enter your correct date of birth. No one will see this information",
        },
        day: createSelectElement(
          "",
          this.validateDay,
          "fas fa-list",
          "col-4",
          dayOptions,
          "Day",
          this.inputChangedHandler.bind(this, "day"),
          props.user.birthday ? props.user.birthday.day + "" : "",
          ""
        ),
        month: createSelectElement(
          "",
          this.validateMonth,
          "fas fa-list",
          "col-4",
          monthOptions,
          "Month",
          this.inputChangedHandler.bind(this, "month"),
          props.user.birthday ? props.user.birthday.month + "" : "",
          ""
        ),
        year: createSelectElement(
          "",
          this.validateYear,
          "fas fa-list",
          "col-4",
          yearOptions,
          "Year",
          this.inputChangedHandler.bind(this, "year"),
          props.user.birthday ? props.user.birthday.year + "" : "",
          ""
        ),
        belief: createSelectElement(
          "What's Your Religion?",
          this.validateBelief,
          "fas fa-list",
          "col-sm-12",
          beliefs,
          "Select Your Religion",
          this.inputChangedHandler.bind(this, "belief"),
          props.user.belief ? props.user.belief : "",
          "Please select your religion so we can personalise your tasks according to what you believe in."
        ),
        email: createInputElement(
          "text",
          "Email Address",
          "Enter Your Email",
          props.user.email,
          null,
          <p>For security purposes, your email address cannot be changed</p>,
          "fa fa-envelope",
          "col-sm-12",
          null,
          null,
          true
        ),
        phoneNumber: createInputElement(
          "text",
          "Phone Number",
          "Enter Your Phone Number",
          formattedPhoneNumber,
          null,
          "Phone number cannot be edited here. You need to activate your account before you can edit your phone number",
          "fa fa-phone-alt",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "phoneNumber"),
          "",
          true,
          true
        ),
      },
      loading: false,
      error: null,
      completed: false,
    };
  }

  validatePhoneNumber(value) {
    if (validators.isEmpty(value.trim())) {
      return "Pleaase enter valid phone number!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Phone Number. Only numbers are allowed";
    }
    if (!validators.validateLength(value.trim(), 5, 20)) {
      return "Please enter valid phone number.";
    }
    return null;
  }

  validateGender = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select a gender";
    }
    const genderOptions = this.props.meta.gender_options.map((el) => el.value);
    if (!genderOptions.includes(value)) {
      return "Please select valid gender from the list above";
    }
    return null;
  };

  validateUsername(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter your username!";
    }
    if (!validators.isLetterOrNumber(value.trim())) {
      return "Invalid Username. No special characters and spaces allowed.";
    }
    if (!validators.validateLength(value.trim(), 4, 20)) {
      return "Username should contain 4 - 20 characters without spaces";
    }
    return null;
  }

  validateFullName(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter the name of your account.";
    }
    if (!validators.validateLength(value.trim(), 3, 50)) {
      return "Please enter valid name of account. 3 - 50 characters are allowed";
    }
    if (!validators.validateName(value.trim())) {
      return "Please enter valid name of account. Only letters are allowed";
    }
    return null;
  }

  validateBio = (value) => {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 3, 500)) {
      return "Please enter a valid short bio between 3 - 500 characters. You can leave this field empty if you wish";
    }
    return null;
  };

  validateBelief = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select valid religion from the list above";
    }
    const beliefOptions = this.props.meta.beliefs.map((el) => el.value);
    if (!beliefOptions.includes(value)) {
      return "Please select valid religion from the list above";
    }
    return null;
  };

  validateDay = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Select Day";
    }
    const month = this.state.formData.month.value;
    const year = this.state.formData.year.value;
    if (month.length && year.length) {
      const daysInMonth = getDaysInMonth(+month, +year);
      if (+value > daysInMonth) {
        return `No Day ${value} in month`;
      }
    }
    return null;
  };

  validateYear(value) {
    if (validators.isEmpty(value.trim())) {
      return "Select Year";
    }
    return null;
  }

  validateMonth(value) {
    if (validators.isEmpty(value.trim())) {
      return "Select Month";
    }
    return null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectImageHandler = (image, crop) => {
    this.setState({
      image: image,
      crop: crop,
    });
  };

  removeImageHandler = () => {
    this.setState({
      image: null,
      crop: null,
    });
  };

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({ formData: updatedFormData });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const image = this.state.image;
    let crop = this.state.crop;
    const name = this.state.formData.fullName.value;
    const username = this.state.formData.username.value;
    const bio = this.state.formData.bio.value;
    const gender = this.state.formData.gender.value;
    const userToken = this.props.userToken;
    // const phoneNumber = this.state.formData.phoneNumber.value;
    const day = this.state.formData.day.value;
    const month = this.state.formData.month.value;
    const year = this.state.formData.year.value;
    const belief = this.state.formData.belief.value;
    const uploadFormData = new FormData();
    if (!crop) {
      crop = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
    }
    if (image) {
      uploadFormData.append("picture", image);
      uploadFormData.append("cropX", crop.x);
      uploadFormData.append("cropY", crop.y);
      uploadFormData.append("cropWidth", crop.width);
      uploadFormData.append("cropHeight", crop.height);
    }
    uploadFormData.append("name", name);
    uploadFormData.append("username", username);
    uploadFormData.append("bio", bio);
    uploadFormData.append("gender", gender);
    // uploadFormData.append("phoneNumber", phoneNumber);
    uploadFormData.append("belief", belief);
    uploadFormData.append("day", day);
    uploadFormData.append("month", month);
    uploadFormData.append("year", year);
    axios({
      method: "put",
      url: "/users/me/",
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.setState({
          loading: false,
          completed: true,
        });
        this.props.processUser(user);
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error:
                "There is an error in the form. Please go back to the form, correct the errors and try again",
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  render() {
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              {this.state.loading || this.state.error ? (
                <div className={classes.SubmissionModal}>
                  {this.state.loading ? (
                    <UploadSpinner size="10" />
                  ) : (
                    <div className={classes.SubmissionError}>
                      <i className="fa fa-times-circle mb-2"></i>
                      <p className="mb-2">{this.state.error}</p>
                      <p>
                        <button
                          onClick={this.submitHandler}
                          className="mr-1 btn btn-coloured-heavy btn-rounded"
                        >
                          Try Again
                        </button>
                        <button
                          onClick={(event) =>
                            this.setState({ error: null, loading: false })
                          }
                          className="btn btn-danger btn-rounded"
                        >
                          Back to Form
                        </button>
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Edit Profile</h4>
                </TitleHeader>
                {!this.state.completed ? (
                  <TitleLinks>
                    <button onClick={this.submitHandler}>Save</button>
                  </TitleLinks>
                ) : null}
              </Title>
              {!this.state.completed ? (
                <div className={classes.EditProfile}>
                  <div className={classes.UserProfileImage}>
                    <div className={classes.ProfileImage}>
                      <UploadImage
                        nomargin
                        text="Change Picture"
                        image={this.props.user.profile_pic}
                        size="18"
                        remove={this.removeImageHandler}
                        upload={this.selectImageHandler}
                        showText
                      />
                    </div>
                  </div>
                  <div className={classes.UserProfile}>
                    <Form formData={this.state.formData} />
                  </div>
                </div>
              ) : (
                <div className={classes.EditProfile}>
                  <div className={classes.CreateCompleted}>
                    <div className={classes.CreateCompletedContent}>
                      <i
                        style={{ color: "#5f04f6" }}
                        className="fa fa-check-circle fa-5x mb-2"
                      ></i>
                      <h4 className="mb-3">Profile Updated Successfully!</h4>
                      <p className="mb-3">
                        Your profile has been updated successfully. Don't forget
                        to invite your contacts and friends to join Hawkit so
                        you can get large number of followers and post views.
                      </p>
                      <p>
                        <Link
                          replace
                          to={`/users/${this.props.user.username}`}
                          className="btn btn-coloured-heavy btn-rounded btn-xl"
                        >
                          MY PROFILE
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
