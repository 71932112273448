import React from "react";
import classes from "./Post.module.css";
// import UserPhoto from "../User/UserPhoto/UserPhoto";
import Engagement from "./Engagement/Engagement";
// import PostOptions from "./PostOptions/PostOptions";
// import PostTitle from "./PostTitle/PostTitle";
import PostContent from "./PostContent/PostContent";
import Repost from "./Repost/Repost";
import BelieveCount from "./BelieveDetail/BelieveCount";
import BelieveContent from "./BelieveDetail/BelieveContent";
// import CommentEditor from "../../../containers/App/Create/CommentEditor/CommentEditor";
import CommentBox from "./CommentBox/CommentBox";
import PostContext from "../../../context/post-context";
import { withRouter } from "react-router-dom";
import PreviewLikeContent from "./PreviewPostDetail/PreviewLikeContent";
import PostMarketContent from "./PostMarketContent/PostMarketContent";
import UserPhoto from "../User/UserPhoto/UserPhoto";
import PostOptions from "./PostOptions/PostOptions";
import PostTitle from "./PostTitle/PostTitle";

const post = (props) => {
  return (
    <PostContext.Consumer>
      {(context) => {
        // const addCommentHandler = () => {
        //   const postIndex = context.posts.findIndex(
        //     (el) => el._id === props.post._id
        //   );
        //   const updatedPosts = [...context.posts];
        //   const updatedPost = { ...context.posts[postIndex] };
        //   updatedPost.comments++;
        //   updatedPosts[postIndex] = updatedPost;
        //   context.setPosts(updatedPosts);
        // };
        return (
          <div className={classes.Post}>
            <div className={classes.PostDetail}>
              {!props.post.product.is_main_product ? <div className={classes.TopSummary}>
                <UserPhoto
                  photo={props.post.user.profile_pic}
                  width="40"
                  height="40"
                />
                <PostTitle post={props.post} />
                <PostOptions activeUser={context.user} post={props.post} />
              </div> : null}
              {props.post.product.is_main_product ?
                <PostContent
                  activeUser={context.user ? context.user.username : null}
                  description={props.descriptionHandler}
                  review={props.reviewHandler}
                  buy={props.buyHandler}
                  post={props.post}
                  showImage={props.showImage}
                /> :
                <PostMarketContent
                  activeUser={context.user ? context.user.username : null}
                  description={props.descriptionHandler}
                  review={props.reviewHandler}
                  buy={props.buyHandler}
                  post={props.post}
                  showImage={props.showImage}
                />}
              <div className={classes.EngagementDetail}>
                <div className={classes.PostEngagement}>
                  <div className={classes.Engagements}>
                    <Engagement
                      loading={false}
                      activeUser={context.user}
                      reqUser
                      clicked={() => context.toggleLikes(props.post._id)}
                      active={props.post.likes_count ? "#d21f3c" : null}
                      icon="fal fa-heart"
                    />
                    <Engagement
                      noLoading
                      clicked={props.resellHandler}
                      active={props.post.shares_count ? "#5f04f6" : null}
                      icon="fal fa-share-alt"
                    />
                    <Engagement
                      noLoading
                      clicked={props.showImage}
                      loading={false}
                      icon="fal fa-external-link"
                    />
                  </div>
                  <Repost
                    showComment={props.showComment}
                    setPostModal={context.setPostModal}
                    post={props.post}
                  />
                </div>
                <div className={classes.BelieveDetail}>
                  <BelieveCount
                    postId={props.post._id}
                    setPostModal={context.setPostModal}
                    count={props.post.likes}
                    type="believe"
                  />
                  {context.user ? (
                    <BelieveContent
                      postId={props.post._id}
                      content={props.post.mutual}
                      addPostView={context.addPostView}
                    />
                  ) : (
                    <PreviewLikeContent
                      postId={props.post._id}
                      addPostView={context.addPostView}
                      content="Login or Sign up to Like and Comment on this post"
                    />
                  )}
                </div>
              </div>
              {props.showComment ? <CommentBox post={props.post} /> : null}
              {/* {context.user ? (
                <div className={classes.CommentEditor}>
                  <CommentEditor
                    submit={addCommentHandler}
                    post={props.post}
                    photoSize="32"
                    label="Write a Comment..."
                  />
                </div>
              ) : null} */}
            </div>
          </div>
        );
      }}
    </PostContext.Consumer>
  );
};

// export default post;

export default withRouter(post);
