import React from "react";
import { withRouter } from "react-router-dom";

const navigationButton = props => {
  return (
    <button onClick={() => props.history.push(props.to)} className={props.btnClasses}>
      {props.children}
    </button>
  );
};

export default withRouter(navigationButton);
