/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Sales.module.css";
import OrderView from "../../../../components/App/Order/OrderView/OrderView";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import { connect } from "react-redux";
import TitleLinks from "../../../../components/App/Layout/Title/TitleLinks";
import PaymentCompletedModal from "../../../../components/App/Order/PaymentCompletedModal/PaymentCompletedModal";
import SalesOrderView from "../../../../components/App/Order/SalesOrderView/SalesOrderView";

class Sales extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      is_action_required: false,
      error: null,
      order: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadOrder();
  }

  loadOrder = () => {
    const { orderId } = this.props.match.params;
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/orders/sales/${orderId}`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const order = response.data.data.order;
        let is_action_required = false;
        for (const sales_order of order.sales_orders) {
          if (sales_order.status.seller === window.ORDER_AWAITING_DELIVERY) {
            is_action_required = true;
            break;
          }
        }
        this.setState({
          loading: false,
          is_action_required: is_action_required,
          order: order,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let content = null;
    if (this.state.loading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className={classes.Loading}>
          <Error refresh={this.loadOrder} error={this.state.error} />
        </div>
      );
    } else if (this.state.order) {
      content = (
        <div className={classes.Sales}>
          {this.state.is_action_required ? (
            <div className={classes.ActionInfo}>
              The buyer has successfully made payment for your order. Please
              bring the order to the nearest Hawkit Collection center so that we
              can deliver the item to the seller. Your money will be paid into
              the Hawkit wallet after a successful delivery. If the items
              ordered are out of stock, simply cancel the order below. Note that
              order cancellations attract penalty charges and possible
              suspension of the seller from using our service.
            </div>
          ) : null}
          <SalesOrderView refresh={this.loadOrder} order={this.state.order} />
        </div>
      );
    }

    let titleButton = null;

    if (this.state.order && this.state.is_action_required) {
      titleButton = (
        <div className={`${classes.ActionRequired} blink`}>
          <p>Action Required</p>
        </div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>View Order</h4>
                </TitleHeader>
                <TitleLinks>{titleButton}</TitleLinks>
              </Title>
              {content}
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(Sales);
