export const SET_LOADER_STATUS = "SET_LOADER_STATUS";
export const SET_LOADER_STATUS_MSG = "SET_LOADER_STATUS_MSG";
export const SET_LOADER_PROGRESS = "SET_LOADER_PROGRESS";
export const INITIALISE_LOADER_STATE = "INITIALISE_LOADER_STATE";
export const UPDATE_CLIENT_TOKEN = "UPDATE_CLIENT_TOKEN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHECK_AUTH_USER = "CHECK_AUTH_USER";
export const SET_AUTH_COUNTRY = "SET_AUTH_COUNTRY";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const SET_VERIFY_PHONE = "SET_VERIFY_PHONE";
export const SET_COMPLETE_SIGN_UP = "SET_COMPLETE_SIGN_UP";
export const SET_FORGOT_PASSWORD = "SET_FORGOT_PASSWORD";
export const SET_REDIRECT_PATH = "SET_REDIRECT_PATH";
export const SET_USER = "SET_USER";
export const SET_USER_CURRENCY = "SET_USER_CURRENCY";
export const SET_COLLAPSE_STATUS = "SET_COLLAPSE_STATUS";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_TOP_GROCERY_PRODUCTS = "SET_TOP_GROCERY_PRODUCTS";
export const SET_TOP_AIRTIME_PRODUCTS = "SET_TOP_AIRTIME_PRODUCT";
export const SET_POSTS = "SET_POSTS";
export const SET_FEED_SCROLL = "SET_FEED_SCROLL";
export const SET_POST_MODAL = "SET_POST_MODAL";
export const SET_DELETE_MODAL = "SET_DELETE_MODAL";
export const SET_COMMENT_MODAL = "SET_COMMENT_MODAL";
export const SET_HAWKING_LOCATION = "SET_HAWKING_LOCATION";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const SET_LOAD_COUNT = "LOAD_COUNT";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const SET_MESSAGE_COUNT = "SET_MESSAGE_COUNT";
export const SET_SELLERS_PRODUCT_FAILED = "SET_SELLERS_PRODUCT_FAILED";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CATEGORY_SEARCH = "SET_CATEGORY_SEARCH";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SEARCH_SCROLL = "SET_SEARCH_SCROLL";
export const SET_SEARCH_POSTS = "SET_SEARCH_POSTS";
export const SET_SEARCH_LOCATION = "SET_SEARCH_LOCATION";
export const SET_COUNTRY_STATES = "SET_COUNTRY_STATES";
export const SET_ADVERT_BANNER = "SET_ADVERT_BANNER";
export const SET_META = "SET_META";
export const SET_PAGE_STATES = "SET_PAGE_STATES";
