/* eslint-disable no-undef */
import React, { Component } from "react";
import classes from "./RefreshUser.module.css";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import axios from "../../../axios/axios-instance";
import * as actionCreators from "../../../store/actions/actions";

class RefreshUser extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.refreshUser();
  }

  refreshUser = () => {
    const userToken = this.props.userToken;
    const url = `/users/me`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        const user = response.data.data.user;
        this.props.processUser(user);
        this.props.history.replace("/");
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          this.props.history.replace("/");
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.refreshUser();
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="5" />
          <p className="mb-2 f-10 text-white">Getting User Info...</p>
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.refreshHandler} error={this.state.error} />
        </div>
      );
    }
    return <div className={classes.RefreshUser}>{loadingContent}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshUser);
