/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./ViewFunding.module.css";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import * as actionCreators from "../../../store/actions/actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import FundPayment from "../../../components/App/User/FundPayment/FundPayment";
import PaymentCompletedModal from "../../../components/App/Order/PaymentCompletedModal/PaymentCompletedModal";
const moment = require("moment");

class FundWallet extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      funding: null,
      paymentCompleted: false,
      paystack_public_key: null,
      flutterwave_public_key: null,
      bank_transfer: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadFunding();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadFunding = () => {
    const userToken = this.props.userToken;
    const { fundingId } = this.props.match.params;
    const url = "/users/funding/" + fundingId;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const funding = response.data.data.funding;
        const paystack_public_key = response.data.data.paystack_public_key;
        const flutterwave_public_key =
          response.data.data.flutterwave_public_key;
        const bank_transfer = response.data.data.bank_transfer;
        this.setState({
          loading: false,
          funding: funding,
          paystack_public_key: paystack_public_key,
          flutterwave_public_key: flutterwave_public_key,
          bank_transfer: bank_transfer,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  payWithPaystack = () => {
    let total = this.state.funding.amount;
    let flat_fee = 0;
    if (total >= 2500) {
      flat_fee = 100;
    }
    let paystack_charges = Math.ceil((total + flat_fee) / 0.985 - total);
    if (paystack_charges >= 2000) paystack_charges = 2000;
    const handler = window.PaystackPop.setup({
      key: this.state.paystack_public_key,
      email: this.props.user.email,
      amount: (total + paystack_charges) * 100,
      currency: this.props.user.country.currency.short,
      ref: `Funding-${this.state.funding._id}-${Date.now()}`,
      metadata: {
        custom_fields: [
          {
            display_name: "Full Name",
            variable_name: "name",
            value: this.props.user.name,
          },
          {
            display_name: "Phone Number",
            variable_name: "mobile_number",
            value: this.props.user.phoneNumber,
          },
        ],
      },
      callback: (response) => {
        this.setState({
          paymentCompleted: true,
        });
      },
      onClose: () => {},
    });
    handler.openIframe();
  };

  payWithFlutterwave = () => {
    let total = this.state.funding.amount;
    window.FlutterwaveCheckout({
      public_key: this.state.flutterwave_public_key,
      tx_ref: `Funding-${this.state.funding._id}-${Date.now()}`,
      amount: total,
      currency: "NGN",
      country: "NG",
      customer: {
        email: this.props.user.email,
        phone_number: this.props.user.phoneNumber,
        name: this.props.user.name,
      },
      callback: (data) => {
        this.setState({
          paymentCompleted: true,
        });
      },
      onclose: () => {},
    });
  };

  render() {
    let content = null;
    if (this.state.loading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className={classes.Loading}>
          <Error refresh={this.loadFunding} error={this.state.error} />
        </div>
      );
    } else if (this.state.funding) {
      content = (
        <div className={classes.FundingContainer}>
          <div className={classes.Amount}>
            <p>Funding Amount:</p>
            <h4 className="mb-0">
              {this.props.user.country.currency.symbol}{" "}
              {this.state.funding.amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h4>
            {this.state.funding.is_activated ? (
              <p className="badge badge-success fw-500 f-12">
                PAYMENT APPROVED
              </p>
            ) : (
              <p className="badge badge-danger fw-500 f-12">AWAITING PAYMENT</p>
            )}
          </div>
          {!this.state.funding.is_activated ? (
            <FundPayment
              refresh={this.loadFunding}
              paystack={this.payWithPaystack}
              flutterwave={this.payWithFlutterwave}
              bank_transfer={this.state.bank_transfer}
              funding={this.state.funding}
            />
          ) : (
            <div className={classes.PaymentOption}>
              <p>Payment Method:</p>
              <h6>{this.state.funding.payment_method}</h6>
            </div>
          )}
        </div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>View Funding</h4>
                </TitleHeader>
              </Title>
            </PageContent>
            <div className={classes.ViewFunding}>{content}</div>
            {this.state.paymentCompleted ? (
              <PaymentCompletedModal
                description="Thank you for funding your wallet. You can use your funds to shop, buy airtime and data or even withdraw the funds to any bank account of your choice."
                refresh={this.loadFunding}
              />
            ) : null}
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FundWallet));
