/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./VerifyEmail.module.css";
import axios from "../../../axios/axios-instance";
import Form from "../../../components/UI/Forms/Form";
import {
  createButtonElement,
  createInputElement,
  createOtpInputElement,
} from "../../../components/UI/Forms/utility/form-creators";
import * as validators from "../../../components/UI/Forms/utility/validator";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import RegFlowLayout from "../../../components/App/Layout/RegFlowLayout/RegFlowLayout";
import Header from "../../../components/App/Layout/Header/Header";
import { Helmet } from "react-helmet";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import OtpImage from "../../../assets/images/phone.png";
import { Link } from "react-router-dom";

class VerifyEmail extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      emailFormData: {
        email: createInputElement(
          "text",
          "",
          "Enter your new email address",
          this.props.user.email,
          this.validateEmail,
          "Please enter your new email address. You will have to verify this email address.",
          "fa fa-envelope",
          "col-sm-12",
          this.emailInputChangedHandler.bind(this, "email")
        ),
        submit: createButtonElement("submit", "UPDATE EMAIL", true),
      },
      formData: {
        token: createOtpInputElement(
          "text",
          null,
          null,
          "",
          this.validateTwoFactor,
          null,
          null,
          "col-7",
          this.inputChangedHandler.bind(this, "token"),
          10
        ),
        submit: createButtonElement("submit", "CONTINUE", true, "col-5"),
      },
      sendingEmail: false,
      change_email: false,
      error: null,
      result: null,
      otpId: null,
      submitting: false,
      submittingError: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.sendEmail();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  resendEmail = (event) => {
    event.preventDefault();
    this.sendEmail(true);
  };

  sendEmail = (is_forced = false) => {
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: "/users/otp/email/send",
      data: {
        email: this.props.user.email,
        is_forced: is_forced,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const otpId = response.data.data.otp_id;
        this.setState({
          sendingEmail: false,
          otpId: otpId,
          result: true,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              sendingEmail: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              sendingEmail: false,
              error: data.error,
            });
          }
        } else {
          this.setState({
            sendingEmail: false,
            error:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      sendingEmail: true,
      submitting: false,
      submittingError: null,
      error: null,
      result: null,
    });
  };

  submit = (otp) => {
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: "/users/otp/email/verify",
      data: {
        email: this.props.user.email,
        otp: otp,
        otpId: this.state.otpId,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const data = response.data.data;
        const user = data.user;
        this.props.processUser(user);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              submittingError: data.error[0].msg,
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error,
            });
          }
        } else {
          this.setState({
            submitting: false,
            submittingError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      submitting: true,
      submittingError: null,
    });
  };

  validateTwoFactor = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid verification code!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Verification Code. Only Numbers allowed";
    }
    if (!validators.validateLength(value.trim(), 6, 10)) {
      return "Verification Code must contain atleast six(6) digits";
    }
    return null;
  };

  validateEmail(value) {
    if (validators.isEmpty(value)) {
      return "Please enter a valid email address!";
    }
    if (!validators.checkEmail(value)) {
      return "Enter valid email address.";
    }
    return null;
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData
    };
    updatedFormData[name] = formElementData;
    this.setState({
      formData: updatedFormData,
    });
  };

  emailInputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.emailFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.emailFormData
    };
    updatedFormData[name] = formElementData;
    this.setState({ emailFormData: updatedFormData });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  checkIsValidEmailFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.emailFormData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      emailFormData: formData,
    });
    return isFormActivated;
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.checkIsValidFormData()) {
      this.submit(this.state.formData.token.value);
    }
    return false;
  };

  updateEmailHandler = (event) => {
    event.preventDefault();
    if (this.checkIsValidEmailFormData()) {
      this.updateEmail();
    }
    return false;
  };

  updateEmail = () => {
    const email = this.state.emailFormData.email.value;
    const userToken = this.props.userToken;
    axios({
      method: "put",
      url: "/users/otp/email",
      data: {
        email: email,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.props.processUser(user);
        this.setState(
          {
            submitting: false,
            submittingError: null,
            change_email: false,
          },
          () => this.sendEmail(true)
        );
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              submittingError:
                "There is an error in the form. Please check your entries, correct the errors and try again",
              emailFormData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            submitting: false,
            submittingError: errorMsg,
          });
        }
      });
    this.setState({
      submitting: true,
      submittingError: null,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.emailFormData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.emailFormData,
      ...errorForm,
    };
  }

  render() {
    let content = null;

    if (this.state.sendingEmail) {
      content = (
        <div className="text-center px-2">
          <OfficeSpinner size="2" />
          <p className="f-12 mt-2">
            Sending Two Factor Code to <strong>{this.props.user.email}</strong>
            ...
          </p>
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className="my-4 text-center">
          <Error refresh={this.sendEmail} error={this.state.error} />
        </div>
      );
    } else if (this.state.change_email) {
      content = (
        <div
          className={`${classes.OtpContainer} d-flex flex-column align-items-center`}
        >
          <div className="text-center mb-2">
            <h5 className="mb-2">Change Your Email Address</h5>
            <p>
              Simply enter your new email address below. Your two factor code
              will be sent to this new email address.
            </p>
          </div>
          {this.state.submittingError ? (
            <AlertError error={this.state.submittingError} />
          ) : null}
          <div className="mb-2">
            <Form
              loading={this.state.submitting}
              formData={this.state.emailFormData}
              submit={this.updateEmailHandler}
            />
          </div>
          <div className={classes.Resend}>
            <p>
              <button
                onClick={() =>
                  this.setState({ change_email: false, submittingError: null })
                }
                className="btn btn-warning btn-block"
              >
                <i className="fas fa-chevron-left"></i> CANCEL
              </button>
            </p>
          </div>
        </div>
      );
    } else if (this.state.result) {
      content = (
        <div
          className={`${classes.OtpContainer} d-flex flex-column align-items-center`}
        >
          <div className="text-center mb-2">
            <h5 className="mb-2">Verify Your Email Address</h5>
            <p>
              To continue using Hawkit, please verify your email address by
              entering the two factor code sent to your registered email
              address: <strong>{this.props.user.email}</strong>.{" "}
            </p>
            <p className="text-primary f-10">
              Please check your spam or promotions folder if you cannot find the mail in your
              inbox. You can also click the Resend Button below to get the mail
              again.
            </p>
          </div>
          <div className={classes.OtpForm}>
            <div className={classes.OtpImage}>
              <img src={OtpImage} alt="Otp_image" />
            </div>
            <div className={classes.OtpFormContainer}>
              <p>ENTER TWO FACTOR CODE:</p>
              <div style={{ width: "80%" }}>
                {this.state.submittingError ? (
                  <AlertError error={this.state.submittingError} />
                ) : null}
              </div>
              <Form
                loading={this.state.submitting}
                formData={this.state.formData}
                submit={this.submitHandler}
              />
            </div>
          </div>
          <div className={classes.Resend}>
            <p>
              Didn't receive the email? <br />
            </p>
            <p>
              <a
                href="/"
                onClick={this.resendEmail}
                className={classes.ResendLink}
              >
                Click to Resend Email
              </a>
            </p>
            <p className="my-1 fw-600">-OR-</p>
            <p>
              <button
                onClick={() =>
                  this.setState({ change_email: true, submittingError: null })
                }
                className="btn btn-coloured-heavy"
              >
                <i className="fas fa-chevron-left"></i> Change Email Address
              </button>
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="w-100">
        <div className={classes.Header}>
          <Header submit={null} />
        </div>
        <RegFlowLayout noTitle with_logout noSkip title="Welcome to Hawkit!">
          <div className={classes.VerifyEmail}>
            <Helmet>
              <title>Verify Your Email Address</title>
            </Helmet>
            {content}
          </div>
        </RegFlowLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    country_states: state.userState.country_states,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
