import React from "react";
import MobileFooter from "../Footer/MobileFooter/MobileFooter";
import classes from "./RegFlowLayout.module.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const RegFlowLayout = (props) => {
  return (
    <div className={classes.RegFlowLayoutContainer}>
      <div className="container">
        <div className="row">
          <div className="offset-md-3 offset-lg-4 offset-sm-2 col-md-6 col-lg-4 col-sm-8">
            <div className={classes.RegFlowLayout}>
              <div className={classes.RegFlowHeader}>
                <h4>{props.title}</h4>
                {!props.noSkip ? (
                  <button
                    onClick={props.skip}
                    className="btn btn-coloured-heavy btn-rounded"
                    disabled={!props.isSkipEnabled ? true : false}
                  >
                    Skip <i className="fa fa-chevron-right"></i>
                  </button>
                ) : null}
                {props.with_logout ? (
                  <Link to="/logout" className="ml-auto f-10">
                    <i className="fa fa-power-off"></i> Logout
                  </Link>
                ) : null}
              </div>
              <div className={classes.RegFlowContent}>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.MobileFooter}>
        <MobileFooter user={props.user} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps, null)(RegFlowLayout);
