/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import classes from "./SideColumn.module.css";
import affix from "../../../../../hooks/affix-hook";
import { connect } from "react-redux";

const SideColumn = (props) => {
  const sideRef = useRef(null);
  useEffect(() => affix(sideRef.current, props.user), [props.children]);
  return (
    <div
      ref={sideRef}
      className={`${!props.showMobile ? "d-none d-md-flex" : ""} ${
        classes.SideColumn
      }`}
    >
      {props.children}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(SideColumn);
