/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./SignUp.module.css";
import * as validators from "../../../components/UI/Forms/utility/validator";
import Form from "../../../components/UI/Forms/Form";
import {
  createInputElement,
  createButtonElement,
  createPhoneNumberInputElement,
} from "../../../components/UI/Forms/utility/form-creators";
import LogoImage from "../../../components/UI/Images/LogoImage/LogoImage";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "../../../axios/axios-instance";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import * as actionCreators from "../../../store/actions/actions";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";
import OtpModal from "../OtpModal/OtpModal";

class SignUp extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        username: createInputElement(
          "text",
          null,
          "Enter Your Username",
          "",
          this.validateUsername,
          "Your username is also your handle. Make sure you create something unique and interesting.",
          "fa fa-user",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "username")
        ),
        email: createInputElement(
          "email",
          null,
          "Enter Your Email",
          "",
          this.validateEmail,
          null,
          "fa fa-envelope",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "email")
        ),
        password: createInputElement(
          "password",
          null,
          "Password",
          "",
          this.validatePassword,
          "Password must contain atleast 6 Characters",
          "fa fa-lock",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "password")
        ),
        submit: createButtonElement("submit", "CREATE ACCOUNT", true),
      },
      processing: false,
      show_otp_modal: false,
      otpId: null,
      formError: null,
    };
  }

  validateUsername(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter your username!";
    }
    if (!validators.isLetterOrNumber(value.trim())) {
      return "Invalid Username. No special characters and spaces allowed.";
    }
    if (!validators.validateLength(value.trim(), 4, 20)) {
      return "Username should contain 4 - 20 characters without spaces";
    }
    return null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validatePassword(value) {
    if (validators.isEmpty(value)) {
      return "Please enter a password!";
    }
    if (!validators.checkPassword(value)) {
      return "Password must be 6 - 20 characters";
    }
    return null;
  }

  validateEmail(value) {
    if (validators.isEmpty(value)) {
      return "Please enter a valid email address!";
    }
    if (!validators.checkEmail(value)) {
      return "Enter valid email address.";
    }
    return null;
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({
      formData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  processHandler = (otpId) => {
    this.setState(
      {
        show_otp_modal: false,
        otpId: otpId,
      },
      this.registerHandler
    );
  };

  destroyHandler = () => {
    this.setState({
      show_otp_modal: false,
      formError: "You must confirm your email address before you can continue",
      processing: false,
    });
  };

  verifyRegisterOtp = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const username = this.state.formData.username.value;
    const email = this.state.formData.email.value;
    const password = this.state.formData.password.value;
    axios({
      method: "post",
      url: "/auth/signup/otp/verify",
      data: {
        name: this.props.completeSignUp.name,
        referrer: this.props.completeSignUp.referrer,
        username: username,
        email: email,
        password: password,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const data = response.data.data;
        if (data.status === "success") {
          this.setState({
            show_otp_modal: true,
          });
        }
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            const formDataError = this.createFormError(data.error);
            if (formDataError) {
              this.setState({
                processing: false,
                formData: formDataError,
              });
            } else {
              this.setState({
                processing: false,
                formError: data.error[0].msg,
              });
            }
          } else {
            this.setState({
              processing: false,
              formError: data.error,
            });
          }
        } else {
          this.setState({
            processing: false,
            formError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      processing: true,
      formError: null,
    });
    return false;
  };

  registerHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const username = this.state.formData.username.value;
    const email = this.state.formData.email.value;
    const password = this.state.formData.password.value;
    axios({
      method: "post",
      url: "/auth/signup/",
      data: {
        name: this.props.completeSignUp.name,
        referrer: this.props.completeSignUp.referrer,
        username: username,
        email: email,
        password: password
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        this.setState({
          processing: false,
        });
        if (window.fbq) {
          window.fbq("track", "Lead", {
            currency: "NGN",
            content_category: "Registration",
            content_name: "Hawkit_New_Registration",
            value: 1000,
          });
        }
        const userToken = response.data.data.auth;
        this.props.login(userToken);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            const formDataError = this.createFormError(data.error);
            if (formDataError) {
              this.setState({
                processing: false,
                formData: formDataError,
              });
            } else {
              this.setState({
                processing: false,
                formError: data.error[0].msg,
              });
            }
          } else {
            this.setState({
              processing: false,
              formError: data.error,
            });
          }
        } else {
          this.setState({
            processing: false,
            formError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      processing: true,
      formError: null,
    });
    return false;
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  render() {
    let content = <Redirect to="/auth/register" />;
    if (this.props.completeSignUp) {
      content = (
        <div className={classes.Register}>
          <div className={classes.RegisterContent}>
            <div className={classes.RegisterForm}>
              <h3 className="mb-1">Almost Done!</h3>
              <p className="mb-4">Simply create a username and password</p>
              <div
                className={`text-center d-flex flex-column align-items-center`}
              >
                {this.state.formError ? (
                  <AlertError error={this.state.formError} />
                ) : null}
                <Form
                  loading={this.state.processing}
                  formData={this.state.formData}
                  submit={this.registerHandler}
                />
                <p className={classes.TermText}>
                  By signing up, you agree to our <br />
                  <BrowserLinks to="https://hawkit.ng/about/privacy-policy">
                    Terms and Privacy Policy
                  </BrowserLinks>
                </p>
                <div className={classes.Login}>
                  <p>Already have an account on Hawkit?</p>
                  <p>
                    <Link
                      replace
                      className="btn btn-primary btn-xl btn-block"
                      to="/auth/login"
                    >
                      Log In Now
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            {this.state.show_otp_modal ? (
              <OtpModal
                destroy={this.destroyHandler}
                process={this.processHandler}
                email={this.state.formData.email.value}
                title="Confirm Email Address"
              />
            ) : null}
          </div>
        </div>
      );
    }
    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    completeSignUp: state.authState.completeSignUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (userToken) => {
      dispatch(actionCreators.newUserLogin(userToken));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
