/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component } from "react";
import {
  createOtpInputElement,
  createButtonElement
} from "../../../components/UI/Forms/utility/form-creators";
import * as validators from "../../../components/UI/Forms/utility/validator";
import Form from "../../../components/UI/Forms/Form";
import classes from "./OtpModal.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import OtpImage from "../../../assets/images/phone.png";

class OtpModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        token: createOtpInputElement(
          "text",
          null,
          null,
          "",
          this.validateTwoFactor,
          null,
          null,
          "col-7",
          this.inputChangedHandler.bind(this, "token"),
          10
        ),
        submit: createButtonElement("submit", "CONTINUE", true, "col-5")
      },
      sendingEmail: false,
      error: null,
      result: null,
      otpId: null,
      submitting: false,
      submittingError: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.$("#OtpModal").modal({
      backdrop: "static"
    });
    this.sendEmail();
    window.$("#OtpModal").modal("show");
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$(".modal-backdrop").remove();
    window.$("body").removeClass("modal-open");
  }

  resendEmail = event => {
    event.preventDefault();
    this.sendEmail();
  };

  sendEmail = () => {
    axios({
      method: "post",
      url: "/auth/otp/email/send",
      data: {
        email: this.props.email
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const otpId = response.data.data.otp_id;
        this.setState({
          sendingEmail: false,
          otpId: otpId,
          result: true
        });
      })
      .catch(error => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              sendingEmail: false,
              error: data.error[0].msg
            });
          } else {
            this.setState({
              sendingEmail: false,
              error: data.error
            });
          }
        } else {
          this.setState({
            sendingEmail: false,
            error:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message
          });
        }
      });
    this.setState({
      sendingEmail: true,
      submitting: false,
      submittingError: null,
      error: null,
      result: null
    });
  };

  submit = otp => {
    axios({
      method: "post",
      url: "/auth/otp/email/verify",
      data: {
        email: this.props.email,
        otp: otp,
        otpId: this.state.otpId
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const data = response.data.data;
        if (data.status === "success") {
          this.processHandler();
        }
      })
      .catch(error => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              submittingError: data.error[0].msg
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error
            });
          }
        } else {
          this.setState({
            submitting: false,
            submittingError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message
          });
        }
      });
    this.setState({
      submitting: true,
      submittingError: null
    });
  };

  validateTwoFactor = value => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid verification code!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Verification Code. Only Numbers allowed";
    }
    if (!validators.validateLength(value.trim(), 6, 10)) {
      return "Verification Code must contain atleast six(6) digits";
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData
    };
    this.setState({
      formData: updatedFormData
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData
    });
    return isFormActivated;
  }

  submitHandler = event => {
    event.preventDefault();
    if (this.checkIsValidFormData()) {
      this.submit(this.state.formData.token.value);
    }
    return false;
  };

  closeHandler = event => {
    event.preventDefault();
    window.$("#OtpModal").off("hidden.bs.modal");
    window.$("#OtpModal").on("hidden.bs.modal", event => {
      this.props.destroy();
    });
    window.$("#OtpModal").modal("hide");
  };

  processHandler = () => {
    window.$("#OtpModal").off("hidden.bs.modal");
    window.$("#OtpModal").on("hidden.bs.modal", event => {
      this.props.process(this.state.otpId);
    });
    window.$("#OtpModal").modal("hide");
  };

  render() {
    let content = null;

    if (this.state.sendingEmail) {
      content = (
        <div className="text-center px-2">
          <OfficeSpinner size="2" />
          <p className="f-12 mt-2">
            Sending Two Factor Code to <strong>{this.props.email}</strong>...
          </p>
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className="my-4 text-center">
          <Error refresh={this.sendEmail} error={this.state.error} />
        </div>
      );
    } else if (this.state.result) {
      content = (
        <div
          className={`${classes.OtpContainer} d-flex flex-column align-items-center`}
        >
          <div className="text-center mb-2">
            <h5 className="mb-2">{this.props.title}</h5>
            <p>
              To continue with your request, please enter the two factor code
              sent to your registered email address:{" "}
              <strong>{this.props.email}</strong>
            </p>
          </div>
          <div className={classes.OtpForm}>
            <div className={classes.OtpImage}>
              <img src={OtpImage} alt="Otp_image" />
            </div>
            <div className={classes.OtpFormContainer}>
              <p>ENTER TWO FACTOR CODE:</p>
              <div style={{ width: "80%" }}>
                {this.state.submittingError ? (
                  <AlertError error={this.state.submittingError} />
                ) : null}
              </div>
              <Form
                loading={this.state.submitting}
                formData={this.state.formData}
                submit={this.submitHandler}
              />
            </div>
          </div>
          <div className={classes.Resend}>
            <p>
              Didn't receive the email? <br />
            </p>
            <a
              href="/"
              onClick={this.resendEmail}
              className={classes.ResendLink}
            >
              Click to Resend Email
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.OtpModal}>
        <div
          id="OtpModal"
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby=""
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`${classes.OtpModalContent} modal-content`}>
              <div
                id="OtpModalBody"
                className={`${classes.OtpModalBody} modal-body`}
              >
                <button
                  onClick={this.closeHandler}
                  className={classes.CloseButton}
                >
                  <span className="fas fa-times"></span>
                </button>
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OtpModal;
