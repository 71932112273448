/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classes from "./UserFollower.module.css";
import { abbreviateNumber } from "../../../../util/util";
// import HashLinks from "../../../UI/Links/HashLinks/HashLinks";

const UserFollower = (props) => {
  const followers = abbreviateNumber(props.user.followers);
  const following = abbreviateNumber(props.user.following);
  const referrals = abbreviateNumber(props.user.referrals);

  const showReferrals = (event) => {
    event.preventDefault();
    props.setUserModal({
      title: "My Referrals",
      count: referrals,
      username: props.username,
      link: "referrals",
    });
  };

  const showFollower = (event) => {
    event.preventDefault();
    props.setUserModal({
      title: "Followers",
      count: followers,
      username: props.username,
      link: "followers",
    });
  };

  const showFollowing = (event) => {
    event.preventDefault();
    props.setUserModal({
      title: "Following",
      count: following,
      username: props.username,
      link: "following",
    });
  };

  return (
    <div className={classes.UserFollower}>
      <a className={classes.ContentLink} onClick={showReferrals} href="/">
        <div className={classes.Content}>
          <h4>{referrals}</h4>
          <p>Referrals</p>
        </div>
      </a>
      <a className={classes.ContentLink} onClick={showFollower} href="/">
        <div className={classes.Content}>
          <h4>{followers}</h4>
          <p>Followers</p>
        </div>
      </a>
      <a className={classes.ContentLink} onClick={showFollowing} href="/">
        <div className={classes.Content}>
          <h4>{following}</h4>
          <p>Following</p>
        </div>
      </a>
    </div>
  );
};

export default UserFollower;
