import React from "react";
import classes from "./ProfileDropdown.module.css";
import { Link } from "react-router-dom";
import NavigationButton from "../../Buttons/NavigationButtons/NavigationButton";

const profileDropdown = (props) => {
  return (
    <div
      className={`${classes.ProfileDropdown} dropdown-menu shadow dropdown-menu-right`}
    >
      <div className={classes.Profile}>
        <div className={classes.ProfileLinks}>
          <div className={classes.Link}>
            <Link to={`/users/${props.username}`}>My Profile</Link>
          </div>
          <div className={classes.Link}>
            <Link to="/settings">Account Settings</Link>
          </div>
          <div className={classes.Link}>
            <Link to="/settings/profile">Change Profile Picture</Link>
          </div>
          <div className={classes.Link}>
            <Link to="/settings/password">Change Password</Link>
          </div>
        </div>
        <div className={classes.ProfileHeading}>
          <p>
            <NavigationButton
              to="/logout"
              btnClasses="btn btn-danger btn-xs btn-block"
            >
              <span className="fa fa-power-off" /> LOGOUT
            </NavigationButton>
          </p>
        </div>
      </div>
    </div>
  );
};
export default profileDropdown;
