import React from "react";
import classes from "./Checkbox.module.css";

const checkbox = (props) => {
  const inputClasses = ["form-check-input"];
  if (props.invalid) {
    inputClasses.push("is-invalid");
  }
  let hint = null;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    hint = <div className="invalid-feedback">{props.invalid}</div>;
  }
  let checkboxElement = (
    <React.Fragment>
      <input
        type={props.type ? props.type : "text"}
        className={inputClasses.join(" ")}
        onChange={props.changeHandler}
        disabled={props.disabled}
      />
      {hint}
    </React.Fragment>
  );
  return (
    <div className="form-group">
      <div className={`${classes.Checkbox} form-check`}>
        <label className="form-check-label">
          {checkboxElement}
          {props.label}
        </label>
      </div>
    </div>
  );
};

export default checkbox;
