import React, { useState } from "react";
import Post from "./Post";
import classes from "./Post.module.css";
import PostModal from "./PostModal/PostModal";
import PostContext from "../../../context/post-context";
import DeleteModal from "./DeleteModal/DeleteModal";
import ShareModal from "./ShareModal/ShareModal";
import ReviewsModal from "../Products/ReviewsModal/ReviewsModal";
import ProductDescriptionModal from "./ProductDescriptionModal/ProductDescriptionModal";
import AirtimeBuyModal from "./AirtimeBuyModal/AirtimeBuyModal";
import ResellModal from "../Products/ResellModal/ResellModal";
import { withRouter } from "react-router";

const Posts = (props) => {
  const [activePost, setActivePost] = useState(null);
  const [buyPost, setBuyPost] = useState(null);
  const [reviewPost, setReviewPost] = useState(null);
  const [resellPost, setResellPost] = useState(null);
  const [descriptionPost, setDescriptionPost] = useState(null);

  const shareHandler = (id) => {
    setActivePost(id);
  };

  const buyHandler = (post) => {
    setBuyPost(post);
  };

  const reviewHandler = (post) => {
    setReviewPost(post);
  };

  const resellHandler = (post) => {
    setResellPost(post);
  };

  const descriptionHandler = (post) => {
    setDescriptionPost(post);
  };

  let postContent = props.posts.map((post) => {
    return (
      <Post
        shareHandler={() => shareHandler(post._id)}
        buyHandler={() => buyHandler(post)}
        reviewHandler={(event) => {
          event.preventDefault();
          reviewHandler(post);
        }}
        resellHandler={(event) => {
          resellHandler(post);
        }}
        showImage={() => {
          props.history.push(`/posts/${post._id}`);
        }}
        descriptionHandler={() => descriptionHandler(post)}
        key={post._id}
        post={post}
      />
    );
  });

  let buyPostContent = null;

  if (buyPost) {
    buyPostContent = (
      <AirtimeBuyModal post={buyPost} destroy={() => setBuyPost(null)} />
    );
  }

  return (
    <PostContext.Consumer>
      {(context) => {
        return (
          <div id="postContent" className={classes.Posts}>
            {postContent}
            {context.postModal ? (
              <PostModal
                activeUser={context.user}
                destroy={() => context.setPostModal(null)}
                context={context}
              />
            ) : null}
            {context.deleteModal && context.user ? (
              <DeleteModal
                destroy={() => context.setDeleteModal(null)}
                context={context}
              />
            ) : null}
            {activePost ? (
              <ShareModal
                postId={activePost}
                destroy={() => setActivePost(null)}
              />
            ) : null}
            {buyPostContent}
            {reviewPost ? (
              <ReviewsModal
                product={reviewPost.product}
                destroy={() => setReviewPost(null)}
              />
            ) : null}
            {resellPost ? (
              <ResellModal
                product={resellPost.product}
                destroy={() => setResellPost(null)}
              />
            ) : null}
            {descriptionPost ? (
              <ProductDescriptionModal
                post={descriptionPost}
                destroy={() => setDescriptionPost(null)}
              />
            ) : null}
          </div>
        );
      }}
    </PostContext.Consumer>
  );
};

export default withRouter(Posts);
