import axios from "axios";
import store from "../store/store";
import * as actionCreators from "../store/actions/actions";
import { getBaseUrl, getClientId } from "../util/util";

const instance = axios.create({
  baseURL: getBaseUrl(false),
  onUploadProgress: function (progressEvent) {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    store.dispatch(actionCreators.setLoaderProgress(percentCompleted));
  },
  headers: {
    "X-Client-Hash": "1a26257c5fb5e4c7edc048035704ca0a",
    "X-Client-Id": getClientId(),
    "X-Client-Version": window.VERSION
  }
});

instance.interceptors.request.use(
  request => {
    store.dispatch(actionCreators.setLoaderProgress(0));
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  response => {
    store.dispatch(actionCreators.setLoaderProgress(0));
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
