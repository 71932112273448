/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import classes from "./CommentInput.module.css";

const input = props => {
  let extended = null;
  if (props.invalid) {
    extended = <div className="d-block invalid-feedback">{props.invalid}</div>;
  } else if (props.submitted) {
    extended = (
      <small className="text-success form-text text-muted">
        Comment added successfully!
      </small>
    );
  }
  return (
    <div className={classes.CommentInput}>
      <div className={`${classes.CommentInputGroup} input-group`}>
        <input
          id={props.id}
          type="text"
          className="form-control"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.changeHandler}
        />
        <div className="input-group-append">
          <button type="submit" className={classes.SendButton}>
            {props.submitting ? (
              <span className="fas fa-spin fa-spinner"></span>
            ) : (
              <span className="fas fa-location-circle"></span>
            )}
          </button>
        </div>
      </div>
      {extended}
    </div>
  );
};

export default input;
