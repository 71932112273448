import * as actionTypes from "./actionTypes";

export const setLoaderStatus = status => {
  return {
    type: actionTypes.SET_LOADER_STATUS,
    status: status
  };
};

export const setLoaderStatusMessage = statusMsg => {
  return {
    type: actionTypes.SET_LOADER_STATUS_MSG,
    statusMsg: statusMsg
  };
};

export const setLoaderProgress = progress => {
  return {
    type: actionTypes.SET_LOADER_PROGRESS,
    progress: progress
  };
};

export const dispatchError = error => {
  return dispatch => {
    dispatch(setLoaderStatus("ERROR"));
    dispatch(setLoaderStatusMessage(error));
  };
};

export const dispatchLoading = msg => {
  return dispatch => {
    dispatch(setLoaderStatus("LOADING"));
    dispatch(setLoaderStatusMessage(msg));
  };
};

export const clearLoading = () => {
  return dispatch => {
    dispatch(setLoaderStatus(null));
    dispatch(setLoaderStatusMessage(null));
  };
};
