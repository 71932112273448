import React from "react";
import classes from "./RippleCircle.module.css";

const RippleCircle = (props) => {
  return (
    <div
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
        top: `${props.top}px`,
        right: `${props.right}px`,
      }}
      className={classes.RippleCircle}
    >
      <div className={classes.RippleCircleComp}></div>
    </div>
  );
};

export default RippleCircle;
