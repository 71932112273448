/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import { connect } from "react-redux";
import classes from "./AllEarningTasks.module.css";
import { Link } from "react-router-dom";
import EarnTask from "../EarnTask/EarnTask";
import * as actionCreators from "../../../../store/actions/actions";
import { toFirstLetterUppercase } from "../../../../util/util";
import ConfirmModal from "../../../../components/UI/Modal/ConfirmModal/ConfirmModal";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";

class AllEarningTasks extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      tasks: [],
      generated: null,
      category: null,
      confirm_modal: null,
    };
  }

  hideConfirm = () => {
    this.setState({
      confirm_modal: null,
    });
  };

  showConfirm = () => {
    this.setState({
      confirm_modal: true,
    });
  };

  loadTasks = (isRefresh = true) => {
    let page = 1;
    if (!isRefresh && this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    const { status } = this.props.match.params;
    const { category } = this.props.match.params;
    axios({
      method: "get",
      url: `/social/tasks/${category}/${status}?page=${page}`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const tasks = response.data.data.tasks;
        const category = response.data.data.category;
        const filteredTasks = tasks.filter((el) => {
          return !this.state.tasks.find((task) => task._id === el._id);
        });
        const updatedTasks = [...this.state.tasks, ...filteredTasks];
        const pages = response.data.data.pages;
        this.setState({
          loading: false,
          pages: pages,
          tasks: updatedTasks,
          category: category,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
    if (isRefresh) {
      this.setState({
        tasks: [],
      });
    }
  };

  generateTaskHandler = () => {
    const userToken = this.props.userToken;
    const { category } = this.props.match.params;
    axios({
      method: "get",
      url: `/social/tasks/${category}/generate-task`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const tasks = response.data.data.tasks;
        const filteredTasks = tasks.filter((el) => {
          return !this.state.tasks.find((task) => task._id === el._id);
        });
        const updatedTasks = [...this.state.tasks, ...filteredTasks];
        const pages = response.data.data.pages;
        this.setState({
          loading: false,
          pages: pages,
          tasks: updatedTasks,
          generated: true,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      confirm_modal: null,
      generated: null,
    });
  };

  loadMoreTasks = (event) => {
    event.preventDefault();
    this.loadTasks(false);
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadTasks(true);
  }

  componentDidUpdate(prevProps) {
    const prevStatus = prevProps.match.params.status;
    const { status } = this.props.match.params;
    if (prevStatus && status && prevStatus !== status) {
      this.loadTasks(true);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.Loading}>
          <a onClick={this.loadMoreTasks} href="/">
            Load More <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.Loading}>
          <Error refresh={this.loadMoreTasks} error={this.state.error} />
        </div>
      );
    }
    let content = null;
    const { status } = this.props.match.params;
    if (this.state.tasks.length) {
      const tasks = this.state.tasks.map((task, index) => {
        return (
          <div key={index} className={classes.EarnTask}>
            <EarnTask updateTask={this.loadTasks} task={task} />
          </div>
        );
      });
      content = (
        <div className={classes.AllEarningTasks}>
          {tasks}
          {status === "pending" ? (
            <div className={classes.PendingInfo}>
              <p className="f-10">
                Only one task is generated per time. You have to do the pending
                task before it expires so that another one can be generated for
                you.
              </p>
            </div>
          ) : null}
        </div>
      );
    } else if (
      !this.state.loading &&
      !this.state.error &&
      this.state.category
    ) {
      const categoryType = toFirstLetterUppercase(this.state.category.type);
      const user_social = this.state.category.user_social;
      content = (
        <div className={classes.TasksEmpty}>
          <i className="fal fa-spin fa-spinner"></i>
          <p className="mb-2 f-10">
            You do not have any {categoryType} {status.toUpperCase()} social
            task yet. Your {categoryType} {status.toUpperCase()} tasks will
            appear on this page.{" "}
          </p>
          <Link to="/earn" className="btn btn-coloured-heavy">
            SEE WHAT YOU WILL BE EARNING PER TASK
          </Link>
        </div>
      );
      if (status === "pending") {
        if (
          (user_social && user_social.status === "VERIFIED") ||
          !this.state.category.is_required
        ) {
          content = (
            <div className={classes.TasksEmpty}>
              {this.state.confirm_modal ? (
                <ConfirmModal
                  title={`Generate Next ${categoryType} Task?`}
                  description={`Are you sure you want to generate your next ${categoryType} task now. You have ${
                    this.state.category.type === "whatsapp" ? "2 hours" : "1 hour"
                  } to perform this task. Please confirm only if you are ready to perform the task. `}
                  confirm={this.generateTaskHandler}
                  cancel={this.hideConfirm}
                />
              ) : null}
              <i className="fal fa-spin fa-spinner"></i>
              {this.state.generated ? (
                <AlertError
                  error={`Ooops! There are no ${categoryType} tasks at the moment. You can keep trying until a task is available for you.`}
                />
              ) : null}
              <p className="mb-2 f-10">
                You have to click on the button below so that your next{" "}
                {categoryType} task will be generated for you. Only one task is
                generated per time. You have to do the generated task before
                another one can be generated for you.
              </p>
              <button
                onClick={this.showConfirm}
                className="btn btn-coloured-heavy"
              >
                GENERATE YOUR NEXT TASK
              </button>
            </div>
          );
        } else {
          content = (
            <div className={classes.TasksEmpty}>
              <i className="text-danger fas fa-times-circle"></i>
              <p className="mb-2 f-10">
                Your {categoryType} Profile is not yet verified. You need to
                verify your {categoryType} profile before you can generate your
                next task.
              </p>
            </div>
          );
        }
      }
    }

    return (
      <>
        {content}
        {loadingContent}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMeta: (meta) => dispatch(actionCreators.setMeta(meta)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEarningTasks);
