/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import classes from "./ChatInput.module.css";

const ChatInput = (props) => {
  const textArea = useRef(null);

  useEffect(() => {
    if (props.value.length === 0) {
      textArea.current.style.height = "0";
    } else {      
      textArea.current.style.height = textArea.current.scrollHeight + "px";
    }
  });

  let extended = null;
  if (props.invalid) {
    extended = <div className="mt-2 d-block invalid-feedback">{props.invalid}</div>;
  }

  return (
    <div className={classes.ChatInput}>
      <div className={classes.ChatGroup}>
        <textarea
          ref={textArea}
          // rows="3"
          className="form-control"
          placeholder={props.placeholder}
          onChange={props.changeHandler}
          value={props.value}
          id={props.id}
        />
        <button type="submit">
          {props.submitting ? (
            <span className="fas fa-spin fa-spinner"></span>
          ) : (
            <span className="fas fa-paper-plane"></span>
          )}
        </button>
      </div>
      {extended}
    </div>
  );
};

export default ChatInput;
