import React from "react";
import classes from "./PreviewVideos.module.css";
import PreviewVideo from "./PreviewVideo/PreviewVideo";
import UploadVideo from "../UploadVideo/UploadVideo";

const previewVideos = (props) => {  
  return (
    <div className={classes.PreviewVideo}>
      {props.previewVideo ? (
        <>
          <PreviewVideo
            removeVideo={props.removeVideo}
            setVideoSize={props.setVideoSize}
            video={props.previewVideo.video}
          />         
        </>
      ) : (
        <UploadVideo
          upload={props.upload}
          text={props.caption ? props.caption : "Upload Video"}
        />
      )}
    </div>
  );
};
export default previewVideos;
