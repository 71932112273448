import React, { useRef, useEffect } from "react";
import classes from "./ShowUser.module.css";
import FetchLink from "../../../../UI/FetchLink/FetchLink";
import UserPhoto from "../../UserPhoto/UserPhoto";
import { withRouter } from "react-router-dom";

const ShowUser = props => {
  const nameRef = useRef(null);
  const locationRef = useRef(null);
  const usernameRef = useRef(null);
  useEffect(() => {
    const titleWidth = document.getElementById("showUsers").offsetWidth - 105;
    const usernameWidth = usernameRef.current.offsetWidth;
    nameRef.current.style.width = titleWidth - usernameWidth - 5 + "px";
    locationRef.current.style.width = titleWidth - 5 + "px";
  }, []);
  let followContent = null;
  if (props.user.followers_count) {
    const urls = [
      {
        url: `/users/${props.user.username}/followers`,
        method: "delete"
      },
      {
        url: `/users/${props.user.username}/followers`,
        method: "post"
      }
    ];
    followContent = (
      <FetchLink title={["Unfollow", "Follow"]} url={urls}>
        <i className="fad fa-users"></i>
        <i className="fal fa-users"></i>
        <i className="fal fa-spin fa-spinner"></i>
      </FetchLink>
    );
  } else {
    const urls = [
      {
        url: `/users/${props.user.username}/followers`,
        method: "post"
      },
      {
        url: `/users/${props.user.username}/followers`,
        method: "delete"
      }
    ];
    followContent = (
      <FetchLink title={["Follow", "Unfollow"]} url={urls}>
        <i className="fal fa-users"></i>
        <i className="fad fa-users"></i>
        <i className="fal fa-spin fa-spinner"></i>
      </FetchLink>
    );
  }

  const showUserHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    props.history.push(`/users/${props.user.username}`);
  };

  const userAddress = [];

  if (props.user.location) {
    if (props.user.location.lga) {
      userAddress.push(props.user.location.lga);
    }
    if (props.user.location.state) {
      userAddress.push(props.user.location.state);
    }
    userAddress.push(props.user.country.name);
  }

  return (
    <div onClick={showUserHandler} className={classes.ShowUser}>
      <div className={classes.UserPhoto}>
        <UserPhoto photo={props.user.profile_pic} width="36" height="36" />
      </div>
      <div className={classes.UserContent}>
        <div className={classes.Title}>
          <div className={classes.MainTitle}>
            <a onClick={showUserHandler} ref={usernameRef} href="/">
              <h4>{props.user.username}</h4>
            </a>
            <p ref={nameRef}>
              <span className="fal fa-user"></span> {props.user.name}
            </p>
          </div>
          <div className={classes.Handle}>
            <p ref={locationRef}>
              <span className="fal fa-map-marker-alt"></span>{" "}
              {userAddress.length ? userAddress.join(", ") : ""}
            </p>
          </div>
        </div>
        {props.showFollow ? (
          <div className={classes.UserFollow}>{followContent}</div>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(ShowUser);
