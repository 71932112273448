import React from "react";
import classes from "./PurchaseOrder.module.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const moment = require("moment");

const PurchaseOrder = (props) => {
  const now = moment();
  const orderMoment = moment(props.order.created_on);
  const daysDiff = now.diff(orderMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = orderMoment.calendar();
  } else {
    formatDate = orderMoment.fromNow();
  }

  let orderStatus = null,
    actionRequired = 0;
  const sales_order = props.order.sales_orders[0];
  if (sales_order.status.buyer === window.ORDER_PAID) {
    orderStatus = (
      <div className={`${classes.Status} bg-success`}>
        <p>{sales_order.status.seller}</p>
      </div>
    );
  } else if (sales_order.status.buyer === window.ORDER_COMPLETED) {
    orderStatus = (
      <div className={`${classes.Status} bg-success`}>
        <p>ORDER COMPLETED</p>
      </div>
    );
  } else {
    orderStatus = (
      <div className={`${classes.Status} bg-warning text-dark`}>
        <p>{sales_order.status.buyer}</p>
      </div>
    );
  }

  let items = 0;
  items += sales_order.cart.length;
  if (sales_order.status.buyer === window.ORDER_AWAITING_PAYMENT) {
    actionRequired++;
  } else if (
    sales_order.status.seller === window.ORDER_DELIVERED &&
    sales_order.status.buyer === window.ORDER_PAID
  ) {
    actionRequired++;
  }

  return (
    <div className={classes.PurchaseOrder}>
      <div className={classes.PurchaseOrderHeader}>
        <div className={classes.Index}>{props.index}</div>
        <div className={classes.OrderHeadInfo}>
          <h5>
            Order ID:{" "}
            <Link to={`/orders/checkout/${props.order._id}`}>
              {props.order._id}
            </Link>
          </h5>
          <p>{formatDate}</p>
        </div>
      </div>
      <div className={classes.PurchaseOrderSummary}>
        <div className="table-responsive">
          <table className="table table-sm table-borderless">
            <thead className="thead-light">
              <tr>
                <th>Item(s)</th>
                <th>Total</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{items}</td>
                <td>{`${
                  props.user.country.currency.symbol
                } ${props.order.total_cost
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                <td>{orderStatus}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.OrderStatus}>
          <Link
            className="ml-2 btn btn-coloured-heavy btn-xs"
            to={`/orders/checkout/${props.order._id}`}
          >
            {!props.order.is_activated ? "MAKE PAYMENTS" : "TRACK THIS ORDER"}
          </Link>
          {actionRequired ? (
            <div
              className={`${classes.ActionRequired} ml-1 bg-danger text-white text-center blink rounded`}
            >
              <p>Action Required</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(PurchaseOrder));
