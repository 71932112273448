import React from "react";
import { Link } from "react-router-dom";
import classes from "./AppHomeFeatures.module.css";
import { connect } from "react-redux";
import socialIcon from "../../../../assets/images/social-icons.jpg";
import handIcon from "../../../../assets/images/hand.png";

const AppHomeFeatures = (props) => {
  const content = (
    <div className={classes.AppHomeFeaturesContainer}>
      <div className="row no-gutters">
        <div className="col-6">
          <div className={`${classes.AppHomeFeaturesContent} mx-3`}>
            <div className={classes.Image}>
              <img alt="social-icon" src={handIcon} />
            </div>
            <div className={classes.Text}>
              <p className="mb-2">
                Earn daily by posting adverts and performing tasks on your
                social media accounts
              </p>
              <p>
                <Link className="f-8" to="/earn">
                  <i className="fas fa-share"></i> Get Started
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className={`${classes.AppHomeFeaturesContent} mr-3`}>
            <div className={classes.Image}>
              <img alt="social-icon" src={socialIcon} />
            </div>
            <div className={classes.Text}>
              <p className="mb-2">
                Get people to repost your adverts and perform social tasks for
                you on their social media account.
              </p>
              <p>
                <Link className="f-8" to="/social/buy/pricing">
                  <i className="fas fa-share"></i> Get Started
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  let member_content = null;
  if (props.user.is_member) {
    member_content = (
      <div className={classes.MemberContent}>
        <div className={classes.Icon}>
          <i className="lni lni-user-check"></i>
        </div>
        <div className={classes.MemberDetail}>
          <h5>Congratulations! You are now a MEMBER!</h5>
          <p className="mb-1">
            Your membership has been activated successfully. You can now earn
            daily by posting adverts and performing tasks on your social media
            accounts. Click the button below to start earning by generating your
            next task. You can perform as many tasks as possible.
          </p>
          <p className="mb-2">
            <span className="badge badge-success">
              {props.meta.tasks_running} total tasks for you to perform
            </span>
          </p>
          <p>
            <Link to="/earn" className="btn btn-outline">
              PERFORM TASKS AND EARN
            </Link>
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      {member_content}
      {content}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
  };
};

export default connect(mapStateToProps, null)(AppHomeFeatures);
