import React from "react";
import classes from "./ShowUsers.module.css";
import ShowUser from "./ShowUser/ShowUser";

const ShowUsers = (props) => {
  const content = props.users.map((user) => (
    <ShowUser
      showFollow={
        props.activeUser &&
        user.username !== props.activeUser.username &&
        !props.nofollow
      }
      key={user._id}
      user={user}
    />
  ));

  return (
    <div
      style={props.nomargin ? { margin: 0 } : null}
      id="showUsers"
      className={classes.ShowUsers}
    >
      {content}
    </div>
  );
};

export default ShowUsers;
