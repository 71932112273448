/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import classes from "./PreviewImage.module.css";

const previewImage = props => {  
  let classArr = [classes.PreviewImage];
  if (props.active) {
    classArr.push(classes.Active);
  }
  const previewImageRef = useRef(null);
  useEffect(() => {
    if (props.active) {
      const offsetLeft =
        previewImageRef.current.getBoundingClientRect().left - 20;
      document.getElementById("PreviewImages").scrollLeft =
        offsetLeft + document.getElementById("PreviewImages").scrollLeft;
    }
  });

  return (
    <div
      onClick={props.clicked}
      ref={previewImageRef}
      className={classArr.join(" ")}
    >
      <img src={props.image} alt="" />
      <div onClick={props.removePhoto} title="Remove Photo">
        <span className="fa fa-times"></span>
      </div>
    </div>
  );
};
export default previewImage;
