/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import Error from "../../UI/Error/Error";
import OfficeSpinner from "../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import classes from "./PaymentWalletModal.module.css";

const PaymentWalletModal = (props) => {
  const successHandler = (event) => {
    event.preventDefault();
    window.$("#PaymentWalletModal").off("hidden.bs.modal");
    window.$("#PaymentWalletModal").on("hidden.bs.modal", (event) => {
      props.go_success();
    });
    window.$("#PaymentWalletModal").modal("hide");
  };

  const cancelHandler = (event) => {
    event.preventDefault();
    window.$("#PaymentWalletModal").off("hidden.bs.modal");
    window.$("#PaymentWalletModal").on("hidden.bs.modal", (event) => {
      props.cancel();
    });
    window.$("#PaymentWalletModal").modal("hide");
  };

  useEffect(() => {
    window.$("#PaymentWalletModal").modal({
      backdrop: "static",
      keyboard: false
    });
    window.$("#PaymentWalletModal").modal("show");
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);

  let content = (
    <div className={classes.Loading}>
      <OfficeSpinner size="4" />
      <p>Please wait while we process your payment. Do not close this page.</p>
    </div>
  );

  if (props.error) {
    content = (
      <div className={classes.Loading}>
        <Error error={props.error} refresh={cancelHandler} />
      </div>
    );
  } else if (props.success) {
    content = (
      <div className="py-2 text-center">
        <i className="mb-2 fas fa-check-circle fa-3x text-success"></i>
        <h5 className="mb-2">Payment Completed Successfully</h5>
        <p className="mb-2">{props.success}</p>
        <button
          onClick={successHandler}
          className="btn btn-success btn-rounded"
        >
          <i className="fas fa-chevron-left"></i> GO BACK
        </button>
      </div>
    );
  }

  return (
    <div className={classes.PaymentWalletModal}>
      <div
        id="PaymentWalletModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.PaymentWalletModalContent} modal-content`}>
            <div
              id="PaymentWalletModalBody"
              className={`${classes.PaymentWalletModalBody} modal-body`}
            >
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentWalletModal;
