/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./BankDetails.module.css";
import { connect } from "react-redux";
import {
  createInputElement,
  createSelectElement,
  createButtonElement
} from "../../../../components/UI/Forms/utility/form-creators";
import Form from "../../../../components/UI/Forms/Form";
import axios from "../../../../axios/axios-instance";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import * as actionCreators from "../../../../store/actions/actions";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import OtpModal from "../../../Auth/OtpModal/OtpModal";

class BankDetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      loading: false,
      error: null,
      completed: false,
      verified: false,
      loadingBanks: false,
      errorBanks: null,
      show_otp_modal: false,
      otpId: null
    };
  }

  initialiseFormData() {
    const formData = {
      bank: createSelectElement(
        "Bank Name",
        this.validateBank,
        "fas fa-list",
        "col-sm-12",
        this.state.banks.map(el => {
          return {
            option: el.name,
            value: el.code
          };
        }),
        "Select Your Bank",
        this.inputChangedHandler.bind(this, "bank"),
        this.props.user.bank_details
          ? this.props.user.bank_details.bank.code
          : ""
      ),
      accountNumber: createInputElement(
        "text",
        "Account Number",
        "Enter your bank account number",
        this.props.user.bank_details
          ? this.props.user.bank_details.account_number
          : "",
        this.validateAccountNumber,
        "Please enter valid account number with the right number of digits.",
        "fa fa-lock",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "accountNumber")
      ),
      submit: createButtonElement("submit", "VERIFY ACCOUNT", true)
    };
    this.setState({
      formData: formData
    });
  }

  loadBanks = () => {
    const userToken = this.props.userToken;
    const url = "/users/banks";
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const banks = response.data.data.banks;
        this.setState(
          {
            banks: banks,
            loadingBanks: false
          },
          this.initialiseFormData
        );
      })
      .catch(error => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loadingBanks: false,
              errorBanks: data.error[0].msg
            });
          } else {
            this.setState({
              loadingBanks: false,
              errorBanks: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loadingBanks: false,
            errorBanks: errorMsg
          });
        }
      });
    this.setState({
      loadingBanks: true,
      errorBanks: null
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadBanks();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData
    };
    if (name === "accountNumber") {
      updatedFormData[name].hint =
        "Please enter valid account number with the right number of digits.";
    } else if (name === "bank") {
      updatedFormData.accountNumber = {
        ...updatedFormData.accountNumber,
        value: "",
        hint:
          "Please enter valid account number with the right number of digits."
      };
    }
    let verified = this.state.verified;
    if (verified) {
      verified = false;
      updatedFormData.submit = {
        ...updatedFormData.submit,
        label: "VERIFY ACCOUNT"
      };
    }
    this.setState({ formData: updatedFormData, verified: verified });
  };

  validateAccountNumber(value) {
    if (validators.isEmpty(value)) {
      return "Please enter your account number!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Account Number. Only digits are allowed";
    }
    return null;
  }

  validateBank = value => {
    if (validators.isEmpty(value)) {
      return "Please select your bank!";
    }
    if (!this.state.banks.find(el => el.code === value)) {
      return "Invalid Bank. Select your bank from the dropdown.";
    }
    return null;
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData
    });
    return isFormActivated;
  }

  verifyBank = () => {
    const bank = this.state.formData.bank.value;
    const accountNumber = this.state.formData.accountNumber.value;
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: "/users/banks/verify",
      data: {
        bank: bank,
        accountNumber: accountNumber
      },
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const data = response.data.data;
        if (data.status === "success") {
          this.setState({
            loading: false,
            verified: true,
            formData: {
              ...this.state.formData,
              accountNumber: {
                ...this.state.formData.accountNumber,
                hint: (
                  <p>
                    <span className="fas text-success fa-check-circle"></span>{" "}
                    {data.account.account_name}
                  </p>
                )
              },
              submit: {
                ...this.state.formData.submit,
                label: "UPDATE BANK DETAILS"
              }
            }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error:
                "There is an error in the form. Please check your entries, correct the errors and try again",
              formData: this.createFormError(data.error)
            });
          } else {
            this.setState({
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      completed: false
    });
  };

  verifyOtp = () => {
    const bank = this.state.formData.bank.value;
    const accountNumber = this.state.formData.accountNumber.value;
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: "/users/banks/otp/verify",
      data: {
        bank: bank,
        accountNumber: accountNumber
      },
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const data = response.data.data;
        if (data.status === "success") {
          this.setState({
            show_otp_modal: true
          });
        }
      })
      .catch(error => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error:
                "There is an error in the form. Please check your entries, correct the errors and try again",
              formData: this.createFormError(data.error)
            });
          } else {
            this.setState({
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      completed: false
    });
  };

  updateBank = () => {
    const bank = this.state.formData.bank.value;
    const accountNumber = this.state.formData.accountNumber.value;
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: "/users/banks",
      data: {
        bank: bank,
        accountNumber: accountNumber
      },
      headers: {
        Authorization: userToken.token,
        TwoFactorAuth: this.state.otpId
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.setState({
          loading: false,
          completed: true
        });
        this.props.processUser(user);
      })
      .catch(error => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error:
                "There is an error in the form. Please check your entries, correct the errors and try again",
              formData: this.createFormError(data.error)
            });
          } else {
            this.setState({
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      completed: false
    });
  };

  submitHandler = event => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    if (!this.state.verified) {
      this.verifyBank();
    } else {
      this.verifyOtp();
    }
  };

  processHandler = otpId => {
    this.setState(
      {
        show_otp_modal: false,
        otpId: otpId
      },
      this.updateBank
    );
  };

  destroyHandler = () => {
    this.setState({
      show_otp_modal: false,
      error:
        "You must confirm this bank update request by entering the two factor code sent to your mail before you can continue",
      loading: false
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach(el => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm
    };
  }

  render() {
    let content = null;
    if (this.state.loadingBanks) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.errorBanks) {
      content = (
        <div className={classes.Loading}>
          <Error refresh={this.loadBanks} error={this.state.errorBanks} />
        </div>
      );
    } else if (this.state.banks) {
      content = (
        <div className={classes.UserProfile}>
          {this.state.error ? (
            <div className="alert alert-danger">{this.state.error}</div>
          ) : null}
          {this.state.completed ? (
            <div className="alert alert-success">
              Bank Details Updated Successfully
            </div>
          ) : null}
          <Form
            loading={this.state.loading}
            formData={this.state.formData}
            submit={this.submitHandler}
          />
        </div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Update Bank Details</h4>
                </TitleHeader>
              </Title>
              <div className={classes.BankDetails}>{content}</div>
              {this.state.show_otp_modal ? (
                <OtpModal
                  destroy={this.destroyHandler}
                  process={this.processHandler}
                  email={this.props.user.email}
                  title="Confirm Bank Details Update"
                />
              ) : null}
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    processUser: user => dispatch(actionCreators.processUser(user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankDetails);
