import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import TitleLinks from "../../../components/App/Layout/Title/TitleLinks";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Settings.module.css";
import UserPhoto from "../../../components/App/User/UserPhoto/UserPhoto";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";

class Settings extends Component {
  logoutHandler = () => {
    this.props.history.replace("/logout");
  };

  componentDidMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }

  render() {
    let total_pending_orders = 0;
    if (this.props.meta && this.props.meta.pending_orders) {
      total_pending_orders += this.props.meta.pending_orders;
    }
    if (this.props.meta && this.props.meta.pending_purchase_orders) {
      total_pending_orders += this.props.meta.pending_purchase_orders;
    }

    let total_sales_orders = 0;

    if (
      this.props.user &&
      this.props.user.order &&
      this.props.user.order.sales_action
    ) {
      total_sales_orders += this.props.user.order.sales_action;
    }

    total_pending_orders += total_sales_orders;

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Settings</h4>
                </TitleHeader>
                <TitleLinks>
                  <button className="bg-danger" onClick={this.logoutHandler}>
                    Logout
                  </button>
                </TitleLinks>
              </Title>
              <div className={classes.Settings}>
                <div className={classes.UserDetails}>
                  <UserPhoto photo={this.props.user.profile_pic} size="50" />
                  <div className={classes.SideText}>
                    <p className={classes.Username}>
                      @{this.props.user.username}
                    </p>
                    <h6 className={classes.Name}>{this.props.user.name}</h6>
                    <p className={classes.UserLink}>
                      <Link to={`/users/${this.props.user.username}`}>
                        View Profile <span className="fa fa-caret-right"></span>
                      </Link>
                    </p>
                  </div>
                </div>
                <Link className={classes.SettingLink} to="/orders">
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-list"></span> My Orders
                    </p>
                    <p className="ml-auto">
                      {total_pending_orders > 0 ? (
                        <span
                          className={`${classes.BadgeCount} badge badge-danger mr-2`}
                        >
                          {total_pending_orders}
                        </span>
                      ) : null}
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link className={classes.SettingLink} to="/funding">
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-wallet"></span> Fund Wallet
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link className={classes.SettingLink} to="/withdraw">
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-sign-out"></span> Place
                      Withdrawals
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link
                  replace
                  className={classes.SettingLink}
                  to="/transactions/shopping"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-list"></span> Transaction History
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link className={classes.SettingLink} to="/chats">
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-comment-alt-dots"></span> My Chats
                    </p>
                    <p className="ml-auto">
                      {this.props.messageCount ? (
                        <span
                          className={`${classes.BadgeCount} badge badge-danger mr-2`}
                        >
                          {this.props.messageCount < 99
                            ? this.props.messageCount
                            : `99+`}
                        </span>
                      ) : null}
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link className={classes.SettingLink} to="/notifications">
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-bell"></span> My Notifications
                    </p>
                    <p className="ml-auto">
                      {this.props.notificationCount ? (
                        <span
                          className={`${classes.BadgeCount} badge badge-danger mr-2`}
                        >
                          {this.props.notificationCount < 99
                            ? this.props.notificationCount
                            : `99+`}
                        </span>
                      ) : null}
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link
                  replace
                  className={classes.SettingLink}
                  to="/settings/profile"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-user"></span> Edit Profile
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link
                  replace
                  className={classes.SettingLink}
                  to="/settings/password"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-lock"></span> Update Password
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                {/* <Link
                  replace
                  className={classes.SettingLink}
                  to="/settings/email"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-envelope"></span> Update Email
                      Address
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link> */}
                <Link
                  replace
                  className={classes.SettingLink}
                  to="/settings/location"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-map-marker-alt"></span> Update
                      Location
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link
                  replace
                  className={classes.SettingLink}
                  to="/settings/bank"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-check-circle"></span> Update Bank
                      Details
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
                <Link className={classes.SettingLink} to="/support">
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-question-circle"></span> Chat With
                      Support
                    </p>
                    <p className="ml-auto">
                      <span className="fab fa-facebook-messenger fa-2x text-primary"></span>
                    </p>
                  </div>
                </Link>
                <BrowserLinks
                  className={classes.SettingLink}
                  to="https://hawkit.ng/about/privacy-policy"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-user-secret"></span> Privacy
                      Policy
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </BrowserLinks>
                <BrowserLinks
                  className={classes.SettingLink}
                  to="https://hawkit.ng/about"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-info-circle"></span> About
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </BrowserLinks>
                <BrowserLinks
                  className={classes.SettingLink}
                  to="https://hawkit.ng/about/terms"
                >
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-info-circle"></span> Terms of Use
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </BrowserLinks>
                <Link replace className={classes.SettingLink} to="/logout">
                  <div className={classes.Setting}>
                    <p>
                      <span className="fal fa-power-off"></span> Logout
                    </p>
                    <p className="ml-auto">
                      <span className="fal fa-chevron-right"></span>
                    </p>
                  </div>
                </Link>
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
    messageCount: state.notificationState.messageCount,
    notificationCount: state.notificationState.notificationCount,
  };
};

export default connect(mapStateToProps)(Settings);
