/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import classes from "./ConfirmModal.module.css";

const ConfirmModal = (props) => {
  const cancelModalHandler = (event) => {
    event.preventDefault();
    window.$("#ConfirmModal").on("hidden.bs.modal", (event) => {
      props.cancel();
    });
    window.$("#ConfirmModal").modal("hide");
  };

  const confirmHandler = (event) => {
    event.preventDefault();
    window.$("#ConfirmModal").on("hidden.bs.modal", (event) => {
      props.confirm();
    });
    window.$("#ConfirmModal").modal("hide");
  };

  useEffect(() => {
    window.$("#ConfirmModal").modal({
      backdrop: "static",
      keyboard: false
    });
    window.$("#ConfirmModal").off("hidden.bs.modal");
    window.$("#ConfirmModal").modal("show");
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#ConfirmModal").modal("hide");
      window.$("#ConfirmModal").off("hidden.bs.modal");
    };
  }, []);
  return (
    <div className={classes.ConfirmModal}>
      <div
        id="ConfirmModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.ConfirmModalContent} modal-content`}>
            <div className={`${classes.ConfirmModalHeader} modal-header`}>
              <h5>{props.title}</h5>
            </div>
            <div
              id="ConfirmModalBody"
              className={`${classes.ConfirmModalBody} modal-body`}
            >
              <p>{props.description}</p>
              <div className="py-2">
                <button
                  onClick={confirmHandler}
                  className="mr-1 btn btn-success btn-rounded"
                >
                  YES
                </button>
                <button
                  onClick={cancelModalHandler}
                  className="btn btn-danger btn-rounded"
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
