/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import classes from "./ProductDescriptionModal.module.css";
import { getYoutubeId } from "../../../../util/util";

const ProductDescriptionModal = (props) => {
  let content = "<p>No description to show</p>";
  if (props.post.product.description && props.post.product.description.length) {
    content =
      "<p>" +
      props.post.product.description
        .replace(/\n{2,}/g, "</p><p>")
        .replace(/\n/g, "<br>") +
      "</p>";
  }
  useEffect(() => {
    window.$("#ProductDescriptionModal").modal("show");
    window.$("#ProductDescriptionModal").off("hidden.bs.modal");
    window.$("#ProductDescriptionModal").on("hidden.bs.modal", (event) => {
      props.destroy();
    });
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);

  const detailsContent = props.post.product.details.map((el) => {
    if (!el.value.length) return false;
    return (
      <div key={el._id} className={`${classes.Detail} row`}>
        <div className="col-3">
          <div className={classes.DetailName}>
            <p>{el.name}:</p>
          </div>
        </div>
        <div className="col-9">
          <div className={classes.DetailValue}>
            <p>{el.value}</p>
          </div>
        </div>
      </div>
    );
  });

  let youtube_link = null;

  if (props.post.product.youtube_link) {
    const youtubeId = getYoutubeId(props.post.product.youtube_link);
    youtube_link = youtubeId
      ? `https://youtube.com/embed/${youtubeId}?rel=1&modestbranding=1&showinfo=0&fs=0&showsearch=0`
      : null;
  }

  return (
    <div className={classes.ProductDescriptionModal}>
      <div
        id="ProductDescriptionModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className={`${classes.ProductDescriptionModalContent} modal-content`}
          >
            <div
              className={`${classes.ProductDescriptionModalHeader} modal-header`}
            >
              <h5>{props.post.product.title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="ProductDescriptionModalBody"
              className={`${classes.ProductDescriptionModalBody} modal-body`}
            >
              <div className={classes.Intro}>
                <h4>Product Description</h4>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                className={classes.ProductDescription}
              ></div>
              <div className={classes.DetailsContent}>{detailsContent}</div>
              {youtube_link ? (
                <div className={classes.VideoContainer}>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      title="Hawkit Video"
                      className="embed-responsive-item"
                      src={youtube_link}
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              ) : null}
              {!props.post.product.is_airtime ? (
                <>
                  <div className={classes.Report}>
                    <div className="mr-2">
                      <i className="fas fa-exclamation-triangle"></i>
                    </div>
                    <div className={classes.ReportContent}>
                      <h5>Disclaimer!</h5>
                      <p>
                        Please ensure you make payments on the Hawkit site using
                        the online payment method. Do not transfer any money
                        into any contrary or fraudulent bank account that is
                        different from Hawkit International Limited.
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionModal;
