import React, { Component } from "react";
import Posts from "../../../../components/App/Post/Posts";
import PostContext from "../../../../context/post-context";
import classes from "./UserPosts.module.css";
import Error from "../../../../components/UI/Error/Error";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import axios from "../../../../axios/axios-instance";
import * as actionCreators from "../../../../store/actions/actions";

class UserPosts extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      posts: [],
    };
  }

  loadUserPosts = () => {
    let page = 1;
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    const { username } = this.props.match.params;
    const url = `/posts/user/${username}?page=${page}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const posts = response.data.data.posts;
        const pages = response.data.data.pages;
        const filteredPosts = posts.filter((el) => {
          return !this.state.posts.find((post) => post._id === el._id);
        });
        const reduxPosts = posts.filter((el) => {
          return !this.props.posts.find((post) => post._id === el._id);
        });
        const updatedReduxPosts = [...this.props.posts, ...reduxPosts];
        this.props.setPosts(updatedReduxPosts);
        const updatedPosts = [...this.state.posts, ...filteredPosts];
        this.setState({
          posts: updatedPosts,
          pages: pages,
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  loadMorePosts = (event) => {
    event.preventDefault();
    this.loadUserPosts();
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadUserPosts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.Loading}>
          <a onClick={this.loadMorePosts} href="/">
            Load More Posts <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.Loading}>
          <Error refresh={this.loadMorePosts} error={this.state.error} />
        </div>
      );
    }
    const posts = this.props.posts.filter((el) => {
      return this.state.posts.find((post) => post._id === el._id);
    });
    let postContent = (
      <PostContext.Provider
        value={{
          userToken: this.props.userToken,
          toggleLikes: this.props.toggleLikes,
          addPostView: this.props.addPostView,
          posts: this.props.posts,
          setPosts: this.props.setPosts,
          postModal: this.props.postModal,
          setPostModal: this.props.setPostModal,
          deleteModal: this.props.deleteModal,
          setDeleteModal: this.props.setDeleteModal,
          user: this.props.user,
        }}
      >
        <Posts posts={posts} />
      </PostContext.Provider>
    );
    if (!this.state.loading && !this.state.error && !posts.length) {
      postContent = (
        <div className={`bg-white ${classes.Loading}`}>
          <i className="fal fa-bell-slash"></i>
          <p className="mb-3">This user is not selling any products yet.</p>
          <button
            className="btn btn-coloured-heavy"
            onClick={this.loadUserPosts}
          >
            Try Again
          </button>
        </div>
      );
    }
    return (
      <div className={classes.UserPosts}>
        {postContent}
        {loadingContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    posts: state.postState.posts,
    postModal: state.postState.postModal,
    deleteModal: state.postState.deleteModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPosts: (posts) => dispatch(actionCreators.setPosts(posts)),
    toggleLikes: (id) => dispatch(actionCreators.toggleLikes(id)),
    addPostView: (id) => dispatch(actionCreators.addPostView(id)),
    setPostModal: (postModal) =>
      dispatch(actionCreators.setPostModal(postModal)),
    setDeleteModal: (deleteModal) =>
      dispatch(actionCreators.setDeleteModal(deleteModal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPosts);
