/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Withdraw.module.css";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import * as actionCreators from "../../../store/actions/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  createInputElement,
  createButtonElement,
} from "../../../components/UI/Forms/utility/form-creators";
import Form from "../../../components/UI/Forms/Form";
import * as validators from "../../../components/UI/Forms/utility/validator";
import OtpModal from "../../Auth/OtpModal/OtpModal";

class Withdraw extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      user_loaded: false,
      withdrawal_charge_percentage: null,
      transfer_charges: null,
      minimum_withdrawal: null,
      formData: null,
      submitting: false,
      submittingError: null,
      show_otp_modal: false,
      otpId: null,
      completed: null,
    };
  }

  initialiseFormData = () => {
    const formData = {
      amount: createInputElement(
        "text",
        `Amount (${this.props.user.country.currency.symbol})`,
        "Enter Withdrawal Amount",
        "",
        this.validateAmount,
        `Please note that a transfer charge will be deducted from your account.`,
        "fa fa-list",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "amount")
      ),
      password: createInputElement(
        "password",
        "Password",
        "Enter your password",
        "",
        this.validatePassword,
        "Please validate this transaction using the password that is required when logging in to your account.",
        "fa fa-lock",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "password")
      ),
      submit: createButtonElement("submit", "WITHDRAW", true),
    };
    this.setState({
      formData: formData,
    });
  };

  validatePassword(value) {
    if (validators.isEmpty(value)) {
      return "Please enter your password!";
    }
    if (!validators.checkPassword(value)) {
      return "Password must be 6 - 20 characters";
    }
    return null;
  }

  getWithdrawalCharge = (value) => {
    let withdrawal_charge = Math.ceil(
      (this.state.withdrawal_charge_percentage / 100) * +value
    );
    let transfer_charge = 0;
    for (const chargeObj of this.state.transfer_charges) {
      if (+value >= chargeObj.min) {
        transfer_charge = chargeObj.charge;
      }
    }
    withdrawal_charge += transfer_charge;
    return withdrawal_charge;
  };

  validateAmount = (value) => {
    if (validators.isEmpty(value)) {
      return "Please enter your amount!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Amount. Only digits are allowed";
    }
    if (+value < this.state.minimum_withdrawal) {
      return `Minimum Withdrawal Amount is ${this.props.user.country.currency.symbol}${this.state.minimum_withdrawal}.`;
    }
    const withdrawal_charge = this.getWithdrawalCharge(value);
    const max_withdrawal_charge = this.getWithdrawalCharge(
      this.props.user.wallet.shopping
    );
    let maxWithdrawable =
      this.props.user.wallet.shopping - max_withdrawal_charge;
    maxWithdrawable = maxWithdrawable <= 0 ? 0 : maxWithdrawable;
    if (+value > maxWithdrawable) {
      return `The amount you entered is greater than the maximum withdrawable amount of ${this.props.user.country.currency.symbol}${maxWithdrawable}. Please note that a transfer charge of ${this.props.user.country.currency.symbol}${withdrawal_charge} will be deducted from your account.`;
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    if (name === "amount") {
      const withdrawal_charge = this.getWithdrawalCharge(event.target.value);
      if (!formElementData.invalid) {
        formElementData.hint = `Please note that a transfer charge of ${this.props.user.country.currency.symbol}${withdrawal_charge} will be deducted from your account.`;
      }
    }
    const updatedFormData = {
      ...this.state.formData,
    };
    updatedFormData[name] = formElementData;
    this.setState({ formData: updatedFormData });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadUser = () => {
    const userToken = this.props.userToken;
    const url = "/users/me";
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        const withdrawal_charge_percentage =
          response.data.data.meta.withdrawal_charge_percentage;
        const transfer_charges = response.data.data.withdrawal_charge;
        const minimum_withdrawal = response.data.data.minimum_withdrawal;
        this.props.processUser(user);
        this.setState(
          {
            user_loaded: true,
            loading: false,
            withdrawal_charge_percentage: withdrawal_charge_percentage,
            transfer_charges: transfer_charges,
            minimum_withdrawal: minimum_withdrawal,
          },
          this.initialiseFormData
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  verify = () => {
    const amount = this.state.formData.amount.value;
    const password = this.state.formData.password.value;
    const userToken = this.props.userToken;
    const url = "/users/withdraw/verify";
    axios({
      method: "post",
      url: url,
      data: {
        amount: amount,
        password: password,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const data = response.data.data;
        if (data.status === "success") {
          this.setState({
            show_otp_modal: true,
          });
        }
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            submitting: false,
            submittingError: errorMsg,
          });
        }
      });
    this.setState({
      completed: null,
      submitting: true,
      submittingError: null,
    });
  };

  withdraw = () => {
    const amount = this.state.formData.amount.value;
    const password = this.state.formData.password.value;
    const userToken = this.props.userToken;
    const url = "/users/withdraw";
    axios({
      method: "post",
      url: url,
      data: {
        amount: amount,
        password: password,
      },
      headers: {
        Authorization: userToken.token,
        TwoFactorAuth: this.state.otpId,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        const msg = response.data.data.msg;
        this.props.processUser(user);
        this.setState({
          completed: msg,
          submitting: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            submitting: false,
            submittingError: errorMsg,
          });
        }
      });
    this.setState({
      submitting: true,
      submittingError: null,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    this.verify();
  };

  processHandler = (otpId) => {
    this.setState(
      {
        show_otp_modal: false,
        otpId: otpId,
      },
      this.withdraw
    );
  };

  destroyHandler = () => {
    this.setState({
      show_otp_modal: false,
      submittingError:
        "You must confirm your withdrawal before you can continue",
      submitting: false,
    });
  };

  render() {
    let content = null;
    if (this.state.loading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className={classes.Loading}>
          <Error refresh={this.loadUser} error={this.state.error} />
        </div>
      );
    } else if (this.state.user_loaded) {
      const max_withdrawal_charge = this.getWithdrawalCharge(
        this.props.user.wallet.shopping
      );
      let maxWithdrawable =
        this.props.user.wallet.shopping - max_withdrawal_charge;
      maxWithdrawable = maxWithdrawable <= 0 ? 0 : maxWithdrawable;
      if (this.props.user.bank_details) {
        content = (
          <div className={classes.WithdrawContainer}>
            <div className={classes.WalletBalance}>
              <div className={classes.Wallet}>
                <h5>Wallet Balance</h5>
                <p>Max. Withdrawable Amount</p>
              </div>
              <div className={classes.Balance}>
                <h5>
                  {this.props.user.country.currency.symbol}{" "}
                  {this.props.user.wallet.shopping
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h5>
                <p>
                  {this.props.user.country.currency.symbol}{" "}
                  {maxWithdrawable
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
            </div>
            <div className={classes.BankDetails}>
              <div className={classes.Details}>
                <p className="text-primary">Bank Details</p>
                <h5>{this.props.user.bank_details.account_name}</h5>
                <p>
                  <span className="mr-2">
                    {this.props.user.bank_details.account_number}
                  </span>
                  <span>
                    <span className="far fa-university mr-1"></span>
                    {this.props.user.bank_details.bank.name}
                  </span>
                </p>
              </div>
              <Link
                title="Edit Bank Details"
                className="ml-auto"
                to="/settings/bank"
              >
                <span className="fas fa-edit"></span>
              </Link>
            </div>
            <div className={classes.WithdrawForm}>
              {this.state.submittingError ? (
                <div className="alert alert-danger">
                  {this.state.submittingError}
                </div>
              ) : null}
              {this.state.completed ? (
                <div className="alert alert-success">
                  {this.state.completed}
                </div>
              ) : null}
              <Form
                loading={this.state.submitting}
                formData={this.state.formData}
                submit={this.submitHandler}
              />
              <Link
                to="/transactions/withdrawal"
                className="mt-2 btn btn-block btn-outline"
              >
                Withdrawal History
              </Link>
            </div>
          </div>
        );
      } else {
        content = (
          <div className={classes.BankEmpty}>
            <i className="fal fa-bell-slash"></i>
            <p className="mb-3">
              You have not added your bank account details. Please update your
              bank account details before placing withdrawals of your funds
            </p>
            <Link className="btn btn-coloured-heavy" to="/settings/bank">
              Update Bank Details
            </Link>
          </div>
        );
      }
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Withdraw</h4>
                </TitleHeader>
              </Title>
            </PageContent>
            <div className={classes.Withdraw}>{content}</div>
            {this.state.show_otp_modal ? (
              <OtpModal
                destroy={this.destroyHandler}
                process={this.processHandler}
                email={this.props.user.email}
                title="Confirm Withdrawal"
              />
            ) : null}
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);
