import React, { useRef, useEffect } from "react";
import classes from "./ProductViewHeader.module.css";
import BackButton from "../../../UI/Buttons/BackButton/BackButton";

const ProductViewHeader = (props) => {
  const productDetailsHandler = (event) => {
    event.preventDefault();
    window.$("#ProductDetailsModal").modal("show");
  };
  const headerRef = useRef(null);
  const backButtonRef = useRef(null);
  const infoRef = useRef(null);
  const title = useRef(null);
  let price = "Contact for price";
  if (props.product.price && props.product.price !== 0) {
    price =
      props.product.country.currency.symbol +
      " " +
      props.product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    const headerWidth =
      headerRef.current.offsetWidth -
      28 -
      backButtonRef.current.offsetWidth -
      infoRef.current.offsetWidth;
    title.current.style.width = headerWidth - 5 + "px";
  }, []);
  return (
    <div ref={headerRef} className={classes.ProductViewHeader}>
      <div ref={backButtonRef}>
        <BackButton isDarkBg />
      </div>
      <div className={classes.HeaderContent}>
        <h4 ref={title}>{props.product.title}</h4>
        <p>{price}</p>
      </div>
      <div className={classes.Buy} ref={infoRef}>
        <button
          className="btn btn-xs btn-outline btn-rounded"
          onClick={productDetailsHandler}
        >
          More Info
        </button>
      </div>
    </div>
  );
};

export default ProductViewHeader;
