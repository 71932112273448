/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import classes from "./FundPayment.module.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UploadFormImages from "../../../UI/Forms/UploadFormImages/UploadFormImages";
import paymentLogo from "../../../../assets/images/payment_logos.jpg";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import AlertError from "../../../UI/Error/AlertError/AlertError";

let _isMounted = false;

const FundPayment = (props) => {
  const completeUpload = () => {
    props.refresh();
  };

  const [photos, setPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [paymentOption, setPaymentOption] = useState(
    props.funding.payment_method
      ? props.funding.payment_method
      : "Online Payment Using Flutterwave"
  );

  const uploadProof = () => {
    if (uploading) return;
    const uploadFormData = new FormData();
    photos.forEach((el) => {
      uploadFormData.append("photos[]", el.file);
    });
    uploadFormData.append(
      "previous_images",
      JSON.stringify(previousImagesContent)
    );
    axios({
      method: "put",
      url: `/users/funding/${props.funding._id}/upload-proof`,
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: props.userToken.token,
      },
    })
      .then((response) => {
        if (!_isMounted) return;
        completeUpload();
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setUploading(false);
            setUploadError(data.error[0].msg);
          } else {
            setUploading(false);
            setUploadError(data.error);
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setUploading(false);
          setUploadError(errorMsg);
        }
      });
    setUploading(true);
    setUploadError(null);
  };

  const paymentOptionHandler = (event) => {
    setPaymentOption(event.target.value);
  };

  const [previousImagesContent, setPreviousImagesContent] = useState(
    props.funding.proofs
  );

  let paymentContent = null;

  const copyHandler = (event, value) => {
    var $temp = window.$("<input>");
    window.$("body").append($temp);
    $temp.val(value).select();
    document.execCommand("copy");
    $temp.remove();
    window.$(event.target).html("<i class='fas fa-copy'></i> Copied");
  };

  let uploadContent = null;

  if (uploading) {
    uploadContent = (
      <div className="py-3">
        <OfficeSpinner size="3" />
      </div>
    );
  } else {
    uploadContent = (
      <>
        {uploadError ? <AlertError error={uploadError} /> : null}
        {props.funding.payment_status ? (
          <AlertError error={props.funding.payment_status} />
        ) : null}
        <UploadFormImages
          max={5}
          title="Upload Proof of Payment"
          description="To activate your payment, please upload a proof of payment such as
Transfer Screenshots, Teller, Mobile App Receipts etc."
          photos={photos}
          setPhotos={setPhotos}
          setPreviousImagesContent={setPreviousImagesContent}
          previousImagesContent={previousImagesContent}
        />
        <button onClick={uploadProof} className="btn btn-outline mt-1">
          UPLOAD PROOF OF PAYMENT
        </button>
      </>
    );
  }

  switch (paymentOption) {
    case "Online Payment Using Flutterwave":
      paymentContent = (
        <>
          <div className={classes.PaymentOption}>
            <div className={classes.PaymentOptionContent}>
              <h5>Online Payment Using Flutterwave</h5>
              <p>
                You can pay online using the secured Flutterwave Payment
                Platform. Please note that you may incure additional online
                payment charges.
              </p>
            </div>
            <div className={classes.PaymentOptionPrice}>
              <h6>
                {`${
                  props.user.country.currency.symbol
                } ${props.funding.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </h6>
            </div>
          </div>
          <div className={classes.PaymentButton}>
            <button onClick={props.flutterwave} className="btn btn-outline">
              Pay With Flutterwave
            </button>
            <div className={classes.PaymentLogo}>
              <img src={paymentLogo} alt="payment_logo" />
            </div>
          </div>
        </>
      );
      break;
    case "Online Payment Using Paystack":
      paymentContent = (
        <>
          <div className={classes.PaymentOption}>
            <div className={classes.PaymentOptionContent}>
              <h5>Online Payment Using Paystack</h5>
              <p>
                You can pay online using the secured Paystack Payment Platform.
                Please note that you may incure additional online payment
                charges.
              </p>
            </div>
            <div className={classes.PaymentOptionPrice}>
              <h6>
                {`${
                  props.user.country.currency.symbol
                } ${props.funding.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </h6>
            </div>
          </div>
          <div className={classes.PaymentButton}>
            <button onClick={props.paystack} className="btn btn-outline">
              Pay With Paystack
            </button>
            <div className={classes.PaymentLogo}>
              <img src={paymentLogo} alt="payment_logo" />
            </div>
          </div>
        </>
      );
      break;
    case "Direct Bank Transfer":
      paymentContent = (
        <div>
          <div className={`${classes.PaymentOption} ${classes.NoBorder}`}>
            <div className={classes.PaymentOptionContent}>
              <h5>Direct Bank Transfer</h5>
              <p>
                You can complete your order by paying the total amount of{" "}
                <b>{`${
                  props.user.country.currency.symbol
                }${props.funding.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</b>{" "}
                to our corporate bank account shown below. After payment, upload
                your proof of payment using the upload button below.
              </p>
            </div>
            <div className={classes.PaymentOptionPrice}>
              <h6>
                {`${
                  props.user.country.currency.symbol
                } ${props.funding.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </h6>
            </div>
          </div>
          <div className={classes.BankTransferTable}>
            {props.funding.payment_method === "Direct Bank Transfer" ? (
              <div className="alert alert-warning f-12">
                Your Bank Transfer Payment is currently pending. Your wallet
                will be funded once payment is verified.
              </div>
            ) : null}
            <table className="table table-sm table-borderless">
              <tbody>
                <tr>
                  <td>Bank:</td>
                  <th>{props.bank_transfer.bank}</th>
                </tr>
                <tr>
                  <td>Account Number:</td>
                  <th>
                    {props.bank_transfer.account_number}
                    <button
                      onClick={(event) =>
                        copyHandler(event, props.bank_transfer.account_number)
                      }
                      className="ml-1 btn btn-xs btn-outline"
                    >
                      <i className="fas fa-copy"></i> Copy
                    </button>
                  </th>
                </tr>
                <tr>
                  <td>Account Name:</td>
                  <th>{props.bank_transfer.account_name}</th>
                </tr>
              </tbody>
            </table>
            {uploadContent}
          </div>
        </div>
      );
      break;
    default:
      paymentContent = null;
  }

  useEffect(() => {
    _isMounted = true;
    return () => {
      _isMounted = false;
    };
  }, []);

  return (
    <div className={classes.FundPayment}>
      <div className={classes.FundPaymentHeader}>
        <h5>Make Payments</h5>
        <p>Please select your desired payment option below.</p>
        <select
          value={paymentOption}
          onChange={paymentOptionHandler}
          className="form-control custom-select"
        >
          <option>Online Payment Using Flutterwave</option>
          <option>Online Payment Using Paystack</option>
          <option>Direct Bank Transfer</option>
        </select>
      </div>
      <div className={classes.FundPaymentContent}>{paymentContent}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(FundPayment));
