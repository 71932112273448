import React, { Component } from "react";
import classes from "./Layout.module.css";
import Header from "../../../components/App/Layout/Header/Header";
import { createSearchInputElement } from "../../../components/UI/Forms/utility/form-creators";
import { connect } from "react-redux";
import LayoutContext from "../../../context/layout-context";
import * as actionCreators from "../../../store/actions/actions";
import { Switch, Route } from "react-router-dom";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import UploadProfilePicture from "../UploadProfilePicture/UploadProfilePicture";
import { Redirect } from "react-router-dom";
import AddLocation from "../AddLocation/AddLocation";
import axios from "../../../axios/axios-instance";
import SocialMenuModal from "./SocialMenuModal/SocialMenuModal";
import RouteLayout from "./RouteLayout/RouteLayout";
import UserLayout from "../UserLayout/UserLayout";
import ShopLayout from "../ShopLayout/ShopLayout";
import ChatLayout from "../ChatLayout/ChatLayout";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import Settings from "../Settings/Settings";
import Logout from "../../Auth/Logout/Logout";
import WrapperLayout from "../../../hoc/WrapperLayout/WrapperLayout";
import RefPage from "../RefPage/RefPage";
import VerifyPhone from "../VerifyPhone/VerifyPhone";
import Chats from "../Chats/Chats";
import RefreshUser from "../RefreshUser/RefreshUser";
import AirtimeLayout from "../AirtimeLayout/AirtimeLayout";
import MarketMenuModal from "./MarketMenuModal/MarketMenuModal";
// import { playMessageSound } from "../../../util/util";
import { CSSTransition } from "react-transition-group";
import NotificationModal from "../../../components/App/NotificationModal/NotificationModal";
import AddBelief from "../AddBelief/AddBelief";
import Support from "../Support/Support";
import SellersRegister from "../Sell/SellersRegister/SellersRegister";
import PostViewLayout from "./PostViewLayout/PostViewLayout";
import OrderLayout from "./OrderLayout/OrderLayout";
import ResPage from "../ResPage/ResPage";
import MarketLayout from "../MarketLayout/MarketLayout";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        search: createSearchInputElement(
          "Search Products/Services",
          props.search,
          this.searchChangeHandler
        ),
      },
      notification: null,
    };
  }

  componentDidMount() {
    this.initialiseUserProperties();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      // this.processContacts();
      this.addPushNotification();
      this.addWebIntent();
    }
    if (this.props.search !== prevProps.search) {
      const formData = { ...this.state.formData };
      formData.search = {
        ...formData.search,
        value: this.props.search,
      };
      this.setState({
        formData: formData,
      });
    }
  }

  initialiseUserProperties() {
    this.props.setUser();
    this.props.fetchNotificationCount();
    // this.props.fetchCountryStates();
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.props.initialiseUserLoading();
    this.initialiseUserProperties();
  };

  componentWillUnmount() {
    this.props.initialiseUserLoading();
  }

  searchChangeHandler = (event) => {
    const formElementData = { ...this.state.formData.search };
    formElementData.value = event.target.value;
    const updatedFormData = {
      search: formElementData,
    };
    this.setState({ formData: updatedFormData });
  };

  searchFormSubmit = (event) => {
    event.preventDefault();
    let query = this.state.formData.search.value;
    if (!query.trim().length) {
      this.props.setSearch("");
      return;
    }
    // query = query.trim().replace(/ /g, "+");
    this.props.setSearch(query);
    this.props.history.push("/market");
    return false;
  };

  processContacts = () => {
    if (!window.cordova) return;
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: "/users/contacts",
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        const prevContacts = response.data.data.contacts;
        const newContacts = [];
        navigator.contactsPhoneNumbers.list(
          (contacts) => {
            for (var i = 0; i < contacts.length; i++) {
              for (var j = 0; j < contacts[i].phoneNumbers.length; j++) {
                var phone = contacts[i].phoneNumbers[j];
                let phoneNumber = phone.number;
                if (phoneNumber.startsWith("0")) {
                  phoneNumber =
                    this.props.user.country.d_code +
                    phoneNumber.replace(/^0+/, "");
                }
                phoneNumber = phoneNumber.split(" ").join("");
                if (!newContacts.includes(phoneNumber)) {
                  newContacts.push(phoneNumber);
                }
              }
            }
            const uploadContacts = newContacts.filter((contact) => {
              return !prevContacts.find(
                (prevContact) => prevContact.contact === contact
              );
            });
            this.uploadContacts(uploadContacts);
          },
          function (error) {
            alert(error);
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  uploadContacts = (contacts) => {
    if (!window.cordova || !contacts.length) return;
    const userToken = this.props.userToken;
    axios({
      method: "post",
      data: {
        contacts: contacts,
      },
      url: "/users/contacts",
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => { })
      .catch((error) => {
        console.log(error);
      });
  };

  switchToPage = (url) => {
    if (!url) return;
    const urlObj = new URL(url);
    if (urlObj.hostname !== "hawkit.ng" && urlObj.hostname !== "www.hawkit.ng")
      return;
    const fullPath = `${urlObj.pathname}?${urlObj.search}`;
    this.props.history.push(fullPath);
  };

  addWebIntent = () => {
    if (!window.plugins || !window.plugins.webintent) return;
    window.plugins.webintent.getUri((url) => {
      this.switchToPage(url);
    });
    window.plugins.webintent.onNewIntent((url) => {
      this.switchToPage(url);
    });
  };

  addPushNotification = async () => {
    if (!window.cordova) return;
    console.log("Processing Push Notification");
    const uploadPushNotificationToken = (token) => {
      const userToken = this.props.userToken;
      axios({
        method: "post",
        data: {
          token: token,
        },
        url: "/users/push-notification-token",
        headers: {
          Authorization: userToken.token,
        },
      })
        .then((response) => {
          console.log("Push Notification Token Added Successfully", token);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (window.FCM) {
      console.log("Found FCM");
      const firebasePlugin = window.FCM;
      try {
        const token = await firebasePlugin.getToken();
        console.log("Token", token);
        uploadPushNotificationToken(token);
        console.log("Started Pushing Upload Token", token);
      } catch (error) {
        console.error(error);
      }
      firebasePlugin.onTokenRefresh(
        function (token) {
          console.log("Token Refresh", token);
          uploadPushNotificationToken(token);
        },
        function (error) {
          console.error(error);
        }
      );
      firebasePlugin.onNotification(
        (notification) => {
          this.props.updateUser();
          this.props.fetchNotificationCount();
        },
        function (error) {
          console.error(error);
        }
      );
    }
  };

  closeHandler = () => {
    this.setState({
      notification: null,
    });
  };

  render() {
    let mainContent = (
      <Switch>
        <Route path="/ref/:username" component={RefPage} />
        <Route path="/resell/:reseller_code" component={ResPage} />
        <Route path="/refresh" component={RefreshUser} />
        <Route path="/airtime/:category" component={AirtimeLayout} />
        <Route path="/airtime" component={AirtimeLayout} />
        <Route path="/shop/sell" component={RouteLayout} />
        <Route path="/shop/:category" component={ShopLayout} />
        <Route path="/shop" component={ShopLayout} />
        <Route path="/market/:category" component={MarketLayout} />
        <Route path="/market" component={MarketLayout} />
        <Route path="/content" component={null} />
        <Route path="/posts/:postId" component={PostViewLayout} />
        <Route path="/orders" component={OrderLayout} />
        <Route path="/users/sellers" component={RouteLayout} />
        <Route path="/users/:username" component={UserLayout} />
        <Route path="/chats/:username" component={ChatLayout} />
        <Route path="/" component={RouteLayout} />
      </Switch>
    );
    if (
      this.props.user &&
      this.props.user.is_member &&
      !this.props.user.phoneNumber
    ) {
      mainContent = (
        <Switch>
          <Route
            path="/logout"
            render={(props) => {
              return (
                <WrapperLayout>
                  <Logout {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/settings"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Settings {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/support"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Support {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/chats"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Chats {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route path="/chats/:username" exact component={ChatLayout} />
          <Route path="/" exact component={VerifyPhone} />
          <Redirect to="/" />
        </Switch>
      );
    } else if (this.props.user && !this.props.user.is_activated) {
      mainContent = (
        <Switch>
          <Route
            path="/logout"
            render={(props) => {
              return (
                <WrapperLayout>
                  <Logout {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/settings"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Settings {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/chats"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Chats {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/support"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Support {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route path="/chats/:username" exact component={ChatLayout} />
          <Route path="/" exact component={VerifyEmail} />
          <Redirect to="/" />
        </Switch>
      );
    } else if (this.props.user && this.props.user.reg_flow === 0) {
      mainContent = (
        <Switch>
          <Route
            path="/logout"
            render={(props) => {
              return (
                <WrapperLayout>
                  <Logout {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/settings"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Settings {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/chats"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Chats {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route path="/chats/:username" exact component={ChatLayout} />
          <Route path="/" exact component={AddLocation} />
          <Redirect to="/" />
        </Switch>
      );
    } else if (this.props.user && this.props.user.reg_flow === 1) {
      mainContent = (
        <Switch>
          <Route
            path="/logout"
            render={(props) => {
              return (
                <WrapperLayout>
                  <Logout {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/settings"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Settings {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route path="/" exact component={UploadProfilePicture} />
          <Redirect to="/" />
        </Switch>
      );
    } else if (this.props.user && this.props.user.reg_flow === 2) {
      mainContent = (
        <Switch>
          <Route
            path="/logout"
            render={(props) => {
              return (
                <WrapperLayout>
                  <Logout {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/settings"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Settings {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route path="/" exact component={AddBelief} />
          <Redirect to="/" />
        </Switch>
      );
    } else if (
      this.props.user &&
      this.props.user.kyc_required &&
      !this.props.user.is_seller_verified
    ) {
      mainContent = mainContent = (
        <Switch>
          <Route
            path="/logout"
            render={(props) => {
              return (
                <WrapperLayout>
                  <Logout {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/settings"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Settings {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/support"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <Support {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Route
            path="/kyc"
            exact
            render={(props) => {
              return (
                <WrapperLayout>
                  <SellersRegister {...props} />
                </WrapperLayout>
              );
            }}
          />
          <Redirect to="/kyc" />
        </Switch>
      );
    }
    let app = (
      <LayoutContext.Provider
        value={{
          logout: this.props.logout,
          collapsed: this.props.collapsed,
          user: this.props.user,
          notificationCount: this.props.notificationCount,
          messageCount: this.props.messageCount,
          isAuth: this.props.isAuthenticated,
          setCollapseStatus: this.props.setCollapseStatus,
        }}
      >
        <div className={classes.Layout}>
          <div className={classes.Header}>
            <Header
              searchFormData={this.state.formData}
              submit={this.searchFormSubmit}
            />
          </div>
          <div className={classes.MainContainer}>{mainContent}</div>
          <SocialMenuModal />
          <MarketMenuModal />
          <CSSTransition
            in={this.state.notification === null ? false : true}
            timeout={300}
            unmountOnExit
            classNames="transition"
          >
            {this.state.notification ? (
              <NotificationModal
                close={this.closeHandler}
                notification={this.state.notification}
              />
            ) : (
              <div></div>
            )}
          </CSSTransition>
        </div>
      </LayoutContext.Provider>
    );
    if (this.props.userLoading) {
      app = (
        <div style={{ height: "100vh", background: "#5f04f6" }}>
          <OfficeSpinner size="5" />
        </div>
      );
    }
    if (this.props.userError) {
      app = (
        <div style={{ height: "100vh", background: "#5f04f6" }}>
          <Error
            refresh={this.refreshHandler}
            iconColor="#fff"
            color="#fff"
            error={this.props.userError}
          />
        </div>
      );
    }
    return app;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authState.userToken !== null,
    user: state.userState.user,
    userToken: state.authState.userToken,
    notificationCount: state.notificationState.notificationCount,
    messageCount: state.notificationState.messageCount,
    collapsed: state.userState.collapsed,
    userLoading: state.userState.userLoading,
    userError: state.userState.userError,
    search: state.searchState.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: () => dispatch(actionCreators.setUser()),
    updateUser: () => dispatch(actionCreators.updateUser()),
    fetchNotificationCount: () =>
      dispatch(actionCreators.fetchNotificationCount()),
    // fetchCountryStates: () => dispatch(actionCreators.fetchCountryStates()),
    setCollapseStatus: (collapsed) =>
      dispatch(actionCreators.setCollapseStatus(collapsed)),
    initialiseUserLoading: () =>
      dispatch(actionCreators.initialiseUserLoading()),
    logout: () => dispatch(actionCreators.logout()),
    setSearch: (search) => dispatch(actionCreators.setSearch(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
