import React from "react";
import classes from "./Spinner.module.css";
import LogoImage from "../Images/LogoImage/LogoImage";

const spinner = props => {
  return (
    <div style={{height: props.height}} className={classes.Spinner}>
      <LogoImage />
    </div>
  );
};

export default spinner;
