import React, { useRef, useEffect } from "react";
import classes from "./ProductMarketPostContent.module.css";
import { Link } from "react-router-dom";
import ProductPostImages from "../ProductPostImages/ProductPostImages";
import { abbreviateNumber } from "../../../../util/util";

const ProductMarketPostContent = (props) => {
  const titleRef = useRef(null);

  useEffect(() => {
    window.$clamp(titleRef.current, { clamp: 2 });
  }, []);

  const rating = [];

  let product_rating = 0;

  if (props.post.product.rating) {
    product_rating = props.post.product.rating;
  }

  for (let index = 0; index < Math.floor(product_rating); index++) {
    rating.push(
      <span key={index} className={`${classes.RatingActive} fa fa-star`}></span>
    );
  }
  if (product_rating % 1) {
    rating.push(
      <span
        key={Date.now()}
        className={`${classes.RatingActive} fa fa-star-half-alt`}
      ></span>
    );
  }

  for (let index = Math.ceil(product_rating); index < 5; index++) {
    rating.push(<span key={index} className="fal fa-star"></span>);
  }

  const showDescription = (event) => {
    event.preventDefault();
    props.description();
  };

  const tagsContent = [];

  const categoryId = props.post.product.category._id;
  const categoryName = props.post.product.category.name;
  tagsContent.push(
    <Link
      to={`/${
        props.post.product.is_airtime ? "airtime" : "shop"
      }/${categoryId}`}
      key={`${categoryName}-${Date.now()}`}
      className={classes.Tag}
    >
      <i className="mr-1 fas fa-tag"></i> {categoryName}
    </Link>
  );

  const dummyPercentage = Math.floor(
    ((props.post.product.dummy_price - props.post.product.price) /
      props.post.product.dummy_price) *
      100
  );

  return (
    <div className={classes.ProductMarketPostContent}>
      <ProductPostImages postId={props.post._id} product={props.post.product} />
      {/* <PostImages postId={props.post._id} imageData={postPhotos} /> */}
      <div className={classes.ProductDetail}>
        {tagsContent.length ? (
          <div className={classes.ProductTags}>{tagsContent}</div>
        ) : null}
        <div ref={titleRef} className={classes.ProductTitle}>
          {props.post.product.title}
        </div>
        <div className={classes.DetailsLink}>
          <a onClick={showDescription} href="/">
            More Details <i className="fas fa-external-link"></i>
          </a>
        </div>
        <div className={classes.ProductPrice}>
          <div className={classes.Price}>
            <p className={classes.MainPrice}>
              {`${
                props.post.product.country.currency.symbol
              } ${props.post.product.price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </p>
            <div className={classes.DummyPrice}>
              <p>
                {`${
                  props.post.product.country.currency.symbol
                } ${props.post.product.dummy_price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </p>
              <p className={`${classes.Badge} ml-2 badge badge-warning py-1`}>
                -{dummyPercentage}%
              </p>
            </div>
          </div>
          <div className={classes.Buy}>
            {props.post.product.is_airtime ? (
              <div className={classes.ProductRating}>
                <p className={classes.Rating}>
                  <span className={classes.Stars}>{rating}</span>
                  <a
                    onClick={props.review}
                    href="/"
                    className={classes.RatingNumber}
                  >
                    ({props.post.product.reviews.toFixed(0)} Reviews)
                  </a>
                </p>
              </div>
            ) : null}
            <div className={classes.BuyDetail}>
              {props.post.product.is_airtime ? (
                <p>Enjoy discount and instant Recharge. No Network Failure.</p>
              ) : (
                <div className={classes.PostStats}>
                  <p>{abbreviateNumber(props.post.likes)} Likes</p>
                  <p>{abbreviateNumber(props.post.views)} Views</p>
                  <p>{abbreviateNumber(props.post.comments)} Comments</p>
                </div>
              )}
              <button
                onClick={props.buy}
                className="mt-1 btn btn-block btn-outline"
              >
                {props.post.product.is_airtime ? "BUY NOW" : "VIEW PRODUCT"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMarketPostContent;
