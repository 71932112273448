import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Chats.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import Chat from "../../../components/App/Chat/Chat";
import { Link } from "react-router-dom";

class Chats extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      chats: [],
    };
    this.chatContentRef = React.createRef();
    this.chatRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadChats();
  }

  loadChats = () => {
    let page = 1;
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    const url = `/chats?page=${page}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const chats = response.data.data.chats;
        const pages = response.data.data.pages;
        const filteredChats = chats.filter((el) => {
          return !this.state.chats.find((chat) => chat._id === el._id);
        });
        const updatedChats = [...this.state.chats, ...filteredChats];
        this.setState({
          chats: updatedChats,
          pages: pages,
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  loadMoreChats = (event) => {
    event.preventDefault();
    this.loadChats();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  chatHandler = (chat, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.history.push("/chats/");
  };

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <a onClick={this.loadMoreChats} href="/">
            Load More <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadMoreChats} error={this.state.error} />
        </div>
      );
    }
    let content = null;
    if (this.state.chats.length) {
      content = this.state.chats.map((chat) => {
        return <Chat key={chat._id} chat={chat} />;
      });
    } else if (!this.state.loading && !this.state.error) {
      content = (
        <div className={classes.ChatEmpty}>
          <i className="fal fa-bell-slash"></i>
          <p>You don't have any chats.</p>
        </div>
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Chats</h4>
                </TitleHeader>
              </Title>
              <div className={classes.Chats}>
                <div className="alert alert-info f-10 rounded-0">
                  Please note that this chat is meant for USERS ONLY. Hawkit
                  will NEVER send you a message on this chat. Beware of
                  fraudsters posing as Hawkit Customer Support Staffs or agents
                  on this chat. We have a separate support and chat center where
                  you can chat directly with our support. Please Click{" "}
                  <Link to="/support">here</Link> to contact Hawkit Customer
                  Support Center and chat with our support staffs if you want.
                </div>
                {content}
                {loadingContent}
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps, null)(Chats);
