import React from "react";

const layoutContext = React.createContext({
  collapsed: null,
  logout: null,
  user: null,  
  isAuth: null,
  setCollapseStatus: null,  
});

export default layoutContext;
