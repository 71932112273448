/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./AddLocation.module.css";
import axios from "../../../axios/axios-instance";
import Form from "../../../components/UI/Forms/Form";
import {
  createButtonElement,
  createSelectElement,
} from "../../../components/UI/Forms/utility/form-creators";
import * as validators from "../../../components/UI/Forms/utility/validator";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import RegFlowLayout from "../../../components/App/Layout/RegFlowLayout/RegFlowLayout";
import Header from "../../../components/App/Layout/Header/Header";
import { Helmet } from "react-helmet";

class AddLocation extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      loading: false,
      error: false,
    };
  }

  initialiseFormData = () => {
    const genderOptions = this.props.meta.gender_options;
    const formData = {
      gender: createSelectElement(
        "",
        this.validateGender,
        "fas fa-list",
        "col-sm-12",
        genderOptions,
        "Select Gender",
        this.inputChangedHandler.bind(this, "gender"),
        this.props.user.gender ? this.props.user.gender : "",
        ""
      ),
      state: createSelectElement(
        "",
        this.validateState,
        "fas fa-list",
        "col-sm-12",
        this.props.country_states.map((el) => {
          return {
            value: el.name,
            option: el.name,
          };
        }),
        "Select State",
        this.inputChangedHandler.bind(this, "state"),
        ""
      ),
      lga: createSelectElement(
        "",
        this.validateLGA,
        "fas fa-list",
        "col-sm-12",
        [],
        "Select LGA",
        this.inputChangedHandler.bind(this, "lga"),
        "",
        "You will have to select your state before selecting the LGA."
      ),
      submit: createButtonElement("submit", "SET LOCATION", true),
    };
    this.setState({
      formData: formData,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.initialiseFormData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country_states !== this.props.country_states) {
      this.initialiseFormData();
    }
  }

  validateGender = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select a gender";
    }
    const genderOptions = this.props.meta.gender_options.map(
      (el) => el.value
    );
    if (!genderOptions.includes(value)) {
      return "Please select valid gender from the list above";
    }
    return null;
  };

  validateLGA = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your LGA from the list above. Make sure you select the state first before selecting the LGA.";
    }
    return null;
  };

  validateState = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your state from the list above.";
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    if (name === "state") {
      const state = this.props.country_states.find((el) => {
        return el.name === formElementData.value;
      });
      const lgas = state.locals.map((el) => {
        return {
          value: el.name,
          option: el.name,
        };
      });
      updatedFormData.lga = {
        ...updatedFormData.lga,
        value: "",
        options: lgas,
      };
    }
    this.setState({
      formData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  submitHandler = async (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const state = this.state.formData.state.value;
    const lga = this.state.formData.lga.value;
    const gender = this.state.formData.gender.value;
    const userToken = this.props.userToken;
    axios({
      method: "put",
      url: "/users/me/location/new",
      data: {
        state: state,
        lga: lga,
        gender: gender
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.setState({
          loading: false,
          upload: true,
        });
        this.props.processUser(user);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  render() {
    return (
      <div className="w-100">
        <div className={classes.Header}>
          <Header submit={null} />
        </div>
        <RegFlowLayout noSkip title="More About You">
          <div className={classes.AddLocation}>
            <Helmet>
              <title>More About You</title>
            </Helmet>
            <div className={classes.AddLocationContent}>
              <div className={classes.AddLocationForm}>
                <div className={classes.AddLocationContainer}>
                  <i
                    style={{ color: "#5f04f6" }}
                    className="fa fa-check-circle fa-5x mb-2"
                  ></i>
                  <h4 className="mb-2">Welcome to Hawkit!</h4>
                  <p className="mb-4 f-12">
                    Congratulations {this.props.user.name}, your email has
                    been verified successfully. You will need to set your
                    gender and current location before you can continue.
                  </p>
                  <div className={classes.LocationData}>
                    {this.state.error ? (
                      <AlertError error={this.state.error} />
                    ) : null}
                    <Form
                      loading={this.state.loading}
                      formData={this.state.formData}
                      submit={this.submitHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RegFlowLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    country_states: state.userState.country_states,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
