import React, { useEffect } from "react";
import classes from "./ProductDetailsModal.module.css";

const ProductDetailsModal = (props) => {
  let content = "<p>No description to show</p>";
  if (props.product.description && props.product.description.length) {
    content =
      "<p>" +
      props.product.description
        .replace(/\n{2,}/g, "</p><p>")
        .replace(/\n/g, "<br>") +
      "</p>";
  }
  useEffect(() => {
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);

  const detailsContent = props.product.details.map((el, index) => {
    if (!el.value.length) return false;
    return (
      <div key={index} className={`${classes.Detail} row`}>
        <div className="col-3">
          <div className={classes.DetailName}>
            <p>{el.name}:</p>
          </div>
        </div>
        <div className="col-9">
          <div className={classes.DetailValue}>
            <p>{el.value}</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={classes.ProductDetailsModal}>
      <div
        id="ProductDetailsModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className={`${classes.ProductDetailsModalContent} modal-content`}
          >
            <div
              className={`${classes.ProductDetailsModalHeader} modal-header`}
            >
              <h5>{props.product.title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="ProductDetailsModalBody"
              className={`${classes.ProductDetailsModalBody} modal-body`}
            >
              <div className={classes.Intro}>
                <h4>Product Description</h4>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                className={classes.ProductDescription}
              ></div>
              <div className={classes.DetailsContent}>{detailsContent}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsModal;
