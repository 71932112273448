/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./SocialTask.module.css";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";
import { withRouter, Link } from "react-router-dom";
import { getImage } from "../../../../util/image-util";
import BrowserLinks from "../../../../components/UI/Links/BrowserLinks/BrowserLinks";
import UploadPhoto from "../../../../components/App/UploadPhoto/UploadPhoto";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import ConfirmModal from "../../../../components/UI/Modal/ConfirmModal/ConfirmModal";
import VideoModal from "../../../../components/UI/Modal/VideoModal/VideoModal";

const moment = require("moment");

class SocialTask extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      formError: null,
      formSubmitting: false,
      image: props.task.proof ? props.task.proof.photo : null,
      formData: {
        photo: null,
        name: {
          value: props.task.proof ? props.task.proof.name : "",
        },
      },
      cancel_task: null,
      show_video: false,
    };
    this.captionRef = React.createRef();
  }

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({ formData: updatedFormData });
  };

  uploadPhoto = (file) => {
    const formElementData = { ...this.state.formData };
    formElementData.photo = file;
    this.setState({ formData: formElementData });
  };

  removePhoto = () => {
    const formElementData = { ...this.state.formData };
    formElementData.photo = null;
    this.setState({ formData: formElementData });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateName = () => {
    const value = this.state.formData.name.value;
    if (!value || validators.isEmpty(value.trim())) {
      return false;
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 2, 500)) {
      return false;
    }
    return true;
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.validateName()) {
      this.setState(
        {
          formError:
            "Please enter a valid name or username of your social media account that you used in performing the task.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              formError: null,
            });
          }, 3000);
        }
      );
      return;
    }
    if (!this.state.formData.photo) {
      this.setState(
        {
          formError:
            "You must upload a photo screenshot that shows you have performed the task above.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              formError: null,
            });
          }, 3000);
        }
      );
      return;
    }
    const userToken = this.props.userToken;
    const uploadFormData = new FormData();
    uploadFormData.append("photo", this.state.formData.photo);
    uploadFormData.append("name", this.state.formData.name.value);
    axios({
      method: "post",
      url: `/social/task/${this.props.task._id}/proof`,
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const task = response.data.data.task;
        this.props.updateTask(task);
        this.setState(
          {
            formSubmitting: false,
          },
          this.props.updateUser
        );
      })
      .catch((error) => {
        if (!this._isMounted) return;
        window.scrollTo(0, 0);
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              formSubmitting: false,
              formError: data.error[0].msg,
            });
          } else {
            this.setState({
              formSubmitting: false,
              formError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            formSubmitting: false,
            formError: errorMsg,
          });
        }
      });
    this.setState({
      formSubmitting: true,
      formError: null,
    });
  };

  cancelTaskHandler = () => {
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: `/social/task/${this.props.task._id}/cancel`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const task = response.data.data.task;
        this.props.updateTask(task);
        this.setState({
          formSubmitting: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        window.scrollTo(0, 0);
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              formSubmitting: false,
              formError: data.error[0].msg,
            });
          } else {
            this.setState({
              formSubmitting: false,
              formError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            formSubmitting: false,
            formError: errorMsg,
          });
        }
      });
    this.setState({
      formSubmitting: true,
      formError: null,
      cancel_task: null,
    });
  };

  hideCancelTask = () => {
    this.setState({
      cancel_task: null,
    });
  };

  showCancelTask = () => {
    this.setState({
      cancel_task: true,
    });
  };

  closeVideo = () => {
    this.setState({
      show_video: false,
    });
  };

  showVideo = () => {
    this.setState({
      show_video: true,
    });
  };

  copyReferralLink = (event) => {
    var captionText = this.captionRef.current;
    captionText.select();
    document.execCommand("Copy");
    window.$(event.target).html("Copied");
  };

  render() {
    const orderMoment = moment(this.props.task.created_on);
    let statusIcon, actionContent;
    if (this.props.task.status === "COMPLETED") {
      actionContent = (
        <div className="ml-auto">
          <Link
            to={`/social/task/${this.props.task._id}`}
            className="btn btn-success btn-xs"
          >
            <i className="fas fa-check-circle"></i> VIEW TASK
          </Link>
        </div>
      );
      statusIcon = (
        <div className="text-success ml-auto pr-2">
          <i className="fas fa-check-circle"></i>
        </div>
      );
    } else if (
      this.props.task.status === "CANCELLED" ||
      this.props.task.status === "FAILED"
    ) {
      actionContent = (
        <div className="ml-auto">
          <Link
            to={`/social/task/${this.props.task._id}`}
            className="btn btn-danger btn-xs"
          >
            <i className="fas fa-times-circle"></i> {this.props.task.status}
          </Link>
        </div>
      );
      statusIcon = (
        <div className="text-danger ml-auto pr-2">
          <i className="fas fa-times-circle"></i>
        </div>
      );
    } else {
      actionContent = (
        <div className="ml-auto">
          <Link
            to={`/social/task/${this.props.task._id}`}
            className="btn btn-outline btn-xs"
          >
            <i className="fas fa-check-circle"></i> DO NOW
          </Link>
        </div>
      );
      statusIcon = (
        <div className="text-gray ml-auto pr-2">
          <i className="fas fa-spin fa-spinner"></i>
        </div>
      );
    }

    const commentText = this.props.task.social_task_order.comments
      ? "<p>" +
        this.props.task.social_task_order.comments
          .replace(/\n{2,}/g, "</p><p>")
          .replace(/\n/g, "<br>") +
        "</p>"
      : null;

    const captionText = this.props.task.social_task_order.caption
      ? "<p class='f-14'>" +
        this.props.task.social_task_order.caption
          .replace(/\n{2,}/g, "</p><p>")
          .replace(/\n/g, "<br>") +
        "</p>"
      : null;

    let uploadProofStatusContent, cancelTaskContent;

    if (this.props.task.proof && this.props.task.proof.photo) {
      if (this.props.task.status === "UPLOADED") {
        uploadProofStatusContent = (
          <div className="alert alert-primary px-3 py-1 f-10">
            Thank you for performing this task. We are currently verifying what
            you have done. Your money is currently pending until the task you
            have performed is confirmed and approved. The funds will be
            available in your main wallet for withdrawal within 24hrs.
          </div>
        );
      } else if (this.props.task.status === "FAILED") {
        uploadProofStatusContent = (
          <div className="alert alert-danger px-3 py-1 f-10">
            Your task has been disapproved because you didnt follow the strict
            instructions given above. You will be no longer be able to perform
            the task. Ensure you follow strict instructions when performing
            tasks in order not to have your membership suspended.
            <br />
            <br />
            <b>Reason for Disapproval:</b> {this.props.task.cancel_reason || ""}
          </div>
        );
      } else if (this.props.task.status === "COMPLETED") {
        uploadProofStatusContent = (
          <div className="alert alert-success px-3 py-1 f-10">
            Your task has been approved and completed successfully. Your money
            has been deposited into your wallet for withdrawal. Thank you.
          </div>
        );
      }
    }
    if (
      this.props.task.status === "CANCELLED" ||
      this.props.task.status === "FAILED"
    ) {
      cancelTaskContent = (
        <div className="alert alert-danger px-3 py-1 f-10">
          This task has been cancelled. You can no longer perform the task.
        </div>
      );
    } else if (!this.props.task.proof || !this.props.task.proof.photo) {
      cancelTaskContent = (
        <div className="alert text-center bg-light alert-secondary px-3 py-2">
          <p className="f-10 mb-1">
            Don't want to perform this task? You can delete the task below and
            you will NOT be penalised.
          </p>
          <button
            disabled={this.state.formSubmitting}
            onClick={this.showCancelTask}
            className="btn btn-danger btn-xs"
          >
            {this.state.formSubmitting ? (
              <i className="fas fa-spin fa-spinner fa-2x"></i>
            ) : (
              "DELETE THIS TASK"
            )}
          </button>
        </div>
      );
    }

    return (
      <div className={classes.SocialTask}>
        {this.props.task.social_task_category.video &&
        this.props.task.social_task_category.video.seller ? (
          <div className={classes.VideoTutorialContainer}>
            <p>
              <i>
                Please watch this short video tutorial to learn how to do this
                task properly without failing the task and losing your money.
              </i>
            </p>
            <button onClick={this.showVideo} className="btn btn-xs btn-outline">
              <i className="fas fa-play-circle"></i> WATCH VIDEO
            </button>
          </div>
        ) : null}
        <div className={classes.SocialTaskHeading}>
          <div className={classes.SocialTaskHeaderIcon}>
            <img
              src={getImage(this.props.task.social_task_order.platform)}
              alt="icon"
            />
          </div>
          <div className={classes.SocialTaskHeader}>
            <p className="f-8 text-gray">
              {orderMoment.format("MMM Do YYYY h:mm a")}
            </p>
            <h5>{this.props.task.social_task_category.title.seller}</h5>
            <p>
              Earnings:{" "}
              <b>{this.props.task.social_task_category.pricing_title.seller}</b>
            </p>
          </div>
          {this.props.show_info ? statusIcon : actionContent}
        </div>
        {this.props.show_info ? (
          <div className={classes.SocialTaskContent}>
            {uploadProofStatusContent}
            <div className={classes.Info}>
              <div className="input-group mb-1">
                <input
                  type="text"
                  className="form-control f-10"
                  value={this.props.task.link}
                  readOnly
                />
                <div className="input-group-append">
                  <BrowserLinks
                    to={this.props.task.link}
                    className="d-flex align-items-center btn btn-warning btn-xs"
                  >
                    VISIT LINK
                  </BrowserLinks>
                </div>
              </div>
              <p className="f-10 fw-600 mb-2">
                Please follow the instructions below to do your task:
              </p>
              <div className="px-2">
                {this.props.task.social_task_category.task_steps.map(
                  (el, index) => {
                    return (
                      <p key={index} className="f-10 mb-1">
                        {index + 1}. {el}
                      </p>
                    );
                  }
                )}
              </div>
              {this.props.task.social_task_order.caption ? (
                <div className={`my-2 ${classes.Comments}`}>
                  <h5>Whatsapp Status Caption</h5>
                  <div className="input-group mt-1">
                    <input
                      ref={this.captionRef}
                      type="text"
                      className="form-control f-10"
                      value={this.props.task.social_task_order.caption}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        onClick={this.copyReferralLink}
                        className="d-flex align-items-center btn btn-warning btn-xs"
                      >
                        COPY CAPTION
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.props.task.social_task_order.comments ? (
                <div className={classes.Comments}>
                  <h5>
                    {this.props.task.social_task_category.instructions
                      ? this.props.task.social_task_category.instructions
                      : `${this.props.task.social_task_category.text} Examples`}
                  </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: commentText,
                    }}
                  ></div>
                </div>
              ) : null}
              <div className="alert alert-info px-3 py-1 f-10">
                This task must be performed with a REAL and ACTIVE Account. You
                will be penalised and your membership will be cancelled if you
                use a fake or dormant account.
              </div>
            </div>
            {this.props.task.status !== "CANCELLED" &&
            this.props.task.status !== "FAILED" ? (
              <>
                <p className="f-10 fw-600 mb-1">Upload Proof of Work</p>
                {this.state.formError ? (
                  <AlertError error={this.state.formError} />
                ) : null}
                <div className={classes.Form}>
                  <div className={classes.UploadPhoto}>
                    <UploadPhoto
                      upload={this.uploadPhoto}
                      remove={this.removePhoto}
                      image={this.state.image}
                      nomargin
                      showText
                      size="10"
                    />
                  </div>
                  <div className={classes.Input}>
                    <label>
                      Enter the Name or Username of your social media account
                      that used to perform this task. If this is a whatsapp
                      task, simply enter your phone number. For other kind of
                      tasks, you can enter just your full names
                    </label>
                    <input
                      type="text"
                      placeholder="Name or Username"
                      className="form-control f-12"
                      value={this.state.formData.name.value}
                      onChange={(e) => this.inputChangedHandler("name", e)}
                    />
                    <button
                      onClick={this.submitHandler}
                      disabled={
                        this.state.formSubmitting ||
                        this.props.task.status === "COMPLETED"
                      }
                      className="btn btn-xs btn-coloured-heavy"
                    >
                      {this.state.formSubmitting ? (
                        <i className="fas fa-spin fa-spinner fa-2x"></i>
                      ) : (
                        "UPLOAD PROOF"
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            {this.state.cancel_task ? (
              <ConfirmModal
                title="Delete this Task?"
                description="Are you sure you want to delete this task. This action cannot be undone once it is performed."
                confirm={this.cancelTaskHandler}
                cancel={this.hideCancelTask}
              />
            ) : null}
            {this.props.task.social_task_category.video &&
            this.props.task.social_task_category.video.seller &&
            this.state.show_video ? (
              <VideoModal
                close={this.closeVideo}
                video={this.props.task.social_task_category.video.seller}
              />
            ) : null}
            {cancelTaskContent}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
    updateUser: () => dispatch(actionCreators.updateUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SocialTask));
