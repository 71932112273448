/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import UserPhoto from "../User/UserPhoto/UserPhoto";
import classes from "./MessageNotificationModal.module.css";

const MessageNotificationModal = (props) => {
  const contentRef = useRef(null);
  const userPhotoRef = useRef(null);
  const modalRef = useRef(null);

  const showMessageHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.history.push(`/chats/${props.chat.sender.username}`);
    props.close();
  };

  useEffect(() => {
    const modalWidth = modalRef.current.offsetWidth;
    const photoWidth = userPhotoRef.current.offsetWidth;
    contentRef.current.style.width = modalWidth - photoWidth - 25 + "px";
  }, []);

  return (
    <div className={classes.MessageNotificationModal}>
      <div
        className={classes.RemoveContainer}
        onClick={props.close}
        title="Close"
      >
        <span className="fa fa-times"></span>
      </div>
      <div
        onClick={showMessageHandler}
        ref={modalRef}
        className={classes.MessageNotificationModalContent}
      >
        <div ref={userPhotoRef} className={classes.UserPhoto}>
          <UserPhoto
            photo={props.chat.sender.profile_pic}
            width="36"
            height="36"
          />
        </div>
        <div ref={contentRef} className={classes.MessageContent}>
          <div className={classes.MainTitle}>
            <p>You have a new message</p>
            <a onClick={showMessageHandler} href="/">
              <h4>
                {props.chat.sender.username}{" "}
                {props.chat.sender.is_verified ? (
                  <i className="fas fa-badge-check"></i>
                ) : null}
              </h4>
            </a>
          </div>
          <div className={classes.Message}>
            <p>{props.chat.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MessageNotificationModal);
