import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Activities.module.css";
import ShowActivities from "../../../components/App/User/ShowActivities/ShowActivities";
import { Link } from "react-router-dom";

class Activities extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      loading: false,
      error: null,
      pages: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadRecentActivities();
  }

  loadRecentActivities = (event) => {
    if (event) event.preventDefault();
    if (this.state.loading) return;
    const userToken = this.props.userToken;
    let limit = this.props.limit;
    if (!limit) {
      limit = 10;
    }
    let page = 1;
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const url = `/search/activities?page=${page}&limit=${limit}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : "",
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const activities = response.data.data.activities;
        const filteredActivities = activities.filter((el) => {
          return !this.state.activities.find(
            (activity) => activity._id === el._id
          );
        });
        const updatedActivities = [
          ...this.state.activities,
          ...filteredActivities,
        ];
        const pages = response.data.data.pages;
        this.setState({
          loading: false,
          activities: updatedActivities,
          pages: pages,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.Loading}>
          <a onClick={this.loadRecentActivities} href="/">
            Load More <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    } else if (this.state.loading) {
      loadingContent = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.Loading}>
          <Error error={this.state.error} refresh={this.loadRecentActivities} />
        </div>
      );
    }
    let content;
    if (this.state.activities.length) {
      content = (
        <ShowActivities
          activeUser={this.props.user}
          activities={this.state.activities}
        />
      );
    } else if (!this.state.loading && !this.state.error) {
      content = (
        <div className={classes.ActivitiesEmpty}>
          <i className="fal fa-bell-slash"></i>
          <p className="mb-3">
            There are no recent activities yet. Click the button below to
            perform a task.
          </p>
          <Link to="/social/tasks" className="btn btn-coloured-heavy">
            PERFORM A TASK
          </Link>
        </div>
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>All Activities</h4>
                </TitleHeader>
              </Title>
              <div className={classes.Activities}>
                {content}
                {loadingContent}
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps, null)(Activities);
