import React from "react";
import classes from "./PreviewImages.module.css";
import PreviewImage from "./PreviewImage/PreviewImage";
import UploadProduct from "../UploadProduct/UploadProduct";

const PreviewImages = (props) => {
  const content = props.products.map((el, index) => (
    <PreviewImage
      clicked={() => props.setActive(index)}
      active={props.active === index ? true : false}
      key={index}
      removePhoto={(event) => props.removePhoto(event, index)}
      image={el.photo}
    />
  ));
  return (
    <div id="PreviewImages" className={classes.PreviewImages}>
      {content}
      {props.noUpload ? null : (
        <UploadProduct
          upload={props.upload}
          multiple={props.multiple}
          text={props.caption ? props.caption : "Add New Photo(s)"}
        />
      )}
    </div>
  );
};

export default PreviewImages;
