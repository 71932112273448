/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./ChatLayout.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import SideMenu from "../../../components/App/Layout/SideMenu/SideMenu";
import UserSideMenu from "../../../components/App/Layout/SideMenu/UserSideMenu/UserSideMenu";
import SideColumn from "../../../components/App/Layout/SideMenu/SideColumn/SideColumn";
import Footer from "../../../components/App/Layout/Footer/Footer";
import ChatEditor from "../Create/ChatEditor/ChatEditor";
import { Link } from "react-router-dom";
import ChatMessage from "../../../components/App/ChatMessage/ChatMessage";
import socketio from "../../../util/socket";
import RecentActivities from "../RecentActivities/RecentActivities";
import UserPhoto from "../../../components/App/User/UserPhoto/UserPhoto";
import { playMessageSound } from "../../../util/util";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";

const moment = require("moment");

class ChatLayout extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      messages: [],
      chat_id: null,
      recipientUser: null,
    };
    this.chatContentRef = React.createRef();
    this.chatEditorRef = React.createRef();
    this.chatLayoutRef = React.createRef();
  }

  refreshLayout = () => {
    this.setState(
      {
        loading: false,
        error: null,
        pages: null,
        messages: [],
        chat_id: null,
        recipientUser: null,
      },
      () => {
        this.closeAllSocketListeners();
        this.loadChats();
        this.chatEditorRef.current.style.width =
          this.chatEditorRef.current.offsetWidth + "px";
        this.chatEditorRef.current.style.position = "fixed";
        const socket = socketio.getSocket();
        if (socket) {
          socket.on("message received", this.messageReceivedListener);
          socket.on("message read", (message) => {
            if (!this._isMounted || !this.state.recipientUser) return;
            this.updateMessage(message);
          });
          socket.on("message delivered", (message) => {
            if (!this._isMounted || !this.state.recipientUser) return;
            this.updateMessage(message);
          });
          socket.on("message all_read", (recipient) => {
            if (!this._isMounted || !this.state.recipientUser) return;
            if (this.state.recipientUser._id === recipient) {
              this.markAllMessagesAsRead();
            }
          });
        }
      }
    );
  };

  messageReceivedListener = (message) => {
    const socket = socketio.getSocket();
    if (!socket || !this._isMounted || !this.state.recipientUser) return;
    if (
      (message.sender._id || message.sender) === this.props.user._id ||
      (message.sender._id || message.sender) === this.state.recipientUser._id
    ) {
      this.addMessage(message, "received");
      socket.emit("message read", message._id);
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadChats();
    const socket = socketio.getSocket();
    if (socket) {
      socket.on("message received", this.messageReceivedListener);
      socket.on("message read", (message) => {
        if (!this._isMounted || !this.state.recipientUser) return;
        this.updateMessage(message);
      });
      socket.on("message delivered", (message) => {
        if (!this._isMounted || !this.state.recipientUser) return;
        this.updateMessage(message);
      });
      socket.on("message all_read", (recipient) => {
        if (!this._isMounted || !this.state.recipientUser) return;
        if (this.state.recipientUser._id === recipient) {
          this.markAllMessagesAsRead();
        }
      });
    }
    window.addEventListener("resize", this.chatFocusHandler);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.username !== prevProps.match.params.username) {
      this.refreshLayout();
    }
  }

  addMessage = (message, type = "sent") => {
    const chat_message = this.state.messages.find(
      (el) => message._id === el._id
    );
    if (!chat_message) {
      const messages = [...this.state.messages];
      messages.push(message);
      messages.sort((el1, el2) => {
        return (
          new Date(el1.created_on).getTime() -
          new Date(el2.created_on).getTime()
        );
      });
      this.setState(
        {
          messages: messages,
        },
        () => playMessageSound(type)
      );
    }
  };

  updateMessage = (message) => {
    const chat_message_index = this.state.messages.findIndex(
      (el) => message._id === el._id
    );
    if (chat_message_index !== -1) {
      const messages = [...this.state.messages];
      messages[chat_message_index] = message;
      messages.sort((el1, el2) => {
        return (
          new Date(el1.created_on).getTime() -
          new Date(el2.created_on).getTime()
        );
      });
      this.setState({
        messages: messages,
      });
    }
  };

  onSocketMessages = (messages) => {
    const filteredMessages = messages.filter((el) => {
      return !this.state.messages.find((message) => message._id === el._id);
    });
    const updatedMessages = [...filteredMessages, ...this.state.messages];
    updatedMessages.sort((el1, el2) => {
      return (
        new Date(el1.created_on).getTime() - new Date(el2.created_on).getTime()
      );
    });
    this.setState({
      messages: updatedMessages,
    });
  };

  markAllMessagesAsRead = () => {
    const messages = this.state.messages;
    const updatedMessages = [];
    for (let message of messages) {
      const updatedMessage = {
        ...message,
      };
      if (this.props.user._id === (message.sender._id || message.sender)) {
        updatedMessage.status.read = true;
        updatedMessage.status.delivered = true;
      }
      updatedMessages.push(updatedMessage);
    }
    this.setState({
      messages: updatedMessages,
    });
  };

  loadChats = () => {
    let page = 1;
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const { username } = this.props.match.params;
    const userToken = this.props.userToken;
    const url = `/chats/${username}?page=${page}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const messages = response.data.data.messages;
        const pages = response.data.data.pages;
        const recipientUser = response.data.data.recipientUser;
        const messageCount = response.data.data.messageCount;
        this.props.setMessageCount(messageCount);
        const chat_id = response.data.data.chat_id;
        const filteredMessages = messages.filter((el) => {
          return !this.state.messages.find((message) => message._id === el._id);
        });
        const updatedMessages = [...filteredMessages, ...this.state.messages];
        updatedMessages.sort((el1, el2) => {
          return (
            new Date(el1.created_on).getTime() -
            new Date(el2.created_on).getTime()
          );
        });
        this.setState({
          messages: updatedMessages,
          pages: pages,
          loading: false,
          chat_id: chat_id,
          recipientUser: recipientUser,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  loadMoreChats = (event) => {
    event.preventDefault();
    this.loadChats();
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.closeAllSocketListeners();
    window.removeEventListener("resize", this.chatFocusHandler);
  }

  closeAllSocketListeners() {
    const socket = socketio.getSocket();
    if (!socket) return;
    socket.off("message received", this.messageReceivedListener);
    socket.off("message read");
    socket.off("message delivered");
    socket.off("message all_read");
  }

  chatHandler = (chat, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.history.push("/chats/");
  };

  chatFocusHandler = () => {
    this.chatContentRef.current.scrollTo(
      0,
      this.chatContentRef.current.scrollHeight
    );
  };

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <a onClick={this.loadMoreChats} href="/">
            Load More Messages <span className="fa fa-chevron-up"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadMoreChats} error={this.state.error} />
        </div>
      );
    }
    let chatContent = null,
      titleContent = null;
    let chatBox = null;
    if (this.state.recipientUser) {
      chatBox = (
        <div ref={this.chatEditorRef} className={classes.ChatEditor}>
          <ChatEditor
            focus={this.chatFocusHandler}
            submit={this.addMessage}
            chat_id={this.state.chat_id}
            recipientUser={this.state.recipientUser}
          />
        </div>
      );
      const now = moment();
      const postMoment = moment(this.state.recipientUser.last_seen);
      const daysDiff = now.diff(postMoment, "days");
      let formatDate;
      if (daysDiff >= 1) {
        formatDate = postMoment.calendar();
      } else {
        formatDate = postMoment.fromNow();
      }
      titleContent = (
        <div className={classes.UserTitle}>
          <div className={classes.UserPhoto}>
            <UserPhoto
              photo={this.state.recipientUser.profile_pic}
              width="36"
              height="36"
            />
          </div>
          <div className={classes.MainTitle}>
            <Link to={`/users/${this.state.recipientUser.username}`}>
              <h4>
                {this.state.recipientUser.username}{" "}
                {this.state.recipientUser.is_verified ? (
                  <i className="fas fa-badge-check"></i>
                ) : null}
              </h4>
            </Link>
            {this.state.recipientUser.is_online ? (
              <p>
                <span className="text-success">
                  <i className="fas fa-check-circle"></i>
                </span>{" "}
                Online
              </p>
            ) : (
              <p>
                <span className="fal fa-clock"></span> Last Seen: {formatDate}
              </p>
            )}
          </div>
        </div>
      );
    } else {
      chatBox = (
        <div ref={this.chatEditorRef} className={classes.ChatEditor}>
          <OfficeSpinner size="3" />
        </div>
      );
      titleContent = <h4>Loading Messages...</h4>;
    }
    if (this.state.messages.length) {
      chatContent = this.state.messages.map((message) => {
        return (
          <ChatMessage
            is_user_active={
              this.props.user._id === (message.sender._id || message.sender)
            }
            key={message._id}
            message={message}
          />
        );
      });
    } else if (!this.state.loading && !this.state.error) {
      chatContent = (
        <div className={classes.ChatEmpty}>
          <i className="fal fa-bell-slash"></i>
          <p>You don't have any messages.</p>
        </div>
      );
    }

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="offset-xl-1 col-xl-10">
              <div className="row">
                <div className="d-none pr-0 d-lg-block col-lg-3">
                  <div className={classes.SideMenu}>
                    <SideMenu>
                      <UserSideMenu />
                    </SideMenu>
                  </div>
                </div>
                <div className="pr-md-0 col-sm-8 col-lg-6">
                  <ContentLayout>
                    <SingleColumnLayout>
                      <MainContent>
                        <PageContent>
                          <Title fixed>
                            <TitleHeader>
                              <BackButton />
                              {titleContent}
                            </TitleHeader>
                          </Title>
                          <div
                            ref={this.chatLayoutRef}
                            className={classes.ChatLayout}
                          >
                            <div className="alert alert-info f-10 rounded-0">
                              Please note that this chat is meant for USERS
                              ONLY. Hawkit will NEVER send you a message on this
                              chat. Beware of fraudsters posing as Hawkit
                              Customer Support Staffs or agents on this chat. We
                              have a separate support and chat center where you
                              can chat directly with our support. Please
                              Click <Link to="/support">here</Link> to contact
                              Hawkit Customer Support Center and chat with our
                              support staffs if you want.
                            </div>
                            <div
                              ref={this.chatContentRef}
                              className={classes.ChatContent}
                            >
                              {loadingContent}
                              {chatContent}
                            </div>
                            {chatBox}
                          </div>
                        </PageContent>
                      </MainContent>
                    </SingleColumnLayout>
                  </ContentLayout>
                </div>
                {window.outerWidth >= 768 ? (
                  <div className="col-sm-4 col-lg-3">
                    <div className={classes.SideColumn}>
                      <SideColumn>
                        <RecentActivities />
                        <Footer />
                      </SideColumn>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageCount: (messageCount) =>
      dispatch(actionCreators.setMessageCount(messageCount)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatLayout);
