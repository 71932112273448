/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./CancelDeliveryModal.module.css";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";

let is_Mounted = false;

const CancelDeliveryModal = props => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    completed: null
  });

  const cancelModalHandler = event => {
    event.preventDefault();
    window.$("#CancelDeliveryModal").on("hidden.bs.modal", event => {
      props.refresh();
    });
    window.$("#CancelDeliveryModal").modal("hide");
  };

  const cancelHandler = () => {
    setState({
      ...state,
      error: null,
      loading: true
    });
    const userToken = props.userToken;
    axios({
      method: "post",
      url: `orders/${props.cancelDelivery.orderId}/${props.cancelDelivery.salesOrderId}/delivery/cancel`,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!is_Mounted) return;
        const status = response.data.data.status;
        if (status === "success") {
          setState({
            ...state,
            loading: false,
            completed: true
          });
        }
      })
      .catch(error => {
        if (!is_Mounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg
          });
        }
      });
  };

  let content = null;
  if (state.loading) {
    content = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.completed) {
    content = (
      <div className="py-2 text-center">
        <i className="mb-2 fas fa-check-circle fa-3x text-success"></i>
        <p className="mb-2">Order has been cancelled successfully.</p>
        <button
          onClick={cancelModalHandler}
          className="btn btn-danger btn-rounded"
        >
          BACK
        </button>
      </div>
    );
  } else {
    content = (
      <div className="py-2">
        {state.error ? (
          <div className="alert alert-danger">{state.error}</div>
        ) : null}
        <button
          onClick={cancelHandler}
          className="mr-1 btn btn-success btn-rounded"
        >
          YES
        </button>
        <button
          data-dismiss="modal"
          aria-label="Close"
          className="btn btn-danger btn-rounded"
        >
          NO
        </button>
      </div>
    );
  }

  useEffect(() => {
    is_Mounted = true;
    window.$("#CancelDeliveryModal").modal({
      backdrop: "static"
    });
    window.$("#CancelDeliveryModal").off("hidden.bs.modal");
    window.$("#CancelDeliveryModal").modal("show");
    window.$("#CancelDeliveryModal").on("hidden.bs.modal", event => {
      props.destroy();
    });
    return () => {
      is_Mounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#CancelDeliveryModal").modal("hide");
    };
  }, []);
  return (
    <div className={classes.CancelDeliveryModal}>
      <div
        id="CancelDeliveryModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className={`${classes.CancelDeliveryModalContent} modal-content`}
          >
            <div
              className={`${classes.CancelDeliveryModalHeader} modal-header`}
            >
              <h5>Cancel Order</h5>
            </div>
            <div
              id="CancelDeliveryModalBody"
              className={`${classes.CancelDeliveryModalBody} modal-body`}
            >
              <p>
                Are you sure you want to cancel this order? Please note that
                this may attract penalty charges and possible suspension from
                using our services.
              </p>
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userToken: state.authState.userToken
  };
};

export default connect(
  mapStateToProps,
  null
)(CancelDeliveryModal);
