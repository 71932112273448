/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import classes from "./Home.module.css";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Content from "../../../components/App/Layout/ContentLayout/Content/Content";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
// import FloatingButton from "../../../components/UI/Buttons/FloatingButton/FloatingButton";
import Header from "../../../components/App/Layout/Header/Header";
// import Banner from "../../../components/App/Banner/Banner";
// import MobileAppDownload from "../../../components/App/MobileAppDownload/MobileAppDownload";
import RecentActivities from "../RecentActivities/RecentActivities";
import UserHomeWallet from "../../../components/App/User/UserHomeWallet/UserHomeWallet";
// import UserReferral from "../../../components/App/User/UserReferral/UserReferral";
// import TopProducts from "../../../components/App/Products/TopProducts/TopProducts";
import { Helmet } from "react-helmet";
import AppHomeFeatures from "../../../components/App/AppFeatures/AppHomeFeatures/AppHomeFeatures";
import WelcomeFeatures from "../../../components/App/AppFeatures/WelcomeFeatures/WelcomeFeatures";
import SocialTransactionContents from "../../../components/App/SocialTransactionContents/SocialTransactionContents";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import axios from "../../../axios/axios-instance";
import { Link } from "react-router-dom";

class Home extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      viewedAll: false,
      suggestions: null,
      transactions: null,
      loading: false,
      error: null
    };
    this.homeRef = React.createRef();
    this.nowSellingRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    this.loadTaskPricing();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createHandler = (event) => {
    event.preventDefault();
    this.props.history.push("/post");
  };

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadPosts(false);
  };

  loadTaskPricing = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/tasks/pricing`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const transactions = response.data.data.task_pricing;
        this.setState({
          transactions: transactions,
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadTaskPricing} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;
    const transactions = this.state.transactions;
    if (transactions) {
      const active_transactions = transactions.filter(
        (el) => el.tasks !== 0
      );
      content = (
        <SocialTransactionContents
          rowClass=""
          content_type="task"
          transactions={active_transactions}
        />
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <Content>
              <Helmet>
                <title>
                  Hawkit - Perform Social Tasks and Earn Steady Income Daily
                </title>
              </Helmet>
              <div id="home" ref={this.homeRef} className={classes.Home}>
                <div className={classes.Header}>
                  <Header submit={null} />
                </div>
                {/* <MobileAppDownload /> */}
                {/* <PreviewEditor user={this.props.user} /> */}
                <UserHomeWallet />
                {this.props.user.user_type === "member" ||
                  this.props.user.user_type === "advertiser" ||
                  this.props.user.user_type === "buyer" ? (
                  <>
                    <AppHomeFeatures />
                  </>
                ) : (
                  <WelcomeFeatures />
                )}
                <div className={classes.TaskContainer}>
                  <div className={classes.TaskHeader}>
                    <h4>Available Tasks</h4>
                    <p>See our currently available tasks below. Click any of the tasks below to start earning</p>
                    <button
                      onClick={() => this.props.history.push("/shop")}
                      className={classes.ResellButton}
                    >
                      <h4 className="mr-2">
                        CLICK HERE TO RESELL PRODUCTS AND EARN UP TO ₦100,000
                        MONTHLY IN SALES COMMISSIONS
                      </h4>
                      <div className="mr-auto">
                        <span className="badge badge-danger">
                          HOT AND NEW!!!
                        </span>
                      </div>
                    </button>
                  </div>
                  <div className={classes.TaskContent}>
                    {content}
                  </div>
                  <div className="p-3 text-center">
                    <Link className="btn btn-primary btn-xl" to="/earn">SEE ALL TASKS</Link>
                  </div>
                </div>
                {/* <TopProducts
                  is_grocery
                  link=""
                  parent="TopProductPosts"
                  scrollContainer="TopProductRow"
                /> */}
                {/* <UserReferral /> */}
                {/* <UserTasks /> */}
                {window.outerWidth < 768 ? (
                  <div className={classes.RecentActivities}>
                    <RecentActivities />
                  </div>
                ) : null}
              </div>
            </Content>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    is_seller_product_failed: state.notificationState.is_seller_product_failed,
    meta: state.userState.meta,
    advert_banner: state.notificationState.advert_banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
    fetchCountryStates: () => dispatch(actionCreators.fetchCountryStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
