/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./ResetPassword.module.css";
import * as validators from "../../../components/UI/Forms/utility/validator";
import Form from "../../../components/UI/Forms/Form";
import {
  createInputElement,
  createButtonElement,
} from "../../../components/UI/Forms/utility/form-creators";
import LogoImage from "../../../components/UI/Images/LogoImage/LogoImage";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "../../../axios/axios-instance";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import * as actionCreators from "../../../store/actions/actions";
import { Helmet } from "react-helmet";

class ResetPassword extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        code: createInputElement(
          "text",
          null,
          "Enter Code",
          "",
          this.validateCode,
          "A password reset code was sent to the email associated with your account. Please enter the code in the space provided above.",
          "fa fa-user",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "code")
        ),
        password: createInputElement(
          "password",
          null,
          "New Password",
          "",
          this.validatePassword,
          "Password must contain atleast 6 Characters",
          "fa fa-lock",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "password")
        ),
        submit: createButtonElement("submit", "RESET PASSWORD", true),
      },
      processing: false,
      formError: null,
    };
  }

  validateCode(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid reset code.!";
    }
    if (!validators.isLetterOrNumber(value.trim())) {
      return "Invalid Password Reset Code. Please check your mail for the correct code.";
    }
    return null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validatePassword(value) {
    if (validators.isEmpty(value)) {
      return "Please enter a password!";
    }
    if (!validators.checkPassword(value)) {
      return "Password must be 6 - 20 characters";
    }
    return null;
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({
      formData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  resetPasswordHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const code = this.state.formData.code.value;
    const password = this.state.formData.password.value;
    axios({
      method: "post",
      url: "/auth/reset-password/",
      data: {
        forgotPasswordId: this.props.forgotPassword.id,
        code: code,
        password: password,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        this.setState({
          processing: false,
        });
        const userToken = response.data.data.auth;
        this.props.login(userToken);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            const formDataError = this.createFormError(data.error);
            if (formDataError) {
              this.setState({
                processing: false,
                formData: formDataError,
              });
            } else {
              this.setState({
                processing: false,
                formError: data.error[0].msg,
              });
            }
          } else {
            this.setState({
              processing: false,
              formError: data.error,
            });
          }
        } else {
          this.setState({
            processing: false,
            formError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      processing: true,
      formError: null,
    });
    return false;
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  render() {
    let content = <Redirect to="/forgot-password" />;
    if (this.props.forgotPassword) {
      content = (
        <div className={classes.Register}>
          <Helmet>
            <title>Reset Password</title>
          </Helmet>
          <div className={classes.RegisterContent}>
            <div className={classes.RegisterForm}>
              <h3 className="mb-1">Reset Password</h3>
              <p className="mb-4">
                Enter the reset code sent to your registered email address{" "}
                <b>({this.props.forgotPassword.email})</b> followed by your new
                password
              </p>
              <div
                className={`text-center d-flex flex-column align-items-center`}
              >
                {this.state.formError ? (
                  <AlertError error={this.state.formError} />
                ) : null}
                <Form
                  loading={this.state.processing}
                  formData={this.state.formData}
                  submit={this.resetPasswordHandler}
                />
                <Link className="mt-3" replace to="/auth/login">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    forgotPassword: state.authState.forgotPassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (userToken) => {
      dispatch(actionCreators.newUserLogin(userToken));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
