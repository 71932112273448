import React, { Component } from "react";
import classes from "./PreviewLayout.module.css";
import { Switch, Route } from "react-router-dom";
import PreviewRouteLayout from "./PreviewRouteLayout/PreviewRouteLayout";
import Auth from "../../Auth/Auth";
import SocialMenuModal from "../../App/Layout/SocialMenuModal/SocialMenuModal";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import RefPage from "../../App/RefPage/RefPage";
import MarketMenuModal from "../../App/Layout/MarketMenuModal/MarketMenuModal";
import PreviewHeader from "../../../components/App/Layout/PreviewHeader/PreviewHeader";
import PreviewFooter from "../../../components/App/Layout/Footer/PreviewFooter/PreviewFooter";
import PostViewLayout from "../../App/Layout/PostViewLayout/PostViewLayout";
import OrderLayout from "../../App/Layout/OrderLayout/OrderLayout";
import ResPage from "../../App/ResPage/ResPage";

class PreviewLayout extends Component {
  render() {
    return (
      <div className={classes.PreviewLayout}>
        <div className={classes.Header}>
          <PreviewHeader />
        </div>
        <div className={classes.MainContainer}>
          <Switch>
            <Route path="/posts/:postId" component={PostViewLayout} />
            <Route path="/orders/checkout/:orderId" component={OrderLayout} />
            <Route path="/shop/sell" component={PreviewRouteLayout} />
            <Route path="/resell/:reseller_code" component={ResPage} />
            <Route path="/ref/:username" component={RefPage} />
            <Route path="/content" component={null} />
            <Route path="/" component={PreviewRouteLayout} />
          </Switch>
        </div>
        {!this.props.location.pathname.includes("shop") &&
        !this.props.location.pathname.includes("posts") &&
        !this.props.location.pathname.includes("orders") &&
        !window.cordova ? (
          <PreviewFooter />
        ) : null}
        <SocialMenuModal />
        <MarketMenuModal />
        <div className={classes.Auth}>
          <Switch>
            <Route path="/auth" component={Auth} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.searchState.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearch: (search) => dispatch(actionCreators.setSearch(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewLayout);
