/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { withRouter } from "react-router";
import classes from "./InsufficientFundModal.module.css";

const InsufficientFundModal = (props) => {
  const fundWalletHandler = (event) => {
    event.preventDefault();
    window.$("#InsufficientFundModal").off("hidden.bs.modal");
    window.$("#InsufficientFundModal").on("hidden.bs.modal", (event) => {
      props.history.push("/funding");
    });
    window.$("#InsufficientFundModal").modal("hide");
  };

  const cancelHandler = (event) => {
    event.preventDefault();
    window.$("#InsufficientFundModal").off("hidden.bs.modal");
    window.$("#InsufficientFundModal").on("hidden.bs.modal", (event) => {
      props.cancel();
    });
    window.$("#InsufficientFundModal").modal("hide");
  };

  useEffect(() => {
    window.$("#InsufficientFundModal").modal({
      backdrop: "static",
      keyboard: false
    });
    window.$("#InsufficientFundModal").modal("show");
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);

  let content = (
    <div className="py-2 text-center">
      <i className="mb-2 fas fa-times-circle fa-3x text-danger"></i>
      <h5 className="mb-2">Oops! Insufficient Funds</h5>
      <p className="mb-2">{props.description}</p>
      <p>
        <button
          onClick={fundWalletHandler}
          className="btn btn-success btn-rounded mr-1"
        >
          <i className="fas fa-check-circle"></i> FUND WALLET
        </button>
        <button onClick={cancelHandler} className="btn btn-danger btn-rounded">
          <i className="fas fa-times"></i> CANCEL
        </button>
      </p>
    </div>
  );

  return (
    <div className={classes.InsufficientFundModal}>
      <div
        id="InsufficientFundModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className={`${classes.InsufficientFundModalContent} modal-content`}
          >
            <div
              id="InsufficientFundModalBody"
              className={`${classes.InsufficientFundModalBody} modal-body`}
            >
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(InsufficientFundModal);
