/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import classes from "./Pricing.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import axios from "../../../axios/axios-instance";
import SocialTransactionContents from "../../../components/App/SocialTransactionContents/SocialTransactionContents";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";

class Pricing extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      type: "advertise",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    const transactions = this.props.page_states[this.props.location.pathname];
    if (!transactions) {
      this.loadBuyPricing();
    }
  }

  loadBuyPricing = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/buy/pricing`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const transactions = response.data.data.buy_pricing;
        const page_states = {...this.props.page_states};
        page_states[this.props.location.pathname] = transactions;
        this.props.setPageStates(page_states);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadBuyPricing} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;

    const page_states = {...this.props.page_states};
    const transactions = page_states[this.props.location.pathname];

    if (transactions) {
      const active_transactions = transactions.filter(
        (el) => el.task_type === this.state.type
      );
      content = (
        <SocialTransactionContents
          rowClass="col-md-6 col-lg-4"
          content_type="advertise"
          transactions={active_transactions}
        />
      );
    }

    return (
      <div className={classes.Pricing}>
        <div className="container">
          <div className="row no-gutters">
            <div className="offset-md-2 col-md-8 offset-lg-2 col-lg-8">
              <div className={classes.MainHeadingTitle}>
                <div className={classes.Title}>
                  <h1>Affordable & Flexible Pricing For You</h1>
                  <p>
                    We offer very affordable pricing for different advert and
                    engagement tasks depending on the size of your campaign.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.PricingHeader}>
          <div className="row">
            <div className="offset-md-4 col-md-4">
              <p className="mb-3">
                <button
                  onClick={() => this.setState({ type: "advertise" })}
                  className={`btn mr-2 ${
                    this.state.type === "advertise"
                      ? "btn-success"
                      : "btn-light"
                  }`}
                >
                  Advert Tasks
                </button>
                <button
                  onClick={() => this.setState({ type: "engagement" })}
                  className={`btn ${
                    this.state.type === "engagement"
                      ? "btn-success"
                      : "btn-light"
                  }`}
                >
                  Engagement Tasks
                </button>
              </p>
              {this.state.type === "advertise" ? (
                <p className="f-10">
                  Advert tasks are created to get people to post your adverts on
                  various social media platforms. Check below to see the price
                  of creating various advert tasks:
                </p>
              ) : (
                <p className="f-10">
                  Engagement tasks are created to get people to perform simple
                  tasks for you on their social media account. Check below to
                  see the price of creating various engagement tasks:
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={classes.PricingContent}>
          <div className="container">{content}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
