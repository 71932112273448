/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import classes from "./PaymentModal.module.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const PaymentModal = (props) => {
  let user = props.user;

  if (props.activeUser) {
    user = props.activeUser;
  }

  const cancelModalHandler = (event) => {
    event.preventDefault();
    window.$("#PaymentModal").off("hidden.bs.modal");
    window.$("#PaymentModal").on("hidden.bs.modal", (event) => {
      props.close();
    });
    window.$("#PaymentModal").modal("hide");
  };

  const onlinePaymentHandler = (event) => {
    event.preventDefault();
    window.$("#PaymentModal").off("hidden.bs.modal");
    window.$("#PaymentModal").on("hidden.bs.modal", (event) => {
      props.pay_online();
    });
    window.$("#PaymentModal").modal("hide");
  };

  const walletHandler = (event) => {
    event.preventDefault();
    window.$("#PaymentModal").off("hidden.bs.modal");
    window.$("#PaymentModal").on("hidden.bs.modal", (event) => {
      if (user.wallet.shopping < props.amount) {
        props.history.push("/funding");
      } else props.pay_wallet();
    });
    window.$("#PaymentModal").modal("hide");
  };

  useEffect(() => {
    window.$("#PaymentModal").modal({
      backdrop: "static",
    });
    window.$("#PaymentModal").modal("show");
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
    };
  }, []);
  return (
    <div className={classes.PaymentModal}>
      <div
        id="PaymentModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.PaymentModalContent} modal-content`}>
            <div
              id="PaymentModalBody"
              className={`${classes.PaymentModalBody} modal-body`}
            >
              <button
                onClick={cancelModalHandler}
                className={classes.CloseButton}
              >
                <span className="fas fa-times"></span>
              </button>
              <div className={classes.PaymentModalHeader}>
                <h5>Select Payment Method</h5>
              </div>
              <div className={classes.PaymentModalMain}>
                <button
                  onClick={onlinePaymentHandler}
                  className={classes.PaymentModalMainButton}
                >
                  <div className={classes.Icon}>
                    <i className="fas fa-lock"></i>
                  </div>
                  <div className={classes.Content}>
                    <h4>100% Secure Online Payment</h4>
                    <p>
                      Pay through our highly secured online payment partner
                      using your Mastercard/VISA/Verve Card, Bank Transfer via
                      USSD or Internet Bank Transfer. You can select your
                      preferred online payment method on the Payment Checkout
                      page that comes up after clicking here.
                    </p>
                  </div>
                </button>
                <button
                  onClick={walletHandler}
                  className={classes.PaymentModalMainButton}
                >
                  <div className={classes.Icon}>
                    <i className="fas fa-wallet"></i>
                  </div>
                  <div className={classes.Content}>
                    <h4>Pay with your Wallet</h4>
                    <p className="mb-1">
                      Wallet Balance:{" "}
                      <b>
                        {user.country.currency.symbol}{" "}
                        {user.wallet.shopping
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </b>
                    </p>
                    {user.wallet.shopping < props.amount ? (
                      <div className="alert alert-danger py-2 f-10 mb-0">
                        Your Wallet is insufficient to perform this transaction.
                        Click here to fund your wallet now.
                      </div>
                    ) : (
                      <div className="alert alert-success py-2 f-10 mb-0">
                        You have enough money in your wallet to perform this
                        transaction. Click here to pay using your wallet now.
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(PaymentModal));
