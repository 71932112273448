/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./Engagement.module.css";
import { withRouter } from "react-router-dom";

const Engagement = (props) => {
  const [state, setState] = useState({
    loading: props.loading,
  });
  useEffect(() => {
    setState({
      loading: false,
    });
  }, [props.active]);
  const engagementHandler = (event) => {
    event.preventDefault();
    if (!props.activeUser && props.reqUser) {
      props.history.push("/auth/login");
      return;
    }
    if (!state.loading) {
      console.log(props.clicked);
      props.clicked();
      if (!props.noLoading) {
        setState({
          loading: true,
        });
      }
    }
  };
  return (
    <div
      onClick={engagementHandler}
      style={props.active ? { color: props.active } : null}
      className={`${classes.Engagement} ${
        props.active ? `${classes.Active}` : null
      }`}
    >
      <i
        style={props.size ? { fontSize: `${props.size}px` } : null}
        className={state.loading ? "fal fa-spin fa-spinner" : props.icon}
      ></i>
    </div>
  );
};

export default withRouter(Engagement);
