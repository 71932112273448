import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const intialiseSearchState = () => {
  return {
    categories: [],
    category: [],
    category_search: null,
    sortBy: "Most Popular",
    search: "",
    location: "",
    searchScroll: "",
    searchPosts: [],
  };
};

const initialState = intialiseSearchState();

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES:
      draft.categories = action.categories;
      return;
    case actionTypes.SET_CATEGORY:
      draft.category = action.category;
      return;
    case actionTypes.SET_CATEGORY_SEARCH:
      draft.category_search = action.category_search;
      return;
    case actionTypes.SET_SORT_BY:
      draft.sortBy = action.sortBy;
      return;
    case actionTypes.SET_SEARCH:
      draft.search = action.search;
      return;
    case actionTypes.SET_SEARCH_POSTS:
      draft.searchPosts = action.searchPosts;
      return;
    case actionTypes.SET_SEARCH_SCROLL:
      draft.searchScroll = action.searchScroll;
      return;
    case actionTypes.SET_SEARCH_LOCATION:
      draft.location = action.location;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
