/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import classes from "./ShareModal.module.css";
import BrowserLinks from "../../../UI/Links/BrowserLinks/BrowserLinks";

const ShareModal = props => {
  useEffect(() => {
    window.$("#shareModal").off("shown.bs.modal");
    window.$("#shareModal").off("hidden.bs.modal");
    window.$("#shareModal").modal("show");
    window.$("#shareModal").on("hidden.bs.modal", event => {
      props.destroy();
    });
    return () => {
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#shareModal").modal("hide");
    };
  }, []);
  return (
    <div className={classes.ShareModal}>
      <div
        id="shareModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.ShareModalContent} modal-content`}>
            <div className={`${classes.ShareModalHeader} modal-header`}>
              <h5>Share Post</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="shareModalBody"
              className={`${classes.ShareModalBody} modal-body`}
            >
              <BrowserLinks
                to={`https://www.facebook.com/sharer/sharer.php?u=https%3A//hawkit.ng/posts/${props.postId}`}
                className={`${classes.ShareContent} ${classes.Facebook}`}
              >
                <div className={classes.ShareIcon}>
                  <i className="fab fa-facebook"></i>
                </div>
                <h5>Facebook</h5>
              </BrowserLinks>
              <BrowserLinks
                to={`https://twitter.com/share?text=I am sharing this Hawkit Post&url=https%3A//hawkit.ng/posts/${props.postId}`}
                className={`${classes.ShareContent} ${classes.Twitter}`}
              >
                <div className={classes.ShareIcon}>
                  <i className="fab fa-twitter"></i>
                </div>
                <h5>Twitter</h5>
              </BrowserLinks>
              <a
                target="_new"
                href={`https://api.whatsapp.com/send?text=https%3A%2F%2Fhawkit.ng%2Fposts%2F${props.postId}%20Share%20Hawkit%20Post`}
                className={`${classes.ShareContent} ${classes.Whatsapp}`}
              >
                <div className={classes.ShareIcon}>
                  <i className="fab fa-whatsapp"></i>
                </div>
                <h5>Whatsapp</h5>
              </a>
              <BrowserLinks
                to={`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fhawkit.ng%2Fposts%2F${props.postId}`}
                className={`${classes.ShareContent} ${classes.LinkedIn}`}
              >
                <div className={classes.ShareIcon}>
                  <i className="fab fa-linkedin"></i>
                </div>
                <h5>LinkedIn</h5>
              </BrowserLinks>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
