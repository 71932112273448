import React from "react";
import { Link } from "react-router-dom";
import classes from "./WelcomeFeatures.module.css";
import { connect } from "react-redux";

const WelcomeFeatures = (props) => {
  return (
    <div className={classes.WelcomeFeaturesContainer}>
      <div className={classes.WelcomeFeatureHeader}>
        <h4>Welcome to Hawkit</h4>
        <p>Please select what you want to do on Hawkit today</p>
      </div>
      <div className="row no-gutters">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <div className={classes.IntroContent}>
            <div className={classes.IntroHeading}>
              <p className="f-12 mb-2">For Social Media Users and Advertisers</p>
              <h4>Buy Social Media Engagments and Get People to Post Your Adverts on their Social Media</h4>
            </div>
            <div className={classes.IntroDetails}>
              <p className="mb-3">
                Get real people on various social media to post your adverts and
                perform social engagement tasks for you on their social media
                account.
              </p>
              <p>
                <Link
                  to="/social/buy/pricing"
                  className="btn btn-coloured-heavy mr-2"
                >
                  Get Started Now
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className={classes.IntroContent}>
            <div className={classes.IntroHeading}>
              <p className="f-12 mb-2">For Earners</p>
              <h4>Get Paid for Posting Adverts and Engagements on Your Social Media</h4>
            </div>
            <div className={classes.IntroDetails}>
              <p className="mb-3">
                Earn steady income by reselling products and posting adverts and
                performing social media engagement tasks for businesses and top brands on your
                social media account.
              </p>
              <p>
                <Link to="/earn/member" className="btn btn-coloured-heavy mr-2">
                  BECOME A MEMBER
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
  };
};

export default connect(mapStateToProps, null)(WelcomeFeatures);
