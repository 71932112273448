/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./SocialOrderTask.module.css";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";
import { withRouter, Link } from "react-router-dom";
import { getImage } from "../../../../util/image-util";
import BrowserLinks from "../../../../components/UI/Links/BrowserLinks/BrowserLinks";
import UploadPhoto from "../../../../components/App/UploadPhoto/UploadPhoto";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import ConfirmModal from "../../../../components/UI/Modal/ConfirmModal/ConfirmModal";
import UserPhoto from "../../../../components/App/User/UserPhoto/UserPhoto";

const moment = require("moment");

class SocialOrderTask extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      show_reject: null,
      formData: {
        review: {
          value: props.task.review ? props.task.review : "",
        },
      },
      task: props.task,
      submitted: false,
    };
  }

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({ formData: updatedFormData });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateReview = () => {
    const value = this.state.formData.review.value;
    if (!value || validators.isEmpty(value.trim())) {
      return false;
    }
    if (value.length > 0 && !validators.validateLength(value.trim(), 2, 1000)) {
      return false;
    }
    return true;
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.validateReview()) {
      this.setState(
        {
          formError: "Please enter a valid review of the task performed.",
        },
        () => {
          setTimeout(() => {
            this.setState({
              formError: null,
            });
          }, 3000);
        }
      );
      return;
    }
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: `/social/orders/${this.state.task.social_task_order._id}/task/${this.state.task._id}/review`,
      data: {
        review: this.state.formData.review.value,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const task = response.data.data.task;
        this.setState({
          formSubmitting: false,
          submitted: response.data.data.status,
          task: task,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              formSubmitting: false,
              formError: data.error[0].msg,
            });
          } else {
            this.setState({
              formSubmitting: false,
              formError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            formSubmitting: false,
            formError: errorMsg,
          });
        }
      });
    this.setState({
      formSubmitting: true,
      formError: null,
    });
  };

  acceptTask = () => {
    const userToken = this.props.userToken;
    axios({
      method: "post",
      url: `/social/orders/${this.state.task.social_task_order._id}/task/${this.state.task._id}/accept-task`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const task = response.data.data.task;
        this.setState({
          formSubmitting: false,
          submitted: response.data.data.status,
          task: task,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              formSubmitting: false,
              formError: data.error[0].msg,
            });
          } else {
            this.setState({
              formSubmitting: false,
              formError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            formSubmitting: false,
            formError: errorMsg,
          });
        }
      });
    this.setState({
      formSubmitting: true,
      formError: null,
      show_reject: null,
    });
  };

  showImage = () => {
    if (!this.state.task.proof) {
      return;
    }
    this.props.showImage(this.state.task.proof.photo);
  };

  showCaptionImage = () => {
    if (!this.state.task.proof || !this.state.task.proof.caption_photo) {
      return;
    }
    this.props.showImage(this.state.task.proof.caption_photo);
  };

  rejectTask = () => {
    this.setState({
      show_reject: true,
    });
  };

  render() {
    const orderMoment = moment(this.state.task.created_on);
    let statusContent;
    if (this.state.task.status === "COMPLETED") {
      statusContent = (
        <p className="text-success f-10">
          <i className="fas fa-check-circle"></i> COMPLETED
        </p>
      );
    } else if (
      this.state.task.status === "CANCELLED" ||
      this.state.task.status === "FAILED"
    ) {
      statusContent = (
        <p className="text-danger f-10">
          <i className="fas fa-times-circle"></i> {this.state.task.status}
        </p>
      );
    } else {
      statusContent = (
        <p className="text-info f-10">
          <i className="fas fa-clock"></i> {this.state.task.status}
        </p>
      );
    }

    const required_types = ["facebook", "instagram", "twitter", "tiktok"];

    return (
      <div className={classes.SocialOrderTask}>
        <div className={classes.SocialOrderTaskHeading}>
          <div className={classes.SocialOrderTaskHeaderIcon}>
            <UserPhoto
              photo={this.state.task.userId.profile_pic}
              width="40"
              height="40"
            />
          </div>
          <Link
            to={`/users/${this.state.task.userId.username}`}
            className={classes.SocialOrderTaskHeader}
          >
            <p>@{this.state.task.userId.username}</p>
            <h5>{this.state.task.userId.name}</h5>
            <p className="f-8 text-gray">
              <i className="fal fa-clock"></i>{" "}
              {orderMoment.format("MMM Do YYYY h:mm a")}
            </p>
          </Link>
        </div>
        <div className={classes.SocialTaskOrderContent}>
          {this.state.task.proof ? (
            <>
              <div className="row no-gutters">
                <div className="col-4">
                  {required_types.includes(
                    this.state.task.social_task_category.type
                  ) ? (
                    <div className={`${classes.Info} mr-2`}>
                      <h5>
                        <img
                          src={getImage(
                            this.state.task.social_task_category.type
                          )}
                          alt="icon"
                        ></img>{" "}
                        {
                          this.state.task.social_task_category.proof_of_work
                            .title
                        }
                      </h5>
                      <BrowserLinks
                        className="f-10"
                        to={this.state.task.proof.name}
                      >
                        View Post Link
                      </BrowserLinks>
                    </div>
                  ) : (
                    <div className={`${classes.Info} mr-2`}>
                      <h5>
                        <img
                          src={getImage(
                            this.state.task.social_task_order.platform
                          )}
                          alt="icon"
                        ></img>{" "}
                        {
                          this.state.task.social_task_category.proof_of_work
                            .title
                        }
                      </h5>
                      <p>{this.state.task.proof.name}</p>
                    </div>
                  )}
                </div>
                <div className="col-5">
                  {this.state.task.social_task_category.type === "whatsapp" ||
                  this.state.task.social_task_category
                    .caption_screenshot_text ? (
                    <div className={classes.Info}>
                      <h5>
                        {this.state.task.social_task_category
                          .main_screenshot_text
                          ? this.state.task.social_task_category
                              .main_screenshot_text
                          : "Advert Screenshot"}
                      </h5>
                      <p className="mb-2">
                        <button onClick={this.showImage}>Click to view</button>
                      </p>
                      <h5>
                        {this.state.task.social_task_category
                          .caption_screenshot_text
                          ? this.state.task.social_task_category
                              .caption_screenshot_text
                          : "Caption Screenshot"}
                      </h5>
                      <p>
                        <button onClick={this.showCaptionImage}>
                          Click to view
                        </button>
                      </p>
                    </div>
                  ) : (
                    <div className={classes.Info}>
                      <h5>
                        {this.state.task.social_task_category
                          .main_screenshot_text
                          ? this.state.task.social_task_category
                              .main_screenshot_text
                          : "Screenshot / Proof Of Work"}
                      </h5>
                      <p>
                        <button onClick={this.showImage}>Click to view</button>
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-3">
                  <div className={classes.Status}>
                    <p>Status</p>
                    {statusContent}
                  </div>
                </div>
              </div>
              {this.state.formError ? (
                <div className="mt-2 mb-1 alert alert-danger py-2 px-3 f-10 mb-0">
                  {this.state.formError}
                </div>
              ) : null}
              {this.state.submitted ? (
                <div className="mt-2 mb-1 alert alert-success py-1 px-3 f-10 mb-0">
                  {this.state.submitted}
                </div>
              ) : null}
              {this.state.task.owner_verification ? (
                <div className="my-2">
                  <p className="f-10 mb-1">
                    Verification Status:{" "}
                    {this.state.task.owner_verification === "ACCEPTED" ? (
                      <span className="mr-2 fw-600 text-success">
                        {this.state.task.owner_verification}
                      </span>
                    ) : (
                      <span className="fw-600 text-danger">
                        {this.state.task.owner_verification}
                      </span>
                    )}
                  </p>
                  {this.state.task.review ? (
                    <p className="f-10">
                      Your Review: <b>{this.state.task.review}</b>
                    </p>
                  ) : null}
                </div>
              ) : (
                <>
                  <div className="my-2">
                    <p className="f-10">
                      Select one of the options below to verify this task:
                    </p>
                    <p>
                      <button
                        onClick={this.acceptTask}
                        disabled={this.state.formSubmitting}
                        className="btn btn-success btn-xs mr-1"
                      >
                        {this.state.formSubmitting ? (
                          <i className="fas fa-spin fa-spinner fa-2x"></i>
                        ) : (
                          "ACCEPT"
                        )}
                      </button>
                      <button
                        onClick={this.rejectTask}
                        disabled={this.state.formSubmitting}
                        className="btn btn-danger btn-xs"
                      >
                        {this.state.formSubmitting ? (
                          <i className="fas fa-spin fa-spinner fa-2x"></i>
                        ) : (
                          "REJECT"
                        )}
                      </button>
                    </p>
                  </div>
                  {this.state.show_reject ? (
                    <div className={classes.Form}>
                      <label>
                        Please write detailed reason why you want to reject this
                        task below:
                      </label>
                      <form
                        className={classes.InputGroup}
                        onSubmit={this.submitHandler}
                      >
                        <input
                          type="text"
                          placeholder="Reason for Rejection"
                          className="form-control f-12"
                          value={this.state.formData.review.value}
                          onChange={(e) =>
                            this.inputChangedHandler("review", e)
                          }
                        />
                        <button
                          type="submit"
                          disabled={this.state.formSubmitting}
                          className="btn btn-xs btn-danger"
                        >
                          {this.state.formSubmitting ? (
                            <i className="fas fa-spin fa-spinner fa-2x"></i>
                          ) : (
                            "REJECT TASK"
                          )}
                        </button>
                      </form>
                    </div>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <div>
              <p className="f-10 mb-1">
                This user has not yet performed the task. Once the task is
                performed, you will be able to see and verify screenshots and
                details of what the user been done
              </p>
              <div className="d-flex align-items-center">
                <p className="f-10 mr-2">Status:</p> {statusContent}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SocialOrderTask));
