/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./VerifyForm.module.css";
import { Link } from "react-router-dom";
import Form from "../../../../components/UI/Forms/Form";
import {
  createOtpInputElement,
  createButtonElement
} from "../../../../components/UI/Forms/utility/form-creators";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import verifyImage from "../../../../assets/images/phone.png";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";

class VerifyForm extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.expiresIn = null;
    this.state = {
      formData: {
        token: createOtpInputElement(
          "text",
          null,
          null,
          this.props.init,
          this.validateTwoFactor,
          null,
          null,
          "col-7",
          this.inputChangedHandler.bind(this, "token"),
          10
        ),
        submit: createButtonElement("submit", "CONTINUE", true, "col-5")
      },
      resend: false,
      sendingSMS: false,
      error: null,
      result: null
    };
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    this.expiresIn =
      this.props.verifyPhone.otp.expiresIn + new Date().getTime();
    this.timer = setInterval(this.count_down_timer, 1000);
  }

  resendSMS = event => {
    event.preventDefault();
    clearInterval(this.timer);
    const fullName = this.props.verifyPhone.name;
    const phoneNumber = this.props.verifyPhone.otp.phoneNumber;
    const country = this.props.country.code;
    axios({
      method: "post",
      url: "/auth/signup/check",
      data: {
        name: fullName,
        phoneNumber: phoneNumber,
        country: country
      }
    })
      .then(response => {
        const verifyPhone = response.data.data;
        this.setState(
          {
            sendingSMS: false,
            result: "SMS has been resent successfully"
          },
          () => {
            this.expiresIn = verifyPhone.otp.expiresIn + new Date().getTime();
            this.timer = setInterval(this.count_down_timer, 1000);
            setTimeout(() => {
              this.setState({
                result: null
              });
            }, 3000);
          }
        );
      })
      .catch(error => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              sendingSMS: false,
              resend: true,
              error:
                "An error occurred while sending your SMS. Please try again."
            });
          } else {
            this.setState({
              sendingSMS: false,
              resend: true,
              error: data.error
            });
          }
        } else {
          this.setState({
            sendingSMS: false,
            resend: true,
            error:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message
          });
        }
        setTimeout(() => {
          this.setState({
            error: null
          });
        }, 3000);
      });
    this.setState({
      resend: false,
      sendingSMS: true,
      error: null,
      result: null
    });
    return false;
  };

  count_down_timer = () => {
    var distance = this.expiresIn - Date.now();
    if (distance < 0) {
      clearInterval(this.timer);
      this.setState({
        resend: true
      });
      return;
    }
    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const seconds = Math.floor((distance % _minute) / _second);
    const minutes = Math.floor((distance % _hour) / _minute);    
    document.getElementById("minutes").innerHTML = minutes
      .toString()
      .padStart(2, "0");
    document.getElementById("seconds").innerHTML = seconds
      .toString()
      .padStart(2, "0");
  };

  validateTwoFactor = value => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid verification code!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Verification Code. Only Numbers allowed";
    }
    if (!validators.validateLength(value.trim(), 6, 10)) {
      return "Verification Code must contain atleast six(6) digits";
    }
    return null;
  };

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData
    };
    this.setState({
      formData: updatedFormData
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData
    });
    return isFormActivated;
  }

  submitHandler = event => {
    event.preventDefault();
    if (this.checkIsValidFormData()) {
      this.props.submit(this.state.formData.token.value);
    }
    return false;
  };

  render() {
    let resendContent = (
      <span id="countdown" className={classes.ResendCount}>
        <span id="minutes">00</span>mins <span id="seconds">00</span>
        secs
      </span>
    );
    if (this.state.resend) {
      resendContent = (
        <a href="/" onClick={this.resendSMS} className={classes.ResendLink}>
          Click to Resend SMS
        </a>
      );
    } else if (this.state.sendingSMS) {
      resendContent = (
        <div className="mt-2">
          <OfficeSpinner size="2" />
          <p>Sending SMS...</p>
        </div>
      );
    }
    let resendResponse = null;
    if (this.state.result) {
      resendResponse = (
        <div className="mt-2 alert alert-success">{this.state.result}</div>
      );
    }
    if (this.state.error) {
      resendResponse = <AlertError error={this.state.error} />;
    }
    return (
      <div
        className={`${classes.VerifyContainer} d-flex flex-column align-items-center`}
      >
        <div className="text-center mb-4">
          <h5 className="mb-3">Verify Phone Number</h5>
          <p className="mb-1">
            Enter the verification code sent to your phone number: {" "}
            {this.props.verifyPhone.otp.phoneNumber}
          </p>
        </div>
        <div className={classes.VerifyForm}>
          <div className={classes.VerifyImage}>
            <img src={verifyImage} alt="verify_image" />
          </div>
          <div className={classes.VerifyFormContainer}>
            <p>ENTER VERIFICATION CODE:</p>
            <div style={{ width: "80%" }}>
              {this.props.verifyError ? (
                <AlertError error={this.props.verifyError} />
              ) : null}
            </div>
            <Form
              loading={this.props.isSubmitting}
              formData={this.state.formData}
              submit={this.submitHandler}
            />
          </div>
        </div>
        <div className={classes.Resend}>
          <p>
            Didn't receive the SMS? Resend SMS in: <br />
          </p>
          {resendContent}
        </div>
        {resendResponse}
      </div>
    );
  }
}

export default VerifyForm;
