/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classes from "./UserHeading.module.css";
import { Link } from "react-router-dom";
import FetchLink from "../../../UI/FetchLink/FetchLink";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import profileIcon from "../../../../assets/images/no_user.png";

let is_Mounted = false;

const moment = require("moment");

const UserHeading = (props) => {
  const [image, setImage] = useState({
    src: null,
  });

  const initialiseModal = () => {
    var modal = document.getElementById("imageModal");
    var span = document.getElementById("close");
    span.onclick = function () {
      modal.style.display = "none";
    };
  };

  useEffect(() => {
    is_Mounted = true;
    const img = new Image();
    img.onload = () => {
      if (is_Mounted) {
        setImage({
          src: props.user.profile_pic ? props.user.profile_pic : profileIcon,
        });
      }
    };
    img.src = props.user.profile_pic ? props.user.profile_pic : profileIcon;
    initialiseModal();
    return () => {
      is_Mounted = false;
    };
  }, []);

  const viewPhotoHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    var modal = document.getElementById("imageModal");
    var modalImg = document.getElementById("img");
    var captionText = document.getElementById("caption");
    modal.style.display = "block";
    modalImg.src = props.user.profile_pic
      ? props.user.profile_pic
      : profileIcon;
    captionText.innerHTML = `${props.user.name} (@${props.user.username})`;
  };

  let imageContent = <OfficeSpinner size="3" />;
  if (image.src) {
    imageContent = <img src={image.src} alt="user" />;
  }

  let followContent = null;
  if (props.user.followers_count) {
    const urls = [
      {
        url: `/users/${props.user.username}/followers`,
        method: "delete",
      },
      {
        url: `/users/${props.user.username}/followers`,
        method: "post",
      },
    ];
    followContent = (
      <FetchLink
        callback={props.followHandler}
        className="btn btn-outline btn-xs"
        title={["Unfollow", "Follow"]}
        url={urls}
      >
        <span>Unfollow</span>
        <span>Follow</span>
        <i className="fal fa-spin fa-spinner"></i>
      </FetchLink>
    );
  } else {
    const urls = [
      {
        url: `/users/${props.user.username}/followers`,
        method: "post",
      },
      {
        url: `/users/${props.user.username}/followers`,
        method: "delete",
      },
    ];
    followContent = (
      <FetchLink
        callback={props.followHandler}
        className="btn btn-outline btn-xs"
        title={["Follow", "Unfollow"]}
        url={urls}
      >
        <span>Follow</span>
        <span>Unfollow</span>
        <i className="fal fa-spin fa-spinner"></i>
      </FetchLink>
    );
  }

  let settingsContent = (
    <div className={classes.SettingsContent}>
      {followContent}
      <Link
        to={`/chats/${props.user.username}`}
        className="ml-1 btn btn-xs btn-outline"
      >
        <i className="fal fa-comment-alt-dots"></i> Chat
      </Link>
    </div>
  );
  if (props.active) {
    settingsContent = (
      <div className={classes.SettingsContent}>
        <Link className="btn btn-xs btn-outline" to="/settings">
          <i className="fal fa-cog"></i> Account Settings
        </Link>
      </div>
    );
  } else if (!props.activeUser) {
    settingsContent = (
      <div className={classes.SettingsContent}>
        <p className="f-12">
          <Link to="/auth/login">Login</Link> to follow or chat with this user
        </p>
      </div>
    );
  }

  const rating = [];

  for (let index = 0; index < Math.floor(props.user.rating); index++) {
    rating.push(
      <span key={index} className={`${classes.Active} fa fa-star`}></span>
    );
  }

  if (props.user.rating % 1) {
    rating.push(
      <span
        key={Date.now()}
        className={`${classes.Active} fa fa-star-half-alt`}
      ></span>
    );
  }

  for (let index = Math.ceil(props.user.rating); index < 5; index++) {
    rating.push(<span key={index} className="fal fa-star"></span>);
  }

  const now = moment();
  const postMoment = moment(props.user.last_seen);
  const daysDiff = now.diff(postMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = postMoment.calendar();
  } else {
    formatDate = postMoment.fromNow();
  }

  return (
    <>
      <div id="imageModal" className={classes.Modal}>
        <span id="close" className={classes.Close}>
          &times;
        </span>
        <img className={classes.ModalContent} id="img" />
        <div className={classes.Caption} id="caption"></div>
      </div>
      <div className={classes.UserHeading}>
        <div className={classes.UserMainHeading}>
          <div onClick={viewPhotoHandler} className={classes.UserPhoto}>
            {imageContent}
          </div>
          <div className={classes.Details}>
            <div className={classes.Rating}>
              <span className={classes.Stars}>{rating}</span>
              <span className={classes.Number}>
                {props.user.rating.toFixed(1)}
              </span>
            </div>
            <h4>{props.user.name}</h4>
            <p>@{props.user.username}</p>
            {settingsContent}
            <div className={classes.LastSeen}>
              {props.user.is_online ? (
                <p>
                  <span className="text-success">
                    <i className="fas fa-check-circle"></i>
                  </span>{" "}
                  <b>Online</b>
                </p>
              ) : (
                <p>
                  Last Seen: {formatDate}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHeading;
