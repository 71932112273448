/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import classes from "./PreviewMobileHome.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import { Link } from "react-router-dom";

class PreviewMobileHome extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    window.$("#MobileHomeCarousel").on("touchstart", function (event) {
      const xClick = event.originalEvent.touches[0].pageX;
      window.$(this).one("touchmove", function (event) {
        const xMove = event.originalEvent.touches[0].pageX;
        const sensitivityInPx = 5;

        if (Math.floor(xClick - xMove) > sensitivityInPx) {
          window.$(this).carousel("next");
        } else if (Math.floor(xClick - xMove) < -sensitivityInPx) {
          window.$(this).carousel("prev");
        }
      });
      window.$(this).on("touchend", function () {
        window.$(this).off("touchmove");
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createHandler = (event) => {
    event.preventDefault();
    this.props.history.push("/post");
  };

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadPosts(false);
  };

  render() {
    return (
      <div className={classes.PreviewHome}>
        <div
          id="MobileHomeCarousel"
          className={`carousel slide ${classes.MobileHomeCarousel}`}
          data-ride="carousel"
          data-interval="2500"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className={classes.MainHeadingTitle}>
                <div className={classes.Title}>
                  <h1>
                    Get Paid for Posting Adverts Daily on Your Social Media
                  </h1>
                  <p>
                    Earn daily income by posting adverts and performing simple
                    social tasks for top businesses and brands on your social
                    media account.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className={classes.MainHeadingTitle}>
                <div className={classes.Title}>
                  <h1>Get People to Post Your Adverts on their Social Media</h1>
                  <p>
                    Get people with aleast 1,000 followers to post your adverts
                    and perform social tasks for you on their social media
                    account.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className={classes.MainHeadingTitle}>
                <div className={classes.Title}>
                  <h1>Boost Your Social Media Engagement and Portfolio</h1>
                  <p>
                    Get real people to grow your social media portfolio and
                    engagements by getting them to perform engagement tasks for
                    you using their social media account.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className={classes.MainHeadingTitle}>
                <div className={classes.Title}>
                  <h1>Sell Faster on Hawkit Marketplace</h1>
                  <p>
                    Take advantage of our huge web traffic and sell your
                    products/faster anything on the Hawkit Marketplace.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ol className="carousel-indicators">
            <li
              data-target="#MobileHomeCarousel"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#MobileHomeCarousel" data-slide-to="1"></li>
            <li data-target="#MobileHomeCarousel" data-slide-to="2"></li>
            <li data-target="#MobileHomeCarousel" data-slide-to="3"></li>
          </ol>
        </div>
        <div className={classes.Buttons}>
          <p className="mb-2">
            <Link
              className="btn btn-rounded btn-block btn-coloured-heavy btn-xl py-3 px-4 f-14 mr-2"
              to="/auth/register"
            >
              Create Free Account
            </Link>
          </p>
          <p className="mb-2">
            <Link
              className="btn btn-rounded btn-block btn-outline btn-xl py-3 px-4 f-14 mr-2"
              to="/auth/login"
            >
              Login to your Account
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    is_seller_product_failed: state.notificationState.is_seller_product_failed,
    meta: state.userState.meta,
    advert_banner: state.notificationState.advert_banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
    fetchCountryStates: () => dispatch(actionCreators.fetchCountryStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewMobileHome);
