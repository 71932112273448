import React, { useState } from "react";
import classes from "./UploadImage.module.css";
import ImageEditor from "./ImageEditor/ImageEditor";

const UploadImage = props => {
  const [state, setState] = useState({
    image: null,
    original: null,
    crop: null,
    result: null
  });

  const resultHandler = (image, crop) => {
    setState({
      result: image
    });
    props.upload(state.original, crop);
  };

  const removeImage = () => {
    setState({
      image: null,
      original: null,
      crop: null,
      result: null
    });
    props.remove();
  };

  const uploadHandler = event => {
    const files = event.target.files;
    if (!files || files.length > 1 || !files[0]) {
      return;
    }
    const file = files.item(0);
    const type = file["type"];
    if (!type.startsWith("image/")) {
      return;
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = e => {
      const blob = new Blob([e.target.result], { type: type });
      const image = window.URL.createObjectURL(blob);
      const elem = document.createElement("canvas");
      if (!elem.getContext && !elem.getContext("2d")) {
        resultHandler(image);
        setState({
          original: file
        });
      } else {
        setState({
          image: image,
          original: file
        });
      }
    };
    event.target.value = null;
  };

  let imageContent = <span className="fad fa-user-circle"></span>;

  if (props.image) {
    imageContent = <img src={props.image} alt="" />;
  }

  return (
    <React.Fragment>
      <div
        style={props.size ? { fontSize: `${props.size}px` } : null}
        className={`${classes.UploadImage} ${
          props.nomargin ? classes.NoMargin : ""
        }`}
      >
        {state.result ? <img src={state.result} alt="" /> : imageContent}
        {!state.result ? (
          <div
            className={`${classes.UploadLabel} ${
              props.image || state.result ? classes.OverLay : ""
            }`}
          >
            <input
              type="file"
              name="image"
              onChange={uploadHandler}
              className={classes.InputFile}
              value=""
              id="uploadField"
              accept="image/*"
            />
            <label htmlFor="uploadField">
              <span className="fal fa-camera"></span>
              {props.showText
                ? `${props.text ? props.text : "Click to Upload"}`
                : null}
            </label>
          </div>
        ) : (
          <div className={classes.UploadLabel}>
            <span
              onClick={removeImage}
              className="fal fa-times-circle fa-2x"
            ></span>
          </div>
        )}
      </div>
      {state.image ? (
        <ImageEditor result={resultHandler} image={state.image} />
      ) : null}
    </React.Fragment>
  );
};

export default UploadImage;
