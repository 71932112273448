import React, { useState } from "react";
import classes from "./OrderView.module.css";
import OrderHeader from "../OrderHeader/OrderHeader";
import paymentLogos from "../../../../assets/images/payment_logos.jpg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ConfirmItemsModal from "../ConfirmItemsModal/ConfirmItemsModal";
import { getDeliveryDate } from "../../../../util/util";

const moment = require("moment");

const OrderView = (props) => {
  const [confirmItems, setConfirmItems] = useState(null);

  const confirmHandler = (orderId, salesOrderId, seller) => {
    setConfirmItems({
      orderId: orderId,
      salesOrderId: salesOrderId,
      seller: seller,
    });
  };

  const salesOrders = props.order.sales_orders.map((sales_order) => {
    const orderItems = sales_order.cart.map((item) => {
      let deliveryMoment = moment(
        getDeliveryDate(
          item.product.delivery_days ? item.product.delivery_days : 5
        )
      );
      if (item.expected_delivery_date) {
        deliveryMoment = moment(item.expected_delivery_date);
      }
      return (
        <div key={item._id} className={classes.OrderItem}>
          <OrderHeader item={item} />
          <div className="table-responsive">
            <table className="table table-sm table-borderless">
              <thead className="thead-light">
                <tr>
                  <th>Qtys</th>
                  <th>Size</th>
                  <th>Colour</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item.quantity}</td>
                  {item.options && item.options.length ? (
                    <td>{item.options}</td>
                  ) : (
                    <td>None</td>
                  )}
                  {item.colours && item.colours.length ? (
                    <td>{item.colours}</td>
                  ) : (
                    <td>None</td>
                  )}
                  <td>
                    {`${item.product.country.currency.symbol} ${(
                      item.price * item.quantity
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="f-10 mt-2">
            This item should be delivered on or before{" "}
            <b>{deliveryMoment.format("dddd, MMMM Do")}</b>
          </p>
        </div>
      );
    });
    let orderStatus = null,
      confirmOptions = null;
    if (sales_order.status.buyer === window.ORDER_AWAITING_PAYMENT) {
      orderStatus = (
        <div className={`${classes.Status} bg-warning text-dark`}>
          <p>{sales_order.status.buyer}</p>
        </div>
      );
    } else if (sales_order.status.buyer === window.ORDER_PAID) {
      let activeClass;
      switch (sales_order.status.seller) {
        case window.ORDER_CANCELLED:
          activeClass = "bg-danger";
          break;
        case window.ORDER_NOT_DELIVERED:
          activeClass = "bg-danger";
          break;
        case window.ORDER_AWAITING_DELIVERY:
          activeClass = "bg-warning text-dark";
          break;
        case window.ORDER_SHIPPED:
          activeClass = "bg-warning text-dark";
          break;
        default:
          activeClass = "bg-success";
      }
      orderStatus = (
        <div className={`${classes.Status} ${activeClass} `}>
          <p>{sales_order.status.seller}</p>
        </div>
      );
    } else if (sales_order.status.buyer === window.ORDER_COMPLETED) {
      orderStatus = (
        <div className={`${classes.Status} bg-success `}>
          <p>{sales_order.status.buyer}</p>
        </div>
      );
    } else if (sales_order.status.buyer === window.ORDER_REJECTED) {
      orderStatus = (
        <div className={`${classes.Status} bg-danger `}>
          <p>{sales_order.status.buyer}</p>
        </div>
      );
    }
    if (sales_order.status.seller === window.ORDER_DELIVERED) {
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            Your order has been delivered successfully. Please confirm if you
            have received the items and drop a review about the product.
          </p>
          <p>
            <button
              onClick={() =>
                confirmHandler(
                  props.order._id,
                  sales_order._id,
                  sales_order.seller._id
                )
              }
              className="btn btn-success mr-1"
            >
              CONFIRM DELIVERY
            </button>
          </p>
        </div>
      );
      if (sales_order.status.buyer === window.ORDER_REJECTED) {
        confirmOptions = (
          <div className={classes.ConfirmOptions}>
            <p className="mb-2">
              This order is under dispute as the seller claims that the order
              has been delivered while the buyer claims that order was rejected.
              We are working to resolve the dispute after which appropriate
              refund will take place.
            </p>
          </div>
        );
      } else if (sales_order.status.buyer === window.ORDER_COMPLETED) {
        confirmOptions = (
          <div className={classes.ConfirmOptions}>
            <p className="mb-2">The items have been delivered successfully.</p>
          </div>
        );
      }
    } else if (
      sales_order.status.seller === window.ORDER_CANCELLED ||
      sales_order.status.seller === window.ORDER_NOT_DELIVERED
    ) {
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            The order has been cancelled. Your money has been refunded into your
            Hawkit Wallet. Please click <Link to="/withdraw">here</Link> to
            withdraw the money into your bank account. Thank you.
          </p>
        </div>
      );
    } else if (sales_order.status.seller === window.ORDER_SHIPPED) {
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            Your order has been shipped by the seller to the nearest collection
            center. We will most likely deliver the item to your location within
            the next 24hrs. Thank you for using Hawkit. We are here to serve you
            always.
          </p>
        </div>
      );
    } else if (props.order.is_activated && sales_order.status.seller === window.ORDER_AWAITING_DELIVERY) {
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            Your payment for this order has been received successfully. Kindly
            exercise patience while we deliver your order on or before the
            expected date shown to you above. Thank you for using Hawkit.
          </p>
        </div>
      );
    }
    return (
      <div key={sales_order._id} className={classes.SalesOrder}>
        {orderItems}
        <div className={classes.OrderItemFooter}>
          <div className={classes.OrderStatus}>
            {orderStatus}
            <div className={classes.OrderItemsTotal}>
              <div className={classes.Total}>
                <p className="ml-auto">Shipping Fee:</p>
                <h6>
                  {`${
                    props.user.country.currency.symbol
                  } ${sales_order.shipping_cost
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </h6>
              </div>
              <div className={classes.Total}>
                <p className="ml-auto">SubTotal:</p>
                <h6>
                  {`${
                    props.user.country.currency.symbol
                  } ${sales_order.sub_total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </h6>
              </div>
            </div>
          </div>
          {confirmOptions}
        </div>
      </div>
    );
  });

  return (
    <div className={classes.OrderView}>
      <div className={classes.OrderItems}>{salesOrders}</div>
      <div className={`${classes.Total} py-1 px-3 border-bottom`}>
        <p className="ml-auto">Net Total:</p>
        <h6 className="fw-600">
          {`${props.user.country.currency.symbol} ${props.order.total_cost
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </h6>
      </div>
      <div className={classes.Delivery}>
        <h5>Delivery Address</h5>
        <p>
          <span className="fas fa-map-marker-alt"></span>{" "}
          {props.order.delivery_address.address}
        </p>
        <p>
          {props.order.delivery_address.lga},{" "}
          {props.order.delivery_address.state}
        </p>
      </div>
      {!props.order.is_activated ? (
        <>
          <div className={classes.Payment}>
            <div className={classes.PaymentButton}>
              <button
                onClick={props.pay}
                className="btn btn-coloured-heavy btn-xl"
              >
                {!props.paying ? (
                  "MAKE PAYMENT"
                ) : (
                  <span>
                    <i className="fas fa-spinner fa-spin"></i> Please Wait...
                  </span>
                )}
              </button>
            </div>
            <div className={classes.PaymentLogo}>
              <img src={paymentLogos} alt="paymentLogos" />
            </div>
          </div>
          <div className={classes.Report}>
            <div className="mr-2">
              <i className="fad fa-truck"></i>
            </div>
            <div className={classes.ReportContent}>
              <h5>Return Policy</h5>
              <p>
                We have a very good return policy that allows you to return
                faulty or wrong items that are delivered to you. You can return
                the item within few days after purchase depending on the product
                return policy and conditions.
              </p>
            </div>
          </div>
        </>
      ) : null}
      {confirmItems ? (
        <ConfirmItemsModal
          refresh={props.refresh}
          destroy={() => setConfirmItems(null)}
          confirmItems={confirmItems}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(OrderView));
