import React from "react";
// import classes from "./AlertError.module.css";

const AlertError = (props) => {
  return (
    <div className="alert alert-danger px-3 py-2">
      <p className="f-12 mb-0">{props.error}</p>
    </div>
  );
};

export default AlertError;
