import React from "react";
import classes from "./FormButton.module.css";

const formButton = props => {
  const btnClasses = `${classes.FormButton} btn ${
    props.isBlock ? "btn-block" : ""
  } btn-coloured-heavy`;
  let content = null;
  if (props.show) {
    content = (
      <button
        disabled={props.disabled}
        className={btnClasses}
        type={props.type}
      >
        {props.disabled ? (
          <span className="fas fa-spin fa-2x fa-spinner" />
        ) : (
          props.label
        )}
      </button>
    );
  }
  return content;
};

export default formButton;
