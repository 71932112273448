import React from "react";
import classes from "./FooterIconLink.module.css";
import { NavLink, useLocation } from "react-router-dom";
import UserPhoto from "../../App/User/UserPhoto/UserPhoto";
import BrowserLinks from "../Links/BrowserLinks/BrowserLinks";

const FooterIconLink = (props) => {
  const location = useLocation();
  let content = (
    <NavLink
      to={props.target}
      exact={props.exact}
      activeClassName={classes.IconActive}
    >
      {props.sourceType === "image" ? (
        <UserPhoto
          no_rounded
          nomargin
          width="24"
          height="24"
          photo={props.icon}
        />
      ) : (
        // <img src={props.icon} alt="" />
        <div className={classes.Icon}>
          {props.badge && props.badge > 0 ? (
            <div className={classes.Badge}>
              <span className={`${classes.BadgeCount} badge badge-danger`}>
                {props.badge < 99 ? props.badge : `99+`}
              </span>
            </div>
          ) : null}
          <i
            style={
              props.size || props.color
                ? { fontSize: `${props.size}px`, color: `${props.color}` }
                : null
            }
            className={props.icon}
          ></i>
          <p>{props.text}</p>
        </div>
      )}
    </NavLink>
  );
  if (props.type === "link") {
    content = (
      <BrowserLinks to={props.target}>
        <div className={classes.Icon}>
          {props.badge ? (
            <div className={classes.Badge}>
              <span className={classes.BadgeCount}>
                {props.badge < 99 ? props.badge : `99+`}
              </span>
            </div>
          ) : null}
          <i
            style={
              props.size || props.color
                ? { fontSize: `${props.size}px`, color: `${props.color}` }
                : null
            }
            className={props.icon}
          ></i>
        </div>
      </BrowserLinks>
    );
  }
  if (props.type === "button") {
    content = (
      <a href="/" onClick={props.clicked}>
        {props.sourceType === "image" ? (
          <UserPhoto nomargin width="24" height="24" photo={props.icon} />
        ) : (
          // <img src={props.icon} alt="" />
          <div
            className={`${
              location.pathname.startsWith(props.target)
                ? classes.IconActive
                : null
            } ${classes.Icon}`}
          >
            <i
              style={
                props.size || props.color
                  ? { fontSize: `${props.size}px`, color: `${props.color}` }
                  : null
              }
              className={props.icon}
            ></i>
            <p>{props.text}</p>
          </div>
        )}
      </a>
    );
  }
  if (props.type === "dropdown") {
    content = (
      <React.Fragment>
        <NavLink
          activeClassName={classes.IconActive}
          exact={props.exact}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          className={`${classes.DropDownLink} dropdown-toggle`}
          to={props.target}
        >
          {props.sourceType === "image" ? (
            <UserPhoto nomargin width="24" height="24" photo={props.icon} />
          ) : (
            <i
              style={
                props.size || props.color
                  ? { fontSize: `${props.size}px`, color: `${props.color}` }
                  : null
              }
              className={props.icon}
            ></i>
          )}
        </NavLink>
        {props.children}
      </React.Fragment>
    );
  }
  return <div className={classes.IconLink}>{content}</div>;
};

export default FooterIconLink;
