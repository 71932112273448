import React from "react";
import classes from "./Dropdown.module.css";

const Dropdown = props => {
  return (
    <div className={classes.Dropdown}>
      {props.children[0]}
      {props.children[1]}
    </div>
  );
};
export default Dropdown;
