import React from "react";
import classes from "./SideLink.module.css";
import { NavLink } from "react-router-dom";
import LazyImage from "../../../../../../hoc/LazyImage/LazyImage";

const sideLink = (props) => {
  return (
    <div key={props.index} className={classes.SideLink}>
      <NavLink
        target={props.type === "external" ? "_blank" : ""}
        to={props.target}
        exact={props.exact}
        activeClassName={classes.LinkActive}
      >
        <div className={classes.SideContent}>
          <div className={classes.SideIcon}>
            <LazyImage no_center src={props.icon} size="3" />
          </div>
          <div className={classes.SideText}>            
            <p>{props.description}</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
export default sideLink;
