import React from "react";
import { Link } from "react-router-dom";
import GoogleImage from "../../../../../assets/images/google-play-store.jpg";
import AppleImage from "../../../../../assets/images/coming-soon-appstore.png";
import BrowserLinks from "../../../../UI/Links/BrowserLinks/BrowserLinks";

const PreviewFooter = (props) => {
  return (
    <div className="footer">
      <div className="section-content bg-light">
        <div className="container">
          <div className="section-header">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <h3 className="fw-800">
                  Advertise and Earn Daily With Your Social Media Account
                </h3>
                <p>
                  Join thousands of people using Hawkit to advertise and earn
                  steady income with their social media accounts. Get started
                  today for free.
                </p>
                <div className="line"></div>
              </div>
            </div>
          </div>
          <div className="welcome-button">
            <Link
              className="btn btn-success btn-xl px-4 py-3 f-12 mr-2 mb-2"
              to="/auth/register"
            >
              Create Free Account
            </Link>
            <Link
              className="btn btn-outline btn-xl px-4 py-3 f-12 mb-2"
              to="/auth/login"
            >
              Login
            </Link>
          </div>
          <div className="intro-button">
            <a
              className="google mr-2 wow fadeInUp"
              href="https://play.google.com/store/apps/details?id=com.app.hawkitng"
            >
              <img alt="google-play" src={GoogleImage} />
            </a>
            <Link className="apple wow fadeInUp" to="/" data-wow-delay="0.4s">
              <img alt="iphone-store" src={AppleImage} />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <div className="container">
          <div className="row">
            <div className="footer-div col-sm-3">
              <h5>Company</h5>
              <p>
                <BrowserLinks to="https://hawkit.ng/about">About</BrowserLinks>
              </p>
              <p>
                <BrowserLinks to="https://hawkit.ng/about/careers">
                  Careers
                </BrowserLinks>
              </p>
              <p>
                <BrowserLinks to="https://hawkit.ng/about/terms">
                  Terms
                </BrowserLinks>
              </p>
              <p>
                <BrowserLinks to="https://hawkit.ng/about/refund-policy">
                  Refund Policy
                </BrowserLinks>
              </p>
              <p>
                <BrowserLinks to="https://hawkit.ng/about/privacy-policy">
                  Privacy Policy
                </BrowserLinks>
              </p>
            </div>
            <div className="footer-div col-sm-3">
              <h5>Quick Links</h5>
              <p>
                <Link to="/auth/register">Create Account</Link>
              </p>
              <p>
                <Link to="/auth/login">Login</Link>
              </p>
              <p>
                <Link to="/pricing">Pricing</Link>
              </p>
              <p>
                <Link to="/earn">Earnings</Link>
              </p>
              <p>
                <Link to="/market">Marketplace</Link>
              </p>
            </div>
            <div className="footer-div col-sm-3">
              <h5>Support</h5>
              <p>
                <Link to="/support">
                  Contact Support
                </Link>
              </p>
              <p>
                <BrowserLinks to="https://hawkit.ng/support/faq">
                  Frequently Asked Questions (FAQ)
                </BrowserLinks>
              </p>
              <p>
                <BrowserLinks to="https://hawkit.ng/support/knowledgebase">
                  Knowledgebase
                </BrowserLinks>
              </p>
            </div>
            <div className="footer-div col-sm-3">
              <h5>Contact Us</h5>
              <div className="row mb-3">
                <div className="col-1 pr-0">
                  <span className="fa fa-envelope"></span>
                </div>
                <div className="col-11">
                  <p>support@hawkit.ng</p>
                </div>
              </div>
              <div className="footer-socials">
                <ul className="list-inline">
                  <li>
                    <a
                      href="http://web.facebook.com/officialhawkit"
                      className="social"
                    >
                      <span className="fab fa-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a href="http://instagram.com/hawkit.ng" className="social">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-div container">
          <p className="d-inline-block">
            Hawkit 
            <span className="fa fa-copyright"></span> {new Date().getFullYear()}
            . All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default PreviewFooter;
