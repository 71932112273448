import React from "react";
import classes from "./NoUserSideTop.module.css";
import { Link } from "react-router-dom";

const sideTop = (props) => {
  return (
    <div className={classes.SideTop}>
      <div className={classes.SideContent}>
        <p>
          <Link to="/auth/login">Log in</Link> or{" "}
          <Link to="/auth/register">Register</Link> to enjoy the amazing
          features on Hawkit.
        </p>
      </div>
    </div>
  );
};
export default sideTop;
