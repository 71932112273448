/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./SellersRegister.module.css";
import { connect } from "react-redux";
import {
  createInputElement,
  createSelectElement,
  createPhoneNumberInputElement,
} from "../../../../components/UI/Forms/utility/form-creators";
import Form from "../../../../components/UI/Forms/Form";
import TitleLinks from "../../../../components/App/Layout/Title/TitleLinks";
import axios from "../../../../axios/axios-instance";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import UploadSpinner from "../../../../components/UI/Spinner/UploadSpinner/UploadSpinner";
import * as actionCreators from "../../../../store/actions/actions";
import { Link } from "react-router-dom";
import PreviewImages from "../../../../components/App/CreatePost/PreviewImages/PreviewImages";

class SellersRegister extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const state = props.country_states.find((el) => {
      return el.name === props.user.location.state;
    });
    let lgas;
    if (state) {
      lgas = state.locals.map((el) => {
        return {
          value: el.name,
          option: el.name,
        };
      });
    }

    this.state = {
      image: null,
      photos: [],
      previousImages:
        props.user.kyc && props.user.kyc.uploads ? props.user.kyc.uploads : [],
      active: 0,
      formData: {
        fullName: createInputElement(
          "text",
          "Name",
          "Enter Your Name",
          props.user.name,
          this.validateFullName,
          "Your Name should match the names on your Government-Issued means of identification such as Drivers Licence, International passport, National ID, Voters Registration Card etc.",
          "fa fa-user",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "fullName")
        ),
        email: createInputElement(
          "text",
          "Email Address",
          "Enter Your Email",
          props.user.kyc.email || "",
          this.validateEmail,
          <p>
            Make sure you enter a valid email address. All notification and
            updates will be sent to this email address
          </p>,
          "fa fa-envelope",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "email")
        ),
        phoneNumber: createPhoneNumberInputElement(
          "text",
          "Phone Number",
          "Enter Your Phone Number",
          props.user.kyc && props.user.kyc.phoneNumber
            ? props.user.kyc.phoneNumber.replace("+234", "")
            : "",
          this.validatePhoneNumber,
          "We will contact you via this phone number. Accounts with wrong phone numbers will not be verified.",
          "fa fa-user",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "phoneNumber"),
          [
            {
              d_code: "+234",
              code: "NG",
              name: "Nigeria",
            },
          ]
        ),
        state: createSelectElement(
          "Select Your State",
          this.validateState,
          "fas fa-list",
          "col-sm-12",
          this.props.country_states.map((el) => {
            return {
              value: el.name,
              option: el.name,
            };
          }),
          "Select State",
          this.inputChangedHandler.bind(this, "state"),
          this.props.user.location && this.props.user.location.state
            ? this.props.user.location.state
            : ""
        ),
        lga: createSelectElement(
          "Select Your Local Govt. Area",
          this.validateLGA,
          "fas fa-list",
          "col-sm-12",
          lgas ? lgas : [],
          "Select LGA",
          this.inputChangedHandler.bind(this, "lga"),
          this.props.user.location && this.props.user.location.lga
            ? this.props.user.location.lga
            : "",
          "You will have to select your state before selecting the LGA."
        ),
        location: createInputElement(
          "text",
          "Enter Full Address",
          "Building No, Street, Area, City, State",
          this.props.user.location && this.props.user.location.address
            ? this.props.user.location.address
            : "",
          this.validateLocation,
          "Please enter your full address such as Building No., Street, Area, City, State.",
          "fa fa-map-marker-alt",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "location"),
          "locationField"
        ),
        bvn: createInputElement(
          "text",
          "Enter Your BVN",
          "BVN",
          this.props.user.kyc.bvn || "",
          this.validateBvn,
          "Enter your valid Bank Verification Number(BVN). This is stored securely and will not be shown to anyone. Please Note that your BVN does not give us access to your bank account in any way.",
          "fa fa-check-circle",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "bvn"),
          "bvnField"
        ),
      },
      loading: false,
      error: null,
      completed: false,
    };
  }

  validateLGA = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your LGA from the list above. Make sure you select the state first before selecting the LGA.";
    }
    return null;
  };

  validateState = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please select your state from the list above.";
    }
    return null;
  };

  validatePhoneNumber(value) {
    if (validators.isEmpty(value.trim())) {
      return "Pleaase enter valid phone number!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Phone Number. Only numbers are allowed";
    }
    if (!validators.validateLength(value.trim(), 5, 20)) {
      return "Please enter valid phone number.";
    }
    return null;
  }

  validateEmail(value) {
    if (validators.isEmpty(value)) {
      return "Please enter a valid email address!";
    }
    if (!validators.checkEmail(value)) {
      return "Enter valid email address.";
    }
    return null;
  }

  validateLocation = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter your full address.";
    }
    if (!validators.validateLength(value.trim(), 3, 500)) {
      return "Please enter your full location such as Building No., Street, Area, City, State.";
    }
    return null;
  };

  validateBvn = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter your valid bvn.";
    }
    if (!validators.validateLength(value.trim(), 10, 100)) {
      return "Please enter your bank verification number";
    }
    return null;
  };

  validateFullName(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter the name of your account.";
    }
    if (!validators.validateLength(value.trim(), 3, 50)) {
      return "Please enter valid name of account. 3 - 50 characters are allowed";
    }
    if (!validators.validateName(value.trim())) {
      return "Please enter valid name of account. Only letters are allowed";
    }
    return null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    if (name === "state") {
      const state = this.props.country_states.find((el) => {
        return el.name === formElementData.value;
      });
      const lgas = state.locals.map((el) => {
        return {
          value: el.name,
          option: el.name,
        };
      });
      updatedFormData.lga = {
        ...updatedFormData.lga,
        value: "",
        options: lgas,
      };
    }
    this.setState({ formData: updatedFormData });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    if (this.state.photos.length + this.state.previousImages.length < 2) {
      this.setState({
        error:
          "Invalid Number of Documents uploaded. You must upload a valid identification document and your utility bill",
      });
      return false;
    }
    const name = this.state.formData.fullName.value;
    const email = this.state.formData.email.value;
    const phoneNumber =
      "+234" + this.state.formData.phoneNumber.value.replace(/^0+/, "");
    const state = this.state.formData.state.value;
    const lga = this.state.formData.lga.value;
    const bvn = this.state.formData.bvn.value;
    const location = this.state.formData.location.value;
    const userToken = this.props.userToken;
    const uploadFormData = new FormData();
    this.state.photos.forEach((el) => {
      uploadFormData.append("photos[]", el.file);
    });
    uploadFormData.append("name", name);
    uploadFormData.append("email", email);
    uploadFormData.append("phoneNumber", phoneNumber);
    uploadFormData.append("state", state);
    uploadFormData.append("lga", lga);
    uploadFormData.append("bvn", bvn);
    uploadFormData.append("location", location);
    uploadFormData.append(
      "previous_images",
      JSON.stringify(this.state.previousImages)
    );
    axios({
      method: "put",
      url: "/users/sellers/verify",
      data: uploadFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        this.setState({
          loading: false,
          completed: true,
        });
        this.props.processUser(user);
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error:
                "There is an error in the form. Please go back to the form, correct the errors and try again",
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  uploadHandler = (event) => {
    const files = event.target.files;
    if (
      !files ||
      !files.length ||
      files.length +
        this.state.photos.length +
        this.state.previousImages.length >
        10
    ) {
      this.setState({
        error:
          "Invalid Number of Photos selected. You can only upload a maximum of 10 photos per product. Please try again.",
      });
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(file["type"])) {
        continue;
      }
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: file["type"] });
        const previewImage = window.URL.createObjectURL(blob);
        const previewPhoto = {
          file: file,
          photo: previewImage,
        };
        const previewPhotos = [...this.state.photos];
        previewPhotos.push(previewPhoto);
        this.setState({
          photos: previewPhotos,
        });
      };
    }
  };

  removePhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const photos = [...this.state.photos];
    photos.splice(index, 1);
    if (index === this.state.active) {
      if (index >= 1) {
        this.setState({
          active: index - 1,
        });
      }
    }
    this.setState({
      photos: photos,
    });
  };

  removePreviousPhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const photos = [...this.state.previousImages];
    photos.splice(index, 1);
    this.setState({
      previousImages: photos,
    });
  };

  render() {
    const previousImagesContent = this.state.previousImages.map((photo) => {
      return {
        photo: photo.src,
      };
    });

    let reviewBg = "alert alert-info rounded-0 mb-0";
    if (this.props.user.seller_status === "SUCCESS") {
      reviewBg = "alert alert-success rounded-0 mb-0";
    } else if (this.props.user.seller_status === "FAILED") {
      reviewBg = "alert alert-danger rounded-0 mb-0";
    }

    let feedbackContent = null;
    if (this.props.user.seller_feedback) {
      feedbackContent =
        "<p>" +
        this.props.user.seller_feedback
          .replace(/\n{2,}/g, "</p><p>")
          .replace(/\n/g, "<br>") +
        "</p>";
    }

    const identity_text = `We observed that you performed some suspicious
    transaction and you need to verify your identity before
    you can continue using Hawkit. Your withdrawals and
    other account functionalities are put ON HOLD until we
    verify your identity. Please fill the form below to
    verify your identity.`;

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              {this.state.loading || this.state.error ? (
                <div className={classes.SubmissionModal}>
                  {this.state.loading ? (
                    <UploadSpinner size="10" />
                  ) : (
                    <div className={classes.SubmissionError}>
                      <i className="fa fa-times-circle mb-2"></i>
                      <p className="mb-2">{this.state.error}</p>
                      <p>
                        <button
                          onClick={this.submitHandler}
                          className="mr-1 btn btn-coloured-heavy btn-rounded"
                        >
                          Try Again
                        </button>
                        <button
                          onClick={(event) =>
                            this.setState({ error: null, loading: false })
                          }
                          className="btn btn-danger btn-rounded"
                        >
                          Back to Form
                        </button>
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Verify Your Identity</h4>
                </TitleHeader>
                {!this.state.completed ? (
                  <TitleLinks>
                    <button onClick={this.submitHandler}>Submit</button>
                  </TitleLinks>
                ) : null}
              </Title>
              {!this.state.completed ? (
                <div className={classes.SellersRegister}>
                  {feedbackContent ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: feedbackContent }}
                      className={`${classes.ProductStatus} ${reviewBg}`}
                    ></div>
                  ) : (
                    <div className={classes.SellersInfoReg}>
                      <p>
                        {this.props.meta.identity_text
                          ? this.props.meta.identity_text
                          : identity_text}
                      </p>
                    </div>
                  )}
                  <div className="px-3 mt-3">
                    <p className="f-10">
                      Do you have questions or need help concerning your
                      identity verification? Please click{" "}
                      <Link to="/support">here</Link> to contact our support
                      center
                    </p>
                  </div>
                  <div className={classes.UserProfile}>
                    <Form formData={this.state.formData} />
                    <div className={classes.UploadImages}>
                      <h6 className="mb-2">
                        Upload Valid Means of Identification and Utility Bill
                      </h6>
                      <p className="f-12 mb-2">
                        Please you must upload two documents below which are
                        your Utility Bill (e.g Nepa Bill) and a Government
                        Issued Identification such as International Passport,
                        Drivers License, Voters Card, National ID card.
                      </p>
                      <div className={classes.EditImages}>
                        <div className={classes.SellImages}>
                          <PreviewImages
                            multiple
                            noUpload
                            setActive={() => {}}
                            removePhoto={this.removePreviousPhotoHandler}
                            products={previousImagesContent}
                          />
                        </div>
                        <div className={classes.SellImages}>
                          <PreviewImages
                            multiple
                            setActive={this.setActive}
                            active={this.state.active}
                            upload={this.uploadHandler}
                            removePhoto={this.removePhotoHandler}
                            products={this.state.photos}
                            caption="Click to Upload"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.SellersRegister}>
                  <div className={classes.CreateCompleted}>
                    <div className={classes.CreateCompletedContent}>
                      <i
                        style={{ color: "#5f04f6" }}
                        className="fad fa-spin fa-spinner fa-5x mb-2"
                      ></i>
                      <h4 className="mb-3">
                        Your Verification is Under Review
                      </h4>
                      <p className="mb-3">
                        We are currently reviewing your verification in order to
                        make sure all KYC guidelines are followed. We should be
                        done with the review within 48 hours. You will receive a
                        notification immediately your account is verified. Thank
                        you for choosing Hawkit.
                      </p>
                      <p>
                        <button
                          onClick={() => {
                            this.setState({ completed: false });
                          }}
                          className="btn btn-coloured-heavy btn-rounded btn-xl"
                        >
                          VIEW/EDIT YOUR SUBMISSION
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    userToken: state.authState.userToken,
    country_states: state.userState.country_states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SellersRegister);
