import React from "react";

const PostContext = React.createContext({
  userToken: null,
  addMutual: null,
  toggleLikes: null,
  postModal: null,
  setPostModal: null,
  deleteModal: null,
  setDeleteModal: null,
  posts: null,
  setPosts: null,
  user: null
});

export default PostContext;
