import React, { useState } from "react";
import classes from "./UploadPhoto.module.css";

const UploadPhoto = (props) => {
  const [state, setState] = useState({
    result: null,
  });

  const resultHandler = (image, file) => {
    setState({
      result: image,
    });
    props.upload(file);
  };

  const removeImage = () => {
    setState({
      result: null,
    });
    props.remove();
  };

  const uploadHandler = (event) => {
    const files = event.target.files;
    if (!files || files.length > 1 || !files[0]) {
      return;
    }
    const file = files.item(0);
    const type = file["type"];
    if (!type.startsWith("image/")) {
      return;
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const blob = new Blob([e.target.result], { type: type });
      const image = window.URL.createObjectURL(blob);
      resultHandler(image, file);
    };
    event.target.value = null;
  };

  let imageContent = null;

  if (props.image) {
    imageContent = <img src={props.image} alt="" />;
  }

  return (
    <React.Fragment>
      <div
        style={props.size ? { fontSize: `${props.size}px` } : null}
        className={`${classes.UploadPhoto} ${
          props.nomargin ? classes.NoMargin : ""
        } ${state.result || props.image ? "bg-dark" : ""}`}
      >
        {state.result ? <img src={state.result} alt="" /> : imageContent}
        {!state.result ? (
          <div
            className={`${classes.UploadLabel} ${
              props.image || state.result ? classes.OverLay : ""
            }`}
          >
            <input
              type="file"
              name="image"
              onChange={uploadHandler}
              className={classes.InputFile}
              value=""
              id="uploadField"
              accept="image/*"
            />
            <label htmlFor="uploadField">
              <span className="fal fa-camera"></span>
              {props.showText
                ? `${props.text ? props.text : "Click to Upload"}`
                : null}
            </label>
          </div>
        ) : (
          <div className={classes.UploadLabel}>
            <div onClick={removeImage} className={classes.RemoveContainer}>
              <i className="fal fa-times"></i>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UploadPhoto;
