import React, { useState } from "react";
import classes from "./SalesOrderView.module.css";
import OrderHeader from "../OrderHeader/OrderHeader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import CancelDeliveryModal from "../CancelDeliveryModal/CancelDeliveryModal";
import BrowserLinks from "../../../UI/Links/BrowserLinks/BrowserLinks";

const SalesOrderView = (props) => {
  const [cancelDelivery, setCancelDelivery] = useState(null);

  const cancelHandler = (orderId, salesOrderId) => {
    setCancelDelivery({
      orderId: orderId,
      salesOrderId: salesOrderId,
    });
  };

  const salesOrders = props.order.sales_orders.map((sales_order) => {
    const orderItems = sales_order.cart.map((item) => {
      return (
        <div key={item._id} className={classes.OrderItem}>
          <OrderHeader item={item} />
          <div className="table-responsive">
            <table className="table table-sm table-borderless">
              <thead className="thead-light">
                <tr>
                  <th>Qtys</th>
                  <th>Size</th>
                  <th>Colour</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item.quantity}</td>
                  {item.options && item.options.length ? (
                    <td>{item.options}</td>
                  ) : (
                    <td>None</td>
                  )}
                  {item.colours && item.colours.length ? (
                    <td>{item.colours}</td>
                  ) : (
                    <td>None</td>
                  )}
                  <td>
                    {`${item.product.country.currency.symbol} ${(
                      item.price * item.quantity
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    });
    let orderStatus = null,
      confirmOptions = null;
    if (sales_order.status.seller === window.ORDER_AWAITING_DELIVERY) {
      orderStatus = (
        <div className={`${classes.Status} bg-warning text-dark`}>
          <p>{sales_order.status.seller}</p>
        </div>
      );
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            The buyer has already made full payment for your products into our
            escrow account. Please bring the right items ordered by the buyer
            with the right specification to the nearest Hawkit collection center
            for inspection and delivery.
          </p>
          <p className="mb-2">
            <BrowserLinks
              to="https://hawkit.ng/collection_centers"
              className="btn btn-block btn-coloured-heavy"
            >
              VIEW HAWKIT COLLECTION CENTERS
            </BrowserLinks>
          </p>
          <p className="mb-1">
            If you don't have the item in stock or you are not interested in
            fulfilling this order, please cancel the order below. Note that you
            will attract penalty charges for cancelling the order and you may be
            suspended from using our service.
          </p>
          <p>
            <button
              onClick={() => cancelHandler(props.order._id, sales_order._id)}
              className="btn btn-danger"
            >
              CANCEL THIS ORDER
            </button>
          </p>
        </div>
      );
    } else if (sales_order.status.seller === window.ORDER_DELIVERED) {
      orderStatus = (
        <div className={`${classes.Status} bg-success `}>
          <p>{sales_order.status.seller}</p>
        </div>
      );
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            Thank you for fulfilling your order. Please wait for the buyer to
            confirm the delivery before receiving your money.
          </p>
        </div>
      );
    } else if (
      sales_order.status.seller === window.ORDER_CANCELLED ||
      sales_order.status.seller === window.ORDER_NOT_DELIVERED
    ) {
      orderStatus = (
        <div className={`${classes.Status} bg-danger `}>
          <p>{sales_order.status.seller}</p>
        </div>
      );
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            The order has been cancelled by you. Please note that this may
            attract penalty charges from you or possible suspension from our
            services.
          </p>
        </div>
      );
    } else if (sales_order.status.seller === window.ORDER_SHIPPED) {
      orderStatus = (
        <div className={`${classes.Status} bg-warning text-dark`}>
          <p>{sales_order.status.seller}</p>
        </div>
      );
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            You have successfully delivered this item to the nearest Hawkit
            collection center. Please wait for us to deliver the item to the
            buyer. You will be credited after a successful delivery. Thank you
            for using Hawkit.
          </p>
        </div>
      );
    }
    if (sales_order.status.buyer === window.ORDER_COMPLETED) {
      orderStatus = (
        <div className={`${classes.Status} bg-success `}>
          <p>{sales_order.status.buyer}</p>
        </div>
      );
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            The buyer has confirmed the delivery of the order. Your money has
            been paid into your Pending Balance and will be available in your
            MAIN wallet for withdrawal within the next 7 days. This provision is
            to implement the return policy in situations where the buyer returns
            the product due to defects.
          </p>
        </div>
      );
    } else if (sales_order.status.buyer === window.ORDER_REJECTED) {
      orderStatus = (
        <div className={`${classes.Status} bg-danger `}>
          <p>{sales_order.status.buyer}</p>
        </div>
      );
      confirmOptions = (
        <div className={classes.ConfirmOptions}>
          <p className="mb-2">
            This order is under dispute as the seller claims that the order has
            been delivered while the buyer claims that order was rejected. We
            are working to resolve the dispute after which appropriate
            allocation of funds will take place.
          </p>
        </div>
      );
    }
    return (
      <div key={sales_order._id} className={classes.SalesOrder}>
        <h5>
          Order ID:{" "}
          <Link to={`/orders/sales/${props.order._id}`}>
            @{props.order._id}
          </Link>
        </h5>
        {orderItems}
        <div className={classes.OrderItemFooter}>
          <div className={classes.OrderStatus}>
            {orderStatus}
            <div className={classes.OrderItemsTotal}>
              <div className={classes.Total}>
                <p className="ml-auto">Shipping Fee:</p>
                <h6>
                  {`${
                    props.user.country.currency.symbol
                  } ${sales_order.shipping_cost
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </h6>
              </div>
              <div className={classes.Total}>
                <p className="ml-auto">Total:</p>
                <h6>
                  {`${
                    props.user.country.currency.symbol
                  } ${sales_order.sub_total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </h6>
              </div>
              <div className={classes.Total}>
                <p className="ml-auto">Your Return:</p>
                <h6>
                  {`${props.user.country.currency.symbol} ${sales_order.returns
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </h6>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.Delivery}>
          <h5>Delivery Address</h5>
          <p>
            <span className="fas fa-map-marker-alt"></span>{" "}
            {props.order.delivery_address.address}
          </p>
        </div> */}
        {confirmOptions}
      </div>
    );
  });

  return (
    <div className={classes.SalesOrderView}>
      <div className={classes.OrderItems}>
        {salesOrders}
        {cancelDelivery ? (
          <CancelDeliveryModal
            refresh={props.refresh}
            destroy={() => setCancelDelivery(null)}
            cancelDelivery={cancelDelivery}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(SalesOrderView));
