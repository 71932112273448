import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Transactions.module.css";
import { Switch, Route, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import WalletTransactions from "./WalletTransactions/WalletTransactions";
import AirtimeTransactions from "./AirtimeTransactions/AirtimeTransactions";
import WithdrawalTransactions from "./WithdrawalTransactions/WithdrawalTransactions";

class Transactions extends Component {
  render() {
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>My Transactions</h4>
                </TitleHeader>
              </Title>
              <div className={classes.Transactions}>
                <div className={classes.TransactionsNav}>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/transactions/shopping"
                  >
                    Shopping
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/transactions/airtime"
                  >
                    Airtime History
                  </NavLink>
                  <NavLink
                    replace
                    exact
                    activeClassName={classes.Active}
                    to="/transactions/withdrawal"
                  >
                    Withdrawal History
                  </NavLink>
                </div>
                <div className={classes.TransactionsContent}>
                  <Switch>
                    <Route
                      path="/transactions/airtime"
                      component={AirtimeTransactions}
                    />
                    <Route
                      path="/transactions/withdrawal"
                      component={WithdrawalTransactions}
                    />
                    <Route
                      path="/transactions/:wallet"
                      component={WalletTransactions}
                    />
                  </Switch>
                </div>
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(Transactions);
