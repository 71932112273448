import React from "react";
import classes from "./SalesOrder.module.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const moment = require("moment");

const SalesOrder = (props) => {
  const now = moment();
  const orderMoment = moment(props.order.created_on);
  const daysDiff = now.diff(orderMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = orderMoment.calendar();
  } else {
    formatDate = orderMoment.fromNow();
  }

  let orderStatus = null,
    actionRequired = null;
  const sales_order = props.order.sales_orders[0];
  if (sales_order.status.seller === window.ORDER_AWAITING_DELIVERY) {
    orderStatus = (
      <div className={`${classes.Status} bg-warning text-dark`}>
        <p>AWAITING DELIVERY</p>
      </div>
    );
    actionRequired = (
      <div
        className={`${classes.ActionRequired} ml-1 bg-danger text-white text-center blink rounded`}
      >
        <p>Action Required</p>
      </div>
    );
  } else if (
    sales_order.status.seller === window.ORDER_CANCELLED ||
    sales_order.status.seller === window.ORDER_NOT_DELIVERED
  ) {
    orderStatus = (
      <div className={`${classes.Status} bg-danger`}>
        <p>ORDER NOT DELIVERED</p>
      </div>
    );
  } else if (sales_order.status.seller === window.ORDER_DELIVERED) {
    orderStatus = (
      <div className={`${classes.Status} bg-success`}>
        <p>ORDER DELIVERED</p>
      </div>
    );
  } else if (sales_order.status.seller === window.ORDER_SHIPPED) {
    orderStatus = (
      <div className={`${classes.Status} bg-warning text-dark`}>
        <p>ORDER SHIPPED</p>
      </div>
    );
  }
  if (sales_order.status.buyer === window.ORDER_COMPLETED) {
    orderStatus = (
      <div className={`${classes.Status} bg-success`}>
        <p>ORDER COMPLETED</p>
      </div>
    );
  } else if (sales_order.status.buyer === window.ORDER_REJECTED) {
    orderStatus = (
      <div className={`${classes.Status} bg-danger`}>
        <p>ORDER REJECTED</p>
      </div>
    );
  }

  return (
    <div className={classes.SalesOrder}>
      <div className={classes.SalesOrderHeader}>
        <div className={classes.Index}>{props.index}</div>
        <div className={classes.OrderHeadInfo}>
          <h5>
            Order ID:{" "}
            <Link to={`/orders/sales/${props.order._id}`}>
              {props.order._id}
            </Link>
          </h5>
          <p>{formatDate}</p>
        </div>
      </div>
      <div className={classes.SalesOrderSummary}>
        <div className="table-responsive">
          <table className="table table-sm table-borderless">
            <thead className="thead-light">
              <tr>
                <th>Item(s)</th>
                <th>Total</th>
                <th>Your Return</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{sales_order.cart.length}</td>
                <td>{`${
                  props.user.country.currency.symbol
                } ${sales_order.sub_total
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                <td>{`${
                  props.user.country.currency.symbol
                } ${sales_order.returns
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.OrderStatus}>
          {orderStatus}
          <Link
            className="ml-2 btn btn-coloured-heavy btn-xs"
            to={`/orders/sales/${props.order._id}`}
          >
            View Order
          </Link>
          {actionRequired}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(withRouter(SalesOrder));
