/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from "react";
import classes from "./ImageEditor.module.css";
import ImageCropper from "./ImageCropper/ImageCropper";

const ImageEditor = props => {
  const imageContainerRef = useRef(null);
  const imageEditorSectionRef = useRef(null);
  const cropSectionRef = useRef(null);
  const imageSectionRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const scrollSizeRef = useRef(null);
  const scrollIndicatorRef = useRef(null);
  const applyButtonRef = useRef(null);  

  useEffect(() => {
    window.$("#imageEditor").off("shown.bs.modal");
    window.$("#imageEditor").off("hidden.bs.modal");
    window.$("#imageEditor").modal("show");
    let imageCropper = null;
    window.$("#imageEditor").on("shown.bs.modal", event => {
      const image = new Image();
      const callback = (image, crop) => {
        window.$("#imageEditor").modal("hide");
        props.result(image, crop);
      }
      image.src = props.image;
      imageCropper = new ImageCropper(
        imageContainerRef.current,
        image,
        cropSectionRef.current,
        imageEditorSectionRef.current,
        imageSectionRef.current,
        callback
      );
      imageCropper.setApplyButton(applyButtonRef.current)
      imageCropper.setScrollSection({
        container: scrollContainerRef.current,
        size: scrollSizeRef.current,
        indicator: scrollIndicatorRef.current
      });
      imageCropper.initialize();            
    });
    window.$("#imageEditor").on("hidden.bs.modal", event => {
      if (imageCropper) {
        imageCropper.destroy();
      }
    });
  }, [props.image, props.result]);

  return (
    <div className={classes.ImageEditor}>
      <div
        id="imageEditor"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className={classes.ImageModal}>
          <div
            className={`${classes.ImageEditorDialog} modal-dialog`}
            role="document"
          >
            <div className={`${classes.ImageEditorContent} modal-content`}>
              <div className={`${classes.ImageEditorHeader} modal-header`}>
                <button
                  type="button"
                  className={`${classes.CloseButton} close`}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    className="fal fa-times-circle"
                    aria-hidden="true"
                  ></span>
                </button>
                <h5>Edit Image</h5>
                <button
                  ref={applyButtonRef}
                  className={`${classes.ApplyButton} ml-auto btn btn-coloured-heavy`}
                >
                  Apply
                </button>
              </div>
              <div className={`${classes.ImageEditorBody} modal-body`}>
                <div
                  ref={imageContainerRef}
                  className={classes.ImageEditorContainer}
                >
                  <div
                    ref={imageEditorSectionRef}
                    className={classes.ImageEditorSection}
                  >
                    <div
                      ref={imageSectionRef}
                      className={classes.ImageSection}
                    ></div>
                    <img draggable={false} src={props.image} alt="" />
                  </div>
                  <div
                    ref={cropSectionRef}
                    className={classes.CropSection}
                  ></div>
                </div>
                <div className={classes.ZoomBar}>
                  <div className={classes.ZoomContent}>
                    <div className={classes.ZoomContainer}>
                      <div className={classes.ScrollIconSm}>
                        <span className="fal fa-search-minus"></span>
                      </div>
                      <div
                        ref={scrollContainerRef}
                        className={classes.ScrollContainer}
                      >
                        <div className={classes.ZoomSection}>
                          <div
                            ref={scrollSizeRef}
                            className={classes.ScrollSize}
                          ></div>
                          <div
                            ref={scrollIndicatorRef}
                            className={classes.ScrollIndicator}
                          >
                            <div className={classes.Indicator}></div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.ScrollIconLg}>
                        <span className="fal fa-search-plus"></span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.ZoomTitle}>
                    <h5>Slide to Zoom</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;
