import React, { Component } from "react";
import classes from "./ChatEditor.module.css";
import UserPhoto from "../../../../components/App/User/UserPhoto/UserPhoto";
import Form from "../../../../components/UI/Forms/Form";
import { createChatInputElement } from "../../../../components/UI/Forms/utility/form-creators";
import { connect } from "react-redux";
import axios from "../../../../axios/axios-instance";

class ChatEditor extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        chat: createChatInputElement(
          "Type a message",
          "",
          this.chatChangeHandler,
          null,
          false,
          false,
          "chatEditor",
          props.focus        
        ),
      },
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    document.getElementById("chatEditor").focus();
  }

  chatChangeHandler = (event) => {
    const formElementData = { ...this.state.formData.chat };
    formElementData.value = event.target.value;
    const updatedFormData = {
      chat: formElementData,
    };
    this.setState({ formData: updatedFormData });
  };

  submitChatHandler = (event) => {
    event.preventDefault();
    const formElementData = { ...this.state.formData.chat };
    if (
      formElementData.value.length > 0 &&
      formElementData.value.length <= 5000 &&
      !formElementData.submitting
    ) {
      formElementData.submitting = true;
      formElementData.invalid = null;
      const updatedFormData = {
        chat: formElementData,
      };
      this.setState({ formData: updatedFormData });
      const userToken = this.props.userToken;
      axios({
        method: "post",
        url: `/chats/${this.props.chat_id}`,
        data: {
          message: formElementData.value,
          recipient: this.props.recipientUser.username,
        },
        headers: {
          Authorization: userToken.token,
        },
      })
        .then((response) => {
          if (!this._isMounted) return;
          const chat_message = response.data.data.message;
          this.props.submit(chat_message);
          formElementData.submitting = false;
          formElementData.submitted = true;
          formElementData.value = "";
          const updatedFormData = {
            chat: formElementData,
          };
          this.setState({ formData: updatedFormData });
        })
        .catch((error) => {
          if (error.response) {
            const data = error.response.data;
            formElementData.invalid = Array.isArray(data.error)
              ? data.error[0].msg
              : data.error;
          } else {
            const errorMsg =
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message;
            formElementData.invalid = errorMsg;
          }
          formElementData.submitting = false;
          const updatedFormData = {
            chat: formElementData,
          };
          this.setState({ formData: updatedFormData });
        });
    } else {
      formElementData.invalid = "Message should contain 1 - 5000 characters";
      const updatedFormData = {
        chat: formElementData,
      };
      this.setState({ formData: updatedFormData });
    }
  };

  render() {
    return (
      <div className={classes.ChatEditor}>
        <div className={classes.ChatField}>
          <UserPhoto
            photo={this.props.user.profile_pic}
            width="40"
            height="40"
          />
          <div className="flex-grow-1">
            <Form
              formData={this.state.formData}
              submit={this.submitChatHandler}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(ChatEditor);
