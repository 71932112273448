import React from "react";
import classes from "./List.module.css";
import { Link } from "react-router-dom";

const List = (props) => {
  return (
    <div className={classes.List}>
      {props.title ? (
        <div className={classes.Title}>
          <h4>{props.title}</h4>
          {props.description ? <p>{props.description}</p> : null}
        </div>
      ) : null}
      <div className={classes.Content}>{props.children}</div>
      {props.showMore ? (
        <div className={classes.ShowMore}>
          <Link to={props.showMore}>
            View More <span className="fal fa-chevron-down"></span>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default List;
