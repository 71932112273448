import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./SocialOrders.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import SocialOrder from "../../../components/App/Order/SocialOrder/SocialOrder";
import { Link } from "react-router-dom";
import ConfirmModal from "../../../components/UI/Modal/ConfirmModal/ConfirmModal";
import TitleLinks from "../../../components/App/Layout/Title/TitleLinks";

class SocialOrders extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      orders: null,
      paymentCompleted: false,
      deleteModal: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    this.loadSocialOrders();
  }

  loadSocialOrders = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/orders`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const orders = response.data.data.orders;
        let new_meta = response.data.data.meta;
        if (!new_meta) new_meta = {};
        let meta = this.props.meta;
        this.props.setMeta({ ...meta, ...new_meta });
        this.setState({
          orders: orders,
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      orders: null,
      error: null,
    });
  };

  deleteOrder = (id) => {
    const userToken = this.props.userToken;
    axios({
      method: "delete",
      url: `/social/orders/${id}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const orders = response.data.data.orders;
        let new_meta = response.data.data.meta;
        if (!new_meta) new_meta = {};
        let meta = this.props.meta;
        this.props.setMeta({ ...meta, ...new_meta });
        this.setState({
          orders: orders,
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      deleteModal: null,
      orders: null,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  showDeleteModal = (order) => {
    const title = "Delete Order: " + order.social_task_category.title.buyer;
    const description =
      "Are you sure you want to delete this order? This action cannot be undone once it is performed.";
    this.setState({
      deleteModal: {
        title: title,
        description: description,
        id: order._id,
      },
    });
  };

  hideDeleteModal = () => {
    this.setState({
      deleteModal: null,
    });
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadSocialOrders} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;
    const orders = this.state.orders;

    if (orders) {
      content = orders.length ? (
        orders.map((el, index) => {
          return (
            <div key={index}>
              <SocialOrder
                deleteOrder={() => this.showDeleteModal(el)}
                order={el}
              />
            </div>
          );
        })
      ) : (
        <div className={classes.PostLoading}>
          <h6 className="mb-1">No orders found</h6>
          <p className="f-12">
            You have not made any order yet. Click the button below to make an
            order now.
          </p>
          <p>
            <Link
              className="btn btn-xs btn-coloured-heavy"
              to="/social/buy/pricing"
            >
              CREATE NEW ORDER
            </Link>
          </p>
        </div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>My Orders</h4>
                </TitleHeader>
                <TitleLinks>
                  <Link
                    to="/social/buy/pricing"
                    className="btn btn-outline btn-rounded py-2"
                  >
                    <i className="fas fa-plus-circle"></i> NEW ORDER
                  </Link>
                </TitleLinks>
              </Title>
              <div className={classes.SocialOrders}>
                {content}{" "}
                {this.state.deleteModal ? (
                  <ConfirmModal
                    title={this.state.deleteModal.title}
                    description={this.state.deleteModal.description}
                    confirm={() => this.deleteOrder(this.state.deleteModal.id)}
                    cancel={this.hideDeleteModal}
                  />
                ) : null}
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
    setMeta: (meta) => dispatch(actionCreators.setMeta(meta)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialOrders);
