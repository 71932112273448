/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import classes from "./ProductPosts.module.css";
import ProductDescriptionModal from "../ProductDescriptionModal/ProductDescriptionModal";
import ReviewsModal from "../../Products/ReviewsModal/ReviewsModal";
import ProductPost from "../ProductPost/ProductPost";
import ScrollButtons from "../../../UI/Buttons/ScrollButtons/ScrollButtons";
import { Link, withRouter } from "react-router-dom";
import AirtimeBuyModal from "../AirtimeBuyModal/AirtimeBuyModal";
import ResellModal from "../../Products/ResellModal/ResellModal";

const ProductPosts = (props) => {
  const [buyPost, setBuyPost] = useState(null);
  const [reviewPost, setReviewPost] = useState(null);
  const [resellProduct, setResellProduct] = useState(null);
  const [descriptionPost, setDescriptionPost] = useState(null);

  const buyHandler = (post) => {
    if (post.product.is_airtime) {
      setBuyPost(post);
    } else {
      props.history.push(`/posts/${post._id}`);
    }
  };

  const descriptionHandler = (post) => {
    setDescriptionPost(post);
  };

  const reviewHandler = (post) => {
    setReviewPost(post);
  };

  const resellHandler = (post) => {
    setResellProduct(post);
  };

  let postContent = props.posts.map((post) => {
    return (
      <ProductPost
        col={props.col}
        hideUser={props.hideUser}
        buyHandler={() => buyHandler(post)}
        descriptionHandler={() => descriptionHandler(post)}
        reviewHandler={(event) => {
          event.preventDefault();
          reviewHandler(post);
        }}
        resellHandler={(event) => {
          event.preventDefault();
          resellHandler(post);
        }}
        key={`${post._id}-${props.parent}`}
        post={post}
      />
    );
  });

  let scrollMore = null;

  if (props.showScroll) {
    scrollMore = (
      <div className="col-6 col-sm-4 pb-3">
        <div className={classes.ScrollMore}>
          <img src={props.scroll_info.image} alt="scroll" />
          <h5>{props.scroll_info.title}</h5>
          <p>
            <Link className="btn btn-outline" to={props.scroll_info.link}>
              VIEW MORE
            </Link>
          </p>
        </div>
      </div>
    );
  }

  let buyPostContent = null;

  if (buyPost) {
    buyPostContent = (
      <AirtimeBuyModal post={buyPost} destroy={() => setBuyPost(null)} />
    );
  }

  return (
    <div id={props.parent} className={classes.Posts}>
      <div className="container-fluid position-relative">
        {props.showScroll ? (
          <ScrollButtons
            parent={props.parent}
            scrollContainer={props.scrollContainer}
          />
        ) : null}
        <div
          id={props.scrollContainer}
          className={`row ${props.showScroll ? classes.ShowScroll : ""}`}
        >
          {postContent}
          {scrollMore}
        </div>
      </div>
      {buyPost ? buyPostContent : null}
      {reviewPost ? (
        <ReviewsModal
          product={reviewPost.product}
          destroy={() => setReviewPost(null)}
        />
      ) : null}
      {resellProduct ? (
        <ResellModal
          product={resellProduct.product}
          destroy={() => setResellProduct(null)}
        />
      ) : null}
      {descriptionPost ? (
        <ProductDescriptionModal
          post={descriptionPost}
          destroy={() => setDescriptionPost(null)}
        />
      ) : null}
    </div>
  );
};

export default withRouter(ProductPosts);
