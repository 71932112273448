/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./AirtimeReview.module.css";
import OfficeSpinner from "../../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../../axios/axios-instance";
import { connect } from "react-redux";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import DropdownLink from "../../../../UI/Dropdown/DropdownLink/DropdownLink";
import DropdownMenu from "../../../../UI/Dropdown/DropdownMenu/DropdownMenu";

let is_Mounted = false;

const AirtimeReview = (props) => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    completed: null,
  });

  const [formData, setFormData] = useState({
    rating: 5,
    review: "",
  });

  let ratingContent = [];

  const setRating = (event, index) => {
    event.preventDefault();
    setFormData({
      ...formData,
      rating: index,
    });
  };

  const textChangeHandler = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      review: value,
    });
  };

  const submitHandler = () => {
    if (formData.review.length < 3 || formData.review.length >= 500) {
      setState({
        ...state,
        error: "Please enter your review. 3 - 500 characters allowed",
      });
      return;
    }
    setState({
      ...state,
      error: null,
      loading: true,
    });
    const userToken = props.userToken;
    axios({
      method: "post",
      url: `products/${props.product._id}/reviews`,
      data: {
        rating: formData.rating,
        review: formData.review,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!is_Mounted) return;
        const status = response.data.data.status;
        if (status === "success") {
          setState({
            error: null,
            loading: false,
            completed: true,
          });
        }
      })
      .catch((error) => {
        if (!is_Mounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg,
          });
        }
      });
  };

  let activeRating = null;

  for (let index = 5; index >= 1; index--) {
    const ratingStars = [];
    for (let j = 0; j < index; j++) {
      const ratingStar = <span key={j} className="fas fa-star"></span>;
      ratingStars.push(ratingStar);
    }
    const mainRatingStars = (
      <>
        <div className={classes.Star}>{ratingStars}</div>
        <div className={classes.Number}>
          <span>{index} star</span>
        </div>
      </>
    );
    if (index === formData.rating) {
      activeRating = mainRatingStars;
    }
    ratingContent.push(
      <a key={index} onClick={(event) => setRating(event, index)} href="/">
        <div className={classes.RatingStar}>{mainRatingStars}</div>
      </a>
    );
  }

  const reviewForm = (
    <div className={classes.ReviewForm}>
      <p className="mb-2">
        Kindly rate and review this transaction so other buyers can know about
        your experience.
      </p>
      <div className={classes.Rating}>
        <label className="mb-1">Choose Rating</label>
        <Dropdown>
          <DropdownLink>
            <div className={`${classes.RatingStar} border rounded`}>
              {activeRating}
              <span className="ml-auto fal fa-chevron-down"></span>
            </div>
          </DropdownLink>
          <DropdownMenu>{ratingContent}</DropdownMenu>
        </Dropdown>
      </div>
      <div className={classes.ReviewArea}>
        <label className="mb-1">Please enter a review</label>
        <textarea
          placeholder="What's your experience with this seller?"
          rows="4"
          className="form-control"
          value={formData.review}
          onChange={textChangeHandler}
        ></textarea>
      </div>
      <div className="py-3">
        <button
          onClick={submitHandler}
          className="mr-1 btn btn-success btn-rounded"
        >
          SUBMIT REVIEW
        </button>
        <p className="f-10 mt-2">
          You will need to enter the review before you can continue.
        </p>
      </div>
    </div>
  );

  let content = null;
  if (state.loading) {
    content = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.completed) {
    content = <p>Review Posted Successfully!</p>;
  } else {
    content = reviewForm;
  }

  useEffect(() => {
    is_Mounted = true;
    return () => {
      is_Mounted = false;
    };
  }, []);

  return (
    <div className={classes.AirtimeReview}>
      {state.error ? (
        <div className="mb-0 alert alert-danger">{state.error}</div>
      ) : null}
      {content}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps, null)(AirtimeReview);
