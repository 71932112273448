/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./Register.module.css";
import * as validators from "../../../components/UI/Forms/utility/validator";
import Form from "../../../components/UI/Forms/Form";
import {
  createInputElement,
  createPhoneNumberInputElement,
  createButtonElement,
} from "../../../components/UI/Forms/utility/form-creators";
import LogoImage from "../../../components/UI/Images/LogoImage/LogoImage";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import * as actionCreators from "../../../store/actions/actions";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";
import { Helmet } from "react-helmet";
import { getReferrer } from "../../../util/util";

class Register extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    const formData = {
      name: createInputElement(
        "text",
        null,
        "Enter Your Full Name",
        "",
        this.validateName,
        "Please enter your full name.",
        "fa fa-user",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "name")
      ),
      referrer: createInputElement(
        "text",
        "Referrer's Username (Optional)",
        "Your Referrer Username",
        getReferrer() ? getReferrer() : "",
        this.validateReferrer,
        getReferrer() ? (
          <span>
            You have already been referred by{" "}
            <Link to={`/users/${getReferrer()}`}>@{getReferrer()}</Link>. This
            field cannot be edited.
          </span>
        ) : (
          "Please enter the username of the person who referred you to Hawkit. You can leave this empty if you wish."
        ),
        "fas fa-mobile-alt",
        "col-sm-12",
        this.inputChangedHandler.bind(this, "referrer"),
        undefined,
        false,
        getReferrer() ? true : false
      ),
      submit: createButtonElement("submit", "SIGN UP", true),
    };
    this.state = {
      processing: false,
      formError: null,
      formData: formData,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  validateName(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter the name of your account.";
    }
    if (!validators.validateLength(value.trim(), 3, 50)) {
      return "Please enter valid name of account. 3 - 50 characters are allowed";
    }
    if (!validators.validateName(value.trim())) {
      return "Please enter valid name of account. Only letters are allowed";
    }
    return null;
  }

  validateReferrer(value) {
    if (validators.isEmpty(value.trim())) {
      return null;
    }
    if (!validators.validateLength(value.trim(), 4, 20)) {
      return "Please enter valid referrer's username. 4 - 20 Characters are allowed";
    }
    return null;
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({
      formData: updatedFormData,
    });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  registerHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const name = this.state.formData.name.value;
    let referrer;
    if (
      this.state.formData.referrer &&
      this.state.formData.referrer.value.length
    ) {
      referrer = this.state.formData.referrer.value;
    }
    axios({
      method: "post",
      url: "/auth/signup/check",
      data: {
        name: name,
        referrer: referrer,
      },
    })
      .then((response) => {
        this.setState({
          processing: false,
        });
        const verifyUser = response.data.data;
        this.props.setCompleteSignUp(verifyUser);
        this.props.history.replace("/auth/signup");
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          console.log(data);
          if (Array.isArray(data.error)) {
            this.setState({
              processing: false,
              formData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              processing: false,
              formError: data.error,
            });
          }
        } else {
          this.setState({
            processing: false,
            formError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      processing: true,
      formError: null,
    });
    return false;
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm,
    };
  }

  render() {
    let registerContent = (
      <div className={`d-flex flex-column align-items-center`}>
        {this.state.formError ? (
          <AlertError error={this.state.formError} />
        ) : null}
        <Form
          loading={this.state.processing}
          formData={this.state.formData}
          submit={this.registerHandler}
        />
        <p className={classes.TermText}>
          By signing up, you agree to our <br />
          <BrowserLinks to="https://hawkit.ng/about/privacy-policy">
            Terms and Privacy Policy
          </BrowserLinks>
        </p>
        <div className={classes.Login}>
          <p>Already have an account on Hawkit?</p>
          <p>
            <Link
              replace
              className="btn btn-primary btn-xl btn-block"
              to="/auth/login"
            >
              Log In Now
            </Link>
          </p>
        </div>
      </div>
    );
    return (
      <div className={classes.Register}>
        <Helmet>
          <title>
            Hawkit - Sign Up on Hawkit
          </title>
        </Helmet>
        <div className={classes.RegisterContent}>
          <div className={classes.RegisterForm}>
            <h4 className="mb-1">Sign Up on Hawkit</h4>
            <p className="mb-4">Registration is fast and free!</p>
            {registerContent}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientToken: state.authState.clientToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthCountry: (country) => {
      dispatch(actionCreators.setAuthCountry(country));
    },
    setVerifyPhone: (verifyPhone) =>
      dispatch(actionCreators.setVerifyPhone(verifyPhone)),
    setCompleteSignUp: (completeSignUp) =>
      dispatch(actionCreators.setCompleteSignUp(completeSignUp)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
