const affix = (element, is_auth = true) => {
  if (window.$(window).width() < 768) {
    return;
  }
  element.style.width = element.getBoundingClientRect().width + "px";
  element.style.height = "calc(100vh - (60px))";
  element.style.position = "fixed";
  if (is_auth) {
    element.style.top = "calc(60px)";
  } else {
    element.style.top = "calc(85px)";
  }
};

export default affix;
