/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import classes from "../Post.module.css";

const believeContent = (props) => {
  useEffect(() => {
    props.addPostView(props.postId);
  }, []);
  return (
    <div className={classes.BelieveContent}>
      <p>{props.content}</p>
    </div>
  );
};

export default believeContent;
