/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import { connect } from "react-redux";
import classes from "./AirtimeTransactions.module.css";
import { Link } from "react-router-dom";

const moment = require("moment");

class AirtimeTransactions extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      transactions: [],
    };
  }

  loadTransactions = (isRefresh = true) => {
    let page = 1;
    if (!isRefresh && this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/transactions/airtime?page=${page}`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const transactions = response.data.data.transactions;
        const filteredTransactions = transactions.filter((el) => {
          return !this.state.transactions.find(
            (transaction) => transaction._id === el._id
          );
        });
        const updatedTransactions = [
          ...this.state.transactions,
          ...filteredTransactions,
        ];
        const pages = response.data.data.pages;
        this.setState({
          loading: false,
          pages: pages,
          transactions: updatedTransactions,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
    if (isRefresh) {
      this.setState({
        transactions: [],
      });
    }
  };

  loadMoreTransactions = (event) => {
    event.preventDefault();
    this.loadTransactions(false);
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadTransactions(true);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.Loading}>
          <a onClick={this.loadMoreTransactions} href="/">
            Load More <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.Loading}>
          <Error refresh={this.loadMoreTransactions} error={this.state.error} />
        </div>
      );
    }
    let content = null;
    if (this.state.transactions.length) {
      const transactions = this.state.transactions.map((transaction, index) => {
        const orderMoment = moment(transaction.created_on);
        return (
          <tr key={transaction._id}>
            <td>{index + 1}.</td>
            <td className="f-10">{orderMoment.format("MMM Do YYYY h:mm a")}</td>
            <td>{transaction.productId.title}</td>
            <td>{transaction.quantity}</td>
            <td>{transaction.phoneNumber}</td>
            <td>
              <p className="f-10 fw-500 badge badge-info">
                {transaction.status}
              </p>
            </td>
          </tr>
        );
      });
      content = (
        <div className={classes.AirtimeTransactions}>
          <div className="table-responsive">
            <table className="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Product</th>
                  <th>Qty(s)</th>
                  <th>Recipient</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{transactions}</tbody>
            </table>
          </div>
        </div>
      );
    } else if (!this.state.loading && !this.state.error) {
      content = (
        <div className={classes.TransactionsEmpty}>
          <i className="fal fa-shopping-bag"></i>
          <p className="mb-3">
            You have not purchased any airtime or data. Buy Airtime and Data and
            enjoy 3% to 10% discount.
          </p>
          <Link
            to="/shop/5f9daf677f62135fa0a39f3a"
            className="btn btn-coloured-heavy"
          >
            Buy Airtime/Data
          </Link>
        </div>
      );
    }

    return (
      <>
        {content}
        {loadingContent}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(AirtimeTransactions);
