/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classes from "./ShopSideMenu.module.css";
import ShopSideCategory from "./ShopSideCategory/ShopSideCategory";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actionCreators from "../../../../../store/actions/actions";
import OfficeSpinner from "../../../../UI/Spinner/OfficeSpinner/OfficeSpinner";

const ShopSideMenu = (props) => {
  const link = props.is_market ? "market" : "shop";
  const categoriesContent = props.shop.categories.map((category, index) => {
    return (
      <div key={index}>
        <ShopSideCategory link={link} category={category} />
      </div>
    );
  });

  const setLocationHandler = (event) => {
    if (props.closeMenu) props.closeMenu();
    props.setSearchLocation(event.target.value);
  };

  const setSortByHandler = (event) => {
    if (props.closeMenu) props.closeMenu();
    props.setSearch("");
    props.setSortBy(event.target.value);
  };

  let locationContent;

  if (props.country_states.length) {
    const statesContent = props.country_states.map((state) => (
      <option value={state.name} key={state._id}>
        {state.name}
      </option>
    ));
    locationContent = (
      <select
        onChange={(event) => setLocationHandler(event)}
        value={props.location}
        className="form-control custom-select"
      >
        <option value="">All Nigeria</option>
        {statesContent}
      </select>
    );
  } else {
    locationContent = <OfficeSpinner size="2" />;
  }

  const sortByContent = (
    <select
      onChange={(event) => setSortByHandler(event)}
      value={props.sortBy}
      className="form-control custom-select"
    >
      <option value="Most Popular">Most Popular</option>
      <option value="Price (High)">Price (High)</option>
      <option value="Price (Low)">Price (Low)</option>
      <option value="Delivery Date">Delivery Date</option>
      <option value="New Arrivals">New Arrivals</option>
      <option value="What You Like">What You Like</option>
    </select>
  );

  const breadCrumbs = props.shop.parents.map((el) => {    
    return (
      <li key={el._id} className="breadcrumb-item">
        <Link to={`/${props.is_airtime ? "airtime" : link}/${el._id}`}>
          {el.name}
        </Link>
      </li>
    );
  });

  return (
    <div className={classes.ShopSideMenu}>
      <div className={classes.Title}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {props.shop.category.id && !props.is_airtime ? (
              <li className="breadcrumb-item">
                <Link to={props.is_market ? "/market" : "/shop"}>All Categories</Link>
              </li>
            ) : null}
            {breadCrumbs}
          </ol>
        </nav>
        <h5>{props.shop.category.name}</h5>
      </div>
      {props.shop.categories.length ? (
        <div className={classes.Categories}>
          <p>All Subcategories</p>
          {categoriesContent}
        </div>
      ) : null}
      <div className={classes.Options}>
        {!props.is_airtime ? (
          <div className={classes.Option}>
            <p>Select Location:</p>
            {locationContent}
          </div>
        ) : null}
        <div className={classes.Option}>
          <p>Sort By:</p>
          {sortByContent}
        </div>
        {!props.is_airtime ? (
          <div className="mt-2">
            <Link
              to="/shop/sell"
              className="btn btn-xl btn-coloured-heavy btn-block"
            >
              <i className="fas fa-plus-circle"></i> POST YOUR PRODUCTS/SERVICES
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    country_states: state.userState.country_states,
    sortBy: state.searchState.sortBy,
    location: state.searchState.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSortBy: (sortBy) => dispatch(actionCreators.setSortBy(sortBy)),
    setSearchLocation: (location) =>
      dispatch(actionCreators.setSearchLocation(location)),
    setSearch: (search) => dispatch(actionCreators.setSearch(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopSideMenu);
