import React, { Component } from "react";
import classes from "./ShopLayout.module.css";
import SideMenu from "../../../components/App/Layout/SideMenu/SideMenu";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import axios from "../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import { Helmet } from "react-helmet";
import Shop from "../Shop/Shop";
import ShopSideMenu from "../../../components/App/Layout/SideMenu/ShopSideMenu/ShopSideMenu";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import MenuButton from "../../../components/App/Layout/Header/MenuButton/MenuButton";
import Title from "../../../components/App/Layout/Title/Title";
import Form from "../../../components/UI/Forms/Form";
import { createSearchInputElement } from "../../../components/UI/Forms/utility/form-creators";
import { CSSTransition } from "react-transition-group";
import MobileFooter from "../../../components/App/Layout/Footer/MobileFooter/MobileFooter";
import ResellInfoModal from "./ResellInfoModal/ResellInfoModal";

class ShopLayout extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    props.setSortBy("Most Popular");
    props.setSearchLocation("");
    this.state = {
      loading: false,
      error: null,
      shop: null,
      searchFormData: {
        search: createSearchInputElement(
          "Search",
          props.search,
          this.searchChangeHandler,
          35
        ),
      },
      show_menu: false,
    };
  }

  initialiseAll() {
    this.props.setSortBy("Most Popular");
    this.props.setSearchLocation("");
    this.setState({
      shop: null,
      loading: false,
      error: null,
      show_menu: false,
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadShop();
    if (!this.props.country_states.length) {
      this.props.fetchCountryStates();
    }
    // this.searchHandler();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.category !== prevProps.match.params.category) {
      this.initialiseAll();
      this.loadShop();
    } else if (this.props.search !== prevProps.search) {
      this.props.setSortBy("Most Popular");
      this.props.setSearchLocation("");
    }
    if (this.props.search !== prevProps.search) {
      const formData = { ...this.state.searchFormData };
      formData.search = {
        ...formData.search,
        value: this.props.search,
      };
      this.setState({
        searchFormData: formData,
      });
    }
  }

  searchChangeHandler = (event) => {
    event.preventDefault();
    const formElementData = { ...this.state.searchFormData.search };
    formElementData.value = event.target.value;
    const updatedFormData = {
      search: formElementData,
    };
    this.setState({ searchFormData: updatedFormData });
  };

  searchFormSubmit = (event) => {
    event.preventDefault();
    let query = this.state.searchFormData.search.value;
    if (!query.trim().length) {
      this.props.setSearch("");
      return;
    }
    // query = query.trim().replace(/ /g, "+");
    this.props.setSearch(query);
    return false;
  };

  showMenuHandler = () => {
    this.setState((state) => {
      return {
        show_menu: !state.show_menu,
      };
    });
  };

  loadShop = () => {
    if (this.state.loading) return;
    const { category } = this.props.match.params;
    const userToken = this.props.userToken;
    let url = `/shop`;
    if (category) {
      url = `/shop/${category}`;
    }
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const shop = response.data.data.shop;
        const formData = { ...this.state.searchFormData };
        formData.search = {
          ...formData.search,
          value: this.props.search,
          placeholder: "Search " + shop.category.name,
        };
        this.setState({
          loading: false,
          shop: shop,
          searchFormData: formData,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      shop: null,
    });
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadShop} error={this.state.error} />
        </div>
      );
    }
    let content = loadingContent;

    if (this.state.shop) {
      content = (
        <>
          <Helmet>
            <title>Shop | {this.state.shop.category.name}</title>
          </Helmet>
          <div className="container-fluid">
            <div className="row">
              <div className="offset-xl-1 col-xl-10">
                <div className="row">
                  <div className="pr-md-0 d-none d-md-block col-lg-3 col-md-4">
                    <SideMenu>
                      <ShopSideMenu shop={this.state.shop} />
                    </SideMenu>
                  </div>
                  <div className="position-static col-lg-9 col-md-8">
                    <ContentLayout>
                      <SingleColumnLayout>
                        <MainContent>
                          <PageContent>
                            <Title classes="d-block d-lg-none">
                              <TitleHeader title="Shop">
                                <MenuButton
                                  handler={this.showMenuHandler}
                                  classes="d-block d-md-none"
                                  collapsed={!this.state.show_menu}
                                />
                                <div className={classes.SearchForm}>
                                  <Form
                                    formData={this.state.searchFormData}
                                    submit={this.searchFormSubmit}
                                  />
                                </div>
                              </TitleHeader>
                            </Title>
                            <Shop shop={this.state.shop} />
                          </PageContent>
                        </MainContent>
                      </SingleColumnLayout>
                    </ContentLayout>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ResellInfoModal />
          <CSSTransition
            in={this.state.show_menu}
            timeout={300}
            unmountOnExit
            classNames="transition"
          >
            <div className={classes.SideMenuContainer}>
              <div className={classes.SideMenu}>
                <SideMenu>
                  <ShopSideMenu
                    closeMenu={this.showMenuHandler}
                    shop={this.state.shop}
                  />
                </SideMenu>
              </div>
              <div className={classes.MenuButton}>
                <MenuButton
                  handler={this.showMenuHandler}
                  classes="d-block d-md-none"
                  collapsed={!this.state.show_menu}
                />
              </div>
            </div>
          </CSSTransition>
        </>
      );
    }

    return (
      <div className={classes.ShopLayout}>
        {content}
        <div className={classes.MobileFooter}>
          <MobileFooter user={this.props.user} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    country_states: state.userState.country_states,
    sortBy: state.searchState.sortBy,
    searchLocation: state.searchState.location,
    search: state.searchState.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSortBy: (sortBy) => dispatch(actionCreators.setSortBy(sortBy)),
    setSearchLocation: (location) =>
      dispatch(actionCreators.setSearchLocation(location)),
    setSearch: (search) => dispatch(actionCreators.setSearch(search)),
    fetchCountryStates: () => dispatch(actionCreators.fetchCountryStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopLayout);
