import React from "react";
import classes from "../Post.module.css";
import { Link } from "react-router-dom";
import PostProductImages from "../PostProductImages/PostProductImages";
// import PostVideo from "../PostVideo/PostVideo";

const postContent = (props) => {
  const postText =
    props.post.content_type === "article" && props.post.text.length
      ? "<p>" +
        props.post.text.replace(/\n{2,}/g, "</p><p>").replace(/\n/g, "<br>") +
        "</p>"
      : null;

  const rating = [];
  let usernameText = "";

  let product_rating = 0;

  if (props.post.content_type !== "article") {
    if (props.post.product.rating) {
      product_rating = props.post.product.rating;
    }
    for (let index = 0; index < Math.floor(product_rating); index++) {
      rating.push(
        <span
          key={index}
          className={`${classes.RatingActive} fa fa-star`}
        ></span>
      );
    }
    if (product_rating % 1) {
      rating.push(
        <span
          key={Date.now()}
          className={`${classes.RatingActive} fa fa-star-half-alt`}
        ></span>
      );
    }

    for (let index = Math.ceil(product_rating); index < 5; index++) {
      rating.push(<span key={index} className="fal fa-star"></span>);
    }

    usernameText = props.post.product.userId.username;
    if (usernameText.length >= 12) {
      usernameText = usernameText.substring(0, 12) + "...";
    }
  }

  const showDescription = (event) => {
    event.preventDefault();
    props.description();
  };

  const tagsContent = [];

  if (props.post.content_type === "products") {
    const categoryId = props.post.product.category._id;
    const categoryName = props.post.product.category.name;
    if (props.is_preview) {
      tagsContent.push(
        <a
          href="/"
          onClick={props.review}
          key={`${categoryName}-${Date.now()}`}
          className={classes.Tag}
        >
          {categoryName} <i className="ml-1 fas fa-tag"></i>
        </a>
      );
    } else {
      tagsContent.push(
        <Link
          to={`/${
            props.post.product.is_airtime ? "airtime" : "shop"
          }/${categoryId}`}
          key={`${categoryName}-${Date.now()}`}
          className={classes.Tag}
        >
          {categoryName} <i className="ml-1 fas fa-tag"></i>
        </Link>
      );
    }
  }    

  let sellContent;

  if (
    props.post.user.username === props.activeUser &&
    !props.post.product.is_airtime
  ) {
    sellContent = (
      <div className={classes.Buy}>
        <Link
          to={`/edit-product/${props.post.product._id}`}
          className="btn btn-outline btn-xl"
        >
          EDIT PRODUCT
        </Link>
      </div>
    );
  } else {
    sellContent = (
      <div className={classes.Buy}>
        <div className={classes.SellerInfo}>
          <p>
            <span className={classes.Stars}>{rating}</span>
          </p>
          <p className={classes.Rating}>
            <a onClick={props.review} href="/" className={classes.RatingNumber}>
              {props.post.product.reviews} Reviews
            </a>
          </p>
        </div>
        <button onClick={props.buy} className="btn btn-outline btn-xl">
          BUY NOW
        </button>
      </div>
    );
  }

  return (
    <div className={classes.PostContent}>
      {postText ? (
        <div
          dangerouslySetInnerHTML={{ __html: postText }}
          className={classes.TextContent}
        ></div>
      ) : null}
      {/* <PostVideo videoData={props.post.content.videoData} /> */}
      <PostProductImages showImage={props.showImage} post={props.post} />
      {props.post.content_type === "products" ? (
        <div className={classes.ProductDetail}>
          {tagsContent.length ? (
            <div className={classes.ProductTags}>{tagsContent}</div>
          ) : null}
          <div className={classes.ProductTitle}>{props.post.product.title}</div>
          <div className={classes.DetailsLink}>
            <a onClick={showDescription} href="/">
              More Details <i className="fas fa-external-link"></i>
            </a>
          </div>
          <div className={classes.ProductPrice}>
            <div className={classes.Price}>
              <p className={classes.MainPrice}>
                {`${
                  props.post.product.country.currency.symbol
                } ${props.post.product.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </p>
              <p className={classes.DummyPrice}>
                {`${
                  props.post.product.country.currency.symbol
                } ${props.post.product.dummy_price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </p>
            </div>
            {sellContent}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default postContent;
