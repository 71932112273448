/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./VerifyPhone.module.css";
import axios from "../../../axios/axios-instance";
import Form from "../../../components/UI/Forms/Form";
import {
  createButtonElement,
  createInputElement,
  createOtpInputElement,
} from "../../../components/UI/Forms/utility/form-creators";
import * as validators from "../../../components/UI/Forms/utility/validator";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import RegFlowLayout from "../../../components/App/Layout/RegFlowLayout/RegFlowLayout";
import Header from "../../../components/App/Layout/Header/Header";
import { Helmet } from "react-helmet";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import OtpImage from "../../../assets/images/phone.png";
import { Link } from "react-router-dom";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";

class VerifyPhone extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      phoneFormData: {
        phoneNumber: createInputElement(
          "number",
          "",
          "Enter your Phone Number",
          this.props.user.phoneNumber ? this.props.user.phoneNumber : "",
          this.validatePhoneNumber,
          "Please enter your Phone Number. Don't enter the country code (e.g +234). Only the phone number (e.g 080******) is needed",
          "fa fa-mobile-alt",
          "col-sm-12",
          this.phoneInputChangedHandler.bind(this, "phoneNumber")
        ),
        submit: createButtonElement("submit", "UPDATE PHONE NUMBER", true),
      },
      formData: {
        token: createOtpInputElement(
          "text",
          null,
          null,
          "",
          this.validateTwoFactor,
          null,
          null,
          "col-7",
          this.inputChangedHandler.bind(this, "token"),
          10
        ),
        submit: createButtonElement("submit", "CONTINUE", true, "col-5"),
      },
      change_phone_number: true,
      error: null,
      otp: null,
      submitting: false,
      submittingError: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submit = (otp) => {
    const userToken = this.props.userToken;
    axios({
      method: "put",
      url: "/users/otp/phone/verify",
      data: {
        otp: otp,
        otpId: this.state.otp.id,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const data = response.data.data;
        const user = data.user;
        this.props.processUser(user);
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              submittingError: data.error[0].msg,
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error,
            });
          }
        } else {
          this.setState({
            submitting: false,
            submittingError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      submitting: true,
      submittingError: null,
    });
  };

  validateTwoFactor = (value) => {
    if (validators.isEmpty(value.trim())) {
      return "Please enter valid verification code!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Verification Code. Only Numbers allowed";
    }
    if (!validators.validateLength(value.trim(), 6, 10)) {
      return "Verification Code must contain atleast six(6) digits";
    }
    return null;
  };

  validatePhoneNumber(value) {
    if (validators.isEmpty(value.trim())) {
      return "Pleaase enter valid phone number!";
    }
    if (!validators.isPosInteger(value.trim())) {
      return "Invalid Phone Number. Only numbers are allowed";
    }
    if (!validators.validateLength(value.trim(), 5, 11)) {
      return "Please enter valid phone number. Don't enter the country code (e.g +234). Only the phone number (e.g 080******) is needed";
    }
    return null;
  }

  inputChangedHandler = (name, event) => {
    let formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
    };
    updatedFormData[name] = formElementData;
    this.setState({
      formData: updatedFormData,
    });
  };

  phoneInputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.phoneFormData[name] };
    formElementData.value = event.target.value;
    formElementData.invalid = formElementData.validate(formElementData.value);
    const updatedFormData = {
      ...this.state.phoneFormData,
    };
    updatedFormData[name] = formElementData;
    this.setState({ phoneFormData: updatedFormData });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  checkIsValidPhoneFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.phoneFormData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      phoneFormData: formData,
    });
    return isFormActivated;
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.checkIsValidFormData()) {
      this.submit(this.state.formData.token.value);
    }
    return false;
  };

  updatePhoneHandler = (event) => {
    event.preventDefault();
    if (this.checkIsValidPhoneFormData()) {
      this.updatePhoneNumber();
    }
    return false;
  };

  updatePhoneNumber = () => {
    const phoneNumber = this.state.phoneFormData.phoneNumber.value;
    const userToken = this.props.userToken;
    axios({
      method: "put",
      url: "/users/phone",
      data: {
        phoneNumber: phoneNumber,
      },
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        // const data = response.data.data;
        // const user = data.user;
        // this.props.processUser(user);
        const otp = response.data.data.otp;
        this.setState({
          otp: otp,
          submitting: false,
          submittingError: null,
          change_phone_number: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              submitting: false,
              submittingError:
                "There is an error in the form. Please check your entries, correct the errors and try again",
              phoneFormData: this.createFormError(data.error),
            });
          } else {
            this.setState({
              submitting: false,
              submittingError: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            submitting: false,
            submittingError: errorMsg,
          });
        }
      });
    this.setState({
      submitting: true,
      submittingError: null,
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach((el) => {
      errorForm[el.param] = {
        ...this.state.phoneFormData[el.param],
        invalid: el.msg,
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.phoneFormData,
      ...errorForm,
    };
  }

  render() {
    let content = null;

    if (this.state.change_phone_number) {
      content = (
        <div
          className={`${classes.OtpContainer} d-flex flex-column align-items-center`}
        >
          <div className="text-center mb-2">
            <h5>Congratulations, You are now a MEMBER!</h5>
            <p>
              To proceed on Hawkit, you have to update your phone number. Please
              enter a valid phone number. A <b>two factor code</b> will be sent
              to verify this number!
            </p>
          </div>
          {this.state.submittingError ? (
            <AlertError error={this.state.submittingError} />
          ) : (
            <div className="alert alert-primary px-3 py-2">
              <p className="mb-0 f-10">
                Due to mobile network issues and delay, you may have to wait for
                few minutes before getting the two factor code. If you are
                having difficulty getting the code, please use another valid
                phone number and try again. Thank you.
              </p>
            </div>
          )}
          <div className="mb-2">
            <Form
              loading={this.state.submitting}
              formData={this.state.phoneFormData}
              submit={this.updatePhoneHandler}
            />
            <p className="mt-2">
              <Link to="/support" className="btn btn-outline btn-block">
                Contact Support
              </Link>
            </p>
          </div>
        </div>
      );
    } else if (this.state.otp) {
      content = (
        <div
          className={`${classes.OtpContainer} d-flex flex-column align-items-center`}
        >
          <div className="text-center mb-2">
            <h5 className="mb-2">Verify Your Phone Number</h5>
            <p>
              To continue using Hawkit, please verify your phone number by
              entering the two factor code sent to your phone number:{" "}
              <strong>{this.state.otp.phoneNumber}</strong>.{" "}
            </p>
            <div className="alert alert-primary px-3 py-2">
              <p className="mb-0 f-10">
                We have sent the code to your phone number. Due to mobile
                network issues and delay, you may have to wait for few minutes
                before getting the two factor code.
              </p>
            </div>
          </div>
          <div className={classes.OtpForm}>
            <div className={classes.OtpImage}>
              <img src={OtpImage} alt="Otp_image" />
            </div>
            <div className={classes.OtpFormContainer}>
              <p>ENTER TWO FACTOR CODE:</p>
              <div style={{ width: "80%" }}>
                {this.state.submittingError ? (
                  <AlertError error={this.state.submittingError} />
                ) : null}
              </div>
              <Form
                loading={this.state.submitting}
                formData={this.state.formData}
                submit={this.submitHandler}
              />
            </div>
          </div>
          <div className={classes.Resend}>
            <p>
              Didn't receive the OTP? <br />
            </p>
            <p>
              <Link to="/support" className={classes.ResendLink}>
                Chat with Support to Verify Your Phone No.
              </Link>
            </p>
            <p className="my-1 fw-600">-OR-</p>
            <p>
              <button
                onClick={() =>
                  this.setState({
                    change_phone_number: true,
                    submittingError: null,
                  })
                }
                className="btn btn-coloured-heavy"
              >
                <i className="fas fa-chevron-left"></i> Change Phone Number
              </button>
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="w-100">
        <div className={classes.Header}>
          <Header submit={null} />
        </div>
        <RegFlowLayout
          noTitle
          with_logout
          noSkip
          title="Update Your Phone Number"
        >
          <div className={classes.VerifyPhone}>
            <Helmet>
              <title>Update Phone Number</title>
            </Helmet>
            {content}
          </div>
        </RegFlowLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
    country_states: state.userState.country_states,
    userToken: state.authState.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhone);
