import React from "react";
import classes from "../Post.module.css";
import { abbreviateNumber } from "../../../../util/util";

const believeCount = props => {
  let content = null;

  const showLikes = event => {
    event.preventDefault();
    props.setPostModal({
      title: "Likes",
      id: props.postId,
      link: "likes"
    });
  };

  if (props.count) {
    content = (
      <div
        onClick={showLikes}
        className={
          props.type === "believe"
            ? classes.BelieveCount
            : classes.UnBelieveCount
        }
      >
        {abbreviateNumber(props.count)}
      </div>
    );
  }
  return content;
};

export default believeCount;
