import React, { Component } from "react";
import PreviewImages from "../../../App/CreatePost/PreviewImages/PreviewImages";
import classes from "./UploadFormImages.module.css";

class UploadFormImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousImagesContent: props.previousImagesContent,
    };
  }

  uploadHandler = (event) => {
    const files = event.target.files;
    if (
      !files ||
      !files.length ||
      files.length +
        this.props.photos.length +
        this.state.previousImagesContent.length >
        this.props.max
    ) {
      alert(
        `Invalid Number of Photos selected. You can only upload a maximum of ${this.props.max} photos. Please try again.`
      );
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(file["type"])) {
        continue;
      }
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: file["type"] });
        const previewImage = window.URL.createObjectURL(blob);
        const previewPhoto = {
          file: file,
          photo: previewImage,
        };
        const previewPhotos = [...this.props.photos];
        previewPhotos.push(previewPhoto);
        this.props.setPhotos(previewPhotos);
      };
    }
  };

  removePhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const photos = [...this.props.photos];
    photos.splice(index, 1);
    this.props.setPhotos(photos);
  };

  removePreviousPhotoHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    const photos = [...this.state.previousImagesContent];
    photos.splice(index, 1);
    this.setState({
      previousImagesContent: photos,
    });
    this.props.setPreviousImagesContent(photos);
  };

  render() {
    const previousImagesContent = this.state.previousImagesContent.map((el) => {
      return { photo: el.src };
    });

    return (
      <div className={classes.UploadFormImages}>
        <h6 className="mb-2">{this.props.title}</h6>
        <p className="f-12 mb-2">{this.props.description}</p>
        <div className={classes.UploadImages}>
          <div className={classes.UploadImage}>
            <PreviewImages
              multiple
              noUpload
              setActive={() => {}}
              removePhoto={this.removePreviousPhotoHandler}
              products={previousImagesContent}
            />
          </div>
          <div className={classes.UploadImage}>
            <PreviewImages
              multiple
              setActive={() => {}}
              upload={this.uploadHandler}
              removePhoto={this.removePhotoHandler}
              products={this.props.photos}
              caption="Click to Upload"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default UploadFormImages;
