import React from "react";
import classes from "../Post.module.css";
import Dropdown from "../../../UI/Dropdown/Dropdown";
import DropdownLink from "../../../UI/Dropdown/DropdownLink/DropdownLink";
import { Link } from "react-router-dom";
import DropdownMenu from "../../../UI/Dropdown/DropdownMenu/DropdownMenu";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions.js";

const postOptions = (props) => {
  const deleteHandler = (event) => {
    event.preventDefault();
    if (props.isComment) {
      props.setDeleteModal({
        title: "Comment",
        id: props.post._id,
        parent: props.post.parent,
        link: "comment",
      });
    } else if (props.isReply) {
      props.setDeleteModal({
        title: "Reply",
        id: props.post._id,
        parent: props.post.parent,
        link: "reply",
      });
    } else {
      props.setDeleteModal({
        title: "Post",
        id: props.post._id,
        link: "post",
      });
    }
  };

  return (
    <div className={classes.OptionDropdown}>
      <Dropdown>
        <DropdownLink>
          <span
            style={
              props.size
                ? { fontSize: `${props.size}px` }
                : { fontSize: "22px" }
            }
            className="fal fa-ellipsis-h"
          ></span>
        </DropdownLink>
        <DropdownMenu>
          {!props.isComment && !props.isReply ? (
            <Link to={`/posts/${props.post._id}`}>View Post</Link>
          ) : null}
          {props.activeUser && props.activeUser._id === props.post.userId ? (
            <a onClick={deleteHandler} href="/">
              Delete {!props.isComment && !props.isReply ? "Post" : ""}{" "}
              {props.isComment ? "Comment" : ""} {props.isReply ? "Reply" : ""}
            </a>
          ) : (
            <Link to={`/users/${props.post.user.username}`}>
              Follow {props.post.user.username}
            </Link>
          )}
          <Link to="/support">
            Report this {props.isComment ? "comment" : "post"}
          </Link>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeleteModal: (deleteModal) =>
      dispatch(actionCreators.setDeleteModal(deleteModal)),
  };
};

export default connect(null, mapDispatchToProps)(postOptions);
