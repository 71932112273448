/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./ChangePassword.module.css";
import { connect } from "react-redux";
import {
  createInputElement,
  createButtonElement
} from "../../../../components/UI/Forms/utility/form-creators";
import Form from "../../../../components/UI/Forms/Form";
import axios from "../../../../axios/axios-instance";
import * as validators from "../../../../components/UI/Forms/utility/validator";
import * as actionCreators from "../../../../store/actions/actions";
import AlertError from "../../../../components/UI/Error/AlertError/AlertError";

class ChangePassword extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        oldPassword: createInputElement(
          "password",
          "Current Password",
          "Enter your current password",
          "",
          this.validatePassword,
          "",
          "fa fa-lock",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "oldPassword")
        ),
        newPassword: createInputElement(
          "password",
          "New Password",
          "Enter your new password",
          "",
          this.validatePassword,
          "Password must contain atleast 6 Characters",
          "fa fa-lock",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "newPassword")
        ),
        submit: createButtonElement("submit", "CHANGE PASSWORD", true)
      },
      loading: false,
      error: null,
      completed: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData
    };
    this.setState({ formData: updatedFormData });
  };

  validatePassword(value) {
    if (validators.isEmpty(value)) {
      return "Please enter a password!";
    }
    if (!validators.checkPassword(value)) {
      return "Password must be 6 - 20 characters";
    }
    return null;
  }

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData
    });
    return isFormActivated;
  }

  submitHandler = event => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const oldPassword = this.state.formData.oldPassword.value;
    const newPassword = this.state.formData.newPassword.value;
    const userToken = this.props.userToken;
    axios({
      method: "put",
      url: "/users/me/password",
      data: {
        oldPassword: oldPassword,
        newPassword: newPassword
      },
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const data = response.data.data;
        if (data.status === "success") {
          this.setState({
            loading: false,
            completed: true
          });
        }
      })
      .catch(error => {
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error:
                "There is an error in the form. Please check your entries, correct the errors and try again",
              formData: this.createFormError(data.error)
            });
          } else {
            this.setState({
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      completed: false
    });
  };

  createFormError(error) {
    let errorForm = {};
    error.forEach(el => {
      errorForm[el.param] = {
        ...this.state.formData[el.param],
        invalid: el.msg
      };
    });
    if (
      Object.entries(errorForm).length === 0 &&
      errorForm.constructor === Object
    ) {
      return null;
    }
    return {
      ...this.state.formData,
      ...errorForm
    };
  }

  render() {
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Update Your Password</h4>
                </TitleHeader>
              </Title>
              <div className={classes.ChangePassword}>
                <div className={classes.UserProfile}>
                  {this.state.error ? (
                    <div className="alert alert-danger">{this.state.error}</div>
                  ) : null}
                  {this.state.completed ? (
                    <div className="alert alert-success">
                      Password Updated Successfully
                    </div>
                  ) : null}
                  <Form
                    loading={this.state.loading}
                    formData={this.state.formData}
                    submit={this.submitHandler}
                  />
                </div>
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    processUser: user => dispatch(actionCreators.processUser(user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
