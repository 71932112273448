export function isEmpty(str) {
  if (str === null || str.length === 0 || str === "") {
    return true;
  } else {
    return false;
  }
}
export function isPosInteger(str) {
  str = str.toString();
  for (let i = 0; i < str.length; i++) {
    var strChar = str.charAt(i);
    if (strChar < "0" || strChar > "9") {
      return false;
    }
  }
  return true;
}
export function isLetter(str) {
  str = str.toLowerCase();
  for (let i = 0; i < str.length; i++) {
    var strChar = str.charAt(i);
    if (strChar < "a" || strChar > "z") {
      return false;
    }
  }
  return true;
}

export function hasPlusChar(str) {
  str = str.toLowerCase();
  for (let i = 0; i < str.length; i++) {
    var strChar = str.charAt(i);
    if (strChar === "+") {
      return true;
    }
  }
  return false;
}

export function validateName($str) {
  var re = /^[a-zA-Z\s]+$/;
  return re.test($str);
}

export function isLetterOrNumber(str) {
  str = str.toLowerCase();
  for (let i = 0; i < str.length; i++) {
    var strChar = str.charAt(i);
    if ((strChar < "a" || strChar > "z") && (strChar < "0" || strChar > "9")) {
      return false;
    }
  }
  return true;
}

export function validatePhoneNumber(str) {
  str = str.toLowerCase();
  for (let i = 0; i < str.length; i++) {
    var strChar = str.charAt(i);
    if (strChar !== "+" && (strChar < "0" || strChar > "9")) {
      return false;
    }
  }
  return true;
}

export function validateLength(str, minlength, maxlength) {
  if (str.length < minlength || str.length > maxlength) {
    return false;
  }
  return true;
}

export function checkDate(date) {
  var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  return pattern.test(date);
}

export function checkEmail(email) {
  if (hasPlusChar(email)) {
    return false;
  }
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function checkPassword(password) {
  return validateLength(password, 6, 20);
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
