import React from "react";
import classes from "./SearchInput.module.css";

const input = props => {
  return (
    <div className={classes.SearchInput}>
      <div className={`${classes.SearchInputGroup} input-group`}>
        <input
          style={props.height ? { height: `${props.height}px` } : null}
          type="text"
          className="form-control"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.changeHandler}
        />
        <div className="input-group-append">
          <button
            style={props.height ? { height: `${props.height}px` } : null}
            className=""
            type="submit"
          >
            <span className="fas fa-search"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default input;
