import React from "react";
import classes from "./ProductPost.module.css";
import UserPhoto from "../../User/UserPhoto/UserPhoto";
import { withRouter } from "react-router-dom";
import ProductPostTitle from "../ProductPostTitle/ProductPostTitle";
import ProductPostContent from "../ProductPostContent/ProductPostContent";
import ProductMarketPostContent from "../ProductMarketPostContent/ProductMarketPostContent";

const ProductPost = (props) => {
  return (
    <div key={props.key} className={props.col ? props.col : "col-6 col-sm-4"}>
      <div id={props.post._id} className={classes.ProductPost}>
        {!props.hideUser ? (
          <div className={classes.TopSummary}>
            <UserPhoto
              photo={props.post.user.profile_pic}
              width="30"
              height="30"
            />
            <ProductPostTitle post={props.post} />
          </div>
        ) : null}
        {props.post.product.is_main_product ? <ProductPostContent
          description={props.descriptionHandler}
          buy={props.buyHandler}
          review={props.reviewHandler}
          resell={props.resellHandler}
          post={props.post}
        /> :
          <ProductMarketPostContent
            description={props.descriptionHandler}
            buy={props.buyHandler}
            review={props.reviewHandler}
            post={props.post} />}
      </div>
    </div>
  );
};

export default withRouter(ProductPost);
