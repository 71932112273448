import React from "react";
import classes from "./MobileFooter.module.css";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import FooterIconLink from "../../../../UI/FooterIconLink/FooterIconLink";
import RippleCircle from "../../../../UI/Spinner/RippleCircle/RippleCircle";
// import ProfileDropdown from "../../../../UI/Dropdown/ProfileDropdown/ProfileDropdown";

const MobileFooter = (props) => {
  const location = useLocation();
  let total_pending_orders = 0;
  if (props.meta && props.meta.pending_orders) {
    total_pending_orders += props.meta.pending_orders;
  }
  if (props.meta && props.meta.pending_purchase_orders) {
    total_pending_orders += props.meta.pending_purchase_orders;
  }

  let total_sales_orders = 0;

  if (props.user && props.user.order && props.user.order.sales_action > 0) {
    total_sales_orders += props.user.order.sales_action;
  }

  total_pending_orders += total_sales_orders;

  const orderBadge = total_pending_orders ? total_pending_orders : null;
  return (
    <div className={classes.MobileFooter}>
      <div className={classes.IconLinks}>
        <div className={classes.IconLink}>
          <FooterIconLink
            exact
            size="20"
            text="Home"
            target="/"
            icon="fal fa-home"
          />
        </div>
        <div className={classes.IconLink}>
          <FooterIconLink
            clicked={(event) => {
              event.preventDefault();
              if (!props.user) {
                props.history.push("/auth/login");
                return;
              }
              if (props.user && !props.user.is_member) {
                props.history.push("/earn/member");
                return;
              }
              window.$("#SocialMenuModal").modal({
                backdrop: "static",
                keyboard: false,
              });
              window.$("#SocialMenuModal").modal("show");
            }}
            target="/earn"
            type="button"
            text="Earn"
            size="20"
            icon="fal fa-wallet"
          />
          {!location.pathname.startsWith("/earn") ? (
            <RippleCircle top="10" right="2" size="12" />
          ) : null}
        </div>
        <div className={classes.IconLink}>
          <FooterIconLink
            clicked={(event) => {
              // if (!props.user) props.history.push("/login");
              event.preventDefault();
              window.$("#MarketMenuModal").modal({
                backdrop: "static",
                keyboard: false,
              });
              window.$("#MarketMenuModal").modal("show");
            }}
            target="/social"
            type="button"
            text="Advertise"
            size="20"
            icon="fal fa-plus-circle"
          />
        </div>
        <div className={classes.IconLink}>
          <FooterIconLink
            text="Market"
            size="20"
            target="/market"
            icon="fal fa-store"
          />
          {!location.pathname.startsWith("/market") ? (
            <RippleCircle top="10" right="2" size="12" />
          ) : null}
        </div>
        <div className={classes.IconLink}>
          <FooterIconLink
            badge={orderBadge}
            text="More"
            size="20"
            target="/settings"
            icon="fal fa-list"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    meta: state.userState.meta,
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(withRouter(MobileFooter));
