import React from "react";
import classes from "./MenuButton.module.css";

const MenuButton = (props) => {
  const menuClasses = [classes.MenuButton, props.classes];
  if (props.collapsed) {
    menuClasses.push(classes.Collapsed);
  }
  return (
    <div onClick={props.handler} className={menuClasses.join(" ")}>
      <span />
      <span />
      <span />
    </div>
  );
};

export default MenuButton;
