import * as actionTypes from "./actionTypes";
import axios from "../../axios/axios-instance";

export const addProduct = (product) => {
  return {
    type: actionTypes.ADD_PRODUCTS,
    product: product,
  };
};

export const setProducts = (products) => {
  return {
    type: actionTypes.SET_PRODUCTS,
    products: products,
  };
};

export const setTopGroceryProducts = (top_grocery_products) => {
  return {
    type: actionTypes.SET_TOP_GROCERY_PRODUCTS,
    top_grocery_products: top_grocery_products,
  };
};

export const setTopAirtimeProducts = (top_airtime_products) => {
  return {
    type: actionTypes.SET_TOP_AIRTIME_PRODUCTS,
    top_airtime_products: top_airtime_products,
  };
};

export const setProduct = (product) => {
  return {
    type: actionTypes.SET_PRODUCT,
    product: product,
  };
};

export const setLoadCount = (loadCount) => {
  return {
    type: actionTypes.SET_LOAD_COUNT,
    loadCount: loadCount,
  };
};

export const setHawkingLocation = (hawkingLocation) => {
  return {
    type: actionTypes.SET_HAWKING_LOCATION,
    hawkingLocation: hawkingLocation,
  };
};

export const setPosts = (posts) => {
  return {
    type: actionTypes.SET_POSTS,
    posts: posts,
  };
};

export const setPostModal = (postModal) => {
  return {
    type: actionTypes.SET_POST_MODAL,
    postModal: postModal,
  };
};

export const setDeleteModal = (deleteModal) => {
  return {
    type: actionTypes.SET_DELETE_MODAL,
    deleteModal: deleteModal,
  };
};

export const toggleLikes = (id) => {
  return (dispatch, getState) => {
    const posts = getState().postState.posts;
    const postIndex = posts.findIndex((el) => el._id === id);
    if (postIndex === -1) {
      dispatch(setPosts(posts));
      return;
    }
    const post = posts[postIndex];
    const userToken = getState().authState.userToken;    
    axios({
      method: !post.likes_count ? "post" : "delete",
      url: `/posts/${id}/likes`,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        const mutual = response.data.data.mutual;
        const type = response.data.data.type;
        const total = response.data.data.total.likes;
        if (type === "like") {
          post.likes_count = 1;
        } else {
          post.likes_count = 0;
        }
        post.mutual = mutual;
        post.likes = total;
        const updatedPosts = [...posts];
        updatedPosts[postIndex] = post;
        dispatch(setPosts(updatedPosts));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addPostView = (id) => {
  return (dispatch, getState) => {    
    const userToken = getState().authState.userToken;    
    axios({
      method: "post",
      url: `/posts/${id}/views`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {                        
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setFeedScroll = (feedScroll) => {
  return {
    type: actionTypes.SET_FEED_SCROLL,
    feedScroll: feedScroll,
  };
};
