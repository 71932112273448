/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classes from "./UserWallet.module.css";
import { Link } from "react-router-dom";

const UserWallet = (props) => {
  return (
    <div className={classes.UserWallet}>
      <p className="f-12">My Shopping Wallet</p>
      <h3 className="mb-2">
        {props.user.country.currency.symbol}
        {props.user.wallet.shopping
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
      <p>
        <Link to="/funding" className="btn btn-xs btn-outline mr-1">
          <i className="fas fa-mobile-alt"></i> Fund Wallet
        </Link>
        <Link to="/withdraw" className="btn btn-xs btn-outline">
          <i className="fas fa-mobile-alt"></i> Withdraw
        </Link>
      </p>
    </div>
  );
};

export default UserWallet;
