/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import UserPhoto from "../User/UserPhoto/UserPhoto";
import classes from "./NotificationModal.module.css";

const moment = require("moment");

const NotificationModal = (props) => {
  const contentRef = useRef(null);
  const userPhotoRef = useRef(null);
  const modalRef = useRef(null);

  const showNotificationHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.history.push(props.notification.link);
    props.close();
  };

  useEffect(() => {
    const modalWidth = modalRef.current.offsetWidth;
    const photoWidth = userPhotoRef.current.offsetWidth;
    contentRef.current.style.width = modalWidth - photoWidth - 25 + "px";
  }, []);

  const now = moment();
  const postMoment = moment(props.notification.created_on);
  const daysDiff = now.diff(postMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = postMoment.calendar();
  } else {
    formatDate = postMoment.fromNow();
  }

  return (
    <div className={classes.NotificationModal}>
      <div
        className={classes.RemoveContainer}
        onClick={props.close}
        title="Close"
      >
        <span className="fa fa-times"></span>
      </div>
      <div
        onClick={showNotificationHandler}
        ref={modalRef}
        className={classes.NotificationModalContent}
      >
        <div ref={userPhotoRef} className={classes.UserPhoto}>
          <UserPhoto
            photo={props.notification.picture}
            width="36"
            height="36"
          />
        </div>
        <div ref={contentRef} className={classes.MessageContent}>
          <div className={classes.MainTitle}>
            <p>{props.notification.title}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `<p>${props.notification.notification}</p>`,
            }}
            className={classes.Message}
          ></div>
          <p className={classes.Date}>
            <i className="fal fa-clock"></i> {formatDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NotificationModal);
