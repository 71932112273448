/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classes from "./UserDetails.module.css";
import { Link } from "react-router-dom";

const UserDetails = (props) => {

  let bioContent = null;
  if (props.user.bio && props.user.bio.length) {
    const bioText =
      "<p>" +
      props.user.bio.replace(/\n{2,}/g, "</p><p>").replace(/\n/g, "<br>") +
      "</p>";
    bioContent = (
      <div
        dangerouslySetInnerHTML={{ __html: bioText }}
        className={classes.Bio}
      ></div>
    );
  } else if (props.active) {
    bioContent = (
      <div className={classes.Bio}>
        <p>Add a short bio to tell people more about yourself.</p>
        <Link to="/settings/profile">Add Bio</Link>
      </div>
    );
  }

  // const chatPrefilledMsg = encodeURIComponent(
  //   `I saw your profile on Hawkit and I am interested in buying any of your products/services.`
  // );

  // const whatsappLink = `https://wa.me/${props.user.phoneNumber.replace(
  //   /\D/g,
  //   ""
  // )}?text=${chatPrefilledMsg}`;

  const userAddress = [];

  if (props.user.location) {
    if (props.user.location.lga) {
      userAddress.push(props.user.location.lga);
    }
    if (props.user.location.state) {
      userAddress.push(props.user.location.state);
    }
    userAddress.push(props.user.country.name);
  }

  return (
    <div className={classes.UserDetails}>
      {bioContent}
      <div className={classes.Details}>
        <div className={classes.Detail}>
          <div className={classes.DetailIcon}>
            <span className="fas fa-map-marker-alt"></span>
          </div>
          <div className={classes.DetailContent}>
            <p>
              {props.user.location ? userAddress.join(", ") : "No Location Yet"}
            </p>
          </div>
          <div className={classes.DetailOptions}>
            {props.active ? (
              <Link to="/settings/location" className="mr-2">
                <span className="fas fa-edit"></span>
              </Link>
            ) : null}
          </div>
        </div>        
      </div>
    </div>
  );
};

export default UserDetails;
