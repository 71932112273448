import React from "react";
import classes from "./ContentLayout.module.css";

const ContentLayout = props => {
  return (
    <div className={classes.ContentLayout}>      
      {props.children}
    </div>
  );
};
export default ContentLayout;
