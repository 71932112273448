/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
// import PageDropdown from "../../../components/App/PageDropdown/PageDropdown";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./FundWallet.module.css";
import axios from "../../../axios/axios-instance";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../components/UI/Error/Error";
import * as actionCreators from "../../../store/actions/actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import FundingForm from "./FundingForm/FundingForm";
import ConfirmModal from "../../../components/UI/Modal/ConfirmModal/ConfirmModal";
import PaymentCompletedModal from "../../../components/App/Order/PaymentCompletedModal/PaymentCompletedModal";

const moment = require("moment");

class FundWallet extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      funding: null,
      paymentCompleted: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadFunding();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadFunding = () => {
    const userToken = this.props.userToken;
    const url = "/users/funding";
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const user = response.data.data.user;
        const funding = response.data.data.funding;
        this.props.processUser(user);
        this.setState({
          loading: false,
          paymentCompleted: null,
          funding: funding,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      paymentCompleted: null,
    });
  };

  confirmDeleteFunding = (funding) => {
    const userToken = this.props.userToken;
    const url = `/users/funding/${funding._id}`;
    axios({
      method: "delete",
      url: url,
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        this.loadFunding();
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
      delete_funding: null,
    });
  };

  cancelDeleteFunding = () => {
    this.setState({
      delete_funding: null,
    });
  };

  deleteFunding = (funding) => {
    this.setState({
      delete_funding: funding,
    });
  };

  payWithFlutterwave = (funding) => {
    let total = funding.amount;
    const fundingId = funding._id;
    window.FlutterwaveCheckout({
      public_key: this.props.meta.flutterwave_public_key,
      tx_ref: `Funding-${fundingId}-${Date.now()}`,
      amount: total,
      currency: this.props.user.country.currency.short,
      country: this.props.user.country.code,
      customer: {
        email: this.props.user.email,
        phone_number: this.props.user.phoneNumber,
        name: this.props.user.name,
      },
      callback: (data) => {
        if (data.status === "successful" || data.status === "completed") {
          this.setState({
            paymentCompleted: funding,
          });
        }
      },
      onclose: () => {},
    });
  };

  render() {
    let content = null;
    if (this.state.loading) {
      content = (
        <div className={classes.Loading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className={classes.Loading}>
          <Error refresh={this.loadFunding} error={this.state.error} />
        </div>
      );
    } else if (this.state.funding) {
      let historyContent = null;
      if (this.state.funding.history.length) {
        historyContent = this.state.funding.history.map((el, index) => {
          const fundingMoment = moment(el.created_on);
          return (
            <tr key={el._id}>
              <td>{index + 1}.</td>
              <td>{fundingMoment.format("MMM Do YYYY h:mm a")}</td>
              <td>
                {`${this.props.user.country.currency.symbol}${el.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </td>
              <td>{el.payment_method ? el.payment_method : "NONE"}</td>
              <td>
                {el.is_activated ? (
                  <p className="text-success f-12">PAYMENT APPROVED</p>
                ) : (
                  <p className="text-danger f-12">AWAITING PAYMENT</p>
                )}
              </td>
              <td>
                {el.is_activated ? (
                  <Link
                    className="btn btn-xs btn-success"
                    to={`/funding/${el._id}`}
                  >
                    VIEW
                  </Link>
                ) : (
                  <>
                    <button
                      className="btn btn-xs btn-warning mr-2 mb-1 mb-lg-0"
                      onClick={() => {
                        this.payWithFlutterwave(el);
                      }}
                    >
                      PAY NOW
                    </button>
                    <button
                      className="btn btn-xs btn-danger"
                      onClick={() => {
                        this.deleteFunding(el);
                      }}
                    >
                      DELETE
                    </button>
                  </>
                )}
              </td>
            </tr>
          );
        });
      } else {
        historyContent = (
          <tr>
            <td className="text-center" colSpan="6">
              <h6 className="mt-2 mb-1">You have not added any funding yet.</h6>
              <p className="f-12 mb-1">
                Simply fill the form above to add funds into your wallet. You
                can use your funds to shop, buy airtime or even transfer into
                your local bank account
              </p>
            </td>
          </tr>
        );
      }
      content = (
        <div className={classes.FundingContainer}>
          <div className={classes.FundingHistory}>
            <h5>My Funding History</h5>
            <div className="table-responsive">
              <table className="table table-sm table-borderless">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{historyContent}</tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Fund Wallet</h4>
                </TitleHeader>
              </Title>
            </PageContent>
            <div className={classes.FundWallet}>
              {!this.props.user.is_member ? (
                <div className="alert alert-danger rounded-0">
                  <p className="mb-2">
                    If you want to become a member and pay for membership, you
                    don't need to fund your wallet. Just click the button below
                    and pay directly for your membership activation.
                  </p>
                  <p>
                    <Link
                      to="/earn/member"
                      className="btn btn-light f-16 px-4 py-3"
                    >
                      PAY FOR MEMBERSHIP
                    </Link>
                  </p>
                </div>
              ) : null}
              <div className={classes.WalletBalance}>
                <div className={classes.Wallet}>
                  <h5>Wallet Balance</h5>
                </div>
                <div className={classes.Balance}>
                  <h5>
                    {this.props.user.country.currency.symbol}{" "}
                    {this.props.user.wallet.shopping
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h5>
                </div>
              </div>
              <FundingForm
                success={this.loadFunding}
                failed={this.loadFunding}
              />
              {content}
              {this.state.delete_funding ? (
                <ConfirmModal
                  cancel={this.cancelDeleteFunding}
                  confirm={() =>
                    this.confirmDeleteFunding(this.state.delete_funding)
                  }
                  title="Delete Funding"
                  description={`Are you sure you want to delete this funding of ${
                    this.props.user.country.currency.symbol
                  }${this.state.delete_funding.amount} created on ${moment(
                    this.state.delete_funding.created_on
                  ).format("MMM Do YYYY h:mm a")}?`}
                />
              ) : null}
              {this.state.paymentCompleted ? (
                <PaymentCompletedModal
                  description={`Thank you for funding your wallet. Your wallet has been funded with ${this.props.user.country.currency.symbol}${this.state.paymentCompleted.amount} successfully. You can use your funds to shop, buy airtime and data or even withdraw the funds to any bank account of your choice.`}
                  refresh={this.loadFunding}
                />
              ) : null}
            </div>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FundWallet));
