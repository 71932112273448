import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./SocialBuyPricing.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import SocialTransactionContents from "../../../components/App/SocialTransactionContents/SocialTransactionContents";
import * as actionCreators from "../../../store/actions/actions";
import { Link } from "react-router-dom";

class SocialBuyPricing extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      type: "advertise",
    };
    this.taskBottomRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    const transactions = this.props.page_states[this.props.location.pathname];
    if (!transactions) {
      this.loadBuyPricing();
    }
  }

  loadBuyPricing = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/buy/pricing`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const transactions = response.data.data.buy_pricing;
        const page_states = {...this.props.page_states};
        page_states[this.props.location.pathname] = transactions;
        this.props.setPageStates(page_states);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadBuyPricing} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;

    const page_states = {...this.props.page_states};
    const transactions = page_states[this.props.location.pathname];

    if (transactions) {
      const active_transactions = transactions.filter(
        (el) => el.task_type === this.state.type
      );
      content = (
        <SocialTransactionContents
          rowClass=""
          content_type="advertise"
          transactions={active_transactions}
        />
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <div className={classes.SocialBuyPricingTitle}>
                    <h4>Advertise on Social Media</h4>
                    <p>
                      Click <Link to="/social/orders">here</Link> to see and
                      monitor your past orders and adverts
                    </p>
                  </div>
                </TitleHeader>
              </Title>
              <div className={classes.SocialBuyPricing}>
                <div className={classes.SocialBuyPricingHeading}>
                  <p>
                    Get people with atleast 1000 active followers to repost your
                    adverts and perform certain social tasks for you on their
                    social media accounts. Select the type of task you want
                    people to perform below:
                  </p>
                </div>
                <div className={classes.PricingHeader}>
                  <p className="mb-3">
                    <button
                      onClick={() => this.setState({ type: "advertise" })}
                      className={`btn mr-2 ${
                        this.state.type === "advertise"
                          ? "btn-success"
                          : "btn-light"
                      }`}
                    >
                      Advert Tasks
                    </button>
                    <button
                      onClick={() => this.setState({ type: "engagement" })}
                      className={`btn ${
                        this.state.type === "engagement"
                          ? "btn-success"
                          : "btn-light"
                      }`}
                    >
                      Engagement Tasks
                    </button>
                  </p>
                  {this.state.type === "advertise" ? (
                    <p className="f-10">
                      Advert tasks are created to get people to post your
                      adverts on various social media platforms. Check below to
                      see the price of creating various advert tasks:
                    </p>
                  ) : (
                    <p className="f-10">
                      Engagement tasks are created to get people to perform
                      simple tasks for you on their social media account. Check
                      below to see the price of creating various engagement
                      tasks:
                    </p>
                  )}
                </div>
                {content}
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialBuyPricing);
