import * as actionTypes from "../actions/actionTypes";

const intialiseLoaderState = () => {
  return {
    status: null,
    statusMsg: null,
    progress: 0
  };
};

const initialState = intialiseLoaderState();

const reducer = (state = initialState, action) => {  
  switch (action.type) {
    case actionTypes.INITIALISE_LOADER_STATE:
      return intialiseLoaderState();
    case actionTypes.SET_LOADER_STATUS:
      return {
        ...state,
        status: action.status
      };
    case actionTypes.SET_LOADER_STATUS_MSG:
      return {
        ...state,
        statusMsg: action.statusMsg
      };
    case actionTypes.SET_LOADER_PROGRESS:
      return {
        ...state,
        progress: action.progress
      };
    default:
      return state;
  }
};

export default reducer;
