/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import classes from "./ConfirmItemsModal.module.css";
import OfficeSpinner from "../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
import axios from "../../../../axios/axios-instance";
import { connect } from "react-redux";
import Dropdown from "../../../UI/Dropdown/Dropdown";
import DropdownLink from "../../../UI/Dropdown/DropdownLink/DropdownLink";
import DropdownMenu from "../../../UI/Dropdown/DropdownMenu/DropdownMenu";

let is_Mounted = false;

const ConfirmItemsModal = props => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    completed: null
  });

  const [formData, setFormData] = useState({
    rating: 5,
    review: ""
  });

  const cancelModalHandler = event => {
    if (event) event.preventDefault();
    window.$("#ConfirmItemsModal").on("hidden.bs.modal", event => {
      props.refresh();
    });
    window.$("#ConfirmItemsModal").modal("hide");
  };

  const confirmHandler = () => {
    setState({
      completed: null,
      error: null,
      loading: true
    });
    const userToken = props.userToken;
    axios({
      method: "post",
      url: `orders/${props.confirmItems.orderId}/${props.confirmItems.salesOrderId}/items/confirm`,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!is_Mounted) return;
        const status = response.data.data.status;
        if (status === "success") {
          setState({
            error: null,
            loading: false,
            completed: true
          });
        }
      })
      .catch(error => {
        if (!is_Mounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              completed: null,
              loading: false,
              error: data.error[0].msg
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg
          });
        }
      });
  };

  let ratingContent = [];

  const setRating = (event, index) => {
    event.preventDefault();
    setFormData({
      ...formData,
      rating: index
    });
  };

  const textChangeHandler = event => {
    const value = event.target.value;
    setFormData({
      ...formData,
      review: value
    });
  };

  const submitHandler = () => {
    if (formData.review.length < 3 || formData.review.length >= 500) {
      setState({
        ...state,
        error: "Please enter your review. 3 - 500 characters allowed"
      });
      return;
    }
    setState({
      ...state,
      error: null,
      loading: true
    });
    const userToken = props.userToken;
    axios({
      method: "post",
      url: `orders/${props.confirmItems.orderId}/${props.confirmItems.salesOrderId}/reviews`,
      data: {
        rating: formData.rating,
        review: formData.review
      },
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!is_Mounted) return;
        const status = response.data.data.status;
        if (status === "success") {
          cancelModalHandler();
        }
      })
      .catch(error => {
        if (!is_Mounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            setState({
              ...state,
              loading: false,
              error: data.error[0].msg
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          setState({
            ...state,
            loading: false,
            error: errorMsg
          });
        }
      });
  };

  let activeRating = null;

  for (let index = 5; index >= 1; index--) {
    const ratingStars = [];
    for (let j = 0; j < index; j++) {
      const ratingStar = <span key={j} className="fas fa-star"></span>;
      ratingStars.push(ratingStar);
    }
    const mainRatingStars = (
      <>
        <div className={classes.Star}>{ratingStars}</div>
        <div className={classes.Number}>
          <span>{index} star</span>
        </div>
      </>
    );
    if (index === formData.rating) {
      activeRating = mainRatingStars;
    }
    ratingContent.push(
      <a key={index} onClick={event => setRating(event, index)} href="/">
        <div className={classes.RatingStar}>{mainRatingStars}</div>
      </a>
    );
  }

  const reviewForm = (
    <div className={classes.ReviewForm}>
      <p className="mb-2">
        Thank you for confirming that you have received the items. Kindly rate
        and review this seller so other buyers can know about your experience.
      </p>
      <div className={classes.Rating}>
        <label className="mb-1">Choose Rating</label>
        <Dropdown>
          <DropdownLink>
            <div className={`${classes.RatingStar} border rounded`}>
              {activeRating}
              <span className="ml-auto fal fa-chevron-down"></span>
            </div>
          </DropdownLink>
          <DropdownMenu>{ratingContent}</DropdownMenu>
        </Dropdown>
      </div>
      <div className={classes.ReviewArea}>
        <label className="mb-1">Please enter a review</label>
        <textarea
          placeholder="What's your experience with this seller?"
          rows="4"
          className="form-control"
          value={formData.review}
          onChange={textChangeHandler}
        ></textarea>
      </div>
      <div className="py-3">
        <button
          onClick={submitHandler}
          className="mr-1 btn btn-success btn-rounded"
        >
          SUBMIT REVIEW
        </button>
        <p className="f-10 mt-2">
          You will need to enter the review before you can continue.
        </p>
      </div>
    </div>
  );

  let content = null;
  if (state.loading) {
    content = (
      <div className={classes.Loading}>
        <OfficeSpinner size="3" />
      </div>
    );
  } else if (state.completed) {
    content = reviewForm;
  } else {
    content = (
      <>
        <p>
          Are you sure you have received the items from the seller? Please
          confirm the authenticity of the items you have received before you
          proceed.
        </p>
        <div className="py-2">
          <button
            onClick={confirmHandler}
            className="mr-1 btn btn-success btn-rounded"
          >
            YES
          </button>
          <button
            data-dismiss="modal"
            aria-label="Close"
            className="btn btn-danger btn-rounded"
          >
            NO
          </button>
        </div>
      </>
    );
  }

  useEffect(() => {
    is_Mounted = true;
    window.$("#ConfirmItemsModal").modal({
      backdrop: "static"
    });
    window.$("#ConfirmItemsModal").off("hidden.bs.modal");
    window.$("#ConfirmItemsModal").modal("show");
    window.$("#ConfirmItemsModal").on("hidden.bs.modal", event => {
      props.destroy();
    });
    return () => {
      is_Mounted = false;
      window.$(".modal-backdrop").remove();
      window.$("body").removeClass("modal-open");
      window.$("#ConfirmItemsModal").modal("hide");
    };
  }, []);
  return (
    <div className={classes.ConfirmItemsModal}>
      <div
        id="ConfirmItemsModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.ConfirmItemsModalContent} modal-content`}>
            <div className={`${classes.ConfirmItemsModalHeader} modal-header`}>
              <h5>Confirm Items</h5>
            </div>
            <div
              id="ConfirmItemsModalBody"
              className={`${classes.ConfirmItemsModalBody} modal-body`}
            >
              {state.error ? (
                <div className="mb-0 alert alert-danger">{state.error}</div>
              ) : null}
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userToken: state.authState.userToken
  };
};

export default connect(
  mapStateToProps,
  null
)(ConfirmItemsModal);
