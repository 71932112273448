import * as actionTypes from "./actionTypes";
import axios from "../../axios/axios-instance";

export const setNotifications = notifications => {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    notifications: notifications
  };
};

export const setNotificationCount = notificationCount => {
  return {
    type: actionTypes.SET_NOTIFICATION_COUNT,
    notificationCount: notificationCount
  };
};

export const setMessageCount = messageCount => {
  return {
    type: actionTypes.SET_MESSAGE_COUNT,
    messageCount: messageCount
  };
};

export const setSellerProductFailed = is_seller_product_failed => {
  return {
    type: actionTypes.SET_SELLERS_PRODUCT_FAILED,
    is_seller_product_failed: is_seller_product_failed
  };
};

export const setAdvertBanner = advert_banner => {
  return {
    type: actionTypes.SET_ADVERT_BANNER,
    advert_banner: advert_banner
  };
};

export const fetchNotificationCount = () => {
  return (dispatch, getState) => {
    const userToken = getState().authState.userToken;
    axios({
      method: "get",
      url: "/notifications/unread",
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        const notificationCount = response.data.data.total;
        const messageCount = response.data.data.unread_messages;
        const is_seller_product_failed =
          response.data.data.is_seller_product_failed;
        const advert_banner = response.data.data.advert_banner;        
        dispatch(setNotificationCount(notificationCount));
        dispatch(setMessageCount(messageCount));
        dispatch(setSellerProductFailed(is_seller_product_failed));
        dispatch(setAdvertBanner(advert_banner));
      })
      .catch(error => {
        if (error.response) {
          const data = error.response.data;
          console.log(data.error);
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          console.log(errorMsg);
        }
      });
  };
};
