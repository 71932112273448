import React, { Component } from "react";
import ContentLayout from "../../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./Checkout.module.css";
import { connect } from "react-redux";
import axios from "../../../../axios/axios-instance";
import OfficeSpinner from "../../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import Error from "../../../../components/UI/Error/Error";
import Title from "../../../../components/App/Layout/Title/Title";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import TitleHeader from "../../../../components/App/Layout/Title/TitleHeader";
import TitleLinks from "../../../../components/App/Layout/Title/TitleLinks";
import OrderView from "../../../../components/App/Order/OrderView/OrderView";
import PaymentCompletedModal from "../../../../components/App/Order/PaymentCompletedModal/PaymentCompletedModal";
import PaymentModal from "../../../../components/App/PaymentModal/PaymentModal";
import PaymentWalletModal from "../../../../components/App/PaymentWalletModal/PaymentWalletModal";
import * as actionCreators from "../../../../store/actions/actions";

class Checkout extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      order: null,
      show_payment_modal: false,
      show_wallet_payment: false,
      is_action_required: false,
      flw_public_key: null,
      paymentCompleted: null,
      wallet_success: null,
      wallet_error: null,
      user: null,
    };
  }

  loadOrder = () => {
    const { orderId } = this.props.match.params;
    this.setState({
      loading: true,
      error: null,
      show_wallet_payment: false,
    });
    let userToken = this.props.userToken;
    if (!userToken) {
      userToken = this.props.clientToken;
    }
    axios({
      method: "get",
      url: `/orders/purchase/${orderId}`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const order = response.data.data.order;
        const flw_public_key = response.data.data.flutterwave_public_key;
        const user = response.data.data.user;
        if (this.props.user) {
          this.props.processUser(user);
        }
        let is_action_required = false;
        // eslint-disable-next-line no-unused-vars
        for (const sales_order of order.sales_orders) {
          if (
            sales_order.status.seller === window.ORDER_DELIVERED &&
            sales_order.status.buyer === window.ORDER_PAID
          ) {
            is_action_required = true;
            break;
          }
        }
        this.setState({
          loading: false,
          error: null,
          order: order,
          user: user,
          is_action_required: is_action_required,
          flw_public_key: flw_public_key,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
  };

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    this.loadOrder();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  payWithFlutterwave = () => {
    if (!this.state.user || !this.state.order) {
      return;
    }
    this.close_payment_modal();
    const order = this.state.order;
    const order_total = order.total_cost;
    let total = order_total;
    window.FlutterwaveCheckout({
      public_key: this.state.flw_public_key,
      tx_ref: `ProductOrder-${order._id}-${Date.now()}`,
      amount: total,
      currency: this.state.user.country.currency.short,
      country: this.state.user.country.code,
      customer: {
        username: this.state.user.username,
        email: this.state.user.email,
        name: this.state.user.name,
      },
      callback: (data) => {
        if (data.status === "successful") {
          // if (window.fbq) {
          //   window.fbq("track", "Purchase", {
          //     currency: "NGN",
          //     content_name: order.social_task_category.title.buyer,
          //     content_category: "Social_Task",
          //     content_type: "social_task",
          //     value: total,
          //   });
          // }
          this.setState({
            paymentCompleted: order._id,
          });
        }
      },
      onclose: () => {},
    });
  };

  payWithWallet = () => {
    this.close_payment_modal();
    if (!this.state.user || !this.state.order) {
      return;
    }
    let userToken = this.props.userToken;
    if (!userToken) {
      userToken = this.props.clientToken;
    }
    axios({
      method: "post",
      url: `/orders/${this.state.order._id}/pay`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        // if (window.fbq) {
        //   window.fbq("track", "Purchase", {
        //     currency: "NGN",
        //     content_name: order.social_task_category.title.buyer,
        //     content_category: "Social_Task",
        //     content_type: "social_task",
        //     value: order_total,
        //   });
        // }
        const status = response.data.data.status;
        const user = response.data.data.user;
        if (this.props.user && user) {
          this.props.processUser(user);
        }
        this.setState({
          wallet_success: status,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              wallet_error: data.error[0].msg,
            });
          } else {
            this.setState({
              wallet_error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            wallet_error: errorMsg,
          });
        }
      });
    this.setState({
      wallet_success: null,
      wallet_error: null,
      show_wallet_payment: true,
    });
  };

  close_payment_modal = () => {
    this.setState({ show_payment_modal: false });
  };

  open_payment_modal = () => {
    this.setState({ show_payment_modal: true });
  };

  close_wallet_payment_modal = () => {
    this.setState({ show_wallet_payment: false });
  };

  render() {
    let content = null;
    if (this.state.loading) {
      content = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    } else if (this.state.error) {
      content = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadOrder} error={this.state.error} />
        </div>
      );
    } else if (this.state.order) {
      content = (
        <div className={classes.Checkout}>
          {this.state.is_action_required ? (
            <div className={classes.ActionInfo}>
              Your order has been delivered successfully. Please click the
              button to confirm delivery and write a review about the item
              delivered to you.
            </div>
          ) : null}
          {this.state.paymentCompleted ? (
            <PaymentCompletedModal
              description="Your Payment is successful. Thank you for completing your order. Your order will be delivered to you as soon as possible."
              refresh={this.loadOrder}
              goBack="BACK TO ORDER"
            />
          ) : null}
          {this.state.order && this.state.show_payment_modal ? (
            <PaymentModal
              pay_online={this.payWithFlutterwave}
              pay_wallet={this.payWithWallet}
              amount={this.state.order.total_cost}
              close={this.close_payment_modal}
              activeUser={this.state.user}
            />
          ) : null}
          {this.state.order && this.state.show_wallet_payment ? (
            <PaymentWalletModal
              go_success={this.loadOrder}
              success={this.state.wallet_success}
              error={this.state.wallet_error}
              cancel={this.close_wallet_payment_modal}
            />
          ) : null}
          <OrderView
            refresh={this.loadOrder}
            pay={this.open_payment_modal}
            paying={this.state.paying}
            order={this.state.order}
            user={this.state.user}
          />
        </div>
      );
    }

    let titleButton = null;
    if (this.state.order && !this.state.order.is_activated) {
      titleButton = (
        <button onClick={this.open_payment_modal}>Make Payment</button>
      );
    } else if (this.state.order && this.state.is_action_required) {
      titleButton = (
        <div className={`${classes.ActionRequired} blink`}>
          <p>Action Required</p>
        </div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <div
              className={`${classes.Checkout} ${
                this.props.user ? classes.PaddingTop : ""
              }`}
            >
              {this.props.user ? (
                <Title>
                  <TitleHeader>
                    <BackButton />
                    <h4>View Order</h4>
                  </TitleHeader>
                  <TitleLinks>{titleButton}</TitleLinks>
                </Title>
              ) : (
                <div className={classes.CompleteOrder}>
                  <BackButton />
                  <h4>View Order</h4>
                </div>
              )}
              {content}
            </div>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    clientToken: state.authState.clientToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
