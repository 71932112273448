import React from "react";
import classes from "./FullLogo.module.css";
import logo from "../../../../assets/images/logo-full.png";
import { Link } from "react-router-dom";

const fullLogo = props => {
  return (
    <div className={classes.FullLogo}>
      <Link to={props.is_preview ? "#" : "/"}>
        <img src={logo} alt="" />
      </Link>
    </div>
  );
};
export default fullLogo;
