import React, { useRef, useEffect } from "react";
import classes from "./TextArea.module.css";

const TextArea = props => {
  const textArea = useRef(null);

  useEffect(() => {
    if (props.value.length === 0) {
      textArea.current.style.height = "inherit";
    } else {
      textArea.current.style.height = "inherit";
      textArea.current.style.height = textArea.current.scrollHeight + "px";
    }
  });
  let hint, error;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    error = <div className="d-block invalid-feedback">{props.invalid}</div>;
  }

  return (
    <div className={classes.TextArea}>
      <div className="form-group">
        <label>{props.label}</label>
        <textarea
          ref={textArea}
          // rows="3"
          className="form-control"
          placeholder={props.placeholder}
          onChange={props.changeHandler}
          value={props.value}
          id={props.id}
        />
        {error ? error : hint}
      </div>
    </div>
  );
};
export default TextArea;
