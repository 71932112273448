/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classes from "./UserSideMenu.module.css";
import NoUserSideTop from "../SideComponents/NoUserSideTop/NoUserSideTop";
import SideTop from "../SideComponents/SideTop/SideTop";
import SideLink from "../SideComponents/SideLink/SideLink";
import LayoutContext from "../../../../../context/layout-context";
import { withRouter } from "react-router-dom";
import socialIcon from "../../../../../assets/images/social-icons.jpg";
import handIcon from "../../../../../assets/images/hand.png";
import airtimeIcon from "../../../../../assets/images/networks-logo.png";
import marketIcon from "../../../../../assets/images/market-icon.png";
import BrowserLinks from "../../../../UI/Links/BrowserLinks/BrowserLinks";
import GoogleImage from "../../../../../assets/images/google-play-store.jpg";
import AppleImage from "../../../../../assets/images/apple-play-store.jpg";

const UserSideMenu = (props) => {
  return (
    <LayoutContext.Consumer>
      {(context) => {
        return (
          <div className={classes.UserSideMenu}>
            {context && context.isAuth ? (
              <SideTop user={context.user} />
            ) : (
              <NoUserSideTop />
            )}
            <div className={classes.SideMain}>
              <div className={classes.SideLinks}>
                <div className={classes.SideGroup}>
                  <SideLink
                    type="internal"
                    target="/earn"
                    title="Perform Social Tasks and Earn"
                    description="Earn daily by posting adverts of businesses and performing social tasks on your social media
                    accounts"
                    icon={handIcon}
                  />
                  <SideLink
                    type="internal"
                    target="/social/buy/pricing"
                    title="Boost Your Social Portfolio"
                    description="Get people to repost your adverts and perform social tasks for you on their
                    social media pages."
                    icon={socialIcon}
                  />
                  <SideLink
                    type="internal"
                    target="/shop/sell"
                    title="Buy and Sell Anything on Hawkit"
                    description="Take advantage of our huge traffic and advertise anything on the
                    Hawkit Market."
                    icon={marketIcon}
                  />
                  <SideLink
                    type="internal"
                    target="/airtime"
                    title="Start your Airtime/Data Business"
                    description="Buy Airtime and Data and enjoy up to 3% - 10% Discount across all
                    networks."
                    icon={airtimeIcon}
                  />
                  {/* <SideLink
                    type="internal"
                    target="/social/referral"
                    title="Refer and Earn Big on Hawkit"
                    description="Refer friends and family to join Hawkit and earn ₦500 instant referral commission to be paid into your account. You also earn 20% Commission on every transaction carried out by your referral"
                    icon={referralIcon1}
                  /> */}
                </div>
                <div className={classes.Download}>
                  <p>
                    Download the Hawkit Mobile App today and Enjoy a Seamless
                    Experience with Instant Notifications
                  </p>
                  <div className={classes.AppDownload}>
                    <BrowserLinks
                      className="mr-1"
                      to="https://play.google.com/store/apps/details?id=com.app.hawkitng"
                    >
                      <img src={GoogleImage} alt="google-play-store" />
                    </BrowserLinks>
                    <BrowserLinks
                      className="h-100"
                      to="https://hawkit.ng/download"
                    >
                      <img src={AppleImage} alt="apple-play-store" />
                    </BrowserLinks>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </LayoutContext.Consumer>
  );
};
export default withRouter(UserSideMenu);
