import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const intialisePageState = () => {
  return {
    page_states: {},
  };
};

const initialState = intialisePageState();

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE_STATES:
      draft.page_states = action.page_states;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
