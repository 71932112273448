import React from "react";
import classes from "./ShowActivity.module.css";
import UserPhoto from "../../UserPhoto/UserPhoto";
import { withRouter } from "react-router-dom";
import { getImage } from "../../../../../util/image-util";

const ShowActivity = (props) => {
  return (
    <div className={classes.ShowActivity}>
      <div className={classes.UserPhoto}>
        <UserPhoto
          photo={getImage(props.activity.platform)}
          width="36"
          height="36"
        />
      </div>
      <div className={classes.UserContent}>
        <div className={classes.Title}>
          <p dangerouslySetInnerHTML={{ __html: props.activity.text }}></p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ShowActivity);
