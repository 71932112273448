/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import classes from "./ForgotPassword.module.css";
import * as validators from "../../../components/UI/Forms/utility/validator";
import Form from "../../../components/UI/Forms/Form";
import {
  createInputElement,
  createButtonElement,
} from "../../../components/UI/Forms/utility/form-creators";
import LogoImage from "../../../components/UI/Images/LogoImage/LogoImage";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../../axios/axios-instance";
import AlertError from "../../../components/UI/Error/AlertError/AlertError";
import * as actionCreators from "../../../store/actions/actions";
import { Helmet } from "react-helmet";

class ForgotPassword extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      formData: {
        username: createInputElement(
          "text",
          null,
          "Username or Email or Phone No.",
          "",
          this.validateUsername,
          null,
          "fa fa-user",
          "col-sm-12",
          this.inputChangedHandler.bind(this, "username")
        ),
        submit: createButtonElement("submit", "Proceed", true),
      },
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateUsername(value) {
    if (validators.isEmpty(value.trim())) {
      return "Please enter your username!";
    }
    return null;
  }

  inputChangedHandler = (name, event) => {
    const formElementData = { ...this.state.formData[name] };
    formElementData.value = event.target.value;
    const updatedFormData = {
      ...this.state.formData,
      [name]: formElementData,
    };
    this.setState({ formData: updatedFormData });
  };

  checkIsValidFormData() {
    let isFormActivated = true;
    const formData = { ...this.state.formData };
    for (let key in formData) {
      if (formData[key].validate) {
        const invalid = formData[key].validate(formData[key].value);
        formData[key].invalid = invalid;
        isFormActivated = !invalid && isFormActivated;
      }
    }
    this.setState({
      formData: formData,
    });
    return isFormActivated;
  }

  forgotPasswordHandler = (event) => {
    event.preventDefault();
    if (!this.checkIsValidFormData()) {
      return false;
    }
    const username = this.state.formData.username.value;
    axios({
      method: "post",
      url: "/auth/forgot-password",
      data: {
        username: username,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        this.setState({
          loading: false,
        });
        const forgotPasswordId = response.data.data.id;
        const email = response.data.data.email;
        this.props.setForgotPassword({
          id: forgotPasswordId,
          email: email,
        });
        this.props.history.push("/auth/reset-password");
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          this.setState({
            loading: false,
            error:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
    return false;
  };

  render() {
    return (
      <div className={classes.ForgotPassword}>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <div className={classes.ForgotPasswordContent}>
          <div className="mb-3 text-center">
            <h4 className="mb-1">Forgot Password</h4>
            <p className="f-12">
              Enter your Username or Email or Phone Number to reset your
              password
            </p>
          </div>
          <div
            className={`${classes.ForgotPasswordForm} text-center d-flex flex-column align-items-center`}
          >
            {this.state.error ? <AlertError error={this.state.error} /> : null}
            <Form
              loading={this.state.loading}
              formData={this.state.formData}
              submit={this.forgotPasswordHandler}
            />
            <Link replace to="/auth/login" className="mt-3">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setForgotPassword: (forgotPassword) => {
      dispatch(actionCreators.setForgotPassword(forgotPassword));
    },
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
