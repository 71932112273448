/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LazyImage from "../../../../hoc/LazyImage/LazyImage";
import classes from "./PostProductImages.module.css";

const PostProductImages = (props) => {
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);

  const post = props.post;
  const photos =
    post.content_type === "article" ? post.photos : post.product.photos;

  const activePhoto = photos[activePhotoIndex];
  useEffect(() => {
    if (activePhotoIndex + 1 < photos.length) {
      setNext(activePhotoIndex + 1);
    }
    if (activePhotoIndex - 1 >= 0) {
      setPrev(activePhotoIndex - 1);
    }
  }, []);

  const activeImage = activePhoto.src;

  const previousHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const post = props.post;
    const photos =
      post.content_type === "article" ? post.photos : post.product.photos;
    setActivePhotoIndex(prev);
    let next = null,
      newPrev = null;
    if (prev + 1 < photos.length) {
      next = prev + 1;
    }
    if (prev - 1 >= 0) {
      newPrev = prev - 1;
    }
    setNext(next);
    setPrev(newPrev);
  };

  const nextHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const post = props.post;
    const photos =
      post.content_type === "article" ? post.photos : post.product.photos;
    setActivePhotoIndex(next);
    let newNext = null,
      prev = null;
    if (next + 1 < photos.length) {
      newNext = next + 1;
    }
    if (next - 1 >= 0) {
      prev = next - 1;
    }
    setNext(newNext);
    setPrev(prev);
  };

  return (
    <div
      onClick={() => {
        props.showImage(activeImage);
      }}
      className={classes.PhotoViewContent}
    >
      <div className={classes.PhotoViewImage}>
        <LazyImage src={activeImage} size="3" />
      </div>
      <div className={classes.PhotoNavigation}>
        {prev !== null ? (
          <div
            onClick={previousHandler}
            className={`${classes.Navigation} ${classes.Left}`}
          >
            <span className="fal fa-2x fa-chevron-left"></span>
          </div>
        ) : null}
        {next !== null ? (
          <div
            onClick={nextHandler}
            className={`${classes.Navigation} ${classes.Right}`}
          >
            <span className="fal fa-2x fa-chevron-right"></span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default PostProductImages;
