import React from "react";
import classes from "./OtpInput.module.css";

const OtpInput = props => {
  const inputClasses = [classes.OtpInput, "form-control"];
  if (props.invalid) {
    inputClasses.push("is-invalid");
  }
  let hint = null;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    hint = <div className="invalid-feedback">{props.invalid}</div>;
  }
  let inputElement = (
    <React.Fragment>
      <input
        type={props.type ? props.type : "text"}
        className={inputClasses.join(" ")}
        placeholder={props.placeholder}
        value={props.value}
        maxLength={props.maxLength}
        onChange={props.changeHandler}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
      {hint}
    </React.Fragment>
  );
  if (props.sideStyle) {
    inputElement = (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            {props.sideStyle.startsWith("fa") ? (
              <span className={props.sideStyle} />
            ) : (
              props.sideStyle
            )}
          </span>
        </div>
        {inputElement}
      </div>
    );
  }
  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      {inputElement}
    </div>
  );
};

export default OtpInput;
