import React, { Component } from "react";
import classes from "./../Layout.module.css";
import UserSideMenu from "../../../../components/App/Layout/SideMenu/UserSideMenu/UserSideMenu";
import SideMenu from "../../../../components/App/Layout/SideMenu/SideMenu";
import { Switch, Route } from "react-router-dom";
import Home from "../../Home/Home";
import { Redirect } from "react-router-dom";
import Settings from "../../Settings/Settings";
import EditProfile from "../../Settings/EditProfile/EditProfile";
import Logout from "../../../Auth/Logout/Logout";
import ChangePassword from "../../Settings/ChangePassword/ChangePassword";
import ChangeLocation from "../../Settings/ChangeLocation/ChangeLocation";
import Notifications from "../../Notifications/Notifications";
import SideColumn from "../../../../components/App/Layout/SideMenu/SideColumn/SideColumn";
import Footer from "../../../../components/App/Layout/Footer/Footer";
import Sell from "../../Sell/Sell";
import Withdraw from "../../Withdraw/Withdraw";
import BankDetails from "../../Settings/BankDetails/BankDetails";
// import UpdateEmail from "../../Settings/UpdateEmail/UpdateEmail";
import Transactions from "../../Transactions/Transactions";
import ProductView from "../../ProductView/ProductView";
import EditProduct from "../../EditProduct/EditProduct";
import ExploreUsers from "../../ExploreUsers/ExploreUsers";
import FundWallet from "../../FundWallet/FundWallet";
import ViewFunding from "../../ViewFunding/ViewFunding";
import SellerUsers from "../../SellerUsers/SellerUsers";
import Chats from "../../Chats/Chats";
import MobileFooter from "../../../../components/App/Layout/Footer/MobileFooter/MobileFooter";
import { connect } from "react-redux";
import RecentActivities from "../../RecentActivities/RecentActivities";
import SocialLayout from "../SocialLayout/SocialLayout";
import Invite from "../../Invite/Invite";
import Activities from "../../Activities/Activities";
import Suggestions from "../../Suggestions/Suggestions";
import EarnLayout from "../EarnLayout/EarnLayout";
import Support from "../../Support/Support";
import SellersRegister from "../../Sell/SellersRegister/SellersRegister";

class RouteLayout extends Component {
  render() {
    return (
      <div className={classes.RouteLayout}>
        <div className="container-fluid">
          <div className="row">
            <div className="offset-xl-1 col-xl-10">
              <div className="row">
                <div className="d-none pr-0 d-lg-block col-lg-3">
                  <div className={classes.SideMenu}>
                    <SideMenu>
                      <UserSideMenu />
                    </SideMenu>
                  </div>
                </div>
                <div className="pr-md-0 col-sm-8 col-lg-6">
                  <Switch>
                    {/* <Route path="/search" component={Explore} /> */}
                    {/* <Route path="/post/new" component={NewPost} /> */}
                    <Route path="/shop/sell" component={Sell} />
                    <Route
                      path="/edit-product/:productId"
                      component={EditProduct}
                    />
                    <Route
                      path="/products/:productId"
                      component={ProductView}
                    />
                    <Route path="/settings/profile" component={EditProfile} />
                    <Route
                      path="/settings/password"
                      component={ChangePassword}
                    />
                    <Route
                      path="/settings/location"
                      component={ChangeLocation}
                    />
                    <Route path="/settings/bank" component={BankDetails} />
                    {/* <Route path="/settings/email" component={UpdateEmail} /> */}
                    <Route path="/settings" component={Settings} />
                    <Route path="/withdraw" component={Withdraw} />
                    <Route path="/funding/:fundingId" component={ViewFunding} />
                    <Route path="/funding" component={FundWallet} />
                    <Route path="/users/sellers" component={SellerUsers} />
                    <Route path="/users" component={ExploreUsers} />
                    <Route path="/kyc" component={SellersRegister} />
                    <Route
                      path="/transactions/:wallet"
                      component={Transactions}
                    />
                    <Route path="/notifications" component={Notifications} />
                    <Route path="/chats" component={Chats} />
                    <Route path="/invite" component={Invite} />
                    <Route path="/activities" component={Activities} />
                    <Route path="/social" component={SocialLayout} />
                    <Route path="/earn" component={EarnLayout} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/support" component={Support} />
                    <Route path="/error" component={null} />
                    <Redirect from="/home" to="/" />
                    <Redirect from="/auth/login" to="/" />
                    <Redirect from="/auth/verify" to="/" />
                    <Redirect from="/auth/register" to="/" />
                    <Redirect from="/auth/signup" to="/" />
                    <Redirect from="/auth/forgot-password" to="/" />
                    <Redirect from="/auth/reset-password" to="/" />
                    <Route path="/" exact component={Home} />
                    <Redirect to="/error" />
                  </Switch>
                </div>
                {window.outerWidth >= 768 ? (
                  <div className="col-sm-4 col-lg-3">
                    <div className={classes.SideColumn}>
                      <SideColumn>
                        {this.props.location.pathname === "/activities" ? (
                          <Suggestions />
                        ) : (
                          <RecentActivities />
                        )}
                        <Footer />
                      </SideColumn>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.MobileFooter}>
          <MobileFooter user={this.props.user} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(RouteLayout);
