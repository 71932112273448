import React from "react";
import classes from "./Header.module.css";
import FullLogo from "../../../UI/Images/FullLogo/FullLogo";
import Form from "../../../UI/Forms/Form";
import IconLink from "../../../UI/IconLink/IconLink";
import { Link, NavLink } from "react-router-dom";
import ProfileDropdown from "../../../UI/Dropdown/ProfileDropdown/ProfileDropdown";
import LayoutContext from "../../../../context/layout-context";

const header = (props) => {
  return (
    <LayoutContext.Consumer>
      {(context) => {
        const socialHandler = (event) => {
          event.preventDefault();
          // if (!context.user) props.history.push("/login");
          window.$("#SocialMenuModal").modal({
            backdrop: "static",
            keyboard: false,
          });
          window.$("#SocialMenuModal").modal("show");
        };
        const marketHandler = (event) => {
          event.preventDefault();
          // if (!context.user) props.history.push("/login");
          window.$("#MarketMenuModal").modal({
            backdrop: "static",
            keyboard: false,
          });
          window.$("#MarketMenuModal").modal("show");
        };
        return (
          <div className={classes.Header}>
            <div className="container-fluid h-100">
              <div className="row h-100">
                <div className="offset-xl-1 col-xl-10 h-100">
                  <div className={classes.HeaderContent}>
                    <div className={classes.LogoTitle}>
                      <FullLogo />
                    </div>
                    <div className={`d-none d-lg-block ${classes.SearchForm}`}>
                      <Form
                        formData={props.searchFormData}
                        submit={props.submit}
                      />
                    </div>
                    <div className={`d-none d-md-flex ${classes.HeaderLinks}`}>
                      <div className={classes.HeaderLink}>
                        <NavLink
                          activeClassName={classes.HeaderLinkActive}
                          to="/"
                          exact
                        >
                          <i className="fal fa-home mr-2"></i>
                          Home
                        </NavLink>
                      </div>
                      <div className={classes.HeaderLink}>
                        <NavLink
                          activeClassName={classes.HeaderLinkActive}
                          onClick={socialHandler}
                          to="/earn"
                        >
                          <i className="fal fa-wallet mr-2"></i>
                          Earn
                        </NavLink>
                      </div>
                      <div className={classes.HeaderLink}>
                        <NavLink
                          activeClassName={classes.HeaderLinkActive}
                          onClick={marketHandler}
                          to="/social"
                        >
                          <i className="fal fa-plus-circle mr-2"></i>
                          Advertise
                        </NavLink>
                      </div>
                      <div className={`d-none d-sm-flex ${classes.HeaderLink}`}>
                        <NavLink
                          activeClassName={classes.HeaderLinkActive}
                          to="/market"
                        >
                          <i className="fal fa-store mr-2"></i>
                          Market
                        </NavLink>
                      </div>
                      <div className={`d-none d-sm-flex ${classes.HeaderLink}`}>
                        <NavLink
                          className="mr-0"
                          activeClassName={classes.HeaderLinkActive}
                          to="/settings"
                        >
                          <i className="fal fa-bars mr-2"></i>
                          More
                        </NavLink>
                      </div>
                    </div>
                    {context.user ? (
                      <>
                        <div
                          className={`d-none d-sm-flex ${classes.IconLinks}`}
                        >
                          <div className={classes.IconLink}>
                            <IconLink
                              target="/support"
                              icon="fal fa-question-circle"
                              size="24"
                            />
                          </div>
                          <div className={classes.IconLink}>
                            <IconLink
                              badge={context.notificationCount}
                              target="/notifications"
                              icon="fal fa-bell"
                              size="24"
                            />
                          </div>                          
                          <div className={classes.IconLink}>
                            <IconLink
                              type="dropdown"
                              target="#"
                              sourceType="image"
                              icon={context.user.profile_pic}
                            >
                              <ProfileDropdown
                                username={context.user.username}
                                logout={context.logout}
                              />
                            </IconLink>
                          </div>
                        </div>
                        <div
                          className={`d-flex d-sm-none ${classes.IconLinks}`}
                        >
                          <div className={classes.IconLink}>
                            <IconLink
                              target="/support"
                              icon="fal fa-question-circle"
                              size="24"
                            />
                          </div>
                          <div className={classes.IconLink}>
                            <IconLink
                              badge={context.notificationCount}
                              size="24"
                              target="/notifications"
                              icon="fal fa-bell"
                            />
                          </div>                          
                          <div className={classes.IconLink}>
                            <IconLink
                              sourceType="image"
                              target={`/users/${context.user.username}`}
                              icon={context.user.profile_pic}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className={classes.HeaderButtonLinks}>
                        <div className={classes.HeaderButtonLink}>
                          <Link
                            className="btn btn-coloured-heavy btn-xl mr-2"
                            to="/auth/login"
                          >
                            Login
                          </Link>
                        </div>
                        <div className={classes.HeaderButtonLink}>
                          <Link
                            className="btn btn-outline btn-xl"
                            to="/auth/register"
                          >
                            Sign Up
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </LayoutContext.Consumer>
  );
};
export default header;
