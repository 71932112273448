import * as actionTypes from "./actionTypes";

export const setCategories = (categories) => {
  return {
    type: actionTypes.SET_CATEGORIES,
    categories: categories,
  };
};

export const setCategory = (category) => {
  return {
    type: actionTypes.SET_CATEGORY,
    category: category,
  };
};

export const setCategorySearch = (categorySearch) => {
  return {
    type: actionTypes.SET_CATEGORY_SEARCH,
    category_search: categorySearch,
  };
};

export const setSortBy = (sortBy) => {
  return {
    type: actionTypes.SET_SORT_BY,
    sortBy: sortBy,
  };
};

export const setSearch = (search) => {
  return {
    type: actionTypes.SET_SEARCH,
    search: search,
  };
};

export const setSearchLocation = (location) => {
  return {
    type: actionTypes.SET_SEARCH_LOCATION,
    location: location,
  };
};

export const setSearchPosts = (searchPosts) => {
  return {
    type: actionTypes.SET_SEARCH_POSTS,
    searchPosts: searchPosts,
  };
};

export const setSearchScroll = (searchScroll) => {
  return {
    type: actionTypes.SET_SEARCH_SCROLL,
    searchScroll: searchScroll,
  };
};
