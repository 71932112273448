import React, { Component } from "react";
import classes from "./Auth.module.css";
import Login from "./Login/Login";
import Register from "./Register/Register";
import { Switch, Route } from "react-router-dom";
import SignUp from "./SignUp/SignUp";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import Verify from "./Verify/Verify";

class Auth extends Component {
  componentDidMount() {
    window.$("#AuthModal").modal({
      show: true,
      keyboard: false,
      backdrop: "static",
    });
  }

  componentWillUnmount() {
    window.$("#AuthModal").modal("hide");
    window.$(".modal-backdrop").remove();
    window.$("body").removeClass("modal-open");
  }

  closeHandler = () => {
    window.$("#AuthModal").off("hidden.bs.modal");
    window.$("#AuthModal").on("hidden.bs.modal", (event) => {
      this.props.history.push("/");
    });
    window.$("#AuthModal").modal("hide");
  };

  render() {
    return (
      <div className={classes.Auth}>
        <div
          id="AuthModal"
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby=""
          aria-hidden="true"
        >
          <div
            className={`${classes.AuthModalDialog} modal-dialog modal-dialog-centered`}
            role="document"
          >
            <div className={`${classes.AuthModalContent} modal-content`}>
              <div className={`${classes.AuthModalBody} modal-body`}>
                <button
                  onClick={this.closeHandler}
                  className={classes.CloseButton}
                >
                  <span className="fas fa-times"></span>
                </button>
                <Switch>
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/verify" component={Verify} />
                  <Route path="/auth/register" component={Register} />
                  <Route path="/auth/signup" component={SignUp} />
                  <Route
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/auth/reset-password"
                    component={ResetPassword}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Auth;
