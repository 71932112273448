import * as actionTypes from "./actionTypes";
import * as actionCreators from "./actions";
import axios from "../../axios/axios-instance";
import socketio from "../../util/socket";

export const setUserLoading = (userLoading) => {
  return {
    type: actionTypes.SET_USER_LOADING,
    userLoading: userLoading,
  };
};

export const setUserError = (userError) => {
  return {
    type: actionTypes.SET_USER_ERROR,
    userError: userError,
  };
};

export const setCountryStates = (country_states) => {
  return {
    type: actionTypes.SET_COUNTRY_STATES,
    country_states: country_states,
  };
};

export const initialiseUserLoading = () => {
  return (dispatch) => {
    dispatch(setUserError(null));
    dispatch(setUserLoading(true));
  };
};

export const setCollapseStatus = (collapse) => {
  return {
    type: actionTypes.SET_COLLAPSE_STATUS,
    collapsed: collapse,
  };
};

export const processUser = (user) => {
  return {
    type: actionTypes.SET_USER,
    user: user,
  };
};

export const setUserCurrency = (currency) => {
  return {
    type: actionTypes.SET_USER_CURRENCY,
    currency: currency,
  };
};

export const setMeta = (meta) => {
  return {
    type: actionTypes.SET_META,
    meta: meta,
  };
};

export const setUser = () => {
  return (dispatch) => {
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    if (!userToken || new Date().getTime() > userToken.expiryDate) {
      dispatch(actionCreators.logout());
      return;
    }
    axios({
      method: "get",
      url: "/users/me",
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        const user = response.data.data.user;
        const states = response.data.data.states;
        const meta = response.data.data.meta;
        socketio.connectUser(user);
        dispatch(setMeta(meta));
        dispatch(setCountryStates(states));
        dispatch(processUser(user));
        dispatch(setUserLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          if (error.response.status === 401) {
            dispatch(actionCreators.logout());
          } else dispatch(setUserError(data.error));
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          dispatch(setUserError(errorMsg));
        }
      });
  };
};

export const updateUser = () => {
  return (dispatch) => {
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    if (!userToken || new Date().getTime() > userToken.expiryDate) {
      dispatch(actionCreators.logout());
      return;
    }
    axios({
      method: "get",
      url: "/users/me",
      headers: {
        Authorization: userToken.token,
      },
    })
      .then((response) => {
        const user = response.data.data.user;
        const states = response.data.data.states;
        const meta = response.data.data.meta;
        dispatch(setMeta(meta));
        dispatch(setCountryStates(states));
        dispatch(processUser(user));
      })
      .catch((error) => {});
  };
};

export const fetchCountryStates = () => {
  return (dispatch, getState) => {
    const userToken = getState().authState.userToken;
    axios({
      method: "get",
      url: "/users/states",
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        const states = response.data.data.states;
        dispatch(setCountryStates(states));
      })
      .catch((error) => {});
  };
};
