import React from "react";
import classes from "./SideTop.module.css";
import UserPhoto from "../../../../User/UserPhoto/UserPhoto";
import { abbreviateNumber } from "../../../../../../util/util";

const sideTop = props => {
  return (
    <div className={classes.SideTop}>
      <div className={classes.SideContent}>
        <UserPhoto photo={props.user.profile_pic} size="50" />
        <div className={classes.SideText}>
          <h6 className={classes.Name}>{props.user.name}</h6>
          <p className={classes.Username}>@{props.user.username}</p>
          <p className={classes.Following}>
            <span className={classes.FollowingSpan}>
              <span className={classes.Number}>
                {abbreviateNumber(props.user.followers)}
              </span>{" "}
              Followers
            </span>
            <span className={classes.FollowerSpan}>
              <span className={classes.Number}>
                {abbreviateNumber(props.user.following)}
              </span>{" "}
              Following
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default sideTop;
