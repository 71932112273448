import React, { Component } from "react";
import { connect } from "react-redux";
import Wrapper from "./containers/Wrapper/Wrapper";
import { HashRouter } from "react-router-dom";
import * as actionCreators from "./store/actions/actions";
import Spinner from "./components/UI/Spinner/Spinner";
import Error from "./components/UI/Error/Error";

class App extends Component {
  componentDidMount() {
    this.props.checkAuthState();
    const p_s_h = window.location.href.split("#")[0];
    if (p_s_h.length && p_s_h.includes("resell")) {
      const reseller_code = p_s_h.substring(p_s_h.lastIndexOf("/") + 1);
      window.history.pushState({}, null, `/#/resell/${reseller_code.trim()}`);
    }
  }

  refreshHandler = (event) => {
    event.preventDefault();
    this.props.initialiseAuthLoading();
    this.props.checkAuthState();
  };

  render() {
    let app = (
      <HashRouter>
        <Wrapper />
      </HashRouter>
    );
    if (this.props.authLoading) {
      app = (
        <div style={{ height: "100vh" }}>
          <Spinner height="100%" />
        </div>
      );
    }
    if (this.props.authError) {
      app = (
        <div style={{ height: "100vh" }}>
          <Error
            refresh={this.refreshHandler}
            color="#000"
            error={this.props.authError}
          />
        </div>
      );
    }
    return app;
  }
}

const mapStateToProps = (state) => {
  return {
    authLoading: state.authState.authLoading,
    authError: state.authState.authError,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthState: () => dispatch(actionCreators.checkAuthState()),
    initialiseAuthLoading: () => dispatch(actionCreators.initialiseAuthLoading),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
