/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import classes from "./PreviewHome.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import { Link } from "react-router-dom";
import BrowserLinks from "../../../components/UI/Links/BrowserLinks/BrowserLinks";
import GoogleImage from "../../../assets/images/google-play-store.jpg";
import AppleImage from "../../../assets/images/coming-soon-appstore.png";
import AppMockup from "../../../assets/images/app-mockup.jpg";
import Lady1 from "../../../assets/images/lady-1.jpg";
import { Helmet } from "react-helmet";

class PreviewHome extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createHandler = (event) => {
    event.preventDefault();
    this.props.history.push("/post");
  };

  refreshHandler = (event) => {
    event.preventDefault();
    this.loadPosts(false);
  };

  render() {
    return (
      <div className={classes.PreviewHome}>
        <Helmet>
          <title>
            Hawkit - Advertise and Earn Daily with your social media account
          </title>
        </Helmet>
        <div className="container">
          <div className="row no-gutters">
            <div className="offset-md-2 col-md-8 offset-lg-2 col-lg-8">
              <div className={classes.MainHeadingTitle}>
                <div className={classes.Title}>
                  <h1>
                    Get Paid for Posting Adverts Daily on Your Social Media
                  </h1>
                  <p>
                    Earn daily income by reselling products, posting adverts and
                    performing simple social tasks for top businesses and brands
                    on your social media account.
                  </p>
                </div>
                <div className={classes.Buttons}>
                  <p className="mb-2">
                    <Link
                      className="btn btn-rounded btn-coloured-heavy btn-xl py-3 px-5 f-14 mr-2"
                      to="/auth/register"
                    >
                      Create Free Account
                    </Link>
                  </p>
                  <p className="f-16 mb-2">
                    Already a user? <Link to="/auth/login">Login</Link> now
                  </p>
                  <div className={classes.AppDownload}>
                    <BrowserLinks
                      className="mr-1"
                      to="https://play.google.com/store/apps/details?id=com.app.hawkitng"
                    >
                      <img src={GoogleImage} alt="google-play-store" />
                    </BrowserLinks>
                    <BrowserLinks
                      className="h-100"
                      to="https://hawkit.ng/download"
                    >
                      <img src={AppleImage} alt="apple-play-store" />
                    </BrowserLinks>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.Stats}>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className={classes.Stat}>
                  <div className="step-icon">
                    <i className="lni lni-target-customer"></i>
                  </div>
                  <h3>200,000+</h3>
                  <p>Members</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className={classes.Stat}>
                  <div className="step-icon">
                    <i className="lni lni-user-check"></i>
                  </div>
                  <h3>50,000+</h3>
                  <p>Advertisers</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className={classes.Stat}>
                  <div className="step-icon">
                    <i className="lnil lnil-network"></i>
                  </div>
                  <h3>10,000,000+</h3>
                  <p>Advert Views</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className={classes.Stat}>
                  <div className="step-icon">
                    <i className="lnil lnil-mobile-application-alt"></i>
                  </div>
                  <h3>300,000+</h3>
                  <p>App Downloads</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.IntroContainer}>
            <div className="row no-gutters align-items-center">
              <div className="col-md-4">
                <div className={classes.IntroImage}>
                  <img src={Lady1} alt="lady1" className="w-100" />
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className={classes.IntroContent}>
                  <div className={classes.IntroHeading}>
                    <p className="f-12 mb-2">For Advertisers</p>
                    <h4>
                      Get People to Post Your Adverts on their Social Media
                    </h4>
                  </div>
                  <div className={classes.IntroDetails}>
                    <p className="mb-3">
                      Get people with aleast 1,000 followers to post your
                      adverts and perform social tasks for you on their social
                      media account.
                    </p>
                    <p>
                      <Link
                        to="/auth/register"
                        className="btn btn-coloured-heavy mr-2"
                      >
                        Sign Up Today
                      </Link>
                      <Link to="/pricing" className="f-12">
                        <i className="fas fa-caret-circle-right"></i> See
                        Pricing
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className={classes.IntroContent}>
                  <div className={classes.IntroHeading}>
                    <p className="f-12 mb-2">For Earners</p>
                    <h4>Get Paid for Posting Adverts on Your Social Media</h4>
                  </div>
                  <div className={classes.IntroDetails}>
                    <p className="mb-3">
                      Earn steady income by reselling products, posting adverts
                      and performing social tasks for businesses and top brands
                      on your social media account.
                    </p>
                    <p>
                      <Link
                        to="/auth/register"
                        className="btn btn-coloured-heavy mr-2"
                      >
                        Sign Up Today
                      </Link>
                      <Link to="/earn" className="f-12">
                        <i className="fas fa-caret-circle-right"></i> See
                        Earnings
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offset-md-1 col-md-10">
            <div className={classes.AppFeaturesContainer}>
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className={classes.AppMockup}>
                    <img src={AppMockup} alt="app-mockup" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={classes.AppFeatures}>
                    <div className={classes.AppFeatureHeading}>
                      <h1>Experience the Power of Viral Advertising</h1>
                      <p>
                        Imagine what happens when you get thousands of people
                        with atleast 1,000 followers each to post your advert on
                        their social media accounts. Now, that is true power.
                      </p>
                    </div>
                    <div className="asset-feature">
                      <div className="icon-feature">
                        <i className="lni lni-target-customer"></i>
                      </div>
                      <div className="asset-feature-content">
                        <h6>Massive Advert Views</h6>
                        <p>
                          Each person that repost your advert on their social
                          media account has atleast 1,000 followers with active
                          engagements. This will give your advert massive views
                          within the shortest possible time thereby increasing
                          your conversion and revenue.
                        </p>
                        <p className="mt-2">
                          <Link to="/auth/register">
                            <i className="fas fa-caret-circle-right"></i> Get
                            Started
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="asset-feature">
                      <div className="icon-feature">
                        <i className="lni lni-price-tag"></i>
                      </div>
                      <div className="asset-feature-content">
                        <h6>Affordable Pricing</h6>
                        <p>
                          You don't have to break the bank to get people to
                          repost your advert for you. With as low as ₦150 you
                          can get people with atleast 1,000 followers each to
                          repost your advert on their social media.
                        </p>
                        <p className="mt-2">
                          <Link to="/pricing">
                            <i className="fas fa-caret-circle-right"></i> See
                            pricing
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="asset-feature">
                      <div className="icon-feature">
                        <i className="lni lni-alarm-clock"></i>
                      </div>
                      <div className="asset-feature-content">
                        <h6>Fast Posting Time</h6>
                        <p>
                          After making payment and uploading your advert media,
                          the people are readily available to post your adverts
                          within minutes.
                        </p>
                        <p className="mt-2">
                          <Link to="/auth/register">
                            <i className="fas fa-caret-circle-right"></i> Get
                            Started
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="asset-feature">
                      <div className="icon-feature">
                        <i className="lni lni-user-check"></i>
                      </div>
                      <div className="asset-feature-content">
                        <h6>Earn Steady Income Daily</h6>
                        <p>
                          You can also join Hawkit today and start earning daily
                          income by posting adverts of businesses and brands on
                          your social media account. Get started within minutes
                          and earn steady income on Hawkit.
                        </p>
                        <p className="mt-2">
                          <Link to="/earn">
                            <i className="fas fa-caret-circle-right"></i> See
                            Earnings
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    is_seller_product_failed: state.notificationState.is_seller_product_failed,
    meta: state.userState.meta,
    advert_banner: state.notificationState.advert_banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processUser: (user) => dispatch(actionCreators.processUser(user)),
    fetchCountryStates: () => dispatch(actionCreators.fetchCountryStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewHome);
