import React, { Component } from "react";
import classes from "./../Layout.module.css";
import UserSideMenu from "../../../../components/App/Layout/SideMenu/UserSideMenu/UserSideMenu";
import SideMenu from "../../../../components/App/Layout/SideMenu/SideMenu";
import { Switch, Route } from "react-router-dom";
import SideColumn from "../../../../components/App/Layout/SideMenu/SideColumn/SideColumn";
import Footer from "../../../../components/App/Layout/Footer/Footer";
// import UpdateEmail from "../../Settings/UpdateEmail/UpdateEmail";
import { connect } from "react-redux";
import RecentActivities from "../../RecentActivities/RecentActivities";
import Suggestions from "../../Suggestions/Suggestions";
import Checkout from "../../Orders/Checkout/Checkout";
import Orders from "../../Orders/Orders";
import Sales from "../../Orders/Sales/Sales";

class OrderLayout extends Component {
  render() {
    return (
      <div style={{ paddingBottom: "0px" }} className={classes.RouteLayout}>
        <div className="container-fluid">
          <div className="row">
            <div className="offset-xl-1 col-xl-10">
              <div className="row">
                <div className="d-none pr-0 d-lg-block col-lg-3">
                  <div className={classes.SideMenu}>
                    <SideMenu>
                      <UserSideMenu />
                    </SideMenu>
                  </div>
                </div>
                <div className="pr-md-0 col-sm-8 col-lg-6">
                  <Switch>
                    <Route
                      path="/orders/checkout/:orderId"
                      component={Checkout}
                    />
                    <Route path="/orders/sales/:orderId" component={Sales} />
                    <Route path="/orders" component={Orders} />
                  </Switch>
                </div>
                {window.outerWidth >= 768 ? (
                  <div className="col-sm-4 col-lg-3">
                    <div className={classes.SideColumn}>
                      <SideColumn>
                        {this.props.location.pathname === "/activities" ? (
                          <Suggestions />
                        ) : (
                          <RecentActivities />
                        )}
                        <Footer />
                      </SideColumn>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(OrderLayout);
