import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import Title from "../../../components/App/Layout/Title/Title";
import TitleHeader from "../../../components/App/Layout/Title/TitleHeader";
import PageContent from "../../../components/App/Layout/ContentLayout/PageContent/PageContent";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import classes from "./Notifications.module.css";
import axios from "../../../axios/axios-instance";
import UserPhoto from "../../../components/App/User/UserPhoto/UserPhoto";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import * as actionCreators from "../../../store/actions/actions";
import { connect } from "react-redux";

const moment = require("moment");

class Notifications extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      pages: null,
      notifications: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadNotifications();
    this.props.setNotificationCount(0);
  }

  loadNotifications = () => {
    let page = 1;
    if (this.state.pages && this.state.pages.next) {
      page = this.state.pages.next;
    }
    const userToken = this.props.userToken;
    const url = `/notifications?page=${page}`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        const notifications = response.data.data.notifications;
        const pages = response.data.data.pages;
        const filteredNotifications = notifications.filter(el => {
          return !this.state.notifications.find(
            notification => notification._id === el._id
          );
        });
        const updatedNotifications = [
          ...this.state.notifications,
          ...filteredNotifications
        ];
        this.setState({
          notifications: updatedNotifications,
          pages: pages,
          loading: false
        });
      })
      .catch(error => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg
            });
          } else {
            this.setState({
              loading: false,
              error: data.error
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg
          });
        }
      });
    this.setState({
      loading: true,
      error: null
    });
  };

  markNotification = id => {
    const userToken = this.props.userToken;
    const url = `/notifications/unread/${id}`;
    axios({
      method: "put",
      url: url,
      headers: {
        Authorization: userToken.token
      }
    })
      .then(response => {})
      .catch(error => {
        console.log(error);
      });
  };

  loadMoreNotifications = event => {
    event.preventDefault();
    this.loadNotifications();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  notificationHandler = (notification, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.markNotification(notification._id);
    this.props.history.push(notification.link);
  };

  render() {
    let loadingContent = null;
    if (this.state.pages && this.state.pages.next) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <a onClick={this.loadMoreNotifications} href="/">
            Load More <span className="fa fa-chevron-down"></span>
          </a>
        </div>
      );
    }
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error
            refresh={this.loadMoreNotifications}
            error={this.state.error}
          />
        </div>
      );
    }
    let content = null;
    if (this.state.notifications.length) {
      content = this.state.notifications.map(notification => {
        const now = moment();
        const postMoment = moment(notification.created_on);
        const daysDiff = now.diff(postMoment, "days");
        let formatDate;
        if (daysDiff >= 1) {
          formatDate = postMoment.calendar();
        } else {
          formatDate = postMoment.fromNow();
        }
        return (
          <div
            key={notification._id}
            onClick={event => this.notificationHandler(notification, event)}
            className={`${classes.Notification} ${
              notification.is_active ? classes.NotificationActive : ""
            }`}
          >
            <div className={classes.NotificationPicture}>
              <UserPhoto photo={notification.picture} width="40" height="40" />
            </div>
            <div className={classes.NotificationContent}>
              <p>{notification.notification}</p>
              <p className={classes.Date}>
                <i className="fal fa-clock"></i> {formatDate}
              </p>
            </div>
            <div className={classes.NotificationLink}>
              <span className="fa fa-caret-right"></span>
            </div>
          </div>
        );
      });
    } else if (!this.state.loading && !this.state.error) {
      content = (
        <div className={classes.NotificationEmpty}>
          <i className="fal fa-bell-slash"></i>
          <p>You don't have any notifications.</p>
        </div>
      );
    }
    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <PageContent>
              <Title>
                <TitleHeader>
                  <BackButton />
                  <h4>Notifications</h4>
                </TitleHeader>
              </Title>
              <div className={classes.Notifications}>
                {content}
                {loadingContent}
              </div>
            </PageContent>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNotificationCount: notificationCount =>
      dispatch(actionCreators.setNotificationCount(notificationCount))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
