import React, { useState } from "react";
import classes from "./PreviewHeader.module.css";
import FullLogo from "../../../UI/Images/FullLogo/FullLogo";
import { Link, NavLink } from "react-router-dom";
import MenuButton from "../Header/MenuButton/MenuButton";
import BrowserLinks from "../../../UI/Links/BrowserLinks/BrowserLinks";

const PreviewHeader = (props) => {
  const [show_menu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!show_menu);
  };

  return (
    <div className={classes.Header}>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="offset-xl-1 col-xl-10 h-100">
            <div className={classes.HeaderContent}>
              <div className={classes.LogoTitle}>
                <FullLogo />
              </div>
              <div className={`d-none d-md-flex ${classes.HeaderLinks}`}>
                <div className={classes.HeaderLink}>
                  <NavLink
                    activeClassName={classes.HeaderLinkActive}
                    to="/"
                    exact
                  >
                    Home
                  </NavLink>
                </div>
                <div className={classes.HeaderLink}>
                  <BrowserLinks to="https://hawkit.ng/about">
                    About
                  </BrowserLinks>
                </div>
                <div className={classes.HeaderLink}>
                  <NavLink
                    activeClassName={classes.HeaderLinkActive}
                    to="/earn"
                  >
                    Earnings
                  </NavLink>
                </div>
                <div className={classes.HeaderLink}>
                  <NavLink
                    activeClassName={classes.HeaderLinkActive}
                    to="/pricing"
                  >
                    Pricing
                  </NavLink>
                </div>
                <div className={classes.HeaderLink}>
                  <NavLink
                    activeClassName={classes.HeaderLinkActive}
                    to="/market"
                  >
                    Marketplace
                  </NavLink>
                </div>
                <div className={`d-none d-sm-flex ${classes.HeaderLink}`}>
                  <NavLink
                    activeClassName={classes.HeaderLinkActive}
                    to="/support"
                  >
                    Support
                  </NavLink>
                </div>
              </div>
              <div className={`d-none d-md-flex ${classes.HeaderButtonLinks}`}>
                <div className={classes.HeaderLink}>
                  <NavLink
                    activeClassName={classes.HeaderLinkActive}
                    to="/auth/login"
                  >
                    Login
                  </NavLink>
                </div>
                <div className={classes.HeaderButtonLink}>
                  <Link
                    className="btn btn-coloured-heavy btn-rounded f-14 py-3 px-4 btn-xl"
                    to="/auth/register"
                  >
                    Create Account
                  </Link>
                </div>
              </div>
              <div className="d-block d-md-none ml-auto">
                <MenuButton handler={toggleMenu} collapsed={!show_menu} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {show_menu ? (
        <div className={classes.MobileMenu}>
          <div className={classes.MobileMenuLinks}>
            <div className={classes.MobileMenuLink}>
              <NavLink
                onClick={() => setShowMenu(false)}
                activeClassName={classes.MobileMenuLinkActive}
                exact
                to="/"
              >
                Home
              </NavLink>
            </div>
            {!window.cordova ? (
              <div className={classes.MobileMenuLink}>
                <BrowserLinks to="https://hawkit.ng/about">About</BrowserLinks>
              </div>
            ) : null}
            <div className={classes.MobileMenuLink}>
              <NavLink
                onClick={() => setShowMenu(false)}
                activeClassName={classes.MobileMenuLinkActive}
                to="/earn"
              >
                Earning
              </NavLink>
            </div>
            <div className={classes.MobileMenuLink}>
              <NavLink
                onClick={() => setShowMenu(false)}
                activeClassName={classes.MobileMenuLinkActive}
                to="/pricing"
              >
                Pricing
              </NavLink>
            </div>
            <div className={classes.MobileMenuLink}>
              <NavLink
                onClick={() => setShowMenu(false)}
                activeClassName={classes.MobileMenuLinkActive}
                to="/market"
              >
                Marketplace
              </NavLink>
            </div>
            <div className={classes.MobileMenuLink}>
              <NavLink
                onClick={() => setShowMenu(false)}
                activeClassName={classes.HeaderLinkActive}
                to="/support"
              >
                Support
              </NavLink>
            </div>
          </div>
          <div className={classes.MobileMenuButtons}>
            <Link
              to="/auth/login"
              className="btn btn-outline mr-2 f-14 px-4 py-3"
            >
              Login
            </Link>
            <Link
              to="/auth/register"
              className="btn btn-coloured-heavy f-14 py-3 px-4"
            >
              Create Account
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default PreviewHeader;
