/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import classes from "./TaskCountdown.module.css";

const TaskCountdown = (props) => {
  const dayRef = useRef();
  const hourRef = useRef();
  const secondRef = useRef();
  const minuteRef = useRef();

  useEffect(() => {
    var end = new Date(props.timer_count);
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer;

    const showRemaining = () => {
      var now = new Date();
      var distance = end - now;
      if (distance <= 0) {
        clearInterval(timer);
        props.refresh();
        return;
      }
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);
      dayRef.current.innerHTML = `${days}`.padStart(2, "0");
      hourRef.current.innerHTML = `${hours}`.padStart(2, "0");
      minuteRef.current.innerHTML = `${minutes}`.padStart(2, "0");
      secondRef.current.innerHTML = `${seconds}`.padStart(2, "0");
    };
    if (end - new Date() > 0) {
      console.log(end - new Date());
      timer = setInterval(showRemaining, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.TaskCountdown}>
      <div className={classes.TaskCountdownHeading}>
        <p>{props.title}</p>
      </div>
      <div className={classes.TaskCountdownContent}>
        <div className="counter">
          <div className="timer-col">
            <div className="timer" ref={dayRef}>
              00
            </div>
            <div className="timer-type">days</div>
          </div>
          <div className="timer-col">
            <div className="timer" ref={hourRef}>
              00
            </div>
            <div className="timer-type">hrs</div>
          </div>
          <div className="timer-col">
            <div className="timer" ref={minuteRef}>
              00
            </div>
            <div className="timer-type">mins</div>
          </div>
          <div className="timer-col">
            <div className="timer" ref={secondRef}>
              00
            </div>
            <div className="timer-type">secs</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskCountdown;
