import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SocialBuy from "../../SocialBuy/SocialBuy";
import SocialBuyPricing from "../../SocialBuyPricing/SocialBuyPricing";
import SocialOrders from "../../SocialOrders/SocialOrders";
import { connect } from "react-redux";
import SocialOrderPage from "../../SocialOrderPage/SocialOrderPage";

class SocialLayout extends Component {
  render() {
    let content;
    if (this.props.user) {
      content = (
        <Switch>
          <Route path="/social/buy/pricing" component={SocialBuyPricing} />
          <Route path="/social/buy/:id" component={SocialBuy} />
          <Route path="/social/orders/:id" component={SocialOrderPage} />
          <Route path="/social/orders/" exact component={SocialOrders} />
          <Redirect from="/social/member" to="/earn/member" />
          <Redirect from="/social/referral" to="/earn/referral" />
          <Redirect from="/social/task/:id" to="/earn/task/:id" />
          <Redirect from="/social/tasks/pricing" to="/earn" />
          <Redirect from="/social/tasks" to="/earn" />
        </Switch>
      );
    } else {
      content = (
        <Switch>
          <Route path="/social/buy/pricing" component={SocialBuyPricing} />
          <Redirect from="/social/member" to="/earn/member" />
          <Redirect from="/social/referral" to="/earn/referral" />
          <Redirect from="/social/task/:id" to="/earn/task/:id" />
          <Redirect from="/social/tasks/pricing" to="/earn" />
          <Redirect from="/social/tasks" to="/earn" />
          <Redirect to="/login" />
        </Switch>
      );
    }
    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
  };
};

export default connect(mapStateToProps)(SocialLayout);
