/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import ContentLayout from "../../../components/App/Layout/ContentLayout/ContentLayout";
import SingleColumnLayout from "../../../components/App/Layout/ContentLayout/SingleColumnLayout/SingleColumnLayout";
import MainContent from "../../../components/App/Layout/ContentLayout/MainContent/MainContent";
import classes from "./Referral.module.css";
import axios from "../../../axios/axios-instance";
import Error from "../../../components/UI/Error/Error";
import OfficeSpinner from "../../../components/UI/Spinner/OfficeSpinner/OfficeSpinner";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import { Link } from "react-router-dom";
import { getRegistrationFee } from "../../../util/util";

class Referral extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
    this.taskBottomRef = React.createRef();
    this.referralInputRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (this.taskBottomRef.current) {
      this.taskBottomRef.current.style.width =
        this.taskBottomRef.current.offsetWidth + "px";
      this.taskBottomRef.current.style.position = "fixed";
    }
    const write_up = this.props.page_states[this.props.location.pathname];
    if (!write_up) {
      this.loadWriteUp();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  copyReferralLink = (event) => {
    var referralInputText = this.referralInputRef.current;
    referralInputText.select();
    document.execCommand("Copy");
    window.$(event.target).html("Copied");
  };

  loadWriteUp = () => {
    const userToken = this.props.userToken;
    axios({
      method: "get",
      url: `/social/write-up?link=social-referrer-write-up.html`,
      headers: {
        Authorization: userToken ? userToken.token : null,
      },
    })
      .then((response) => {
        if (!this._isMounted) return;
        const write_up = response.data.data.write_up;
        const page_states = {...this.props.page_states};
        page_states[this.props.location.pathname] = write_up;
        this.props.setPageStates(page_states);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              loading: false,
              error: data.error[0].msg,
            });
          } else {
            this.setState({
              loading: false,
              error: data.error,
            });
          }
        } else {
          const errorMsg =
            error.message === "Network Error"
              ? window.ERROR_CONNECTION
              : error.message;
          this.setState({
            loading: false,
            error: errorMsg,
          });
        }
      });
    this.setState({
      loading: true,
      error: null,
    });
  };

  render() {
    let loadingContent = null;
    if (this.state.loading) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <OfficeSpinner size="3" />
        </div>
      );
    }
    if (this.state.error) {
      loadingContent = (
        <div className={classes.PostLoading}>
          <Error refresh={this.loadWriteUp} error={this.state.error} />
        </div>
      );
    }

    let content = loadingContent;

    const page_states = {...this.props.page_states};
    const write_up = page_states[this.props.location.pathname];

    if (write_up) {
      content = (
        <div
          dangerouslySetInnerHTML={{ __html: write_up }}
          className={classes.WriteUp}
        ></div>
      );
    }

    return (
      <ContentLayout>
        <SingleColumnLayout>
          <MainContent>
            <div
              className={`${classes.Referral} ${
                this.props.user && this.props.user.is_member
                  ? classes.NoPadding
                  : ""
              }`}
            >
              <div className={classes.ReferralHeading}>
                <p>My Referral Link:</p>
                {this.props.user && this.props.user.is_member ? (
                  <>
                    <div className="input-group my-1">
                      <input
                        type="text"
                        ref={this.referralInputRef}
                        className="form-control f-12"
                        value={`https://hawkit.ng/#/ref/${this.props.user.username}`}
                        readOnly
                      />
                      <div className="input-group-append">
                        <button
                          onClick={this.copyReferralLink}
                          className="btn btn-warning btn-xs"
                          type="submit"
                        >
                          COPY
                        </button>
                      </div>
                    </div>
                    <p>
                      Or You can tell your referral to use your username{" "}
                      <b>({this.props.user.username})</b> under the referral
                      section at the point of registration.
                    </p>
                  </>
                ) : (
                  <div className="alert alert-danger mb-0 mt-1 py-1 px-3">
                    <p className="mb-0">
                      You must activate your account by paying a one-time
                      membership fee of{" "}
                      {getRegistrationFee(this.props.user, this.props.meta)}{" "}
                      before you can get your referral link and start earning.
                      Click <Link to="/earn/member">here</Link> to see why you
                      should pay a membership fee.
                    </p>
                  </div>
                )}
              </div>
              {!this.props.user || !this.props.user.is_member ? (
                <div ref={this.taskBottomRef} className={classes.TaskBottom}>
                  <div className={classes.TaskBottomText}>
                    <p>Membership Fee</p>
                    <h5>
                      {getRegistrationFee(this.props.user, this.props.meta)}
                    </h5>
                  </div>
                  <div className={classes.TaskBottomAction}>
                    <Link
                      to="/earn/member"
                      className="btn btn-outline d-block btn-block"
                    >
                      BECOME A MEMBER NOW AND START EARNING
                    </Link>
                  </div>
                </div>
              ) : null}
              {content}
            </div>
          </MainContent>
        </SingleColumnLayout>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    userToken: state.authState.userToken,
    page_states: state.pageState.page_states,
    meta: state.userState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageStates: (page_states) =>
      dispatch(actionCreators.setPageStates(page_states)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
