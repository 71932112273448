import React from "react";
import classes from "./Input.module.css";

const input = (props) => {
  const inputClasses = [classes.Input, "form-control border-0"];
  if (props.invalid) {
    inputClasses.push("is-invalid");
  }
  let hint = null;
  let error = null;
  if (props.hint) {
    hint = <small className="form-text text-muted">{props.hint}</small>;
  }
  if (props.invalid) {
    error = <div className="d-block invalid-feedback">{props.invalid}</div>;
  }
  let inputElement = (
    <React.Fragment>
      <input
        type={props.type ? props.type : "text"}
        className={inputClasses.join(" ")}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.changeHandler}
        readOnly={props.readOnly}
        disabled={props.disabled}
        id={props.id}
        autoComplete="new-password"
      />
    </React.Fragment>
  );
  if (props.sideStyle) {
    inputElement = (
      <div
        readOnly={props.readOnly}
        className={`${classes.InputGroup} input-group`}
      >
        <div className="input-group-prepend">
          <span
            style={{ backgroundColor: "inherit" }}
            className="input-group-text border-0"
          >
            {props.sideStyle.startsWith("fa") ? (
              <span className={props.sideStyle} />
            ) : (
              props.sideStyle
            )}
          </span>
        </div>
        {inputElement}
        {props.type === "password" ? (
          <div className="input-group-append">
            <span
              onClick={togglePasswordField}
              className={`${classes.PasswordEye} input-group-text border-0`}
            >
              <span className="fa fa-eye-slash" />
            </span>
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      {inputElement}
      {error ? error : hint}
    </div>
  );
};

const togglePasswordField = (event) => {
  const current = window.$(event.target);
  const inputEl = current.parents(".input-group").find("input");
  if (inputEl.attr("type") === "password") {
    inputEl.attr("type", "text");
    current.html("<span class='fa fa-eye' />");
  } else {
    inputEl.attr("type", "password");
    current.html("<span class='fa fa-eye-slash' />");
  }
};

export default input;
