import React, { Component } from "react";
import classes from "./Verify.module.css";
import VerifyForm from "./VerifyForm/VerifyForm";
import LogoImage from "../../../components/UI/Images/LogoImage/LogoImage";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import { Redirect } from "react-router-dom";
import axios from "../../../axios/axios-instance";
import { Helmet } from "react-helmet";

class Verify extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      verifyError: null,
      init: ""
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.setVerifyPhone(null);
  }

  submitVerifyHandler = token => {
    axios({
      method: "put",
      url: "/auth/phone/confirm",
      data: {
        otp: token,
        phoneNumber: this.props.verifyPhone.otp.phoneNumber,
        referrer: this.props.verifyPhone.referrer,
        name: this.props.verifyPhone.name
      }
    })
      .then(response => {
        if (!this._isMounted) return;
        this.setState({
          isSubmitting: false
        });
        const completeSignUp = response.data.data;
        this.props.setCompleteSignUp(completeSignUp);
        this.props.history.replace("/auth/signup");
      })
      .catch(error => {
        if (!this._isMounted) return;
        if (error.response) {
          const data = error.response.data;
          if (Array.isArray(data.error)) {
            this.setState({
              verifyError: data.error[0].msg,
              isSubmitting: false
            });
          } else {
            this.setState({
              verifyError: data.error,
              isSubmitting: false
            });
          }
        } else {
          this.setState({
            verifyError:
              error.message === "Network Error"
                ? window.ERROR_CONNECTION
                : error.message,
            isSubmitting: false
          });
        }
      });
    this.setState({
      verifyError: null,
      isSubmitting: true
    });
  };

  render() {
    let content = <Redirect to="/" />;
    if (this.props.verifyPhone) {
      content = (
        <div className={classes.Verify}>
          <Helmet>
            <title>Verify Account</title>
          </Helmet>
          <div className={classes.VerifyHeader}>
            <div className={classes.Signup}>
              <Link
                className="btn btn-coloured-heavy btn-rounded"
                to="/auth/register"
              >
                <span className="fa fa-caret-left"></span> Back
              </Link>
            </div>
          </div>
          <div className={classes.VerifyContent}>
            <div className={classes.VerifyForm}>
              <LogoImage />
              <VerifyForm
                isSubmitting={this.state.isSubmitting}
                country={this.props.country}
                verifyError={this.state.verifyError}
                init={this.state.init}
                submit={this.submitVerifyHandler}
                resendSMS={this.resendSMSHandler}
                verifyPhone={this.props.verifyPhone}
              />
            </div>
          </div>
        </div>
      );
    }
    return content;
  }
}

const mapStateToProps = state => {
  return {
    verifyPhone: state.authState.verifyPhone,
    country: state.authState.country
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setVerifyPhone: verifyPhone =>
      dispatch(actionCreators.setVerifyPhone(verifyPhone)),
    setCompleteSignUp: completeSignUp =>
      dispatch(actionCreators.setCompleteSignUp(completeSignUp))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Verify);
