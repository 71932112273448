import React from "react";
import classes from "./ProductPostImage.module.css";
import LazyImage from "../../../../../hoc/LazyImage/LazyImage";

const ProductPostImage = props => {
  return (
    <div onClick={props.clicked} className={classes.ProductPostImage}>
      <LazyImage src={props.image.src} size="3" />
    </div>
  );
};

export default ProductPostImage;
