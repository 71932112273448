import React from "react";
import classes from "./MarketMenuModal.module.css";
import { withRouter } from "react-router-dom";
import marketIcon from "../../../../assets/images/market-icon.png";
import socialIcon from "../../../../assets/images/social-icons.jpg";

const MarketMenuModal = (props) => {
  const closeHandler = (event) => {
    event.preventDefault();
    window.$("#MarketMenuModal").off("hidden.bs.modal");
    window.$("#MarketMenuModal").modal("hide");
  };

  const showSellPage = (event) => {
    event.preventDefault();
    window.$("#MarketMenuModal").off("hidden.bs.modal");
    window.$("#MarketMenuModal").on("hidden.bs.modal", (event) => {
      props.history.push("/shop/sell");
    });
    window.$("#MarketMenuModal").modal("hide");
  };

  const showSocialPage = (event) => {
    event.preventDefault();
    window.$("#MarketMenuModal").off("hidden.bs.modal");
    window.$("#MarketMenuModal").on("hidden.bs.modal", (event) => {
      props.history.push("/social/buy/pricing");
    });
    window.$("#MarketMenuModal").modal("hide");
  };

  return (
    <div className={classes.MarketMenuModal}>
      <div
        id="MarketMenuModal"
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`${classes.MarketMenuModalContent} modal-content`}>
            <div
              id="MarketMenuModalBody"
              className={`${classes.MarketMenuModalBody} modal-body`}
            >
              <button onClick={closeHandler} className={classes.CloseButton}>
                <span className="fas fa-times"></span>
              </button>
              <div
                className={`${classes.SellModalContainer} d-flex flex-column align-items-center`}
              >
                <div className={classes.SellTitle}>
                  <h4>Choose your advert package below:</h4>
                </div>
                <div className="container-fluid">
                  <div className="row no-gutters">
                    <div className="col-6 mb-2">
                      <div className={`${classes.Task} mr-2`}>
                        <div className={classes.TaskHeading}>
                          <h6>Advertise on Hawkit Market</h6>
                        </div>
                        <div className={classes.TaskContent}>
                          <div className={classes.TaskIcon}>
                            <img alt="social-icon" src={marketIcon} />
                          </div>
                          <p className="f-10">
                            Take massive advantage of the huge traffic on Hawkit
                            and advertise your products/services directly to
                            thousands of buyers on Hawkit Market.
                          </p>
                        </div>
                        <div className={classes.TaskFooter}>
                          <p>
                            <button
                              onClick={showSellPage}
                              className="btn btn-outline mb-1"
                            >
                              GET STARTED
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-2">
                      <div className={`${classes.Task}`}>
                        <div className={classes.TaskHeading}>
                          <h6>Advertise On Social Media</h6>
                        </div>
                        <div className={classes.TaskContent}>
                          <div className={classes.TaskIcon}>
                            <img alt="social-icon" src={socialIcon} />
                          </div>
                          <p className="f-10">
                            Get people with atleast 1000 active followers to
                            repost your adverts and perform certain social tasks
                            for you on their social media accounts.
                          </p>
                        </div>
                        <div className={classes.TaskFooter}>
                          <p>
                            <button
                              onClick={showSocialPage}
                              className="btn btn-outline mb-1"
                            >
                              GET STARTED
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(MarketMenuModal);
