import React from "react";
import { Link } from "react-router-dom";
import classes from "./UserHomeWallet.module.css";
import { connect } from "react-redux";

const UserHomeWallet = (props) => {
  let total_pending_orders = 0;
  if (props.meta && props.meta.pending_orders) {
    total_pending_orders += props.meta.pending_orders;
  }
  if (props.meta && props.meta.pending_purchase_orders) {
    total_pending_orders += props.meta.pending_purchase_orders;
  }

  let total_sales_orders = 0;

  if (props.user && props.user.order && props.user.order.sales_action > 0) {
    total_sales_orders += props.user.order.sales_action;
  }

  return (
    <div className={classes.UserHomeWallet}>
      <div className={classes.WalletHeading}>
        <p>Welcome, {props.user.name.split(" ")[0]}</p>
        <p className="ml-auto">
          <Link to="/logout">
            <i className="fas fa-power-off"></i> Logout
          </Link>
        </p>
      </div>
      {total_sales_orders ? (
        <div className="alert alert-info m-0 p-2 rounded-0">
          <p className="f-10">
            You have {total_sales_orders} orders for your products that require
            your immediate or that are awaiting delivery. Click{" "}
            <Link to="/orders/sales">here</Link> to monitor your product orders
            and take the required action
          </p>
        </div>
      ) : null}
      {total_pending_orders ? (
        <div className="alert alert-danger m-0 p-2 rounded-0">
          <p className="f-10">
            You have {total_pending_orders} pending orders that are awaiting
            payments. Click <Link to="/orders">here</Link> to make payments for
            your orders now.
          </p>
        </div>
      ) : null}
      <div className={classes.WalletContent}>
        <p className="f-12 mb-1">My Balance</p>
        <h1>
          {props.user.country.currency.symbol}
          {props.user.wallet.shopping
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </h1>
        <p>
          <Link to="/funding" className="btn btn-outline mr-2">
            <i className="fas fa-wallet"></i> Fund
          </Link>
          <Link to="/withdraw" className="btn btn-outline">
            <i className="fas fa-share-square"></i> Withdraw
          </Link>
        </p>
        <p>
          <Link className="f-10" to="/airtime">
            <i className="fas fa-share"></i> Buy Airtime/Data
          </Link>
        </p>
      </div>
      <div className={classes.WalletFooterContainer}>
        <div className="row no-gutters">
          <div className="col-4">
            <div className={classes.WalletFooterContent}>
              <p>
                <i className="fas fa-list"></i> Total Earnings
              </p>
              <h6>
                {props.user.country.currency.symbol}
                {props.meta.total_earnings
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h6>
            </div>
          </div>
          <div className="col-4">
            <div className={classes.WalletFooterContent}>
              <p>
                <i className="fas fa-wallet"></i> Pending Balance
              </p>
              <h6>
                {props.user.country.currency.symbol}
                {props.user.wallet.locked
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h6>
            </div>
          </div>
          <div className="col-4">
            <div className={classes.WalletFooterContent}>
              <p>
                <i className="fas fa-share-square"></i> Amount Spent
              </p>
              <h6>
                {props.user.country.currency.symbol}
                {props.meta.total_spent
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    meta: state.userState.meta,
  };
};

export default connect(mapStateToProps, null)(UserHomeWallet);
