/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import classes from "./Comment.module.css";
import UserPhoto from "../../../User/UserPhoto/UserPhoto";
import PostOptions from "../../PostOptions/PostOptions";
// import CommentEditor from "../../../../../containers/App/Create/CommentEditor/CommentEditor";
import { abbreviateNumber } from "../../../../../util/util";
import axios from "../../../../../axios/axios-instance";
import { connect } from "react-redux";
import * as actionCreators from "../../../../../store/actions/actions";
// import OfficeSpinner from "../../../../UI/Spinner/OfficeSpinner/OfficeSpinner";
// import Reply from "./Reply";
import { withRouter } from "react-router-dom";

const moment = require("moment");

let is_Mounted = false;

const Comment = (props) => {
  // const [replyTo, setReplyTo] = useState(props.comment.user.username);
  const [replyState] = useState({
    replies: [],
    reply: false,
    loadingReplies: false,
  });
  // const [pages, setPages] = useState(null);
  const [state, setState] = useState({
    likes: {
      count: props.comment.likes,
      me: props.comment.likes_count,
    },
    loading: false,
  });
  const now = moment();
  const postMoment = moment(props.comment.created_on);
  const daysDiff = now.diff(postMoment, "days");
  let formatDate;
  if (daysDiff >= 1) {
    formatDate = postMoment.calendar();
  } else {
    formatDate = postMoment.fromNow();
  }
  const dateRef = useRef(null);
  const locationRef = useRef(null);
  const usernameRef = useRef(null);

  useEffect(() => {
    is_Mounted = true;
    const decrement = 64;
    const titleWidth =
      document.getElementById("postContent").offsetWidth - decrement;
    const usernameWidth = usernameRef.current.offsetWidth;
    dateRef.current.style.width = titleWidth - usernameWidth - 15 + "px";
    locationRef.current.style.width = titleWidth - 5 + "px";
  }, []);

  let likesContent = (
    <>
      {state.likes.me ? (
        <>
          {state.loading ? (
            <span className="fas fa-spin fa-spinner"></span>
          ) : (
            <span className="fas fa-heart"></span>
          )}{" "}
          Unlike
        </>
      ) : (
        <>
          {state.loading ? (
            <span className="fal fa-spin fa-spinner"></span>
          ) : (
            <span className="fal fa-heart"></span>
          )}{" "}
          Like
        </>
      )}
    </>
  );

  // const fetchReplies = (isRefresh = true) => {
  //   let page = 1;
  //   if (pages && pages.next && !isRefresh) {
  //     page = pages.next;
  //   }
  //   const userToken = props.userToken;
  //   if (isRefresh) {
  //     setPages(null);
  //     setReplyState({
  //       ...replyState,
  //       replies: [],
  //       reply: true,
  //       loadingReplies: true,
  //     });
  //   } else {
  //     setReplyState({
  //       ...replyState,
  //       reply: true,
  //       loadingReplies: true,
  //     });
  //   }
  //   axios({
  //     method: "get",
  //     url: `/posts/${props.comment._id}/comments?page=${page}`,
  //     headers: {
  //       Authorization: userToken ? userToken.token : null,
  //     },
  //   })
  //     .then((response) => {
  //       if (!is_Mounted) return;
  //       const replies = response.data.data.comments;
  //       const pages = response.data.data.pages;
  //       let newReplies;
  //       if (!isRefresh) {
  //         const filteredReplies = replies.filter((el) => {
  //           return !replyState.replies.find((reply) => reply._id === el._id);
  //         });
  //         newReplies = [...replyState.replies, ...filteredReplies];
  //       } else {
  //         newReplies = replies;
  //       }
  //       setPages(pages);
  //       setReplyState({
  //         reply: true,
  //         loadingReplies: false,
  //         replies: newReplies,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const viewUserHandler = (event) => {
    event.preventDefault();
    props.history.push(`/users/${props.comment.user.username}`);
  };

  // const replyHandler = (event) => {
  //   event.preventDefault();
  //   if (!replyState.replies.length) {
  //     fetchReplies();
  //   }
  //   const replyTo = `${props.comment.user.username}-${Date.now()}`;
  //   setReplyTo(replyTo);
  //   // document.getElementById(`Editor_${props.comment._id}`).focus();
  // };

  // const setActiveReply = (username, event) => {
  //   event.preventDefault();
  //   const replyTo = `${username}-${Date.now()}`;
  //   setReplyTo(replyTo);
  // };

  const toggleLikes = (event) => {
    if (!props.user) props.history.push("/auth/login");
    event.preventDefault();
    if (!state.loading) {
      setState({
        ...state,
        loading: true,
      });
      const method = state.likes.me ? "delete" : "post";
      const userToken = props.userToken;
      axios({
        method: method,
        url: `/posts/${props.comment._id}/likes`,
        headers: {
          Authorization: userToken.token,
        },
      })
        .then((response) => {
          if (!is_Mounted) return;
          const result = response.data.data;
          const likes = {};
          if (result.type === "like") {
            likes.me = true;
          } else {
            likes.me = false;
          }
          likes.count = result.total.likes;
          setState({
            ...state,
            loading: false,
            likes: likes,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const showLikes = (event) => {
    event.preventDefault();
    props.setPostModal({
      title: "Likes",
      id: props.comment._id,
      link: "likes",
    });
  };

  // const loadMoreReplies = (event) => {
  //   event.preventDefault();
  //   fetchReplies(false);
  // };

  // let loadingContent = null;
  // if (replyState.loadingReplies) {
  //   loadingContent = (
  //     <div className="py-3">
  //       <OfficeSpinner size="3" />
  //     </div>
  //   );
  // } else if (pages && pages.next) {
  //   loadingContent = (
  //     <div className="py-2 text-center">
  //       <a onClick={loadMoreReplies} href="/">
  //         View More Replies <span className="fa fa-chevron-down"></span>
  //       </a>
  //     </div>
  //   );
  // }
  // let repliesContent = null;
  // if (replyState.replies.length) {
  //   repliesContent = replyState.replies.map((reply) => {
  //     return (
  //       <div key={reply._id} className={classes.Replies}>
  //         <Reply replyHandler={setActiveReply} reply={reply} />
  //       </div>
  //     );
  //   });
  // }

  // const addReplyHandler = () => {
  //   // event.preventDefault();
  //   fetchReplies();
  // };

  const commentContent =
    "<p>" +
    props.comment.text.replace(/\n{2,}/g, "</p><p>").replace(/\n/g, "<br>") +
    "</p>";

  const userAddress = [];

  if (props.comment.user.location) {
    if (props.comment.user.location.lga) {
      userAddress.push(props.comment.user.location.lga);
    }
    if (props.comment.user.location.state) {
      userAddress.push(props.comment.user.location.state);
    }
    userAddress.push(props.comment.user.country.name);
  }

  return (
    <div className={classes.Comment}>
      <UserPhoto
        photo={props.comment.user.profile_pic}
        width="24"
        height="24"
      />
      <div className={classes.CommentDetail}>
        <div className={classes.CommentName}>
          <div className={classes.Name}>
            <div className={classes.MainTitle}>
              <a onClick={viewUserHandler} ref={usernameRef} href="/">
                <h4>{props.comment.user.username}</h4>
              </a>
              <p ref={dateRef}>
                <span className="fal fa-clock"></span> {formatDate}
              </p>
            </div>
            <PostOptions
              isComment
              activeUser={props.comment.user}
              post={props.comment}
              size="18"
            />
          </div>
          <div className={classes.Handle}>
            <p ref={locationRef}>
              <span className="fal fa-map-marker-alt"></span>{" "}
              {userAddress.join(", ")}
            </p>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: commentContent }}
          className={classes.CommentText}
        ></div>
        <div className={classes.CommentEngagement}>
          {props.user ? (
            <div className={classes.EngageActions}>
              <a onClick={toggleLikes} href="/">
                {likesContent}
              </a>
              {/* <a onClick={replyHandler} href="/">
                <span className="far fa-comment-alt"></span> Reply
              </a> */}
            </div>
          ) : (
            <div className={classes.EngageActions}>
              <p>
                <i className="fal fa-heart"></i> Login to Like and Reply
              </p>
            </div>
          )}
          <div className={classes.EngageStats}>
            <a onClick={showLikes} href="/" className={classes.LikeLink}>
              {state.likes.me ? (
                <i className="fas fa-heart"></i>
              ) : (
                <i className="far fa-heart"></i>
              )}{" "}
              {abbreviateNumber(state.likes.count)}
            </a>
            {/* <a onClick={replyHandler} href="/" className={classes.ReplyLink}>
              <i className="far fa-comment-alt"></i>{" "}
              {abbreviateNumber(props.comment.comments)}{" "}
              <span className="f-10">Replies</span>
            </a> */}
          </div>
        </div>
        {replyState.reply ? (
          <div className={classes.CommentReplies}>
            {/* {repliesContent} */}
            {/* {loadingContent} */}
            {/* <div className={classes.ReplyEditor}>
              {props.user ? (
                <CommentEditor
                  id={`Editor_${props.comment._id}`}
                  replyTo={`@${replyTo}`}
                  submit={addReplyHandler}
                  post={props.comment}
                  photoSize="24"
                  label="Write a Reply..."
                />
              ) : null}
            </div> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.authState.userToken,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPostModal: (postModal) =>
      dispatch(actionCreators.setPostModal(postModal)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Comment));
